import { Doc, DocSource } from "documents";
import _ from "lodash";
import { User, Permissions } from "users";

function userCannotWorkOnDocumentOutsideTheirProducts(document: Doc, user: User) {
   if (
      document.products.length > 0 &&
      _.intersection(
         document.products.map((p) => p.id),
         user.products.map((p) => p.id),
      ).length === 0 &&
      !user.permissions.includes(Permissions.PostEditDeleteDocumentsOutsideYourProducts)
   ) {
      return true;
   } else return false;
}

export function userCanEditDocument(user: User, doc: Doc) {
   if (doc.source === undefined || doc.uploadedById === undefined || doc.organizationId === undefined || doc.products === undefined) {
      throw new Error("The info required to determine whether you have permission to edit a document has not been loaded.");
   }

   if (doc.type.name == "Administration Form" && user.permissions.includes(Permissions.PostEditDeleteAdministrationForms)) {
      return true;
   } else if (doc.type.name == "Administration Form") {
      return false;
   }

   if (user.isWyth) {
      if (doc.source === DocSource.Partner && !user.permissions.includes(Permissions.EditPartnerPostedDocuments)) {
         return false;
      }

      if (
         doc.source === DocSource.Wyth &&
         doc.uploadedById !== user.id &&
         !user.permissions.includes(Permissions.EditWythPostedDocuments)
      ) {
         return false;
      }

      if (doc.type.isRestrictedForWyth && !user.permissions.includes(Permissions.UseRestrictedDocumentTypes)) {
         return false;
      }
   } else {
      if (user.organizationId !== doc.organizationId) {
         return false;
      }

      if (doc.source === DocSource.Wyth && !user.permissions.includes(Permissions.EditWythPostedDocumentsForYourOrganization)) {
         return false;
      }

      if (
         doc.source === DocSource.Partner &&
         doc.uploadedById !== user.id &&
         !user.permissions.includes(Permissions.EditDocumentsPostedByYourOrganization)
      ) {
         return false;
      }
   }

   const forbiddenProduct = userCannotWorkOnDocumentOutsideTheirProducts(doc, user);
   if (forbiddenProduct) return false;

   return true;
}

export function userCanDeleteDocument(user: User, doc: Doc) {
   if (doc.source === undefined || doc.uploadedById === undefined || doc.products === undefined) {
      throw new Error("The info required to determine whether you have permission to delete a document has not been loaded.");
   }

   if (doc.uploadedById === user.id && !user.permissions.includes(Permissions.DeleteOwnDocuments)) {
      return false;
   }

   if (doc.type.name == "Administration Form" && user.permissions.includes(Permissions.PostEditDeleteAdministrationForms)) {
      return true;
   } else if (doc.type.name == "Administration Form") {
      return false;
   }

   if (user.isWyth) {
      if (doc.source === DocSource.Partner && !user.permissions.includes(Permissions.DeletePartnerPostedDocuments)) {
         return false;
      }

      if (
         doc.source === DocSource.Wyth &&
         doc.uploadedById !== user.id &&
         !user.permissions.includes(Permissions.DeleteWythPostedDocuments)
      ) {
         return false;
      }

      if (doc.type.isRestrictedForWyth && !user.permissions.includes(Permissions.UseRestrictedDocumentTypes)) {
         return false;
      }
   } else {
      if (
         doc.source === DocSource.Partner &&
         doc.uploadedById !== user.id &&
         !user.permissions.includes(Permissions.DeleteDocumentsPostedByYourOrganization)
      ) {
         return false;
      }

      if (doc.source === DocSource.Wyth && !user.permissions.includes(Permissions.DeleteWythPostedDocumentsForYourOrganization)) {
         return false;
      }
   }

   const forbiddenProduct = userCannotWorkOnDocumentOutsideTheirProducts(doc, user);
   if (forbiddenProduct) return false;

   return true;
}

export function userCanReplaceFile(user: User, doc: Doc) {
   if (doc.source === undefined || doc.uploadedById === undefined || doc.organizationId === undefined || doc.products === undefined) {
      throw new Error(
         "The info required to determine whether you have permission to replace the file for this document has not been loaded.",
      );
   }

   if (doc.uploadedById === user.id && !user.permissions.includes(Permissions.ReplaceFileInOwnDocuments)) {
      return false;
   }

   if (user.isWyth) {
      if (doc.source === DocSource.Partner && !user.permissions.includes(Permissions.ReplaceFileInPartnerPostedDocuments)) {
         return false;
      }

      if (
         doc.source === DocSource.Wyth &&
         doc.uploadedById !== user.id &&
         !user.permissions.includes(Permissions.ReplaceFileInWythPostedDocuments)
      ) {
         return false;
      }

      if (doc.type.isRestrictedForWyth && !user.permissions.includes(Permissions.UseRestrictedDocumentTypes)) {
         return false;
      }
   } else {
      if (doc.source === DocSource.Wyth) return false;
      else if (doc.organizationId !== user.organizationId) return false;
      else if (doc.uploadedById !== user.id && !user.permissions.includes(Permissions.ReplaceFileInDocumentsPostedByYourOrganization)) {
         return false;
      }
   }

   const forbiddenProduct = userCannotWorkOnDocumentOutsideTheirProducts(doc, user);
   if (forbiddenProduct) return false;

   return true;
}

export function userCanAssignProductsToUser(
   user: User, userHasPermission: (permission: Permissions) => boolean, userToAssignTo: User
) {
   if (user.isWyth) {
      return userHasPermission(
         userToAssignTo.isWyth ? Permissions.AssignProductsToWythUsers : Permissions.AssignProductsToPartnerUsers,
      );
   } else {
      if (user.organizationId !== userToAssignTo.organizationId) return false;

      if (user.organizationId === userToAssignTo.organizationId && userHasPermission(Permissions.AssignProductsToUsersInYourOrganization)) {
         return true;
      }

      return false;
   }
}

export function userCanAssignRolesToUser(
   user: User, userHasPermission: (permission: Permissions) => boolean, userToAssignTo: User
) {
   if (user.isWyth) {
      return userHasPermission(Permissions.AssignRolesToUsersInAnyOrganization);
   } else {
      if (user.organizationId !== userToAssignTo.organizationId) return false;

      if (user.organizationId === userToAssignTo.organizationId && userHasPermission(Permissions.AssignRolesToUsersInYourOrganization)) {
         return true;
      }

      return false;
   }
}

export function userCanUpdateUserIdentity(
   user: User, userHasPermission: (permission: Permissions) => boolean, userToAssignTo: User
) {
   if (user.isWyth) {
      return userHasPermission(Permissions.ManageUsersInAnyOrganization);
   } else {
      if (user.organizationId !== userToAssignTo.organizationId) return false;

      if (user.organizationId === userToAssignTo.organizationId && userHasPermission(Permissions.ManageUsersInYourOrganization)) {
         return true;
      }

      return false;
   }
}

