import { useQuery, useMutation, gql } from "@apollo/client";
import {   Grid, Box, Typography, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "users/useCurrentUser";
import _ from "lodash";
import { Product } from "products";
import { useNotifications } from "notifications";
import CreateListservDialog from "./CreateListservDialog";
import MaterialTable from "@material-table/core";
import { Autocomplete } from "@mui/material";
import { Listserv, ListservInput } from "./models";
import { announcementStyles } from "announcements";
import { Permissions } from "users";
import clsx from "clsx";
import HtmlEditor from "announcements/HtmlEditor";
import DOMPurify from "dompurify";
import { listservPropertiesFragment } from ".";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
   }),
);

export const ListservScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { userHasPermission } = useCurrentUser();

   const [creatingListserv, setCreatingListserv] = useState(false);

   const optionsQuery = useQuery<{ products: Product[] }, { includeAllProducts: boolean }>(
      gql`
         query GetListservScreenOptions($includeAllProducts: Boolean) {
            products(includeAllProducts: $includeAllProducts) {
               id
               name
               program {
                  id
                  name
               }
            }
         }
      `,
      {
         variables: {
            includeAllProducts: userHasPermission(Permissions.ManageListservs),
         },
      },
   );

   const productOptions = _.orderBy(optionsQuery.data?.products, (p) => p.name.toLowerCase()) ?? [];

   const editListservPropertiesFragment = gql`
      fragment editListservProperties on Listserv {
         ...listservProperties
      }
      ${listservPropertiesFragment}
   `;

   const allListservsQuery = useQuery<{ listservs: Listserv[] }>(
      gql`
         query GetListservsForListservScreen {
            listservs {
               ...editListservProperties
            }
         }
         ${editListservPropertiesFragment}
      `,
   );

   const [editListservMutate] = useMutation<{ listserv: { update: Listserv } }, { listserv: ListservInput }>(
      gql`
         mutation UpdateListserv($listserv: ListservInput!) {
            listserv {
               update(listserv: $listserv) {
                  ...editListservProperties
               }
            }
         }
         ${editListservPropertiesFragment}
      `,
      {
         refetchQueries: ["GetListservsForListservScreen"],
      },
   );

   async function editListserv(updatedListserv: Listserv) {
      if (updatedListserv.products.length === 0) return false;

      const listservInput = {
         id: updatedListserv.id,
         listName: updatedListserv.listName,
         title: updatedListserv.title,
         htmlContent: updatedListserv.htmlContent,
         productIds: updatedListserv.products.map((p) => p.id),
      };
      const updateResult = await editListservMutate({ variables: { listserv: listservInput } });

      if (updateResult.data?.listserv.update) {
         notifications.success("Listserv updated.");
      }
   }

   const [deleteListservMutate] = useMutation<{ listserv: { delete: Listserv } }, { id: number }>(
      gql`
         mutation DeleteListserv($id: Int!) {
            listserv {
               delete(id: $id) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetListservsForListservScreen"],
      },
   );

   async function deleteListserv(listserv: Listserv) {
      const deleteResult = await deleteListservMutate({ variables: { id: listserv.id } });

      if (deleteResult.data?.listserv.delete) {
         notifications.success("Listserv deleted.");
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Listservs - Concentra Partner Portal</title>
         </Helmet>

         <Grid container direction="column" spacing={6}>
            {userHasPermission(Permissions.ManageListservs) && (
               <Grid item container direction="column" spacing={3}>
                  <Grid item container spacing={3} justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h3">Listservs</Typography>
                     </Grid>
                     <Grid item>
                        <Button variant="contained" color="secondary" onClick={() => setCreatingListserv(true)}>
                           Add a listserv
                        </Button>
                     </Grid>
                  </Grid>

                  <Grid item>
                     <MaterialTable
                        columns={[
                           {
                              title: "List Name",
                              field: "listName",
                              sorting: true,
                              width: "16em",
                           },
                           {
                              title: "Products",
                              field: "products",
                              filtering: true,
                              customFilterAndSearch: (term, listserv) =>
                                 listserv.products.some((p) => p.name.toLowerCase().indexOf(term.toLowerCase()) > 0),
                              sorting: false,
                              width: "10em",
                              render: (listserv) => {
                                 return (
                                    <>
                                       {listserv.products.length > 0
                                          ? _.orderBy(listserv.products, (p) => p.name.toLowerCase()).map((p) => (
                                               <Box mb={1} key={p.id}>
                                                  {p.name}
                                               </Box>
                                            ))
                                          : "--"}
                                    </>
                                 );
                              },
                              editComponent: (props) => (
                                 <Autocomplete
                                    handleHomeEndKeys={false}
                                    multiple
                                    autoComplete
                                    size="small"
                                    options={_.orderBy(productOptions, (p) => p.name.toLowerCase())}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props: any, product) => (
                                       <div {...props}>
                                       <div>
                                          <Typography>{product.name}</Typography>
                                          <Typography variant="caption" color="textSecondary">
                                             {product.program.name}
                                          </Typography>
                                       </div>
                                       </div>
                                    )}
                                    value={productOptions.filter((po) => props.rowData.products.some((p) => p.id === po.id))}
                                    onChange={(e, newValue) => props.onChange(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Products" />}
                                 />
                              ),
                           },

                           {
                              title: "Title",
                              field: "title",
                              sorting: true,
                              width: "16em",
                           },

                           {
                              title: "Content",
                              field: "htmlContent",
                              sorting: false,
                              width: "24em",
                              render: (listserv) => (
                                 <div
                                    className={clsx("ql-editor", classes.htmlContent)}
                                    dangerouslySetInnerHTML={{
                                       __html: DOMPurify.sanitize(listserv.htmlContent),
                                    }}
                                 />
                              ),
                              editComponent: (props) => (
                                 <HtmlEditor
                                    fieldLabel="Description"
                                    content={props.rowData.htmlContent}
                                    onChangeContent={(newValue) => {
                                       if (newValue !== props.value) {
                                          // Workaround for issue described here: https://github.com/zenoamaro/react-quill/issues/609
                                          props.onChange(newValue);
                                       }
                                    }}
                                 />
                              ),
                           },
                        ]}
                        data={
                           _.orderBy(allListservsQuery.data?.listservs, (ls) => ls.listName?.toLowerCase()).map((ls) => ({
                              ...ls,
                              htmlContent: DOMPurify.sanitize(ls.htmlContent),
                           })) ?? []
                        }
                        options={{ filtering: true, search: false, paging: false, grouping: false, toolbar: false }}
                        editable={{
                           isEditable: (a) => true,
                           isDeletable: (a) => true,
                           onRowUpdate: editListserv,
                           onRowDelete: deleteListserv,
                        }}
                        localization={{
                           body: {
                              editRow: {
                                 deleteText: "Are you sure you want to delete this listserv component?",
                              },
                           },
                        }}
                     />
                  </Grid>
               </Grid>
            )}
         </Grid>

         <CreateListservDialog open={creatingListserv} handleClose={() => setCreatingListserv(false)} products={productOptions} />
      </div>
   );
};
