import { Grid, Typography } from "@mui/material";
import React from "react";
import _ from "lodash";
import DepositRateProductDisplay from "./DepositRateProductDisplay";
import { RateBulletin } from "rates";
import moment from "moment";
import { dateFormat } from "application/formats";

interface Props {
   bulletin: RateBulletin;
}

const DepositRateBulletinDisplay: React.FunctionComponent<Props> = ({ bulletin }) => {
   return (
      <Grid container direction="column" spacing={3}>
         <Grid item>
            <div>
               <Typography variant="h6">Effective date: {moment.utc(bulletin.effectiveDate).format(dateFormat)}</Typography>
            </div>
            {bulletin.expiryDate && (
               <div>
                  <Typography variant="h6">Expiry date: {moment.utc(bulletin.expiryDate).format(dateFormat)}</Typography>
               </div>
            )}
         </Grid>
         {_.orderBy(bulletin.products, (p) => p.order).map((p) => (
            <Grid item key={p.id}>
               <DepositRateProductDisplay product={p} />
            </Grid>
         ))}

         {bulletin.notes && bulletin.notes.length > 0 && (
            <Grid item>
               <Typography variant="h5">Important notes about this bulletin</Typography>
               <ul>
                  {bulletin.notes.map((n) => (
                     <li key={n.id}>{n.note}</li>
                  ))}
               </ul>
            </Grid>
         )}
      </Grid>
   );
};

export default DepositRateBulletinDisplay;
