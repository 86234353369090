import {   Card, CardContent, Typography, Box, Grid, FormGroup, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Collapse, TextField, Tabs, Tab, DialogActions, Button } from "@mui/material";
import React, { useState } from "react";
import { useNotifications } from "../notifications";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AccessReview, AccessReviewInput, accessReviewPropertiesFragment, AccessReviewSchedule, accessReviewSchedulePropertiesFragment, AccessReviewUserOptionsQuery, AccessReviewUserOptionsQueryResult, AccessReviewUserOptionsQueryVariables } from ".";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FormikErrors, ErrorMessage, Form, Field } from "formik";
import Autocomplete from "@mui/material/Autocomplete";

import { Autocomplete as FmuiAutocomplete, AutocompleteRenderInputParams } from "formik-mui";
import SpinnerButton from "../SpinnerButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { User } from "../users";
import _ from "lodash";
import useCurrentUser from "../users/useCurrentUser";
import { UserAccessReviewTable } from "./UserAccessReviewTable";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      reviewCard: {
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(1),

      },
      header: {
         display: "flex",
         alignItems: "flex-start",
         "& > :first-child": {
            flexGrow: 1,
         },
      },
      buttons: {
         marginLeft: "auto",
         position: "relative",
         top: -theme.spacing(1.5),
         right: -theme.spacing(1.5),
      },
      submit: {
         paddingTop: theme.spacing(1),
      },
      collapsed: {
         width: "350px",
         marginTop: theme.spacing(0),
      },
      expanded: {
         width: "100%",
      },
      formError: {
         color: "red",
         display: "block"
      }
   }),
);

interface Props {
   initiallyCollapsed: boolean;
   organizationId: number;
}

interface FormValues {
   response: string;
   alternateUser: User | null;
}

const UserAccessReviewTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const { user, userLoading } = useCurrentUser();

   const notifications = useNotifications();
   const [collapsed, setCollapsed] = useState(props.initiallyCollapsed);
   const [selectedTab, setSelectedTab] = useState(0);

   const initialFormValues: FormValues = {
      response: "",
      alternateUser: null
   };

   const [responseState, setResponseState] = useState(initialFormValues.response);

   const accessReviewUsersQuery = useQuery<AccessReviewUserOptionsQueryResult, AccessReviewUserOptionsQueryVariables>(AccessReviewUserOptionsQuery, {
      variables: {},
   });

   const orgAccessReviewsQuery = useQuery<{ accessReviews: AccessReview[] }, { organizationId: number }>(
      gql`
         query GetAccessReviews($organizationId: Int) {
            accessReviews(organizationId: $organizationId) {
               ...accessReviewProperties
            }
         }
         ${accessReviewPropertiesFragment}
      `,
      { variables: { organizationId: props.organizationId } }
   );

   const accessReviewSchedulesQuery = useQuery<{ accessReviewSchedules: AccessReviewSchedule[] }, {}>(
      gql`
         query GetAccessReviewSchedules {
            accessReviewSchedules {
               ...accessReviewScheduleProperties
            }
         }
         ${accessReviewSchedulePropertiesFragment}
      `,
      { variables: {} }
   );

   const accessReviewSchedules = accessReviewSchedulesQuery.data?.accessReviewSchedules.map(d => ({ ...d })) ?? [];

   const optionsLoaded =
      !accessReviewUsersQuery.loading &&
      !!accessReviewUsersQuery.data &&
      !orgAccessReviewsQuery.loading &&
      !!orgAccessReviewsQuery.data &&
      !accessReviewSchedulesQuery.loading &&
      !!accessReviewSchedulesQuery.data
      ;


   const orgAccessReviews = orgAccessReviewsQuery.data?.accessReviews.map(d => ({ ...d })) ?? [];


   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.response != "true" && values.response != "false") {
         errors.response = "Response required."
      }

      if (values.response == "false" && values.alternateUser == null) {
         errors.alternateUser = "No alternate user selected."
      }

      return errors;
   }


   const [addMutate] = useMutation<{ accessReview: { add: AccessReview } }, { accessReview: AccessReviewInput }>(
      gql`
         mutation AddUserAccessReview($accessReview: AccessReviewInput!) {
            accessReview {
               add(accessReview: $accessReview) {
                  organizationId
                  response
                  alternateUserId
               }
            }
         }
      `,
      {
         refetchQueries: ["GetUserNotices", "GetAccessReviews" ],
         awaitRefetchQueries: true
      },
   );


   async function addAccessReview(values: FormValues, actions: FormikHelpers<FormValues>) {
      const newAccessReview: AccessReviewInput = {
         organizationId: props.organizationId,
         response: responseState == "true" ? true : false,
         alternateUserId: values.alternateUser?.id ?? null,

      };

      const addResult = await addMutate({ variables: { accessReview: newAccessReview } });

      if (addResult.data?.accessReview.add) {
         notifications.success("Access review submitted.");
      }

   }


   const currentReviewTime = Math.max(...accessReviewSchedules.map(a => getTimeMs(a.date)));
   const foundUserResponded = orgAccessReviews.filter(a => getTimeMs(a.date) > currentReviewTime && a.user?.id == user.id).length > 0;
   const foundOrgCompleted = orgAccessReviews.filter(a => getTimeMs(a.date) > currentReviewTime && a.organization?.id == props.organizationId && a.response).length > 0;

   function getTimeMs(date?: Date | string | null) {
      return date != null ? new Date(date).getTime() : 0;
   }

   const validUsersToDelegate = optionsLoaded
      ? _.orderBy(
         accessReviewUsersQuery.data!.users.filter((u) => u.id !== user?.id && u.roles.find((r) => r.name == "Partner administrator")),
         (u) => u.name.toLowerCase(),
      )
      : [];

   function accessReviewStatus() {
      if (accessReviewSchedules.length == 0) {
         return <Typography paragraph>Review period has not yet started.</Typography>;
      } else {

         if (!user.isWyth) {
            if (foundUserResponded) {
               if (foundOrgCompleted) {
                  return <><Typography paragraph>Organization user access review status: Confirmed</Typography><Typography paragraph>Thank you for submitting your response.</Typography></>;
               } else {
                  return <><Typography paragraph>Organization user access review status: Incomplete</Typography><Typography paragraph>Thank you for submitting your response.</Typography></>;
               }
            } else {
               if (foundOrgCompleted) {
                  <><Typography paragraph>Organization user access review status: Confirmed</Typography><Typography paragraph>This user review was completed by another individual.</Typography></>;
               } else {
                  return <>
                     <Typography paragraph>Organization user access review status: Incomplete</Typography>
                     <Typography paragraph>Hi Partner Admins,</Typography>
                     <Typography paragraph>To ensure the security of the Concentra Partner Portal, we require verification that users' names, email addresses, roles, and product assignments in the portal are correct and up to date for your organization.</Typography>
                     <Typography paragraph>Complete confirmation process for your organization within 14 business days. Unverified users will encounter changes to their permissions, which may include deactivation of their account.</Typography>
                  </>;
               }
            }
         } else {
            if (foundOrgCompleted) {
               return <Typography paragraph>Organization user access review status: Confirmed</Typography>;
            } else {
               return <Typography paragraph>Organization user access review status: Incomplete</Typography>;
            }
         }
      }
   };

   return (
         <Card className={[classes.reviewCard, collapsed ? classes.collapsed : classes.expanded].join(' ')} variant="outlined" >
            <CardContent>
               <div className={classes.header}>
                  <Grid container direction="column">
                     <Typography variant="h6">Portal user access review</Typography>
                  </Grid>
                  <div className={classes.buttons}>
                     <IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
                  </div>
               </div>
               <Collapse in={!collapsed} timeout="auto" unmountOnExit>
               <Grid item container direction="column" spacing={3} m={0} >

                  <Box >
                        <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
                           <Tab label="Review status" />
                           <Tab label="Review form" />
                           <Tab label="Review responses" />
                        </Tabs>
                     </Box>

                  {optionsLoaded && (

                     <Box p={2}>
                        {selectedTab === 0 && (
                           <Box>
                              {accessReviewStatus()}
                           </Box>
                        )}

                        {selectedTab === 1 && (
                           <>
                              <Formik
                                 initialValues={initialFormValues}
                                 validate={
                                    (values) => {
                                       const errors: FormikErrors<FormValues> = {};

                                       if (values.response != "true" && values.response != "false") {
                                          errors.response = "Response required."
                                       }

                                       if (values.response == "false" && values.alternateUser == null) {
                                          errors.alternateUser = "No delegated user selected."
                                       }

                                       return errors;
                                    }
                                 }
                                 onSubmit={addAccessReview}
                                 enableReinitialize={false}
                              >
                                 {(formikProps) => {

                                    return (
                                       <Form>
                                             <FormControl component="fieldset">
                                                <Typography paragraph>As a Partner Admin, you can verify the users or assign the verification to another Partner Admin user within your organization.</Typography>
                                                <Typography paragraph>If you are completing the verification of all users, review the list of users below and complete any revisions or deletions required. When complete, click on the first voting button to confirm review has been completed.</Typography>
                                                <Typography paragraph>If you are assigning the review to another Partner Admin within your organization, click on the second voting button and select the delegated reviewer.</Typography>
                                                <Field
                                                   name="response"
                                                   component={RadioGroup}
                                                >
                                                   <FormControlLabel
                                                      key="true"
                                                      value="true"
                                                   onChange={e => {
                                                         formikProps.setErrors({});
                                                         formikProps.setStatus("");
                                                         formikProps.values.response = "true";
                                                         formikProps.values.alternateUser = null;
                                                         setResponseState("true");
                                                      }}
                                                   control={<Radio checked={formikProps.values.response === "true"} />}
                                                      disabled={formikProps.isSubmitting || foundUserResponded || foundOrgCompleted || currentReviewTime <= 0}
                                                      label="I confirm I have reviewed access levels for users in my organization and made any required changes to ensure the right people have the right level of access."
                                                   />

                                                   <FormControlLabel
                                                      key="false"
                                                      value="false"
                                                      onChange={e => {
                                                         formikProps.setErrors({});
                                                         formikProps.setStatus("");
                                                         formikProps.values.response = "false";
                                                         setResponseState("false");
                                                      }}
                                                   control={<Radio checked={formikProps.values.response === "false"} />}
                                                      disabled={formikProps.isSubmitting || foundUserResponded || foundOrgCompleted || currentReviewTime <= 0}
                                                      label="Another admin user in my organization will complete the review (please select below)."
                                                   />
                                                </Field>
                                             </FormControl>

                                          {(responseState == "false") && (
                                             <Grid item container spacing={1}>
                                                <Grid item xs={6}>
                                                   <Field
                                                      name="alternateUser"
                                                      component={FmuiAutocomplete}
                                                      handleHomeEndKeys={false}
                                                      options={validUsersToDelegate}
                                                      filterSelectedOptions
                                                      getOptionLabel={(u: User) => `${u.name} <${u.email}>`}
                                                      hidden={formikProps.values.response != "false"}
                                                      disabled={formikProps.isSubmitting || foundUserResponded || foundOrgCompleted || currentReviewTime <= 0}
                                                      renderInput={(params: AutocompleteRenderInputParams) => (
                                                         <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            label="Select delegated reviewer"
                                                         />
                                                      )}
                                                      onClick={() => {
                                                         formikProps.setErrors({});
                                                         return true;
                                                      }}
                                                   />
                                                </Grid>
                                             </Grid>
                                          )}

                                          <Grid item container spacing={2} className={classes.submit}>
                                             <Grid item xs={6} >
                                                <div className={classes.formError}>
                                                   <ErrorMessage name="response" />
                                                </div>
                                                <div className={classes.formError}>
                                                   <ErrorMessage className={classes.formError} name="alternateUser" />
                                                </div>
                                                <div className={classes.submit}>
                                                   <Button color="secondary" variant="contained" type="submit" disabled={formikProps.isSubmitting || foundUserResponded || foundOrgCompleted || currentReviewTime <= 0}>
                                                      Submit
                                                   </Button>
                                                </div>
                                             </Grid>
                                          </Grid>
                                       </Form>
                                    )
                                 }}
                              </Formik>

                           </>
                        )}
                        {selectedTab === 2 && (
                           <Grid container direction="column" spacing={3}>
                              <Grid item>
                                 {(orgAccessReviews &&
                                    <UserAccessReviewTable showOrganization={false} accessReviews={orgAccessReviews} />
                                 )}
                              </Grid>
                           </Grid>
                        )}
                     </Box>
                  )}
                  </Grid>
               </Collapse>
            </CardContent>
         </Card>
   )
};

export default UserAccessReviewTile;