import React from "react";
import { Menu, MenuItem, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Doc, getDocumentUrl } from "documents";

interface Props {
   getDocument: () => Doc;
   menuAnchorEl: null | Element | ((element: Element) => Element);
   editDocument: () => void;
   userCanEdit: () => boolean;
   addToFavourites?: () => void;
   deleteDocument: () => void;
   handleMenuClose: () => void;
   userCanDelete: () => boolean;
}

const DocumentActionMenu: React.FunctionComponent<Props> = (props) => {
   const navigate = useNavigate();

   function navigateToDocument() {
      const url = getDocumentUrl(props.getDocument());
      navigate(url);
   }

   return (
      <Menu anchorEl={props.menuAnchorEl} keepMounted open={Boolean(props.menuAnchorEl)} onClose={props.handleMenuClose}>
         {props.userCanEdit() && (
            <MenuItem
               onClick={() => {
                  props.editDocument();
                  props.handleMenuClose();
               }}
            >
               Edit
            </MenuItem>
         )}
         <MenuItem
            onClick={() => {
               navigateToDocument();
               props.handleMenuClose();
            }}
         >
            Details
         </MenuItem>
         <MenuItem
            disabled={props.addToFavourites === undefined}
            onClick={() => {
               props.addToFavourites!();
               props.handleMenuClose();
            }}
         >
            Add to favourites
         </MenuItem>
         {props.userCanDelete() && <Divider />}
         {props.userCanDelete() && (
            <MenuItem
               onClick={() => {
                  props.deleteDocument();
                  props.handleMenuClose();
               }}
            >
               Delete
            </MenuItem>
         )}
      </Menu>
   );
};

export default DocumentActionMenu;
