import { DocType, DocStatus, DocSource, Tag, Jurisdiction } from "documents";
import { Product } from "products";
import { User } from "users";
import { Organization } from "organizations";
import { gql } from "@apollo/client";
import { Program } from "programs";
import { Plan } from "../plan";

export const GetDocumentForDocumentScreen = gql`
         query GetDocumentForDocumentScreen($id: Int, $catalogueNumber: String, $wythVersion: Boolean, $orgIdVersion: Int) {
            document(id: $id, catalogueNumber: $catalogueNumber, wythVersion: $wythVersion, orgIdVersion: $orgIdVersion) {
               id
               identifier
               title
               fileName
               url
               type {
                  id
                  name
                  isRestrictedForWyth
                  parent {
                     id
                     name
                     parent {
                        id
                        name
                        parent {
                           id
                           name
                           parent {
                              id
                              name
                              parent {
                                 id
                                 name
                              }
                           }
                        }
                     }
                  }
               }
               date
               uploadDate
               source
               status
               uploadedById
               uploadedBy {
                  id
                  name
                  organization {
                     id
                     name
                  }
               }
               organizationId
               organization {
                  id
                  name
               }
               products {
                  id
                  name
                  program {
                     id
                     name
                  }
               }
               jurisdictions {
                  id
                  name
                  order
               }
               tags {
                  id
                  value
               }
               catalogueNumber
               version
               comments
               plans{
                  id
                  name
               }
               isInactive
            }
         }
      `

export const GetFutureVersions = gql`
         query GetFutureVersions($id: Int, $catalogueNumber: String, $wythVersion: Boolean, $orgIdVersion: Int) {
            documentVersions(id: $id, catalogueNumber: $catalogueNumber, wythVersion: $wythVersion, orgIdVersion: $orgIdVersion) {
               id
               identifier
               title
               fileName
               url
               type {
                  id
                  name
                  isRestrictedForWyth
                  parent {
                     id
                     name
                     parent {
                        id
                        name
                        parent {
                           id
                           name
                           parent {
                              id
                              name
                              parent {
                                 id
                                 name
                              }
                           }
                        }
                     }
                  }
               }
               date
               uploadDate
               source
               status
               uploadedById
               uploadedBy {
                  id
                  name
                  organization {
                     id
                     name
                  }
               }
               organizationId
               organization {
                  id
                  name
               }
               products {
                  id
                  name
                  program {
                     id
                     name
                  }
               }
               jurisdictions {
                  id
                  name
                  order
               }
               tags {
                  id
                  value
               }
               catalogueNumber
               version
               comments
               plans{
                  id
                  name
               }
            }
         }
`

const documentTypeFragment = gql`
   fragment dtProperties on DocumentType {
      id
      parentId
      name
      hasCatalogueNumber
      catalogueNumberRequired
      hasVersion
      versionRequired
      hasJurisdictions
      hasOrganization
      isRestrictedForWyth
      products {
         id
      }
   }
`;

// We will always include the automated users in our result set from the server
// and let each component filter them out if necessary.
// This avoids unnecessary repeat calls to the server with the only difference being excluding the automated users.
export const UserOptionsQuery = gql`
   query GetUserOptions {
      users(includeAutomatedUser: true, includeAllOrganizations: true, includeWythUsers: true) {
         id
         name
         isWyth
         organizationId
         organization {
            id
            name
         }
         isAutomationUser
      }
   }
`;

export const DocumentTypeOptionQuery = gql`
   query GetDocumentTypeOptions($forUpsert: Boolean, $isAdministrationForm: Boolean) {
      documentTypes(forUpsert: $forUpsert, isAdministrationForm: $isAdministrationForm) {
         ...dtProperties
         children {
            ...dtProperties
            children {
               ...dtProperties
               children {
                  ...dtProperties
                  children {
                     ...dtProperties
                     children {
                        ...dtProperties
                     }
                  }
               }
            }
         }
      }
   }
   ${documentTypeFragment}
`;

export const ProductOptionQuery = gql`
   query GetProductOptions($includeAllProducts: Boolean) {
      products(includeAllProducts: $includeAllProducts) {
         id
         name
         program {
            id
            name
         }
         documentTypes {
            id
         }
         plans {
            id
            name
         }
      }
   }
`;

export const SearchFilterProductOptionQuery = gql`
   query GetSearchFilterProductOptions($includeAllProducts: Boolean) {
      searchFilterProducts(includeAllProducts: $includeAllProducts) {
         id
         name
         program {
            id
            name
         }
         documentTypes {
            id
         }
      }
   }
`;

export const MetadataOptionQuery = gql`
   query GetMetadataOptions {
      programs {
         id
         name
      }
      organizations(includeWyth: false) {
         id
         name
         code
         products {
            id
         }
      }
      tags {
         id
         value
      }
      jurisdictions {
         id
         name
         order
      }
      plans {
         id
         name
      }
   }
`;

export const documentInputFragment = gql`
   fragment DocumentStatusInput on Document {
      id
      status
   }
`;

export const UpdateDocumentStatusMutation = gql`
   mutation UpdateDocumentStatus($document: DocumentStatusInput!) {
      document {
         updateStatus(document: $document) {
            id
            status
         }
      }
   }
`;

export const BatchUpdateDocumentStatusMutation = gql`
   mutation BatchUpdateDocumentStatus($documents: [DocumentStatusInput!]!) {
      document {
         batchUpdateStatus(documents: $documents) {
            id
            status
         }
      }
   }
`;

export const DocumentDownloadInfoQuery = gql`
   query GetDocumentDownloadInfo($ids: [Int!]) {
      documentDownloadInfo(ids: $ids) {
         title
         url
      }
   }
`;

export interface UserOptionsQueryVariables { }

export interface UserOptionsQueryResult {
   users: User[];
}

export interface DocumentTypeOptionQueryVariables {
   forUpsert?: boolean;
   isAdministrationForm?: boolean;
}

export interface DocumentTypeOptionQueryResult {
   documentTypes: DocType[];
}

export interface ProductOptionQueryVariables {
   includeAllProducts?: boolean;
}

export interface ProductOptionQueryResult {
   products: Product[];
}

export interface SearchFilterProductOptionQueryResult {
   searchFilterProducts: Product[];
}

export interface MetadataOptionQueryVariables { }

export interface MetadataOptionQueryResult {
   programs: Program[];
   organizations: Organization[];
   tags: Tag[];
   statuses: DocStatus[];
   sources: DocSource[];
   jurisdictions: Jurisdiction[];
   plans: Plan[];
}
