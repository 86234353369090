import React from "react";
import xlsFileIcon from "./xlsx_icon.svg";

interface Props {
   size?: string;
}

export const ExcelDocIcon: React.FunctionComponent<Props> = (props) => (
   <img alt="Excel doc icon" src={xlsFileIcon} style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }} />
);
