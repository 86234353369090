import React from "react";
import { Chip } from "@mui/material";
import {   darken } from "@mui/material";
import clsx from "clsx";
import { DocStatus } from "documents";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      statusChip: {
         borderRadius: "0.25em",
         backgroundColor: "#fff",
         "& .MuiChip-label": {
            paddingLeft: "0.25em",
            paddingRight: "0.25em",
            textTransform: "uppercase",
         },

         "&&:hover": {
            backgroundColor: darken("#fff", 0.1),
         },

         "&.received": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.dark,
         },

         "&.retrieved": {
            borderColor: theme.palette.success.main,
            color: theme.palette.success.dark,
         },

         "&.corrections-required": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.dark,
         },

      },
   }),
);

const StatusChip: React.FunctionComponent<{ status: DocStatus; onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }> = (
   props,
) => {
   const classes = useStyles();
   const { status } = props;

   return (
      <Chip
         className={clsx(classes.statusChip, {
            clickable: !!props.onClick,
            received: status === DocStatus.Received,
            retrieved: status === DocStatus.Retrieved,
            "corrections-required": status === DocStatus.CorrectionsRequired,
         })}
         size="small"
         label={status}
         variant="outlined"
         clickable={!!props.onClick}
         onClick={props.onClick}
      />
   );
};

export default StatusChip;
