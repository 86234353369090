import { Grid, Typography } from "@mui/material";
import {Button} from "@mui/material";
import {Dialog} from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { useApi } from "api";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "SpinnerButton";
import { Doc } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import FileDropzone from "./FileDropzone";
import { useAuthentication } from "auth/AuthenticationProvider";

interface Props {
   open: boolean;
   handleClose: (fileReplaced: boolean) => void;
   documentIdToEdit: number | null;
   currentFileName: string;
}

interface FormValues {
   selectedFile: File | undefined;
}

const ReplaceFileDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const { postFormDataAsync } = useApi();
   const { getAccessToken } = useAuthentication();

   const initialFormValues: FormValues = {
      selectedFile: undefined,
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (!values.selectedFile) {
         errors.selectedFile = "Select a file.";
      }

      return errors;
   }

   async function uploadNewFile(values: FormValues, actions: FormikHelpers<FormValues>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postFormDataAsync<Doc>(
               "/api/documents/replace",
               {
                  DocumentId: props.documentIdToEdit,
               },
               accessToken,
               [values.selectedFile!],
            );

            notifications.success("Replaced the file.");
         }
      } catch (error: any) {
         notifications.error(error.message ?? error);
      }

      actions.setSubmitting(false);
      props.handleClose(true);
   }

   if (!props.open) return null;

   return (
      <Dialog open={props.open} onClose={props.handleClose} maxWidth="sm" fullWidth={true} scroll="paper">
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={uploadNewFile} enableReinitialize={true}>
            {(formikProps) => {
               return (
                  <>
                     <DialogTitle id="form-dialog-title">Replace file</DialogTitle>
                     <DialogContent>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item>
                                 <Typography variant="caption">Current file name</Typography>
                                 <Typography variant="body1">{props.currentFileName}</Typography>
                              </Grid>
                              <Grid item>
                                 <FormikField component={ReplaceFileDropzone} name="selectedFile" />
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={() => props.handleClose(false)}>Cancel</Button>
                        <SpinnerButton
                           label="Save"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

const ReplaceFileDropzone: React.FunctionComponent<FieldProps<File, FormValues>> = (props) => {
   return (
      <FileDropzone
         prompt="Drag and drop a file here or click to choose a file"
         touched={props.form.touched.selectedFile}
         error={props.form.errors.selectedFile}
         setFieldValue={(files) => props.form.setFieldValue("selectedFile", files[0])}
         limit={1}
      />
   );
};

export default ReplaceFileDialog;
