import { useMutation, gql,  useQuery } from "@apollo/client";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React, { useEffect, useState } from "react";
import SpinnerButton from "SpinnerButton";
import { Product } from "products";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import { Organization, OrganizationInput, OrganizationOfferingsInput } from "organizations";
import ClosableDialogTitle from "application/ClosableDialogTitle";
import { dialogStyle } from "application";
import { Offering } from "plan";
import { OfferingOptionsQuery, OfferingOptionQueryResult, OfferingOptionQueryVariables } from "plan/queries";
import { FieldArray } from "formik";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),

      offeringsRow: {
         borderBottom: '1px solid black',
      },
      ".MuiCheckbox-root": {
         padding: 3,
         border: "1px solid red"
      },
      typeHeader: {
         paddingLeft: 26,
      }
   }),
);

interface Props {
   open: boolean;
   handleClose: (addedOrganizationId?: number) => void;
   organizationToEdit: Organization | null;
}

interface FormValues {
   offerings: Offering[];
   lastUpdated: null | string | Date;
}

const UpdateOrganizationOfferingDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const { loading, error, data } = useQuery<OfferingOptionQueryResult, OfferingOptionQueryVariables>(
      OfferingOptionsQuery,
      {
         variables: {
            
         },
         fetchPolicy: "network-only"
      }
   );

   const dataLoaded =
      !error &&
      !loading &&
      props.organizationToEdit !== null
      ;

   const offeringOptions = dataLoaded ? 
      _.orderBy(
         data!.offerings,
         [(o) => o.plan.type, (o) => o.jurisdiction.order, (o) => o.jurisdiction.name, (o) => o.plan.name])
      : [];

   const offeringJurisdictions = dataLoaded ?
      _.orderBy(
         Array.from(new Set(offeringOptions.map((o: Offering) => o.jurisdiction))),
         [oj => oj.order, oj => oj.name])
      : [];

   const offeringTypes = dataLoaded ?
      _.orderBy(
         Array.from(new Set(offeringOptions.map((o: Offering) => o.plan.type)))
         )
      : [];


   const organizationOfferings = dataLoaded ? _.orderBy(
      offeringOptions.filter((option) => props.organizationToEdit!.offerings.some((orgoffering) => orgoffering.id === option.id)),
      (o) => [o.jurisdiction.name, o.plan.name]
   ) : []

   const [offeringsState, setOfferingsState] = useState(organizationOfferings);

   useEffect(() => {
      if (props.open) {
         // These are lazy queries so that we don't run them until the dialogue is opened.
         if (dataLoaded) {
            setOfferingsState(organizationOfferings);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.open, props.organizationToEdit, dataLoaded]);

   const initialFormValues: FormValues = {
      offerings: organizationOfferings,
      lastUpdated: dataLoaded ? props.organizationToEdit!.lastUpdated : null
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      return errors;
   }



   function getOrgInputFromFormValues(values: FormValues) {
      return {
         id: props.organizationToEdit!.id,
         offeringIds: offeringsState.map((o) => o.id),
         lastUpdated: values.lastUpdated
      };
   }

   const [updateMutate] = useMutation<{ organization: { updateOfferings: Organization } }, { organization: OrganizationOfferingsInput }>(
      gql`
         mutation UpdateOrganization($organization: OrganizationOfferingsInput!) {
            organization {
               updateOfferings(organization: $organization) {
                  id
                  offerings {
                     id
                  }
                  lastUpdated
               }
            }
         }
      `,
      {
         refetchQueries: ["GetOrganizationForEdit"],
      },
   );

   async function updateOrganization(values: FormValues, actions: FormikHelpers<FormValues>) {
      const editedOrganization = getOrgInputFromFormValues(values);
      
      const updateResult = await updateMutate({ variables: { organization: editedOrganization } });

      if (updateResult.data?.organization.updateOfferings) {
         notifications.success("Organization offerings updated.");
      }
      
      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) {

      return null;
   }

      

   return (
      <Dialog
         open={props.open}
         maxWidth="sm"
         fullWidth={true}
         scroll="paper"
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
      >
         <Formik
            initialValues={initialFormValues}
            validate={validate}
            onSubmit={updateOrganization}
            enableReinitialize={true}
         >
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle title={"Edit offerings"} handleClose={props.handleClose} />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    {dataLoaded ? (
                                       <div>
                                          <FieldArray
                                             name="offerings"
                                             render={arrayHelpers => (
                                                <>
                                                <Grid className={classes.offeringsRow} container spacing={1} xs={12}>
                                                   <Grid item xs={3}> </Grid>
                                                      {offeringTypes.map((t) => (
                                                         <>
                                                            <Grid item xs> 
                                                               <Typography variant="subtitle2" className={classes.typeHeader}>{t}</Typography>
                                                            </Grid>
                                                      </>
                                                   ))}
                                                </Grid>
                                                {offeringJurisdictions.map((j) => (
                                                   <Grid className={classes.offeringsRow} container spacing={1} xs={12}>
                                                      <Grid item xs={3}><Typography variant="subtitle2">{j.title}</Typography></Grid>
                                                      {offeringTypes.map((t) => (
                                                         <Grid item xs>
                                                            {offeringOptions.map((o) => (
                                                               (o.jurisdiction.name == j.name && o.plan.type == t && (
                                                                  <Grid item>
                                                                     <FormControlLabel
                                                                        key={"label"+o.id}
                                                                        control={
                                                                           <Checkbox
                                                                              size="small"
                                                                              name="offerings"
                                                                              color="primary"
                                                                              key={"offering" + o.id}
                                                                              checked={offeringsState.includes(o)}
                                                                              onChange={e => {
                                                                                 if (offeringsState.includes(o)) {
                                                                                    setOfferingsState(offeringsState.filter((os) => os != o));
                                                                                 } else {
                                                                                    setOfferingsState([...offeringsState, o]);
                                                                                 }
                                                                              }}
                                                                           />
                                                                        }
                                                                        label={o.jurisdiction.name + " " + o.plan.name}
                                                                     />
                                                                  </Grid>
                                                               ))
                                                            ))}
                                                         </Grid>
                                                      ))}
                                                   </Grid>
                                                ))}
                                                </>
                                             )}
                                             />
                                       </div>
                                    ) : (
                                       <Typography variant="body1">Loading...</Typography>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={() => props.handleClose()}>Cancel</Button>
                        <SpinnerButton
                           label="Save"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default UpdateOrganizationOfferingDialog;
