import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
   TextField,
} from "@mui/material";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "../SpinnerButton";
import { Formik, Form as FormikForm, Field as FormikField, FormikErrors } from "formik";
import { Tag } from "documents";
import { dialogStyle } from "application";
import { makeStyles, createStyles } from '@mui/styles';


import { Autocomplete as FmuiAutocomplete, AutocompleteRenderInputParams } from "formik-mui";
import _ from "lodash";

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   tagsToMerge: Tag[];
   handleClose: (merged: boolean) => void;
}

interface FormValues {
   survivor: Tag | null;
}

const MergeTagsDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const initialFormValues: FormValues = {
      survivor: null,
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.survivor === null) {
         errors.survivor = "Select the tag that will survive the merge.";
      }

      return errors;
   }

   const [mergeMutate] = useMutation<{ tag: { merge: Tag } }, { survivorId: number; mergeeIds: number[] }>(
      gql`
         mutation MergeTags($survivorId: Int!, $mergeeIds: [Int]!) {
            tag {
               merge(survivorId: $survivorId, mergeeIds: $mergeeIds) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetTagsForTagScreen"],
      },
   );

   async function mergeTags(values: FormValues) {
      const result = await mergeMutate({
         variables: {
            survivorId: values.survivor!.id,
            mergeeIds: props.tagsToMerge.filter((t) => t.id !== values.survivor!.id).map((t) => t.id),
         },
      });

      if (result.data?.tag.merge.id) {
         notifications.success("Merged the tags.");
         props.handleClose(true);
      } else {
         props.handleClose(false);
      }
   }

   if (!props.open) return null;

   return (
      <Dialog open={true} onClose={() => props.handleClose(false)}>
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={mergeTags}>
            {(formikProps) => (
               <>
                  <DialogTitle>{`Merge tags`}</DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                     <DialogContentText>
                        This will combine two or more tags together, to eliminate duplicates or variants. Select the tag that will
                        "survive"--that is, be applied to all documents that have one or more of the merged tags.
                     </DialogContentText>
                     <FormikForm>
                        <Grid container direction="column" spacing={2}>
                           <Grid item container spacing={1}>
                              <Grid item xs={12}>
                                 <FormikField
                                    name="survivor"
                                    component={FmuiAutocomplete}
                                    handleHomeEndKeys={false}
                                    options={_.orderBy(props.tagsToMerge, (t) => t.value)}
                                    getOptionLabel={(option: Tag) => option.value}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                       <TextField
                                          {...params}
                                          variant="outlined"
                                          size="small"
                                          label="Survivor"
                                          error={formikProps.touched.survivor && formikProps.errors.survivor !== undefined}
                                          helperText={formikProps.touched.survivor ? formikProps.errors.survivor : undefined}
                                       />
                                    )}
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </FormikForm>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={() => props.handleClose(false)}>Cancel</Button>
                     <SpinnerButton
                        label="Merge"
                        color="secondary"
                        variant="contained"
                        inProgress={formikProps.isSubmitting}
                        onClick={() => {
                           formikProps.submitForm();
                        }}
                     />
                  </DialogActions>
               </>
            )}
         </Formik>
      </Dialog>
   );
};

export default MergeTagsDialog;
