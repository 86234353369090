import React, { useState, useMemo } from "react";
import {
   Theme, TextField, Grid, Typography, Card, FormControlLabel, Checkbox, CardHeader, CardContent, CardActions,
   FormControl, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, InputAdornment, FormGroup, FormHelperText, TableFooter, ButtonGroup, Button
} from "@mui/material";
import { Helmet } from "react-helmet";
import useCurrentUser from "../users/useCurrentUser";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from "@mui/material";
import { RateTier, RpLender, RpRateSubtype, RpRateTerm, User, RpComment, RpProduct } from "./models";
import _ from "lodash";
import { Formik, Form as FormikForm, Field as FormikField, FieldProps, FieldArray, FormikHelpers, validateYupSchema, yupToFormErrors } from "formik";
import { TextField as FmuiTextField, } from "formik-mui";
import SpinnerButton from "SpinnerButton";
import * as Yup from 'yup';
import { gql, useQuery } from "@apollo/client";
import { useNotifications } from "../notifications";
import { useApi } from "../api";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      deleteContainer: {
         marginTop: theme.spacing(5)
      },
      deleteButton: {
         color: theme.palette.error.main,
      },
      rates: {
         border: '1px solid #adadad',
         borderRadius: '4px',
         marginTop: theme.spacing(1)
      }
   }),
);

function emptyRateTerm(length: string, term: string, nonRedeemable: boolean): RpRateTerm {
   return {
      length: length,
      nonRedeemableFlag: nonRedeemable,
      term: term,
      rateValue: "",
      termMaxRangeValue: "",
      termMinRangeValue: ""
   }
}

function emptyRateTier(name: string): RateTier {
   return {
      tierName: name,
      rateValue: "",
      tierMaxRangeValue: "",
      tierMinRangeValue: ""
   }
}

interface FormValues {
   user: User | null;
   comment: RpComment[] | null;
   lender: RpLender | null;
   effectiveDate: Date | null;
   variableRateSubtype: RpRateSubtype[] | null;
   fixedRateSubtype: RpRateSubtype[] | null;
   nonTiered: RateTier;
   tiered: RateTier[];
   shortTerm: RpRateTerm[];
   longTerm: RpRateTerm[];
   specialTerm: RpRateTerm[];
   cashable: RpRateTerm;
   product: RpProduct | null;
}

export const InterestRateChangeForm: React.FunctionComponent = () => {
   const classes = useStyles();
   const { user } = useCurrentUser();
   const notifications = useNotifications();
   const { getAccessToken } = useAuthentication();
   const { postAsync } = useApi();
   const initialFormValues: FormValues = {
      user: {
         userEmail: user.email,
         username: user.name,
      },
      effectiveDate: null,
      lender: {
         lenderName: "",
         creditUnionNumber: ""
      },
      variableRateSubtype: [],
      fixedRateSubtype: [],
      comment: [],
      nonTiered: emptyRateTier("1"),
      tiered: [emptyRateTier("1")],
      shortTerm: [emptyRateTerm("days", "1", true)],
      longTerm: [emptyRateTerm("months", "1", true)],
      specialTerm: [emptyRateTerm("months", "1", true)],
      cashable: emptyRateTerm("months", "1", false),
      product: null
   }

   const fixedRateSubtypeQuery = useQuery<{ ratesubtypes: RpRateSubtype[] }, { userIsWyth: boolean, type: string }>(
      gql`
         query GetRateSubtype($type: String) {
            ratesubtypes(type: $type){
               rateSubtypeId
               rateSubtypeName
            }
         }
      `,
      {
         variables: {
            type: "fixed",
            userIsWyth: user.isWyth,
         },
      },
   );
   const fixedRateSubtypes = useMemo(() => _.orderBy(fixedRateSubtypeQuery.data?.ratesubtypes, (rst) => rst.rateSubtypeId) ?? [], [fixedRateSubtypeQuery.data]);

   const registeredPlanProductQuery = useQuery<{ registeredPlanProducts: RpProduct[] }>(
      gql`
         query GetRegisteredPlanProducts{
            registeredPlanProducts{
               productId
               productCode
               productName
               programId
            }
         }
      `
   );
   const registeredPlanProducts = useMemo(() => _.orderBy(registeredPlanProductQuery.data?.registeredPlanProducts, (rpp) => rpp.productId) ?? [], [registeredPlanProductQuery.data]);

   const variableRateSubtypeQuery = useQuery<{ ratesubtypes: RpRateSubtype[] }, { userIsWyth: boolean, type: string }>(
      gql`
         query GetRateSubtype($type: String) {
            ratesubtypes(type: $type){
               rateSubtypeId
               rateSubtypeName
            }
         }
      `,
      {
         variables: {
            type: "variable",
            userIsWyth: user.isWyth,
         },
      },
   );
   const variableRateSubtypes = useMemo(() => _.orderBy(variableRateSubtypeQuery.data?.ratesubtypes, (rst) => rst.rateSubtypeId) ?? [], [variableRateSubtypeQuery.data]);

   const rateTierSchema = {
      tierName: Yup.string().required('This Field is Required').typeError('This field must be a valid number'),
      rateValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
         .min(0.01, "Value must be above 0.01").max(9.99, "value must be below 9.99")
         .test("maxPrecision", "number field must have 2 digits after the decimal or less", (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() ?? "0")),
      tierMinRangeValue: Yup.number().transform((o, v) => parseFloat(v.replace(/,/g, ''))).required('This Field is Required').typeError('This field must be a valid number')
         .test("shouldBeLessThanMax", "This value should be less than max", (value, context) => {
            const length = context.options.context?.tiered.length;
            const currentTier = context.parent.tierName;
            if (Number(currentTier) === length) {
               return true;
            } else if (typeof (value) !== 'undefined' && value >= context.parent.tierMaxRangeValue) {
               return false;
            }

            return true;
         })
         .test("greaterthanprevious", "Please check value. Note: Enter a value that is greater than maximum of the previous tier", (value, context) => {
            const tiered: RateTier[] = context.options.context?.tiered;
            const currentIndex = tiered.findIndex(tiered => { return tiered.tierName === context.parent.tierName });
            if (currentIndex > 0) {
               const previousValue = tiered[currentIndex - 1];
               if (typeof (value) != "undefined" && value <= previousValue.tierMaxRangeValue) {
                  return false;
               }
            }

            return true;
         }),
      tierMaxRangeValue: Yup.number().transform((o, v) => parseFloat(v.replace(/,/g, ''))).typeError('This field must be a valid number')
         .moreThan(Yup.ref("tierMinRangeValue"), "This value should be greater than min")
         .test("greaterthanprevious", "Please check value. Note: Enter a value that is greater than maximum of the previous tier", (value, context) => {
            const tiered: RateTier[] = context.options.context?.tiered;
            const currentIndex = tiered.findIndex(tiered => { return tiered.tierName === context.parent.tierName });
            if (currentIndex > 0) {
               const previousValue = tiered[currentIndex - 1];
               if (typeof (value) != "undefined" && value <= previousValue.tierMinRangeValue && value <= previousValue.tierMaxRangeValue) {
                  return false;
               }
            }
            return true;
         })
         .test("isRequired", "This Field is Required", (value, context) => {
            const length = context.options.context?.tiered.length;

            if (Number(context.parent.tierName) === length || typeof (value) !== 'undefined') {
               return true;
            }
            return false;
         })
   };

   const rateTermSchema = (min: number, max: number, type: string) => {
      return {
         term: Yup.string().required('This Field is Required').typeError('This field must be a valid number'),
         length: Yup.string().required('This Field is Required').typeError('This field must be a valid number'),
         termMinRangeValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .min(min, `Value must be above ${min}`).max(max, `Value must be below ${max}`)
            .test("greaterOrEqualToMax", "This value should be less than or equal to max", (value, context) => {
               const termMax = context.parent.termMaxRangeValue;
               if (typeof (value) != "undefined" && termMax < value) {
                  return false;
               }
               return true;
            })
            .test("greaterthanprevious", "Please check value. Note: Enter a value that is greater than maximum of the previous tier", (value, context) => {
               const formValues: any = context.options.context;
               const term: RpRateTerm[] = formValues[type];
               const currentIndex = term.findIndex(t => { return t.term === context.parent.term });
               if (currentIndex > 0) {
                  const previousValue = term[currentIndex - 1];
                  if (typeof (value) != "undefined" && value <= previousValue.termMaxRangeValue) {
                     return false;
                  }
               }

               return true;
            }),
         termMaxRangeValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .min(min, `Value must be above ${min}`).max(max, `Value must be below ${max}`)
            .test("greaterOrEqualToMin", "This value should be greater than or equal to min", (value, context) => {
               const termMin = context.parent.termMinRangeValue;
               if (typeof (value) != "undefined" && termMin > value) {
                  return false;
               }
               return true;
            })
            .test("greaterthanprevious", "Please check value. Note: Enter a value that is greater than maximum of the previous tier", (value, context) => {
               const formValues: any = context.options.context;
               const term: RpRateTerm[] = formValues[type];
               const currentIndex = term.findIndex(t => { return t.term === context.parent.term });
               if (currentIndex > 0) {
                  const previousValue = term[currentIndex - 1];
                  if (typeof (value) != "undefined" && value <= previousValue.termMinRangeValue) {
                     return false;
                  }
               }

               return true;
            }),
         rateValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .min(0.01, "Value must be above 0.01").max(9.99, "value must be below 9.99")
            .test("maxPrecision", "number field must have 2 digits after the decimal or less", (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() ?? "0"))
      }
   };

   const rateTermCashable = (min: number, max: number, type: string) => {
      return {
         term: Yup.string().required('This Field is Required').typeError('This field must be a valid number'),
         termMinRangeValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .min(min, `Value must be above ${min}`).max(max, `Value must be below ${max}`),
         rateValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .min(0.01, "Value must be above 0.01").max(9.99, "value must be below 9.99")
            .test("maxPrecision", "number field must have 2 digits after the decimal or less", (value) => /^\d+(\.\d{1,2})?$/.test(value?.toString() ?? "0"))
      }
   }
   const validationSchema = Yup.object().shape({
      user: Yup.object().shape({
         username: Yup.string().nullable().required('This Field is Required'),
         userEmail: Yup.string().email("Enter a valid email").required('This Field is Required'),
      }),
      lender: Yup.object().shape({
         lenderName: Yup.string().required('This Field is Required'),
         creditUnionNumber: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
            .test('len', 'Must be exactly 4 digits', val => val.toString().length === 4)
      }),
      product: Yup.object().shape({
         productId: Yup.number(),
         productCode: Yup.string(),
         productName: Yup.string(),
      }).required('This Field is Required'),
      effectiveDate: Yup.date().nullable().required('This Field is Required').min(new Date(new Date().setHours(0, 0, 0, 0)), "Date must be the present or a future date"),
      variableRateSubtype: Yup.array().of(Yup.object().shape({
         rateSubtypeId: Yup.number(),
         rateSubtypeName: Yup.string()
      })).min(1, "This field is required"),
      fixedRateSubtype: Yup.array().of(Yup.object().shape({
         rateSubtypeId: Yup.number(),
         rateSubtypeName: Yup.string()
      })).min(1, "This field is required"),
      nonTiered: Yup.object().when(("variableRateSubtype"), {
         is: (variableRateSubtype: RpRateSubtype[]) => { return variableRateSubtype.some(vrst => vrst.rateSubtypeName.toLowerCase() === "regular") },
         then: (s) => Yup.object().shape({
            tierName: Yup.string().required('This Field is Required').typeError('This field must be a valid number'),
            rateValue: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => Number.isInteger((value ?? 0) * (10 ** 3)))
         })
      }),
      tiered: Yup.array().when(("variableRateSubtype"), {
         is: (variableRateSubtype: RpRateSubtype[]) => { return variableRateSubtype.some(vrst => vrst.rateSubtypeName.toLowerCase() === "tiered") },
         then: (s) => Yup.array().of(Yup.object().shape(rateTierSchema))
      }),
      shortTerm: Yup.array().when(["fixedRateSubtype"], {
         is: (fixedRateSubtype: RpRateSubtype[]) => { return fixedRateSubtype.some(frst => frst.rateSubtypeName.toLowerCase() === "short term") },
         then: (s) => Yup.array().of(Yup.object().shape(rateTermSchema(1, 364, "shortTerm")))
      }),
      longTerm: Yup.array().when(["fixedRateSubtype"], {
         is: (fixedRateSubtype: RpRateSubtype[]) => { return fixedRateSubtype.some(frst => frst.rateSubtypeName.toLowerCase() === "long term") },
         then: (s) => Yup.array().of(Yup.object().shape(rateTermSchema(12, 131, "longTerm")))
      }),
      specialTerm: Yup.array().when(["fixedRateSubtype"], {
         is: (fixedRateSubtype: RpRateSubtype[]) => { return fixedRateSubtype.some(frst => frst.rateSubtypeName.toLowerCase() === "special term offering") },
         then: (s) => Yup.array().of(Yup.object().shape(rateTermSchema(12, 131, "specialTerm")))
      }),
      cashable: Yup.object().when(["fixedRateSubtype"], {
         is: (fixedRateSubtype: RpRateSubtype[]) => { return fixedRateSubtype.some(frst => frst.rateSubtypeName.toLowerCase() === "cashable") },
         then: (s) => Yup.object().shape(rateTermCashable(12, 24, "cashable"))
      })
   });

   const validateForm = (values: FormValues) => {
      try {
         validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
         return yupToFormErrors(err);
      }
      return {};
   }

   function onChangeFixed(event: any, fieldProps: FieldProps<FormValues>) {
      const value = event.target.value;
      const checked = event.target.checked;
      const fixedType = fixedRateSubtypes.find(frst => frst.rateSubtypeName === value);

      if (checked) {
         if (fixedType?.rateSubtypeName === "Not Applicable") {
            fieldProps.form.setFieldValue("fixedRateSubtype", [fixedType]);
            fieldProps.form.setFieldValue("shortTerm", initialFormValues.shortTerm);
            fieldProps.form.setFieldValue("longTerm", initialFormValues.longTerm);
            fieldProps.form.setFieldValue("cashable", initialFormValues.cashable);
            fieldProps.form.setFieldValue("specialTerm", initialFormValues.specialTerm);
         } else {
            fieldProps.form.setFieldValue("fixedRateSubtype", [...fieldProps.form.values.fixedRateSubtype ?? [], fixedType].filter((frs: RpRateSubtype) => frs.rateSubtypeName.toLowerCase() !== "not applicable"));
         }
      } else {
         fieldProps.form.setFieldValue("fixedRateSubtype", fieldProps.form.values.fixedRateSubtype.filter((frs: RpRateSubtype) => frs.rateSubtypeName !== fixedType?.rateSubtypeName));
      }

      if (!checked && fixedType?.rateSubtypeName.toLowerCase() === "short term") {
         fieldProps.form.setFieldValue("shortTerm", initialFormValues.shortTerm);
      }
      if (!checked && fixedType?.rateSubtypeName.toLowerCase() === "long term") {
         fieldProps.form.setFieldValue("longTerm", initialFormValues.longTerm);
      }
      if (!checked && fixedType?.rateSubtypeName.toLowerCase() === "special term offering") {
         fieldProps.form.setFieldValue("specialTerm", initialFormValues.specialTerm);
      }
      if (!checked && fixedType?.rateSubtypeName.toLowerCase() === "cashable") {
         fieldProps.form.setFieldValue("cashable", initialFormValues.cashable);
      }
   }

   function onChangeVariable(event: any, fieldProps: FieldProps<FormValues>) {
      const value = event.target.value;
      const checked = event.target.checked;
      const variableType = variableRateSubtypes.find(vrst => vrst.rateSubtypeName === value);

      if (checked) {
         if (variableType?.rateSubtypeName.toLowerCase() === "not applicable") {
            fieldProps.form.setFieldValue("variableRateSubtype", [variableType]);
            fieldProps.form.setFieldValue("nonTiered", initialFormValues.nonTiered);
            fieldProps.form.setFieldValue("tiered", initialFormValues.tiered);
         } else {
            fieldProps.form.setFieldValue("variableRateSubtype", [...fieldProps.form.values.variableRateSubtype ?? [], variableType].filter((vrs: RpRateSubtype) => vrs.rateSubtypeName.toLowerCase() !== "not applicable"));
         }
      } else {
         fieldProps.form.setFieldValue("variableRateSubtype", fieldProps.form.values.variableRateSubtype.filter((vrs: RpRateSubtype) => vrs.rateSubtypeName !== variableType?.rateSubtypeName));
      }

      if (!checked && variableType?.rateSubtypeName.toLowerCase() === "regular") {
         fieldProps.form.setFieldValue("nonTiered", initialFormValues.nonTiered);
      }
      if (!checked && variableType?.rateSubtypeName.toLowerCase() === "tiered") {
         fieldProps.form.setFieldValue("tiered", initialFormValues.tiered);
      }
   }

   function onChangeCommentFixed(event: any, fieldProps: FieldProps<FormValues>, commentType: string) {
      var newCommentText = event.target.value;
      const fixedType = fixedRateSubtypes.find(frst => frst.rateSubtypeName.toLowerCase() === commentType);

      var comments: RpComment[] = fieldProps.form.values.comment;
      var currentCommentValue = comments.find((comment => comment.rateSubtype == fixedType));

      if (fixedType != undefined && currentCommentValue != undefined) {
         currentCommentValue.CommentText = newCommentText;
         fieldProps.form.setFieldValue("comment", comments)
      } else if (fixedType != undefined) {
         var newComment: RpComment = {
            CommentText: newCommentText,
            rateSubtype: fixedType
         }
         fieldProps.form.setFieldValue("comment", [...comments, newComment])
      }
   }

   async function submitInterestRateChange(values: FormValues, actions: FormikHelpers<any>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postAsync(
               "/api/registeredplan/new",
               JSON.stringify({
                  user: values.user,
                  effectiveDate: values.effectiveDate,
                  lender: values.lender,
                  variableRateSubtype: values.variableRateSubtype,
                  fixedRateSubtype: values.fixedRateSubtype,
                  comments: values.comment,
                  product: values.product,
                  nonTiered: {
                     rateValue: Number(values.nonTiered.rateValue),
                     tierName: values.nonTiered.tierName,
                     tierMaxRangeValue: Number(values.nonTiered.tierMaxRangeValue),
                     tierMinRangeValue: Number(values.nonTiered.tierMinRangeValue)
                  },
                  tiered: values.tiered.map(t => ({
                     rateValue: Number(t.rateValue),
                     tierName: t.tierName,
                     tierMaxRangeValue: t.tierMaxRangeValue == "" ? null : Number(t.tierMaxRangeValue),
                     tierMinRangeValue: Number(t.tierMinRangeValue)
                  })),
                  shortTerm: values.shortTerm.map(st => ({
                     length: st.length,
                     nonRedeemableFlag: st.nonRedeemableFlag,
                     term: st.term,
                     rateValue: Number(st.rateValue),
                     termMaxRangeValue: Number(st.termMaxRangeValue),
                     termMinRangeValue: Number(st.termMinRangeValue)
                  })),
                  longTerm: values.longTerm.map(lt => ({
                     length: lt.length,
                     nonRedeemableFlag: lt.nonRedeemableFlag,
                     term: lt.term,
                     rateValue: Number(lt.rateValue),
                     termMaxRangeValue: Number(lt.termMaxRangeValue),
                     termMinRangeValue: Number(lt.termMinRangeValue)
                  })),
                  specialTerm: values.specialTerm.map(st => ({
                     length: st.length,
                     nonRedeemableFlag: st.nonRedeemableFlag,
                     term: st.term,
                     rateValue: Number(st.rateValue),
                     termMaxRangeValue: Number(st.termMaxRangeValue),
                     termMinRangeValue: Number(st.termMinRangeValue)
                  })),
                  cashable: {
                     length: values.cashable.length,
                     nonRedeemableFlag: values.cashable.nonRedeemableFlag,
                     term: values.cashable.term,
                     rateValue: Number(values.cashable.rateValue),
                     termMaxRangeValue: Number(values.cashable.termMinRangeValue),
                     termMinRangeValue: Number(values.cashable.termMinRangeValue)
                  }
               }),
               accessToken
            );
         }
         actions.resetForm();
         notifications.success("Rate posted.");
      } catch (error: any) {
         if (error instanceof Error) {
            notifications.error(error.message ?? error);
         }
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Interest Rate Change - Concentra Partner Portal</title>
         </Helmet>
         <Formik initialValues={{ ...initialFormValues, user: { username: user.name, userEmail: user.email }, lender: { lenderName: user.organization.name, creditUnionNumber: "" } }} validate={(values) => validateForm(values)} onSubmit={(values, actions) => { submitInterestRateChange(values, actions) }} enableReinitialize>
            {(formikProps) => {
               return (
                  <Grid container direction="column" spacing={3}>
                     <Grid item container justifyContent="space-between">
                        <Grid item>
                           <Typography variant="h4">Interest Rate Change</Typography>
                        </Grid>
                     </Grid>
                     <FormikForm>
                        <Box mb={0} p={2} pb={0} >
                           <Card>
                              <CardContent>
                                 <Grid item container spacing={2}>
                                    <Grid item container spacing={3}>
                                       <Grid item xs={12} sm={6} lg={6} xl={6}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="user.username"
                                             variant="outlined"
                                             value={formikProps.values.user.username}
                                             label="User Name"
                                             fullWidth
                                             disabled
                                          />
                                       </Grid>
                                       <Grid item xs={12} sm={6} lg={6} xl={6}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="user.userEmail"
                                             variant="outlined"
                                             value={formikProps.values.user.userEmail}
                                             label="Email"
                                             fullWidth
                                             disabled
                                          />
                                       </Grid>
                                    </Grid>
                                    <Grid item container spacing={3}>
                                       <Grid item xs={12} sm={6} lg={3} xl={3}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="lender.lenderName"
                                             variant="outlined"
                                             value={formikProps.values.lender?.lenderName}
                                             label="Credit Union Name"
                                             fullWidth
                                             disabled
                                          />
                                       </Grid>
                                       <Grid item xs={12} sm={6} lg={3} xl={3}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="lender.creditUnionNumber"
                                             variant="outlined"
                                             value={formikProps.values.lender?.creditUnionNumber}
                                             label="Credit Union Number"
                                             fullWidth
                                          />
                                       </Grid>
                                       <Grid item xs={12} sm={6} lg={3} xl={3}>
                                          <FormikField>
                                             {(fieldProps: FieldProps<String>) => (
                                                <Autocomplete
                                                   inputValue={formikProps.values.product?.productName ?? ""}
                                                   disabled={fieldProps.form.isSubmitting}
                                                   autoComplete
                                                   options={_.orderBy(registeredPlanProducts, (rpp) => rpp.productName) ?? []}
                                                   getOptionLabel={(rpp) => rpp.productName}
                                                   onChange={(e, newValue) => fieldProps.form.setFieldValue("product", newValue)}
                                                   renderInput={(params) =>
                                                      <TextField {...params} variant="outlined" label="Product"
                                                         error={formikProps.touched.product && formikProps.errors.product !== undefined}
                                                         helperText={formikProps.touched.product ? formikProps.errors.product : undefined}
                                                      />
                                                   }
                                                />
                                             )}
                                          </FormikField>
                                       </Grid>
                                       <Grid item xs={12} sm={6} lg={3} xl={3}>
                                          <FormikField name="effectiveDate">
                                             {(fieldProps: FieldProps<Date>) => (
                                                <DatePicker
                                                   inputFormat="MMM/dd/yyyy"
                                                   label="Effective Date"
                                                   disabled={fieldProps.form.isSubmitting}
                                                   value={fieldProps.field.value}
                                                   disablePast={true}
                                                   renderInput={(params) =>
                                                      <TextField  {...params}
                                                         fullWidth
                                                         error={
                                                            fieldProps.form.touched.effectiveDate && fieldProps.form.errors.effectiveDate !== undefined
                                                         }
                                                         helperText={
                                                            fieldProps.form.touched.effectiveDate ? fieldProps.form.errors.effectiveDate : undefined
                                                         }
                                                      />}
                                                   onChange={(newDate: Date | null) =>
                                                      fieldProps.form.setFieldValue("effectiveDate", newDate)
                                                   }


                                                />)}
                                          </FormikField>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </CardContent>
                           </Card>
                        </Box>
                        <Box mb={2} p={2} pb={0} pt={0} >
                           <h2>Complete only information fields requiring change</h2>
                           <Card>
                              <CardHeader title="Select Variable Options" />
                              <CardContent>
                                 <Grid item container spacing={3}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                       <FormControl component="fieldset" error={formikProps.touched.variableRateSubtype && formikProps.errors.variableRateSubtype !== undefined}>
                                          <FormikField name="variableRateSubtype">
                                             {(fieldProps: FieldProps) => {
                                                return (
                                                   <FormGroup row>
                                                      {
                                                         variableRateSubtypes.map((vst, index) => (
                                                            <FormControlLabel
                                                               key={index}
                                                               control={
                                                                  <Checkbox value={vst.rateSubtypeName} onChange={(e) => { onChangeVariable(e, fieldProps) }} checked={formikProps.values.variableRateSubtype?.some(vrst => vrst.rateSubtypeId === vst.rateSubtypeId)} />
                                                               }
                                                               label={vst.rateSubtypeName}
                                                            />
                                                         ))
                                                      }
                                                   </FormGroup>
                                                )
                                             }}
                                          </FormikField>
                                          <FormHelperText>{formikProps.touched.variableRateSubtype ? formikProps.errors.variableRateSubtype : undefined}</FormHelperText>
                                       </FormControl>
                                    </Grid>
                                 </Grid>
                              </CardContent>
                              <CardActions>
                                 <FormHelperText>If not selecting a Variable Term Option please check 'Not Applicable'</FormHelperText>
                              </CardActions>
                           </Card>
                        </Box>
                        {
                           formikProps.values.variableRateSubtype?.some(vrs => vrs.rateSubtypeName.toLowerCase() === "non-tiered") ?
                              (
                                 <Box mb={2} p={2} pb={0} pt={0} >
                                    <Card>
                                       <CardHeader title="Non-Tiered Variable Rate" />
                                       <CardContent>
                                          <Grid item container spacing={3} xs={12} sm={12} lg={12} xl={12} pl={3} >
                                             <FormikField
                                                component={FmuiTextField}
                                                name="nonTiered.rateValue"
                                                variant="outlined"
                                                value={formikProps.values.nonTiered.rateValue}
                                                label="Rate %"
                                                fullWidth
                                                error={
                                                   formikProps.touched.nonTiered && formikProps.errors.nonTiered !== undefined
                                                }
                                                helperText={
                                                   formikProps.touched.nonTiered ? formikProps.errors.nonTiered : undefined
                                                }
                                             />
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                 </Box>
                              ) : null
                        }
                        {formikProps.values.variableRateSubtype?.some(vrs => vrs.rateSubtypeName.toLowerCase() === "tiered") ? (
                           <Box mb={2} p={2} pb={0} pt={0} >
                              <Card>
                                 <CardHeader title="Tiered Variable Rates" subheader="Please enter the appropriate tiers for each rate. If the final tier is the maximum amount and over, leave the Ending Tier Balance ($) field blank." />
                                 <CardContent>
                                    <Grid item container spacing={3} xs={12} sm={12} lg={12} xl={12} pl={3} >
                                       <FieldArray name="tiered">
                                          {({ push, remove }) => (
                                             <TableContainer>
                                                <Table>
                                                   <TableHead>
                                                      <TableRow>
                                                         <TableCell>Starting Tier Balance ($)</TableCell>
                                                         <TableCell>Ending Tier Balance ($)</TableCell>
                                                         <TableCell>Rate (input %, so "2.02%" is 2.02)</TableCell>
                                                      </TableRow>
                                                   </TableHead>
                                                   <TableBody>
                                                      <>
                                                         {
                                                            formikProps.values.tiered.map((rate, index) => (
                                                               <TableRow key={index}>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`tiered.${index}.tierMinRangeValue`}
                                                                        value={rate.tierMinRangeValue === "" ? null : Number(rate.tierMinRangeValue.toString().replaceAll(",", "")).toLocaleString()}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                        placeholder="0.00"
                                                                        InputProps={{
                                                                           startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                        }}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`tiered.${index}.tierMaxRangeValue`}
                                                                        value={rate.tierMaxRangeValue === "" ? null : Number(rate.tierMaxRangeValue.toString().replaceAll(",", "")).toLocaleString()}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                        placeholder={formikProps.values.tiered.length == (index + 1) ? "Over" : "0.00"}
                                                                        InputProps={{
                                                                           startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                        }}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`tiered.${index}.rateValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                               </TableRow>
                                                            ))
                                                         }
                                                      </>
                                                   </TableBody>
                                                   <TableFooter>
                                                      <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                                         <Button disabled={formikProps.values.tiered.length === 5} onClick={() => {
                                                            push(emptyRateTier(`${formikProps.values.tiered.length + 1}`));
                                                         }}>Add Row</Button>
                                                         <Button disabled={formikProps.values.tiered.length === 1} onClick={() => {
                                                            remove(formikProps.values.tiered.length - 1);
                                                         }}>Remove Row</Button>
                                                      </ButtonGroup>
                                                   </TableFooter>
                                                </Table>
                                             </TableContainer>
                                          )}
                                       </FieldArray>
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Box>
                        ) : null}

                        <Box mb={2} p={2} pb={0} pt={0} >
                           <h2>Complete only information fields requiring change</h2>
                           <Card>
                              <CardHeader title="Select Fixed Term Options" />
                              <CardContent>
                                 <Grid item container spacing={3}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                       <FormControl component="fieldset" error={formikProps.touched.fixedRateSubtype && formikProps.errors.fixedRateSubtype !== undefined}>
                                          <FormikField name="fixedRateSubtype">
                                             {(fieldProps: FieldProps) => {
                                                return (
                                                   <FormGroup row>
                                                      {
                                                         fixedRateSubtypes.map((fst, index) => (
                                                            <FormControlLabel
                                                               key={index}
                                                               control={
                                                                  <Checkbox value={fst.rateSubtypeName} onChange={(e) => { onChangeFixed(e, fieldProps) }} checked={formikProps.values.fixedRateSubtype?.some(frst => frst.rateSubtypeId === fst.rateSubtypeId)} />
                                                               }
                                                               label={fst.rateSubtypeName}
                                                            />
                                                         ))
                                                      }
                                                   </FormGroup>
                                                )
                                             }}
                                          </FormikField>
                                          <FormHelperText>{formikProps.touched.fixedRateSubtype ? formikProps.errors.fixedRateSubtype : undefined}</FormHelperText>
                                       </FormControl>
                                    </Grid>
                                 </Grid>
                              </CardContent>
                              <CardActions>
                                 <FormHelperText>If not selecting a Fixed Term Option please check 'Not Applicable'</FormHelperText>
                              </CardActions>
                           </Card>
                        </Box>
                        {formikProps.values.fixedRateSubtype?.some(frs => frs.rateSubtypeName.toLowerCase() === "short term") ? (
                           <Box mb={2} p={2} pb={0} pt={0} >
                              <Card>
                                 <CardHeader title="Short Term Rates" />
                                 <CardContent>
                                    <Grid item container spacing={3} xs={12} sm={12} lg={12} xl={12} pl={3} >
                                       <FieldArray name="shortTerm">
                                          {({ push, remove }) => (
                                             <TableContainer>
                                                <Table>
                                                   <TableHead>
                                                      <TableRow>
                                                         <TableCell>Range Start (days)</TableCell>
                                                         <TableCell>Range End (days)</TableCell>
                                                         <TableCell>Rate (input %, so "2.02%" is 2.02)</TableCell>
                                                      </TableRow>
                                                   </TableHead>
                                                   <TableBody>
                                                      <>
                                                         {
                                                            formikProps.values.shortTerm.map((rate, index) => (
                                                               <TableRow key={index}>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`shortTerm.${index}.termMinRangeValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`shortTerm.${index}.termMaxRangeValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`shortTerm.${index}.rateValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                               </TableRow>
                                                            ))
                                                         }
                                                      </>
                                                   </TableBody>
                                                   <TableFooter>
                                                      <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                                         <Button disabled={formikProps.values.shortTerm.length === 8} onClick={() => {
                                                            push(emptyRateTerm("days", `${formikProps.values.shortTerm.length + 1}`, true));
                                                         }}>Add Row</Button>
                                                         <Button disabled={formikProps.values.shortTerm.length === 1} onClick={() => {
                                                            remove(formikProps.values.shortTerm.length - 1);
                                                         }}>Remove Row</Button>
                                                      </ButtonGroup>
                                                   </TableFooter>
                                                </Table>
                                             </TableContainer>
                                          )}
                                       </FieldArray>
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Box>
                        ) : null}

                        {formikProps.values.fixedRateSubtype?.some(frs => frs.rateSubtypeName.toLowerCase() === "long term") ? (
                           <Box mb={2} p={2} pb={0} pt={0} >
                              <Card>
                                 <CardHeader title="Long Term Rates" />
                                 <CardContent>
                                    <Grid item container spacing={3} xs={12} sm={12} lg={12} xl={12} pl={3} >
                                       <FieldArray name="longTerm">
                                          {({ push, remove }) => (
                                             <TableContainer>
                                                <Table>
                                                   <TableHead>
                                                      <TableRow>
                                                         <TableCell>Range Start (month)</TableCell>
                                                         <TableCell>Range End (month)</TableCell>
                                                         <TableCell>Rate (input %, so "2.02%" is 2.02)</TableCell>
                                                      </TableRow>
                                                   </TableHead>
                                                   <TableBody>
                                                      <>
                                                         {
                                                            formikProps.values.longTerm.map((rate, index) => (
                                                               <TableRow key={index}>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`longTerm.${index}.termMinRangeValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`longTerm.${index}.termMaxRangeValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                                  <TableCell>
                                                                     <FormikField
                                                                        component={FmuiTextField}
                                                                        name={`longTerm.${index}.rateValue`}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        disabled={formikProps.isSubmitting}
                                                                     />
                                                                  </TableCell>
                                                               </TableRow>
                                                            ))
                                                         }
                                                      </>
                                                   </TableBody>
                                                   <TableFooter>
                                                      <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                                         <Button disabled={formikProps.values.longTerm.length === 10} onClick={() => {
                                                            push(emptyRateTerm("months", `${formikProps.values.longTerm.length + 1}`, true));
                                                         }}>Add Row</Button>
                                                         <Button disabled={formikProps.values.longTerm.length === 1} onClick={() => {
                                                            remove(formikProps.values.longTerm.length - 1);
                                                         }}>Remove Row</Button>
                                                      </ButtonGroup>
                                                   </TableFooter>
                                                </Table>
                                             </TableContainer>
                                          )}
                                       </FieldArray>
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Box>
                        ) : null}
                        {formikProps.values.fixedRateSubtype?.some(frs => frs.rateSubtypeName.toLowerCase() === "special term offering") ? (
                           <Box mb={2} p={2} pb={0} pt={0} >
                              <Card>
                                 <CardHeader title="Special Term Offering" />
                                 <CardContent>
                                    <Grid container spacing={3}>
                                       <Grid item xs={12} sm={12} lg={12} xl={12}>
                                          <FieldArray name="specialTerm">
                                             {({ push, remove }) => (
                                                <TableContainer>
                                                   <Table>
                                                      <TableHead>
                                                         <TableRow>
                                                            <TableCell>Range Start (month)</TableCell>
                                                            <TableCell>Range End (month)</TableCell>
                                                            <TableCell>Rate (input %, so "2.02%" is 2.02)</TableCell>
                                                         </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                         <>
                                                            {
                                                               formikProps.values.specialTerm.map((rate, index) => (
                                                                  <TableRow key={index}>
                                                                     <TableCell>
                                                                        <FormikField
                                                                           component={FmuiTextField}
                                                                           name={`specialTerm.${index}.termMinRangeValue`}
                                                                           variant="outlined"
                                                                           fullWidth
                                                                           disabled={formikProps.isSubmitting}
                                                                        />
                                                                     </TableCell>
                                                                     <TableCell>
                                                                        <FormikField
                                                                           component={FmuiTextField}
                                                                           name={`specialTerm.${index}.termMaxRangeValue`}
                                                                           variant="outlined"
                                                                           fullWidth
                                                                           disabled={formikProps.isSubmitting}
                                                                        />
                                                                     </TableCell>
                                                                     <TableCell>
                                                                        <FormikField
                                                                           component={FmuiTextField}
                                                                           name={`specialTerm.${index}.rateValue`}
                                                                           variant="outlined"
                                                                           fullWidth
                                                                           disabled={formikProps.isSubmitting}
                                                                        />
                                                                     </TableCell>
                                                                  </TableRow>
                                                               ))
                                                            }
                                                         </>
                                                      </TableBody>
                                                      <TableFooter>
                                                         <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                                                            <Button disabled={formikProps.values.specialTerm.length === 5} onClick={() => {
                                                               push(emptyRateTerm("months", `${formikProps.values.specialTerm.length + 1}`, true));
                                                            }}>Add Row</Button>
                                                            <Button disabled={formikProps.values.specialTerm.length === 1} onClick={() => {
                                                               remove(formikProps.values.specialTerm.length - 1);
                                                            }}>Remove Row</Button>
                                                         </ButtonGroup>
                                                      </TableFooter>
                                                   </Table>
                                                </TableContainer>
                                             )}
                                          </FieldArray>
                                       </Grid>
                                       <Grid item xs={12} sm={12} lg={12} xl={12}>
                                          <FormikField name={`comment`}>
                                             {(fieldProps: FieldProps) => {
                                                return (
                                                   <TextField
                                                      onChange={e => { onChangeCommentFixed(e, fieldProps, "special term offering") }}
                                                      label="Comment"
                                                      focused
                                                      placeholder="Please describe the specifics of this offering"
                                                      multiline
                                                      rows={5}
                                                      variant="outlined"
                                                      fullWidth
                                                   />)
                                             }}
                                          </FormikField>
                                       </Grid>
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Box>
                        ) : null}
                        {formikProps.values.fixedRateSubtype?.some(frs => frs.rateSubtypeName.toLowerCase() === "cashable") ? (
                           <Box mb={2} p={2} pb={0} pt={0} >
                              <Card>
                                 <CardHeader title="Cashable Rates" />
                                 <CardContent>
                                    <Grid item container spacing={3} xs={12} sm={12} lg={12} xl={12} pl={3} >
                                       <TableContainer>
                                          <Table>
                                             <TableHead>
                                                <TableRow>
                                                   <TableCell>Month</TableCell>
                                                   <TableCell>Rate (input %, so "2.02%" is 2.02)</TableCell>
                                                </TableRow>
                                             </TableHead>
                                             <TableBody>
                                                <TableRow>
                                                   <TableCell>
                                                      <FormikField
                                                         component={FmuiTextField}
                                                         name={`cashable.termMinRangeValue`}
                                                         variant="outlined"
                                                         fullWidth
                                                         disabled={formikProps.isSubmitting}
                                                      />
                                                   </TableCell>
                                                   <TableCell>
                                                      <FormikField
                                                         component={FmuiTextField}
                                                         name={`cashable.rateValue`}
                                                         variant="outlined"
                                                         fullWidth
                                                         disabled={formikProps.isSubmitting}
                                                      />
                                                   </TableCell>
                                                </TableRow>
                                             </TableBody>
                                          </Table>
                                       </TableContainer>
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Box>
                        ) : null}
                        <Box mb={2} p={2} pb={0} pt={0} >
                           <Grid item container spacing={3}>
                              <Grid item xs={12} sm={6} lg={4} xl={3}>
                                 <SpinnerButton
                                    label="Post"
                                    color="primary"
                                    variant="contained"
                                    inProgress={formikProps.isSubmitting}
                                    onClick={() => {
                                       formikProps.submitForm();
                                    }}
                                 />
                              </Grid>
                              <Grid item xs={12} sm={6} lg={4} xl={9}>
                                 <div style={{ float: 'right' }}>
                                    <SpinnerButton
                                       label="Clear"
                                       color="secondary"
                                       variant="contained"
                                       inProgress={formikProps.isSubmitting}
                                       onClick={() => {
                                          formikProps.resetForm();
                                       }}
                                    />
                                 </div>
                              </Grid>
                           </Grid>
                        </Box>
                     </FormikForm>
                  </Grid>
               )
            }}
         </Formik>
      </div>
   )
}