import { gql } from "@apollo/client";
import { Offering } from "./models";

export const OfferingOptionsQuery = gql`
   query GetOfferingOptions {
      offerings {
         id
         plan {
            id
            name
            type
            products {
               id
               name
            }
         }
         jurisdiction {
            id
            name
            title
            order
         }

      }
   }
`;

export interface OfferingOptionQueryVariables {

}

export interface OfferingOptionQueryResult {
   offerings: Offering[];
}

