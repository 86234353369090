
import { createFilterOptions } from "@mui/material";
import { User } from "users";
import { Organization } from "./models";

export function getOrganizationOptionLabel(organization: Organization, user: User) {
   return `${user.showOrganizationCodes ? `${organization.code} ` : ""}${organization.name}`;
}

export function getOrganizationFilterOptions() {
   return createFilterOptions({
      stringify: (org: Organization) => `${org.code} ${org.name}`,
   });
}
