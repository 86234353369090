import { TextField, Grid, Box, Typography, Button } from "@mui/material";
import {createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef } from "react";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import MaterialTable from "@material-table/core";
import {
   User,
   Permissions,
   UserGroupInput,

   UpdateUserGroupMutation,
   DeleteUserGroupMutation,
   UserGroup,
} from "users";

import useCurrentUser from "./useCurrentUser";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { CSVLink } from "react-csv";
import { GetApp as FileDownloadIcon } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

const defaultMaterialTheme = createTheme();

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      scrollList: {
         overflowY: 'scroll',
         maxHeight: '9.4em',
         //WebkitMaskImage: "linear-gradient(to bottom, black 80px, transparent 120px)"
      }
   }),
);

interface Props {
   userGroups: UserGroup[];
   loading: boolean;
   getAvailableUsers: User[];
}

export const UserGroupsTable: React.FunctionComponent<Props> = (props) => {
   const { user, userHasPermission } = useCurrentUser();
   const classes = useStyles();
   const notifications = useNotifications();

   const tableRef = useRef<any>(null);

   function currentUserCanManageUserGroups() {
      return user.isWyth && userHasPermission(Permissions.ManageUsersInAnyOrganization);
   }


   const [editUserGroupMutate] = useMutation<{ userGroup: { updateUserGroup: UserGroup } }, { userGroup: UserGroupInput }>(UpdateUserGroupMutation, {
      refetchQueries: ["GetAllUserGroups"],
   });

   async function editUserGroup(updatedUserGroup: UserGroup) {
      const userGroupInput = {
         id: updatedUserGroup.id,
         groupName: updatedUserGroup.groupName.trim(),
         userIds: updatedUserGroup.users.map((s) => s.id),
      };

      let validationError = "";
      if (validationError) {
         notifications.error(validationError);
         throw new Error(validationError);
      }

      const updateResult = await editUserGroupMutate({ variables: { userGroup: userGroupInput } });

      if (updateResult.data?.userGroup.updateUserGroup) {
         notifications.success("User group updated.");
      }
   }


   const [deleteUserGroupMutate] = useMutation<{ userGroup: { deleteUserGroup: UserGroup } }, { id: number }>(DeleteUserGroupMutation, {
      refetchQueries: ["GetAllUserGroups"],
   });

   async function deleteUserGroup(userGroupToDelete: UserGroup) {
      const deleteResult = await deleteUserGroupMutate({ variables: { id: userGroupToDelete.id } });

      if (deleteResult.data?.userGroup.deleteUserGroup) {
         notifications.success("User group unlinked.");
      }
   }


   function mapToCSV(data: UserGroup[]) {
      var results: any[] = [];

      data.forEach(ug => {
         if (ug.users == null || ug.users.length == 0) {
            let entry: any;
            entry = {};
            entry.id = ug.id;
            entry.reference = ug.groupName;
            results.push(entry);
         } else {
            ug.users.forEach(ugu => {
               let entry: any;
               entry = {};
               entry.id = ug.id;
               entry.reference = ug.groupName;
               entry.orgcode = ugu.organization.code;
               entry.organization = ugu.organization.name;
               entry.name = ugu.name;
               results.push(entry);
            });
         }
      });


      var headers = [];

      headers.push(
         { label: 'Id', key: 'id' },
         { label: 'Reference', key: 'reference' },
         { label: 'Name', key: 'name' },
         { label: 'OrgCode', key: 'orgcode' },
         { label: 'Organization', key: 'organization' }

      );

      return { headers: headers, data: results };
   }

   return (
      <MaterialTable
         tableRef={tableRef}
         title="User Groups"
         isLoading={props.loading}
         components={{
            Toolbar: () => (
               <Box p={2}>
                  <Grid container justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h6">UserGroups</Typography>
                     </Grid>

                     <Grid item className={classes.actions}>

                        <CSVLink {...mapToCSV(props.userGroups)}
                           className="btn btn-primary"
                           target="_blank"
                           filename={'UserGroupsExport' + new Date().toISOString() + '.csv'}

                        >
                           <Button
                              variant="outlined"
                              startIcon={<FileDownloadIcon />}
                           >
                              Export all users
                           </Button>
                        </CSVLink>

                     </Grid>


                  </Grid>
               </Box>
            ),
         }}
         columns={[
            {
               title: "UserGroupId",
               field: "id",
               defaultSort: "asc",
               editable: "never"
            },
            {
               title: "GroupName",
               field: "groupName",
               customSort: (a, b) => a.groupName.localeCompare(b.groupName, 'en', { sensitivity: 'base' }),
               defaultSort: "asc",
               editable: currentUserCanManageUserGroups
            },
            {
               title: "Users",
               field: "users",
               filtering: true,
               customFilterAndSearch: (term, rowUser) => rowUser.users.some((u) => (u.organization.name+": "+ u.name).toLowerCase().includes(term.toLowerCase())),
               sorting: false,
               render: (rowUserGroup) => {
                  const users = _.orderBy(rowUserGroup.users, (u) => (u.organization.name + ": " + u.name).toLowerCase());

                  return (
                     <ul className={classes.scrollList}>
                        {users.map((u) => <li key={u.id}>{u.organization.name}: {u.name}</li>)}
                     </ul>
                  );
               },
               editable: currentUserCanManageUserGroups,
               editComponent: (editComponentProps) => (
                  <Autocomplete
                     multiple
                     handleHomeEndKeys={false}
                     autoComplete
                     size="small"
                     options={_.orderBy(
                        _.uniqBy(
                           props.getAvailableUsers.concat(editComponentProps.rowData.users),
                           (u) => u.id,
                        ),
                        [(u) => (u.organization) ? (u.organization.name + " : " + u.name).toLowerCase() : u.name.toLowerCase()],
                     )}
                     groupBy={(u) => u.organization.name}
                     filterSelectedOptions
                     isOptionEqualToValue={(u, value) => u.id === value.id}
                     getOptionLabel={(u) => u.name}
                     value={_.orderBy(editComponentProps.rowData.users, [u => (u.organization) ?
                        (u.organization.name + " : " + u.name).toLowerCase() :
                        u.name.toLowerCase()
                     ])}
                     onChange={(e, newValue) => editComponentProps.onChange(newValue)}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Users" />}
                     renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                           <Chip
                              size="small"
                              {...getTagProps({ index })}
                              label={option.organization.name + ": " + option.name}
                           />
                        ));
                     }}
                  />
               ),
            }
            
         ]}
         data={props.userGroups.map((ug) => ({ ...ug })) ?? []}
         options={{
            filtering: true,
            search: false,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100],
            emptyRowsWhenPaging: false,
            paginationPosition: "both",
            grouping: false,
            toolbar: true
         }}
         editable={{
            isEditable: currentUserCanManageUserGroups,
            onRowUpdate: editUserGroup,
            isDeletable: currentUserCanManageUserGroups,
            onRowDelete: deleteUserGroup
         }}
         actions={[
         ]}
         localization={{

            body: {
               deleteTooltip: "Delete User Group?",
               editRow: {
                  deleteText: "Are you sure you want to unlink these users?",
               },
            },
         }}
         />
   );
};
