import { useQuery, useMutation, gql } from "@apollo/client";
import {   Grid, Box, Typography, Button, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "users/useCurrentUser";
import _ from "lodash";
import { Jurisdiction } from "documents";
import { Product } from "products";
import { useNotifications } from "notifications";
import { Organization } from "organizations";
import UpsertProductAnnouncementDialog from "./UpsertProductAnnouncementDialog";
import UpsertMarketingAnnouncementDialog from "./UpsertMarketingAnnouncementDialog";
import MaterialTable from "@material-table/core";
import { Announcement, MarketingAnnouncement } from "./models";
import moment from "moment";
import { announcementStyles, editAnnouncementPropertiesFragment, editMarketingAnnouncementPropertiesFragment } from "announcements";
import { Permissions } from "users";
import clsx from "clsx";
import MarketingAnnouncementDisplay from "./MarketingAnnouncementDisplay";
import { dateFormat } from "application/formats";
import DOMPurify from "dompurify";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
      backgroundImage: {
         maxWidth: "10em",
         maxHeight: "10em",
      },
   }),
);

export const AnnouncementScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { userHasPermission, userLoading } = useCurrentUser();

   const [creatingProductAnnouncement, setCreatingProductAnnouncement] = useState(false);
   const [productAnnouncementBeingEdited, setProductAnnouncementBeingEdited] = useState<Announcement | null>(null);
   const [showExpiredProductAnnouncements, setShowExpiredProductAnnouncements] = useState(false);

   const [creatingMarketingAnnouncement, setCreatingMarketingAnnouncement] = useState(false);
   const [marketingAnnouncementBeingEdited, setMarketingAnnouncementBeingEdited] = useState<MarketingAnnouncement | null>(null);
   const [showExpiredMarketingAnnouncements, setShowExpiredMarketingAnnouncements] = useState(false);

   const optionsQuery = useQuery<
      { organizations: Organization[]; products: Product[]; jurisdictions: Jurisdiction[] },
      { includeAllProducts: boolean }
   >(
      gql`
         query GetAnnouncementScreenOptions($includeAllProducts: Boolean) {
            organizations(includeWyth: true) {
               id
               name
               code
            }
            products(includeAllProducts: $includeAllProducts) {
               id
               name
               program {
                  id
                  name
               }
            }
            jurisdictions {
               id
               name
            }
         }
      `,
      {
         variables: {
            includeAllProducts: userHasPermission(Permissions.ManageAnnouncementsOutsideYourProducts),
         },
         skip: userLoading,
      },
   );

   const organizationOptions = _.orderBy(optionsQuery.data?.organizations, (o) => o.name.toLowerCase()) ?? [];
   const productOptions = _.orderBy(optionsQuery.data?.products, (p) => p.name.toLowerCase()) ?? [];
   const jurisdictionOptions = _.orderBy(optionsQuery.data?.jurisdictions, (j) => j.name.toLowerCase()) ?? [];

   const productAnnouncementsQuery = useQuery<{ announcements: Announcement[] }, { currentDate: Date; includeExpired: boolean }>(
      gql`
         query GetProductAnnouncementsForAnnouncementScreen($currentDate: DateTime!, $includeExpired: Boolean!) {
            announcements(
               currentDate: $currentDate
               includeUpcoming: true
               includeExpired: $includeExpired
               includeAllOrganizations: true
            ) {
               ...editAnnouncementProperties
            }
         }
         ${editAnnouncementPropertiesFragment}
      `,
      {
         variables: { currentDate: moment().startOf("day").toDate(), includeExpired: showExpiredProductAnnouncements },
         skip: userLoading,
      },
   );

   const [deleteAnnouncementMutate] = useMutation<{ announcement: { delete: Announcement } }, { id: number }>(
      gql`
         mutation DeleteAnnouncement($id: Int!) {
            announcement {
               delete(id: $id) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetProductAnnouncementsForAnnouncementScreen", "GetProgramAnnouncements", "GetProductAnnouncements"],
      },
   );

   async function deleteAnnouncement(announcement: Announcement) {
      const deleteResult = await deleteAnnouncementMutate({ variables: { id: announcement.id } });

      if (deleteResult.data?.announcement.delete) {
         notifications.success("Announcement deleted.");
      }
   }

   const marketingAnnouncementsQuery = useQuery<
      { marketingAnnouncements: MarketingAnnouncement[] },
      { currentDate: Date; includeExpired: boolean }
   >(
      gql`
         query GetMarketingAnnouncementsForAnnouncementScreen($currentDate: DateTime!, $includeExpired: Boolean!) {
            marketingAnnouncements(currentDate: $currentDate, includeUpcoming: true, includeExpired: $includeExpired) {
               ...editMarketingAnnouncementProperties
            }
         }
         ${editMarketingAnnouncementPropertiesFragment}
      `,
      {
         variables: { currentDate: moment().startOf("day").toDate(), includeExpired: showExpiredMarketingAnnouncements },
      },
   );

   const [deleteMarketingAnnouncementMutate] = useMutation<{ marketingAnnouncement: { delete: MarketingAnnouncement } }, { id: number }>(
      gql`
         mutation DeleteMarketingAnnouncement($id: Int!) {
            marketingAnnouncement {
               delete(id: $id) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetMarketingAnnouncementsForAnnouncementScreen", "GetMarketingAnnouncements"],
      },
   );

   async function deleteMarketingAnnouncement(announcement: MarketingAnnouncement) {
      const deleteResult = await deleteMarketingAnnouncementMutate({ variables: { id: announcement.id } });

      if (deleteResult.data?.marketingAnnouncement.delete) {
         notifications.success("Announcement deleted.");
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Announcements - Concentra Partner Portal</title>
         </Helmet>

         <Grid container direction="column" spacing={6}>
            {userHasPermission(Permissions.ManageAnnouncements) && (
               <Grid item container direction="column" spacing={3}>
                  <Grid item container spacing={3} justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h3">Product announcements</Typography>
                     </Grid>
                     <Grid item>
                        <Button variant="contained" color="secondary" onClick={() => setCreatingProductAnnouncement(true)}>
                           Post a product announcement
                        </Button>
                     </Grid>
                  </Grid>

                  <Grid item>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={showExpiredProductAnnouncements}
                              onChange={(e) => setShowExpiredProductAnnouncements(e.target.checked)}
                              color="primary"
                           />
                        }
                        label="Show expired"
                     />
                  </Grid>

                  <Grid item>
                     <MaterialTable
                        columns={[
                           {
                              title: "Effective date",
                              field: "effectiveDate",
                              render: (announcement) => moment.utc(announcement.effectiveDate).format(dateFormat),
                              defaultSort: "desc",
                              filtering: false,
                              width: "5em",
                           },
                           {
                              title: "Expiry date",
                              field: "expiryDate",
                              render: (announcement) => moment.utc(announcement.expiryDate).format(dateFormat),
                              filtering: false,
                              width: "5em",
                           },
                           {
                              title: "Products",
                              field: "products",
                              filtering: true,
                              customFilterAndSearch: (term, announcement) =>
                                 announcement.products.some((p) => p.name.toLowerCase().indexOf(term.toLowerCase()) > 0),
                              sorting: false,
                              width: "10em",
                              render: (announcement) => {
                                 return (
                                    <>
                                       {announcement.products.length > 0
                                          ? announcement.products.map((p) => (
                                               <Box mb={1} key={p.id}>
                                                  {p.name}
                                               </Box>
                                            ))
                                          : "--"}
                                    </>
                                 );
                              },
                           },

                           {
                              title: "Jurisdictions",
                              field: "jurisdictions",
                              filtering: true,
                              customFilterAndSearch: (term, announcement) =>
                                 announcement.jurisdictions.some((j) => j.name.toLowerCase().indexOf(term.toLowerCase()) > 0),
                              sorting: false,
                              width: "5em",
                              render: (announcement) => {
                                 return (
                                    <div>
                                       {announcement.jurisdictions.length > 0
                                          ? announcement.jurisdictions.map((p) => <div key={p.id}>{p.name}</div>)
                                          : "--"}
                                    </div>
                                 );
                              },
                           },

                           {
                              title: "Organization",
                              field: "organization",
                              filtering: true,
                              customFilterAndSearch: (term, announcement) =>
                                 announcement.organization !== null &&
                                 announcement.organization.name.toLowerCase().indexOf(term.toLowerCase()) > 0,
                              sorting: false,
                              width: "10em",
                              render: (announcement) => {
                                 return announcement.organization ? announcement.organization.name : "--";
                              },
                           },

                           {
                              title: "Title",
                              field: "title",
                              sorting: true,
                              width: "16em",
                           },

                           {
                              title: "Content",
                              field: "htmlContent",
                              sorting: false,
                              width: "24em",
                              render: (announcement) => (
                                 <div
                                    className={clsx("ql-editor", classes.htmlContent)}
                                    dangerouslySetInnerHTML={{
                                       __html: DOMPurify.sanitize(announcement.htmlContent),
                                    }}
                                 />
                              ),
                           },
                        ]}
                        data={(productAnnouncementsQuery.data?.announcements || []).map((a) => ({ ...a }))}
                        options={{ filtering: true, search: false, paging: false, grouping: false, toolbar: false }}
                        actions={[
                           {
                              icon: "edit",
                              tooltip: "Edit",
                              onClick: (e, announcement) => setProductAnnouncementBeingEdited(announcement as Announcement),
                           },
                        ]}
                        editable={{
                           isEditable: (a) => false,
                           isDeletable: (a) => true,
                           onRowDelete: deleteAnnouncement,
                        }}
                        localization={{
                           body: {
                              editRow: {
                                 deleteText: "Are you sure you want to delete this announcement?",
                              },
                           },
                        }}
                     />
                  </Grid>
               </Grid>
            )}

            {userHasPermission(Permissions.ManageMarketingAnnouncements) && (
               <Grid item container direction="column" spacing={3}>
                  <Grid item container spacing={3} justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h3">Marketing announcements</Typography>
                     </Grid>
                     <Grid item>
                        <Button variant="contained" color="secondary"  onClick={() => setCreatingMarketingAnnouncement(true)}>
                           Post a marketing announcement
                        </Button>
                     </Grid>
                  </Grid>

                  <Grid item>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={showExpiredMarketingAnnouncements}
                              onChange={(e) => setShowExpiredMarketingAnnouncements(e.target.checked)}
                              color="primary"
                           />
                        }
                        label="Show expired"
                     />
                  </Grid>

                  <Grid item>
                     <MaterialTable
                        columns={[
                           {
                              title: "Effective date",
                              field: "effectiveDate",
                              render: (announcement) => moment.utc(announcement.effectiveDate).format(dateFormat),
                              defaultSort: "desc",
                              filtering: false,
                              width: "5em",
                           },
                           {
                              title: "Expiry date",
                              field: "expiryDate",
                              render: (announcement) => moment.utc(announcement.expiryDate).format(dateFormat),
                              filtering: false,
                              width: "5em",
                           },

                           {
                              title: "Title",
                              field: "title",
                              sorting: true,
                              width: "16em",
                           },

                           {
                              title: "Body",
                              field: "body",
                              sorting: false,
                              width: "16em",
                           },

                           {
                              title: "Link",
                              field: "link",
                              sorting: false,
                              width: "16em",
                           },

                           {
                              title: "Background image",
                              field: "encodedImage",
                              filtering: false,
                              sorting: false,
                              width: "12em",
                              render: (announcement) =>
                                 announcement.encodedImage ? (
                                    <img
                                       alt="Announcement background"
                                       className={classes.backgroundImage}
                                       src={announcement.encodedImage}
                                    />
                                 ) : (
                                    <div>none</div>
                                 ),
                           },

                           {
                              title: "Invert text",
                              field: "invertText",
                              filtering: false,
                              sorting: false,
                              width: "12em",
                              render: (announcement) => (announcement.invertText ? "Yes" : "No"),
                           },

                           {
                              title: "Preview",
                              sorting: false,
                              width: "24em",
                              render: (announcement) => <MarketingAnnouncementDisplay announcement={announcement} />,
                              editable: "never",
                           },
                        ]}
                        data={(marketingAnnouncementsQuery.data?.marketingAnnouncements || []).map((a) => ({ ...a }))}
                        options={{ filtering: true, search: false, paging: false, grouping: false, toolbar: false }}
                        editable={{
                           isEditable: (a) => false,
                           isDeletable: (a) => true,
                           onRowDelete: deleteMarketingAnnouncement,
                        }}
                        actions={[
                           {
                              icon: "edit",
                              tooltip: "Edit",
                              onClick: (e, announcement) => setMarketingAnnouncementBeingEdited(announcement as MarketingAnnouncement),
                           },
                        ]}
                        localization={{
                           body: {
                              editRow: {
                                 deleteText: "Are you sure you want to delete this announcement?",
                              },
                           },
                        }}
                     />
                  </Grid>
               </Grid>
            )}
         </Grid>

         <UpsertProductAnnouncementDialog
            open={creatingProductAnnouncement || productAnnouncementBeingEdited !== null}
            announcementToEdit={productAnnouncementBeingEdited}
            handleClose={() => {
               setCreatingProductAnnouncement(false);
               setProductAnnouncementBeingEdited(null);
            }}
            organizations={organizationOptions}
            products={productOptions}
            jurisdictions={jurisdictionOptions}
         />

         <UpsertMarketingAnnouncementDialog
            open={creatingMarketingAnnouncement || marketingAnnouncementBeingEdited !== null}
            announcementToEdit={marketingAnnouncementBeingEdited}
            handleClose={() => {
               setCreatingMarketingAnnouncement(false);
               setMarketingAnnouncementBeingEdited(null);
            }}
         />
      </div>
   );
};

export function getEncodedImage(imageString: string) {
   const imgTagMatch = /^.*<img src="(.+)">.*$/.exec(imageString);
   return imgTagMatch && imgTagMatch.length > 0 ? imgTagMatch[1] : imageString;
}
