import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {   DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";
import { ProductLink } from "products";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      deleteButton: {
         color: theme.palette.error.main,
      },
   }),
);

interface Props {
   handleClose: () => void;
   linkId: number | null;
   linkTitle: string | null;
}

const DeleteProductLinkDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const [deleting, setDeleting] = useState(false);

   const [deleteMutate] = useMutation<{ productLink: { delete: ProductLink } }, { linkId: number }>(
      gql`
         mutation DeleteProductLink($linkId: Int!) {
            productLink {
               delete(id: $linkId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetProductLinks"],
      },
   );

   async function deleteLink() {
      setDeleting(true);
      const deleteResult = await deleteMutate({ variables: { linkId: props.linkId! } });

      if (deleteResult.data?.productLink?.delete?.id) {
         notifications.success("Deleted link.");
      }

      setDeleting(false);
      props.handleClose();
   }

   if (!props.linkId) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>{`Delete "${props.linkTitle}" link?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>Do you want to delete the link?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Delete" color="primary" inProgress={deleting} onClick={deleteLink} className={classes.deleteButton} />
         </DialogActions>
      </Dialog>
   );
};

export default DeleteProductLinkDialog;
