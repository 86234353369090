import { useQuery, gql } from "@apollo/client";
import {   Grid, Box, Typography, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { AccessReview } from "./models";
import { Role, Permissions } from "users";
import { accessReviewPropertiesFragment } from ".";
import { UserAccessReviewTable } from "./UserAccessReviewTable";
import { Skeleton } from "@mui/material";
import useCurrentUser from "../users/useCurrentUser";
import CreateAccessReviewScheduleDialog from "./CreateAccessReviewScheduleDialog";
import WythAccessReviewTile from "./WythAccessReviewTile";
import EmailAccessReviewDialog from "./EmailAccessReviewDialog";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         "& > button:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
      },
   }),
);



export const UserAccessReviewScreen: React.FunctionComponent = () => {
   const classes = useStyles();

   const { user, userHasPermission, userLoading } = useCurrentUser();
   const userCanAdministrateUserAccessReviews = userHasPermission(Permissions.AdministrateUserAccessReviews);
   const [startingCreate, setStartingCreate] = useState(false);
   const [startingEmail, setStartingEmail] = useState(false);

   function handleNewAccessReviewSchedule() {
      setStartingCreate(false);
   }

   function handleEmailAccessReviewNotices() {
      setStartingEmail(false);
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`User Access Reviews - Concentra Partner Portal`}</title>
         </Helmet>
         <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between">
               <Grid item>
                  <Typography variant="h4">User access reviews</Typography>
               </Grid>
               <Grid item className={classes.actions}>
                  {userCanAdministrateUserAccessReviews && (
                     <Button variant="outlined" color="primary" onClick={() => setStartingCreate(true)}>
                        Start cycle
                     </Button>
                  )}
                  {userCanAdministrateUserAccessReviews && (
                     <Button variant="outlined" color="primary" onClick={() => setStartingEmail(true)}>
                        Email partner administrators
                     </Button>
                  )}
                  </Grid>
            </Grid>
            <Grid item xs={12}>
               <Grid container direction="column" spacing={3}>
                  <Grid item>
                  <WythAccessReviewTile />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <CreateAccessReviewScheduleDialog
            open={startingCreate}
            handleClose={handleNewAccessReviewSchedule}
         />

         <EmailAccessReviewDialog
            open={startingEmail}
            handleClose={handleEmailAccessReviewNotices}
         />

      </div>
   );
}