import { useMutation, gql } from "@apollo/client";
import { Chip,  Grid,  TextField } from "@mui/material";
import {Button} from "@mui/material";
import {Dialog} from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "SpinnerButton";
import { Product } from "products";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel, AutocompleteRenderInputParams } from "formik-mui";
import Autocomplete from "@mui/material/Autocomplete";

import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import { Organization, OrganizationInput } from "organizations";
import ClosableDialogTitle from "application/ClosableDialogTitle";
import { dialogStyle } from "application";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   handleClose: (addedOrganizationId?: number) => void;
   organizationToEdit: Organization | null;
   products: Product[];
   existingOrganizations: Organization[];
}

interface FormValues {
   name: string;
   code: string;
   alternateCodes: string[];
   emailDomain: string;
   alternateEmailDomains: string[];
   hasAzureAd: boolean;
   products: Product[];
   lastUpdated: null | string | Date;
}

const UpsertOrganizationDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const adding = props.organizationToEdit === null;

   const initialFormValues: FormValues = {
      name: adding ? "" : props.organizationToEdit!.name,
      code: adding ? "" : props.organizationToEdit!.code,
      alternateCodes: adding ? [] : props.organizationToEdit!.alternateCodes?.split(";") ?? [],
      emailDomain: adding ? "" : props.organizationToEdit!.emailDomain ?? "",
      alternateEmailDomains: adding ? [] : props.organizationToEdit!.alternateEmailDomains?.split(";") ?? [],
      hasAzureAd: adding ? false : props.organizationToEdit!.hasAzureAd,
      products: adding
         ? []
         : _.orderBy(
            props.products.filter((p) => props.organizationToEdit!.products.some((op) => op.id === p.id)),
            (p) => p.name,
         ),
      lastUpdated: adding ? null : props.organizationToEdit!.lastUpdated
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.name.trim().length === 0) {
         errors.name = "Enter a name.";
      }

      const trimmedCode = values.code.trim();

      if (trimmedCode.length === 0) {
         errors.code = "Enter a primary code.";
      }

      function orgUsesCode(org: Organization, code: string) {
         const alternateCodes = org.alternateCodes ? org.alternateCodes.split(";") : [];
         return org.code === code || alternateCodes.some((ac) => ac === code);
      }

      if (props.existingOrganizations.some((o) => o.id !== props.organizationToEdit?.id && orgUsesCode(o, trimmedCode))) {
         errors.code = "This code is in use by an existing organization.";
      }

      if (
         values.alternateCodes.some((ac) =>
            props.existingOrganizations.some((o) => o.id !== props.organizationToEdit?.id && orgUsesCode(o, ac.trim())),
         )
      ) {
         errors.alternateCodes = "One of these codes is in use by an existing organization.";
      }
      return errors;
   }

   function getOrgInputFromFormValues(values: FormValues) {
      return {
         id: adding ? 0 : props.organizationToEdit!.id,
         name: values.name.trim(),
         code: values.code.trim(),
         alternateCodes: values.alternateCodes.length > 0 ? values.alternateCodes : [],
         emailDomain: values.emailDomain.trim().length > 0 ? values.emailDomain.trim() : null,
         alternateEmailDomains: values.alternateEmailDomains.length > 0 ? values.alternateEmailDomains : [],
         hasAzureAd: values.hasAzureAd,
         productIds: values.products.map((p) => p.id),
         lastUpdated: values.lastUpdated
      };
   }

   const organizationPropertiesFragment = gql`
      fragment organizationProperties on Organization {
         id
         name
         code
         alternateCodes
         emailDomain
         alternateEmailDomains
         hasAzureAd
         products {
            id
         }
      }
   `;

   const [addMutate] = useMutation<{ organization: Organization }, { organization: OrganizationInput }>(
      gql`
         mutation AddOrganization($organization: OrganizationInput!) {
            organization {
               add(organization: $organization) {
                  ...organizationProperties
               }
            }
         }
         ${organizationPropertiesFragment}
      `,
      {
         refetchQueries: ["GetOrganizationScreenOptions"],
      },
   );

   async function createOrganization(values: FormValues, actions: FormikHelpers<FormValues>) {
      const organizationToCreate = getOrgInputFromFormValues(values);
      const updateResult = await addMutate({ variables: { organization: organizationToCreate } });

      if (updateResult.data?.organization) {
         notifications.success("Organization added.");
      }

      actions.setSubmitting(false);
      props.handleClose(updateResult.data?.organization.id);
   }
   const [updateMutate] = useMutation<{ organization: { update: Organization } }, { organization: OrganizationInput }>(
      gql`
         mutation UpdateOrganization($organization: OrganizationInput!) {
            organization {
               update(organization: $organization) {
                  id
                  name
                  code
                  alternateCodes
                  emailDomain
                  alternateEmailDomains
                  hasAzureAd
                  products {
                     id
                  }
               }
            }
         }
      `,
      {
         refetchQueries: ["GetOrganizationForEdit"],
      },
   );

   async function updateOrganization(values: FormValues, actions: FormikHelpers<FormValues>) {
      const editedOrganization = getOrgInputFromFormValues(values);
      const updateResult = await updateMutate({ variables: { organization: editedOrganization } });

      if (updateResult.data?.organization.update) {
         notifications.success("Organization updated.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog
         open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         maxWidth="sm"
         fullWidth={true}
         scroll="paper"
      >
         <Formik
            initialValues={initialFormValues}
            validate={validate}
            onSubmit={adding ? createOrganization : updateOrganization}
            enableReinitialize={true}
         >
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle title={adding ? "Add organization" : "Edit organization"} handleClose={props.handleClose} />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="name"
                                       variant="outlined"
                                       size="small"
                                       label="Name"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                       error={formikProps.errors.name && formikProps.submitCount > 0}
                                       helperText={formikProps.errors.name}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={6}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="code"
                                       variant="outlined"
                                       size="small"
                                       label="Primary code"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                       error={formikProps.errors.code && formikProps.submitCount > 0}
                                       helperText={formikProps.errors.code}
                                    />
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormikField name="alternateCodes">
                                       {(fieldProps: FieldProps<string[]>) => (
                                          <Autocomplete
                                             multiple
                                             freeSolo
                                             options={[]}
                                             value={fieldProps.field.value}
                                             onChange={(e, newValue) => {
                                                fieldProps.form.setFieldValue("alternateCodes", newValue);
                                             }}
                                             renderTags={(value: string[], getTagProps: any) =>
                                                value.map((option: string, index: number) => (
                                                   <Chip label={option} size="small" {...getTagProps({ index })} />
                                                ))
                                             }
                                             renderInput={(params) => (
                                                <TextField
                                                   {...params}
                                                   variant="outlined"
                                                   size="small"
                                                   label="Alternate codes"
                                                   error={formikProps.errors.alternateCodes !== undefined && formikProps.submitCount > 0}
                                                   helperText={formikProps.errors.alternateCodes}
                                                />
                                             )}
                                             disabled={formikProps.isSubmitting}
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={6}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="emailDomain"
                                       variant="outlined"
                                       size="small"
                                       label="Primary email domain"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                       error={formikProps.errors.emailDomain && formikProps.submitCount > 0}
                                       helperText={formikProps.errors.emailDomain}
                                    />
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormikField name="alternateEmailDomains">
                                       {(fieldProps: FieldProps<string[]>) => (
                                          <Autocomplete
                                             multiple
                                             freeSolo
                                             options={[]}
                                             value={fieldProps.field.value}
                                             onChange={(e, newValue) => {
                                                fieldProps.form.setFieldValue("alternateEmailDomains", newValue);
                                             }}
                                             renderTags={(value: string[], getTagProps: any) =>
                                                value.map((option: string, index: number) => (
                                                   <Chip label={option} size="small" {...getTagProps({ index })} />
                                                ))
                                             }
                                             renderInput={(params) => (
                                                <TextField {...params} variant="outlined" size="small" label="Alternate email domains (press enter)" />
                                             )}
                                             disabled={formikProps.isSubmitting}
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiCheckboxWithLabel}
                                       type="checkbox"
                                       name="hasAzureAd"
                                       Label={{ label: "Organization has Microsoft 365" }}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="products"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       limitTags={3}
                                       options={_.orderBy(props.products, [(p) => p.program.name, (p) => p.name])}
                                       groupBy={(p: Product) => p.program.name}
                                       getOptionLabel={(option: Product) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Products"
                                             error={formikProps.touched.products && formikProps.errors.products !== undefined}
                                             helperText={formikProps.touched.products ? formikProps.errors.products : undefined}
                                          />
                                       )}
                                       filterSelectedOptions
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={() => props.handleClose()}>Cancel</Button>
                        <SpinnerButton
                           label={adding ? "Add" : "Save"}
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default UpsertOrganizationDialog;
