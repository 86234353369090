import React, { useState, useEffect, useContext, createContext, PropsWithChildren } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useSettings } from "./SettingsProvider";

export const AppInsightsContext = createContext<ApplicationInsights>(
   new ApplicationInsights({
      config: {
         instrumentationKey: "",
      },
   }),
);

type Props = React.PropsWithChildren<{}>;

export const AppInsightsProvider: React.FunctionComponent<Props> = props => {
   const [appInsights, setAppInsights] = useState<ApplicationInsights>(new ApplicationInsights({ config: {} }));
   const { appInsightsKey } = useSettings();

   useEffect(() => {
      if (!!appInsightsKey) {
         const appInsightsInstance = new ApplicationInsights({
            config: {
               instrumentationKey: appInsightsKey,
            },
         });
         appInsightsInstance.loadAppInsights();
         appInsightsInstance.trackPageView();

         setAppInsights(appInsightsInstance);
      }
   }, [appInsightsKey]);

   return <AppInsightsContext.Provider value={appInsights}>{props.children}</AppInsightsContext.Provider>;
};

export const useAppInsights = () => {
   return useContext(AppInsightsContext);
};
