import React from "react";
import {   Typography, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from '@mui/styles';
import { DialogTitle } from "@mui/material";

const useStyles = makeStyles((theme) =>
   createStyles({
      dialogTitle: {
         margin: 0,
         padding: theme.spacing(2),
      },
      closeButton: {
         position: "absolute",
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: theme.palette.grey[500],
      },
   }),
);

interface Props {
   title: string;
   handleClose?: () => void;
}

const ClosableDialogTitle: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   return (
      <DialogTitle typography="false" className={classes.dialogTitle}>
         <Typography variant="h6">{props.title}</Typography>
         <IconButton className={classes.closeButton} onClick={props.handleClose ? () => props.handleClose!() : undefined}>
            <CloseIcon />
         </IconButton>
      </DialogTitle>
   );
};

export default ClosableDialogTitle;
