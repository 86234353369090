import React, { useState, useMemo } from "react";
import { Theme,   TextField, Grid, Typography, Card, FormControlLabel, Checkbox, CardHeader, CardContent, Box, FormHelperText } from "@mui/material";
import { Helmet } from "react-helmet";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import useCurrentUser from "../users/useCurrentUser";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpIcon from "@mui/icons-material/Help";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Formik, Form as FormikForm, Field as FormikField, FieldProps, FieldArray, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import SpinnerButton from "SpinnerButton";
import * as Yup from 'yup';
import { gql, useQuery } from "@apollo/client";
import { Rate, RateChangeType, RateTerm, User } from ".";
import { useNotifications } from "notifications";
import { useAuthentication } from "auth/AuthenticationProvider";
import { useApi } from "api";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      deleteContainer: {
         marginTop: theme.spacing(5)
      },
      deleteButton: {
         color: theme.palette.error.main,
      },
      rates: {
         border: '1px solid #adadad',
         borderRadius: '4px',
         marginTop: theme.spacing(1)
      },
      arrow: {
         color: theme.palette.common.black,
      }
   }),
);

interface FormValues {
   user: User | null;
   rateChangeType: RateChangeType | null;
   effectiveDate: Date | null;
   cashableRate: RateTerm;
   variableRate: RateTerm;
   hisaRate: RateTerm;
   hisaPromoRate: RateTerm;
   nonRedeemable: RateTerm[];
   retailGIC: RateTerm[];
   rate: Rate | null;
}

export const InternalRateChangeForm: React.FunctionComponent = () => {
   const classes = useStyles();
   const { user } = useCurrentUser();
   const notifications = useNotifications();
   const [isCashable, setIsCashable] = useState(false);
   const [isVariable, setIsVariable] = useState(false);
   const [isHisaPromoRate, setIsHisaPromoRate] = useState(false);
   const { getAccessToken } = useAuthentication();
   const { postAsync } = useApi();
   const initialFormValues: FormValues = {
      user: null,
      rate: null,
      effectiveDate: null,
      rateChangeType: null,
      cashableRate: { term: "1", value: "" },
      variableRate: { term: "1", value: "" },
      hisaRate: { term: "1", value: "" },
      hisaPromoRate: { term: "1", value: "" },
      nonRedeemable: [
         { term: "1", value: "" },
         { term: "2", value: "" },
         { term: "3", value: "" },
         { term: "4", value: "" },
         { term: "5", value: "" }
      ],
      retailGIC: [
         { term: "1", value: "" },
         { term: "1.5", value: "" },
         { term: "2", value: "" },
         { term: "3", value: "" },
         { term: "4", value: "" },
         { term: "5", value: "" }
      ]
   };

   const validationSchema = Yup.object().shape({
      user: Yup.object().shape({
         username: Yup.string().nullable().required('This Field is Required'),
         userEmail: Yup.string().email("Enter a valid email").required('This Field is Required'),
      }),
      effectiveDate: Yup.date().nullable().required('This Field is Required').min(new Date(new Date().setHours(0, 0, 0, 0)), "Date must be the present or a future date"),
      rate: Yup.object().shape({
         rateID: Yup.number(),
         rateName: Yup.string()
      }).notRequired(),
      rateChangeType: Yup.object().shape({
         rateChangeTypeID: Yup.number(),
         rateChangeTypeName: Yup.string()
      }).typeError('This field is Required'),
      cashableRate: Yup.object().when(("rateChangeType"), {
         is: (rateChangeType: RateChangeType) => { return (rateChangeType !== null && (rateChangeType?.rateChangeTypeName === "Credit Union Referred" || rateChangeType?.rateChangeTypeName === "Nominee") && isCashable) },
         then: (s) => Yup.object().shape({
            value: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })
      }),
      variableRate: Yup.object().when(("rateChangeType"), {
         is: (rateChangeType: RateChangeType) => { return (rateChangeType !== null && (rateChangeType?.rateChangeTypeName === "Credit Union Referred" || rateChangeType?.rateChangeTypeName === "Nominee") && isVariable) },
         then: (s) => Yup.object().shape({
            value: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })
      }),
      hisaRate: Yup.object().when("rateChangeType", {
         is: (rateChangeType: RateChangeType) => { return rateChangeType !== null && rateChangeType?.rateChangeTypeName === "Retail Online HISA" && !isHisaPromoRate },
         then: (s) => Yup.object().shape({
            value: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })
      }),
      hisaPromoRate: Yup.object().when("rateChangeType", {
         is: (rateChangeType: RateChangeType) => { return rateChangeType !== null && rateChangeType?.rateChangeTypeName === "Retail Online HISA" && isHisaPromoRate },
         then: (s) => Yup.object().shape({
            value: Yup.number().required('This Field is Required').typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })
      }),
      nonRedeemable: Yup.array().when("rateChangeType", {
         is: (rateChangeType: RateChangeType) => {
            return rateChangeType !== null ? (!isCashable && !isVariable && (rateChangeType?.rateChangeTypeName === "Nominee" || rateChangeType.rateChangeTypeName === "Credit Union Referred")) : false
         },
         then: (s) => Yup.array().of(Yup.object().shape({
            value: Yup.number().typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })).test("oneValue", "There must be at least one rate change.", (nr) => {
            return nr?.some(n => (n.value ?? 0) > 0) ?? false;
         })
      }),
      retailGIC: Yup.array().when("rateChangeType", {
         is: (rateChangeType: RateChangeType) => {
            return rateChangeType !== null ? rateChangeType?.rateChangeTypeName === "Retail Online GIC" : false
         },
         then: (s) => Yup.array().of(Yup.object().shape({
            value: Yup.number().typeError('This field must be a valid number')
               .min(0.0001, "Value must be above 0.001").max(9.999, "value must be below 9.999")
               .test("maxPrecision", "number field must have 3 digits after the decimal or less", (value) => value ? /^\d+(\.{1}\d{0,3})?$/.test(value.toString()): true)
         })).test("oneValue", "There must be at least one rate change.", (gic) => {
            return gic?.some(g => (g.value ?? 0) > 0) ?? false;
         })
      })
   })

   const rateChangeTypeQuery = useQuery<{ ratechangetypes: RateChangeType[] }, { userIsWyth: boolean }>(
      gql`
         query GetRateChangeTypes {
            ratechangetypes{
               rateChangeTypeID
               rateChangeTypeName
            }
         }
      `,
      {
         variables: {
            userIsWyth: user.isWyth,
         },
      },
   );
   const rateChangeTypes = useMemo(() => _.orderBy(rateChangeTypeQuery.data?.ratechangetypes, (rct) => rct.rateChangeTypeName) ?? [], [rateChangeTypeQuery.data]);

   const rateQuery = useQuery<{ rates: Rate[] }, { userIsWyth: boolean, rateIds: number[] }>(
      gql`
         query GetRates($rateIds: [Int]){
            rates(rateIds: $rateIds){
               rateID
               rateName
            }
         }
      `,
      {
         variables: {
            rateIds: [1, 2, 3, 4, 5],
            userIsWyth: user.isWyth
         },
      }
   );
   const rates = useMemo(() => _.orderBy(rateQuery.data?.rates, (r) => r.rateName) ?? [], [rateQuery.data]);

   function onChangeRateChange(newValue: RateChangeType | null, fieldProps: FieldProps) {
      setIsCashable(false);
      setIsVariable(false);
      setIsHisaPromoRate(false);

      var oldRateChangeType = fieldProps.form.values.rateChangeType;

      if (oldRateChangeType?.rateChangeTypeName !== newValue?.rateChangeTypeName) {
         fieldProps.form.resetForm({
            values: {
               ...initialFormValues,
               user: { username: user.name, userEmail: user.email }
            }
         });
         fieldProps.form.setFieldValue("rateChangeType", newValue);
      }
      fieldProps.form.setFieldValue("effectiveDate", null);

      if (newValue?.rateChangeTypeName !== "Retail Online HISA") {
         fieldProps.form.setFieldValue("rate", rates.find(r => r.rateName === "Non-redeemable"));
      } else {
         fieldProps.form.setFieldValue("rate", rates.find(r => r.rateName === "HISA"));
      }
   }

   function onChangeIsHisaPromoRate(newValue: boolean, fieldProps: FieldProps) {
      setIsHisaPromoRate(newValue);
      fieldProps.form.setFieldValue("hisaPromoRate", initialFormValues.hisaPromoRate);
      fieldProps.form.setFieldValue("hisaRate", initialFormValues.hisaRate);
      fieldProps.form.setFieldValue("rate", rates.find(r => newValue ? r.rateName === "HISA Promo" : r.rateName === "HISA"));
   }

   function onChangeIsCashableRate(newValue: boolean, fieldProps: FieldProps) {
      setIsCashable(newValue);
      fieldProps.form.setFieldValue("nonRedeemable", initialFormValues.nonRedeemable);
      fieldProps.form.setFieldValue("cashableRate", initialFormValues.variableRate);
      fieldProps.form.setFieldValue("rate", rates.find(r => newValue ? r.rateName === "Cashable" : r.rateName === "Non-redeemable"));
   }

   function onChangeIsVariableRate(newValue: boolean, fieldProps: FieldProps) {
      setIsVariable(newValue);
      fieldProps.form.setFieldValue("nonRedeemable", initialFormValues.nonRedeemable);
      fieldProps.form.setFieldValue("variableRate", initialFormValues.variableRate);
      fieldProps.form.setFieldValue("rate", rates.find(r => newValue ? r.rateName === "Variable" : r.rateName === "Non-redeemable"));
   }

   async function submitInternalRateChange(values: FormValues, actions: FormikHelpers<any>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postAsync(
               "/api/capitalmarket/new/internal",
               JSON.stringify({
                  user: values.user,
                  effectiveDate: values.effectiveDate,
                  rateChangeType: values.rateChangeType,
                  rate: values.rate,
                  cashableRate: {
                     term: values.cashableRate.term,
                     value: Number(values.cashableRate.value)
                  },
                  variableRate: {
                     term: values.variableRate.term,
                     value: Number(values.variableRate.value)
                  },
                  hisaRate: {
                     term: values.hisaRate.term,
                     value: Number(values.hisaRate.value)
                  },
                  hisaPromoRate: {
                     term: values.hisaPromoRate.term,
                     value: Number(values.hisaPromoRate.value)
                  },
                  nonRedeemable: values.nonRedeemable.map(nr => ({
                     term: nr.term,
                     value: nr.value == "" ? null : Number(nr.value)
                  })),
                  retailGIC: values.retailGIC.map(gic => ({
                     term: gic.term,
                     value: gic.value == "" ? null : Number(gic.value)
                  }))
               }),
               accessToken
            );
         }
         actions.resetForm();
         notifications.success("Rate posted.");
      } catch (error: any) {
         if (error instanceof Error) {
            actions.setSubmitting(false);
            notifications.error(error.message ?? error);
         }
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Internal Rate Change - Concentra Partner Portal</title>
         </Helmet>
         <Formik initialValues={{ ...initialFormValues, user: { username: user.name, userEmail: user.email } }} validationSchema={validationSchema} onSubmit={(values, actions) => { submitInternalRateChange(values, actions) }} enableReinitialize>
            {(formikProps) => {
               return (
                  <Grid container direction="column" spacing={3}>
                     <Grid item container justifyContent="space-between">
                        <Grid item>
                           <Typography variant="h4">Internal Rate Change Form</Typography>
                        </Grid>
                     </Grid>
                     <FormikForm>
                        <Box mb={0} p={2} pb={0} >
                           <Grid item container spacing={3}>
                              <Grid item xs={12} sm={6} lg={4} xl={4}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="user"
                                    variant="outlined"
                                    value={formikProps.values.user.username}
                                    label="User"
                                    fullWidth
                                    disabled
                                 />
                              </Grid>
                              <Grid item xs={12} sm={6} lg={4} xl={4}>
                                 <FormikField>
                                    {(fieldProps: FieldProps<String>) => (
                                       <Autocomplete
                                          inputValue={formikProps.values.rateChangeType?.rateChangeTypeName ?? ""}
                                          disabled={fieldProps.form.isSubmitting}
                                          autoComplete
                                          options={_.orderBy(rateChangeTypes, (rct) => rct.rateChangeTypeName) ?? []}
                                          getOptionLabel={(rct) => rct.rateChangeTypeName}
                                          onChange={(e, newValue) => onChangeRateChange(newValue, fieldProps)}
                                          renderInput={(params) => <TextField {...params} variant="outlined" label="Rate Change Type"
                                             error={formikProps.touched.rateChangeType && formikProps.errors.rateChangeType !== undefined}
                                             helperText={formikProps.touched.rateChangeType ? formikProps.errors.rateChangeType : undefined}
                                          />}
                                       />)}
                                 </FormikField>
                              </Grid>
                              <Grid item xs={12} sm={6} lg={4} xl={4}>
                                 <FormikField name="effectiveDate">
                                    {(fieldProps: FieldProps<Date>) => (
                                       <DatePicker
                                          inputFormat="yyyy-MM-dd"

                                          label="Effective Date"
                                          disabled={fieldProps.form.isSubmitting}
                                          value={fieldProps.field.value}
                                          minDate={formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee"
                                             || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred" ?
                                             new Date(Date.now() + (3600 * 1000 * 24)) : new Date()}
                                          renderInput={(params) =>
                                             <TextField  {...params}
                                                fullWidth
                                                error={
                                                   fieldProps.form.touched.effectiveDate && fieldProps.form.errors.effectiveDate !== undefined
                                                }
                                                helperText={
                                                   fieldProps.form.touched.effectiveDate ? fieldProps.form.errors.effectiveDate : undefined
                                                }
                                             />}
                                          onChange={(newDate : Date | null) =>
                                             fieldProps.form.setFieldValue("effectiveDate", newDate)
                                          }

                                       />)}
                                 </FormikField>
                              </Grid>
                           </Grid>
                           <Grid item container spacing={3}>
                              <Grid item xs={12} sm={6} lg={4} xl={2} hidden={!(formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee" || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred")}>
                                 <FormikField name="isVariable">
                                    {(fieldProps: FieldProps<boolean>) => {
                                       return (
                                          <FormControlLabel
                                             control={<Checkbox name="isVariable" checked={isVariable} onChange={(e, newValue) => onChangeIsVariableRate(newValue, fieldProps)} disabled={isCashable} />}
                                             label="Variable Rate"
                                          />
                                       )
                                    }}
                                 </FormikField>
                                 <Tooltip placement="right" title="Leave unchecked if not applicable" arrow className={classes.arrow}>
                                    <IconButton>
                                       <HelpIcon />
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} lg={4} xl={2} hidden={!(formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee" || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred")}>
                                 <FormikField name="isCashable">
                                    {(fieldProps: FieldProps<boolean>) => {
                                       return (
                                          <FormControlLabel
                                             control={<Checkbox name="isCashable" checked={isCashable} onChange={(e, newValue) => onChangeIsCashableRate(newValue, fieldProps)} disabled={isVariable} />}
                                             label="Cashable Rate"
                                          />
                                       )
                                    }}
                                 </FormikField>
                                 <Tooltip placement="right" title="Leave unchecked if not applicable" arrow className={classes.arrow}>
                                    <IconButton>
                                       <HelpIcon />
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={6} lg={4} xl={3} hidden={(formikProps.values.rateChangeType?.rateChangeTypeName !== "Retail Online HISA")}>
                                 <FormikField name="isHisaPromoRate">
                                    {(fieldProps: FieldProps<boolean>) => {
                                       return (
                                          <FormControlLabel
                                             control={<Checkbox name="isHisaPromoRate" checked={fieldProps.field.value} onChange={(e, newValue) => onChangeIsHisaPromoRate(newValue, fieldProps)} />}
                                             label="Is this a HISA Promo rate?"
                                          />
                                       )
                                    }}
                                 </FormikField>
                                 <Tooltip placement="right" title="Leave unchecked if not applicable" arrow className={classes.arrow}>
                                    <IconButton>
                                       <HelpIcon />
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                           </Grid>
                        </Box>
                        <Box mb={0} p={2} pb={0} >
                           {formikProps.values.rateChangeType?.rateChangeTypeName === "Retail Online HISA" ?
                              <Card>
                                 <CardContent>
                                    <Grid item container spacing={3}>
                                       {isHisaPromoRate ?
                                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                                             <FormikField
                                                component={FmuiTextField}
                                                name="hisaPromoRate.value"
                                                variant="outlined"
                                                value={formikProps.values.hisaPromoRate.value}
                                                label="HISA Promo Rate %"
                                                fullWidth
                                                disabled={formikProps.isSubmitting}
                                                error={
                                                   formikProps.touched.hisaPromoRate && formikProps.errors.hisaPromoRate !== undefined
                                                }
                                                helperText={
                                                   formikProps.touched.hisaPromoRate ? formikProps.errors.hisaPromoRate : undefined
                                                }
                                             />
                                          </Grid>
                                          :
                                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                                             <FormikField
                                                component={FmuiTextField}
                                                name="hisaRate.value"
                                                variant="outlined"
                                                value={formikProps.values.hisaRate.value}
                                                label="HISA Rate %"
                                                fullWidth
                                                disabled={formikProps.isSubmitting}
                                                error={
                                                   formikProps.touched.hisaRate && formikProps.errors.hisaRate !== undefined
                                                }
                                                helperText={
                                                   formikProps.touched.hisaRate ? formikProps.errors.hisaRate : undefined
                                                }
                                             />
                                          </Grid>
                                       }
                                    </Grid>
                                 </CardContent>
                              </Card> : null}
                           {formikProps.values.rateChangeType?.rateChangeTypeName === "Retail Online GIC" ?
                              <Box mt={2} mb={2} >
                              <Card>
                                 <CardHeader title="Retail Online GIC"
                                    subheader="Please only enter rates which require a change." />
                                 <CardContent>
                                    <Grid item container spacing={3} pb={0}>
                                       <FieldArray name="retailGIC">
                                          {() => (
                                             <>
                                                   {formikProps.values.retailGIC.map((rate, index) => (
                                                      <Grid item xs={12} sm={12} lg={12} xl={12} key={index}>
                                                      <FormikField
                                                         component={FmuiTextField}
                                                         name={`retailGIC.${index}.value`}
                                                         variant="outlined"
                                                         label={`${rate.term} Year Non-Redeemable %`}
                                                         fullWidth
                                                         disabled={formikProps.isSubmitting}
                                                      />
                                                   </Grid>
                                                ))}
                                             </>
                                          )}
                                       </FieldArray>
                                    </Grid>
                                       <Grid item container spacing={3} m={0} >
                                       <FormHelperText className="Mui-error MuiFormHelperText-contained">{formikProps.touched.retailGIC && !Array.isArray(formikProps.errors.retailGIC) ? formikProps.errors.retailGIC : undefined}</FormHelperText>
                                    </Grid>
                                 </CardContent>
                              </Card></Box> : null}
                           {(formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee" || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred") && !isVariable && !isCashable ?
                              <Card>
                                 <CardHeader title="Non-Redeemable"
                                    subheader="Please only enter rates which require a change." />
                                 <CardContent>
                                    <Grid item container spacing={3} pb={0}>
                                       <FieldArray name="nonRedeemable">
                                          {() => (
                                             <>
                                                {formikProps.values.nonRedeemable.map((rate, index) => (
                                                   <Grid item xs={12} sm={12} lg={12} xl={12} key={index}>
                                                      <FormikField
                                                         component={FmuiTextField}
                                                         name={`nonRedeemable.${index}.value`}
                                                         variant="outlined"
                                                         label={`${rate.term} Year Non-Redeemable %`}
                                                         fullWidth
                                                         disabled={formikProps.isSubmitting}
                                                      />
                                                   </Grid>
                                                ))}
                                             </>
                                          )}
                                       </FieldArray>
                                    </Grid>
                                    <Grid item container spacing={3} m={0} >
                                       <FormHelperText className="Mui-error MuiFormHelperText-contained">{formikProps.touched.nonRedeemable && !Array.isArray(formikProps.errors.nonRedeemable) ? formikProps.errors.nonRedeemable : undefined}</FormHelperText>
                                    </Grid>
                                 </CardContent>
                              </Card> : null}
                           {(formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee" || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred") && isVariable && !isCashable ?
                              <Card>
                                 <CardContent>
                                    <Grid item container spacing={3}>
                                       <Grid item xs={12} sm={12} lg={12} xl={12}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="variableRate.value"
                                             variant="outlined"
                                             value={formikProps.values.variableRate.value}
                                             label="Variable Rate %"
                                             fullWidth
                                             disabled={formikProps.isSubmitting}
                                          />
                                       </Grid>
                                    </Grid>
                                 </CardContent>
                              </Card> : null}
                           {(formikProps.values.rateChangeType?.rateChangeTypeName === "Nominee" || formikProps.values.rateChangeType?.rateChangeTypeName === "Credit Union Referred") && !isVariable && isCashable ?
                              <Card>
                                 <CardContent>
                                    <Grid item container spacing={3}>
                                       <Grid item xs={12} sm={12} lg={12} xl={12}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="cashableRate.value"
                                             variant="outlined"
                                             value={formikProps.values.cashableRate.value}
                                             label="Cashable Rate %"
                                             fullWidth
                                             disabled={formikProps.isSubmitting}
                                          />
                                       </Grid>
                                    </Grid>
                                 </CardContent>
                              </Card> : null}
                        </Box>
                        <Box mb={0} p={2} pb={0} >
                           <Grid item container spacing={3}>
                              <Grid item xs={12} sm={6} lg={4} xl={3}>
                                 <SpinnerButton
                                    label="Post"
                                    color="primary"
                                    variant="contained"
                                    inProgress={formikProps.isSubmitting}
                                    onClick={() => {
                                       formikProps.submitForm();
                                    }}
                                 />
                              </Grid>
                           </Grid>
                           <Grid item container spacing={3}>
                              <Grid item>
                                 <h3>Please contact <a href="mailto:deposits@eqbank.ca">deposits@eqbank.ca</a> for any questions or concerns regarding the form</h3>
                              </Grid>
                           </Grid>
                        </Box>
                     </FormikForm>
                  </Grid>
               )
            }}
         </Formik>
      </div>
   )
}
