import {   Grid, Box, Typography, Button, Link } from "@mui/material";
import React from "react";
import _ from "lodash";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { AccessReview } from "./models";
import { CSVLink } from "react-csv";
import { GetApp as FileDownloadIcon } from "@mui/icons-material";
import { RouteNames } from "../application/routes";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      scrollList: {
         overflowY: 'scroll',
         maxHeight: '9.4em',
         //WebkitMaskImage: "linear-gradient(to bottom, black 80px, transparent 120px)"
      }
   }),
);

interface Props {
   organizationReviewStatuses: AccessReview[];
}

export const OrganizationReviewStatusesTable: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const navigate = useNavigate();

   const tableData = props.organizationReviewStatuses ?? [];

   function getTimeMs(date? : Date) {
      return date != null ? new Date(date).getTime() : 0; 
   }

   function mapToCSV(data: AccessReview[]) {
      var results: any[] = [];

      data.forEach(a => {
         let entry: any;
         entry = {};

         if (a.date) {
            entry.date = (a.date as Date).toLocaleString();
         } else {
            entry.date = "Incomplete";
         }

         entry.orgcode = a.organization?.code ?? "";
         entry.orgname = a.organization?.name ?? "";
         entry.response = a.response ?? "No response";

         results.push(entry);
      });


      var headers = [];

      headers.push(
         { label: 'OrgCode', key: 'orgcode' },
         { label: 'Organization', key: 'orgname' },
         { label: 'Complete', key: 'response' },
         { label: 'Date', key: 'date' }
      );

      return { headers: headers, data: results.sort((a, b) => { return getTimeMs(b.date) - getTimeMs(a.date); }) };

   }

   return (
      <MaterialTable
         title=""
         components={{
            Toolbar: toolbarProps => (
               <Box p={2}>
                  <Grid container justifyContent="space-between">

                     <Grid item>
                        <Typography variant="h6">Organization review statuses</Typography>
                     </Grid>

                     <Grid item className={classes.actions}>
                        <MTableToolbar {...toolbarProps} />

                        <CSVLink {...mapToCSV(tableData ?? [])}
                           className="btn btn-primary"
                           target="_blank"
                           filename={'PortalOrganizationReviewStatusesExport' + new Date().toISOString() + '.csv'}

                        >
                           <Button
                              variant="outlined"
                              startIcon={<FileDownloadIcon />}
                           >
                              Export statuses
                           </Button>
                        </CSVLink>

                     </Grid>


                  </Grid>
               </Box>
            )
         }}
         columns={[
            {
               title: "Org Code",
               field: "organization",
               filtering: true,
               sorting: true,
               width: "16em",
               render: (row => row.organization?.code ?? ""),
               customSort: (a, b) => (a.organization?.code ?? "").localeCompare(b.organization?.code ?? "", 'en', { sensitivity: 'base' }),
               customFilterAndSearch: (term, row) => (row.organization?.code ?? "").toLowerCase().includes(term.toLowerCase()),
            },

            {
               title: "Org Name",
               field: "organization",
               filtering: true,
               sorting: true,
               width: "24em",
               render: row => <Link onClick={() => navigate(RouteNames.Organization.replace(":id", row.organization?.id?.toString()! ))}> { row.organization?.name } </Link> ?? "",
               customSort: (a, b) => (a.organization?.name ?? "").localeCompare(b.organization?.name ?? "", 'en', { sensitivity: 'base' }),
               customFilterAndSearch: (term, row) => (row.organization?.name ?? "").toLowerCase().includes(term.toLowerCase()),

            },
            {
               title: "Complete",
               field: "response",
               filtering: true,
               sorting: true,
               width: "8em",
               type: "boolean"
            },
            {
               title: "Date",
               field: "date",
               type: "datetime",
               filtering: false,
               sorting: true,
               defaultSort: "desc",
               width: "16em",
            },

         ]}

         data={tableData ?? []}
         options={{
            filtering: true,
            search: false,
            paging: true,
            grouping: false,
            toolbar: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100]
         }}

      />
   );
}