import React from "react";

const apiRequestInit = (options?: { contentType?: string | null; authToken?: string }): RequestInit => {
   let headers = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "-1",
   } as any;

   if (options?.contentType !== null) {
      headers = {
         ...headers,
         Accept: options?.contentType ?? "application/json",
         "Content-Type": options?.contentType ?? "application/json",
      };
   }

   if (options?.authToken) {
      headers = {
         ...headers,
         Authorization: `Bearer ${options.authToken}`,
      };
   }

   return {
      headers: new Headers(headers),
      // credentials: "same-origin",
   };
};

const handleError = async (error: any): Promise<string | null> => {
   if ("status" in error) {
      return handleErrorResponse(error);
   } else if ("message" in error) {
      return error.message;
   } else {
      throw error;
   }
};

const handleErrorResponse = async (response: Response): Promise<string | null> => {
   const unauthorized = 401;
   if (response.status === unauthorized) {
      window.location.reload();
      return null;
   } else {
      const forbidden = 403;
      if (response.status === forbidden) {
         return "You do not have access to this resource.";
      } else {
         const notFound = 404;
         if (response.status === notFound) {
            return "Unknown API method.";
         }
      }
   }

   let responseObject: any;
   try {
      const responseText = await response.text();
      try {
         responseObject = JSON.parse(responseText);

         if (responseObject.title) {
            return `${responseObject.title} ${responseObject.detail}`;
         }

         return responseText;
      } catch (notJsonError) {
         return responseText;
      }
   } catch (noTextError) {
      return response.status.toString();
   }
};

const isArray = (value: any): value is any[] => {
   return (value as any[]).keys !== undefined;
};

export const useApi = () => {
   const fetchObjectAsync = React.useCallback(async <T>(url: string): Promise<T> => {
      try {
         const fetchTask = fetch(url, apiRequestInit());
         const response: Response = await fetchTask;
         if (!response.ok) {
            throw response;
         }
         const responseText = await response.text();
         const responseObject = JSON.parse(responseText);
         return responseObject;
      } catch (response) {
         const error = await handleError(response);
         if (error !== null) {
            throw error;
         } else {
            throw new Error();
         }
      }
   }, []);

   const postAsync = React.useCallback(
      async <T = void>(url: string, body: any, authToken: string, contentType?: string | null): Promise<T> => {
         try {
            const fetchTask = fetch(url, {
               ...apiRequestInit({ contentType, authToken }),
               method: "POST",
               body,
            });

            const response = await fetchTask;
            if (!response.ok) {
               throw response;
            }

            const responseContentType = response.headers.get("content-type");
            if (responseContentType && responseContentType.indexOf("json") !== -1) {
               const responseText = await response.text();
               return JSON.parse(responseText);
            } else {
               return undefined as any;
            }
         } catch (error) {
            const errorMessage = await handleError(error);
            if (errorMessage !== null) {
               throw errorMessage;
            } else {
               throw new Error();
            }
         }
      },
      [],
   );

   const postFormDataAsync = React.useCallback(
      <T = void>(url: string, data: any, authToken: string, files?: File[]): Promise<T> => {
         const formData = new FormData();

         for (const key in data) {
            if (data[key]) {
               if (isArray(data[key])) {
                  for (let i = 0; i < data[key].length; i++) {
                     formData.append(`${key}[${i}]`, data[key][i]);
                  }
               } else {
                  formData.append(key, data[key]);
               }
            }
         }

         for (const file of files || []) {
            formData.append("Files", file);
         }

         return postAsync<T>(url, formData, authToken, null);
      },
      [postAsync],
   );

   return {
      fetchObjectAsync,
      postAsync,
      postFormDataAsync,
   };
};
