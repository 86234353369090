import React from "react";
import { useMutation } from "@apollo/client";
import { MenuItem } from "@mui/material";
import { Menu } from "@mui/material";
import { filter as gqlFilter } from "graphql-anywhere";
import { Doc, DocStatus } from "documents";
import { useNotifications } from "notifications";
import { documentInputFragment, UpdateDocumentStatusMutation } from "./queries";
import StatusChip from "./StatusChip";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         backgroundColor: theme.palette.background.paper,
      },
   }),
);

interface Props {
   document: Doc;
   canEdit: boolean;
}

const StatusMenu: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();

   const [saveMutate] = useMutation<{ document: { updateStatus: Doc } }, { document: Doc }>(UpdateDocumentStatusMutation, {
      onCompleted: () => notifications.success("Updated document status."),
   });

   async function save(newStatus: DocStatus) {
      if (newStatus !== props.document.status) {
         const editedDocument: Doc = {
            ...gqlFilter(documentInputFragment, props.document!),
            status: newStatus,
         };
         await saveMutate({ variables: { document: editedDocument } });
      }
   }

   const classes = useStyles();
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [selectedStatus, setSelectedStatus] = React.useState(props.document.status);

   React.useEffect(() => {
      if (props.document.status !== selectedStatus) {
         setSelectedStatus(props.document.status);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.document.status]);

   const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, selectedMenuStatus: DocStatus) => {
      setSelectedStatus(selectedMenuStatus);
      save(selectedMenuStatus);
      setAnchorEl(null);
   };

   const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div className={classes.root}>
         <StatusChip status={selectedStatus} onClick={props.canEdit ? handleClickListItem : undefined} />
         <Menu id="document-status" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {Object.values(DocStatus)
               .filter((s) => s !== DocStatus.NA && (s !== DocStatus.CorrectionsRequired || props.document.source !== "Concentra" ) )
               .map((status) => (
                  <MenuItem key={status} selected={status === selectedStatus} onClick={(event) => handleMenuItemClick(event, status)}>
                     <StatusChip status={status} />
                  </MenuItem>
               ))}
         </Menu>
      </div>
   );
};
export default StatusMenu;
