import * as React from "react";
import { Typography, Container, CircularProgress, Fade, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   container: {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   content: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   },
   logo: {
      width: 300,
   },
}));

const WaitingView: React.FunctionComponent<{ message: string }> = (props) => {
   const classes = useStyles();
   return (
      <Container maxWidth="lg" className={classes.container}>
         <Fade in>
            <div className={classes.content}>
               <Box mb={3}>
                  <img src="/assets/concentra-logo.svg" alt="Concentra EQ logo" className={classes.logo} />
               </Box>
               <Box mb={1}>
                  <Typography variant="subtitle2">{props.message}</Typography>
               </Box>
               <CircularProgress variant="indeterminate" color="primary" size={20} />
            </div>
         </Fade>
      </Container>
   );
};

export default WaitingView;
