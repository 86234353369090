import React from "react";
import { User } from "users";
import { Organization } from "./models";
import { makeStyles, createStyles } from '@mui/styles';

const useOrganizationOptionStyles = makeStyles((theme) =>
   createStyles({
      root: {
         display: "flex",
      },
      code: {
         color: theme.palette.text.secondary,
         minWidth: "4em",
         marginRight: theme.spacing(1),
      },
   }),
);

const OrganizationOption: React.FunctionComponent<{ props: any; organization: Organization; user: User; }> = (props) => {
   const classes = useOrganizationOptionStyles();

   return (
      <div {...props.props} key={props.organization.id} >
         {props.user.showOrganizationCodes && <span className={classes.code}>{props.organization.code}</span>}
         {props.organization.name}
      </div>
   );
};

export default OrganizationOption;