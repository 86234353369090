import {   Card, CardContent, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { Listserv } from "./models";
import clsx from "clsx";
import { announcementStyles } from "announcements/styles";
import DOMPurify from "dompurify";
import { useNotifications } from "../notifications";
import { gql, useMutation } from "@apollo/client";
import SpinnerButton from "../SpinnerButton";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
      actionButton: {
         alignSelf: "flex-end",
      },
   }),
);

interface Props {
   listserv: Listserv;
}

const ListservTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   const notifications = useNotifications();
   const [subscribeInProgress, setsubscribeInProgress] = useState(false);
   const [unsubscribeInProgress, setunsubscribeInProgress] = useState(false);

   const [subscribeMutate] = useMutation<{ listserv: Listserv }>(
      gql`
         mutation SubscribeListserv($listservId: Int!) {
            listserv {
               subscribe(listservId: $listservId) {
                  id
               }
            }
         }
      `,
   );

   const [unsubscribeMutate] = useMutation<{ listserv: Listserv }>(
      gql`
         mutation UnsubscribeListserv($listservId: Int!) {
            listserv {
               unsubscribe(listservId: $listservId) {
                  id
               }
            }
         }
      `,
   );

   async function subscribe() {
      setsubscribeInProgress(true);
      const result = await subscribeMutate({ variables: { listservId: props.listserv!.id } });

      if (result.data?.listserv) {
         notifications.success(`You have subscribed to ${props.listserv.listName}.`);
      }

      setsubscribeInProgress(false);
   }

   async function unsubscribe() {
      setunsubscribeInProgress(true);
      const result = await unsubscribeMutate({ variables: { listservId: props.listserv!.id } });

      if (result.data?.listserv) {
         notifications.success(`You have unsubscribed from ${props.listserv.listName}.`);
      }

      setunsubscribeInProgress(false);
   }

   if (!props.listserv) return null;

   return (
      <Card variant="outlined" key={props.listserv.id}>
         <CardContent>
            <Box mb={1}>
               <Typography variant="h5">{props.listserv.title ?? props.listserv.listName}</Typography>
            </Box>
            <div
               className={clsx("ql-editor", classes.htmlContent)}
               dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.listserv.htmlContent),
               }}
            />
            <Box mt={2} display="flex" justifyContent="space-around">
               <SpinnerButton label="Subscribe" variant="outlined" color="primary" inProgress={subscribeInProgress} onClick={subscribe} />
               <SpinnerButton
                  label="Unsubscribe"
                  variant="outlined"
                  inProgress={unsubscribeInProgress}
                  onClick={unsubscribe}
               />
            </Box>
         </CardContent>
      </Card>
   );
};

export default ListservTile;
