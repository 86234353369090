import {  Grid,  TextField, InputLabel, Box } from "@mui/material";
import {Button} from "@mui/material";
import {Dialog} from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "SpinnerButton";
import { RateBulletin, RateType, RateSubtype } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useApi } from "api";
import { AutocompleteRenderInputParams, TextField as FmuiTextField } from "formik-mui";
import FileDropzone from "documents/FileDropzone";
import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import { dialogStyle } from "application";
import { pickedDateToUtcMidnight, utcToLocalDate } from "application/formats";
import useCurrentUser from "users/useCurrentUser";
import { Permissions } from "users";
import { useAuthentication } from "auth/AuthenticationProvider";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   handleClose: () => void;
   rateTypes: RateType[];
   defaultRateType: RateType | null;
   defaultSubtype: RateSubtype | null;
}

interface FormValues {
   selectedFile: File | null;
   rateType: RateType | null;
   subtype: RateSubtype | null;
   bulletinNumber: string;
   effectiveDate: Date | null;
   expiryDate: Date | null;
}

const PostRateBulletinDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { postFormDataAsync } = useApi();
   const { getAccessToken } = useAuthentication();
   const { user, userHasPermission } = useCurrentUser();

   const initialFormValues: FormValues = {
      selectedFile: null,
      rateType: props.defaultRateType !== null ? props.defaultRateType : null,
      subtype: props.defaultSubtype !== null ? props.defaultSubtype : null,
      bulletinNumber: "",
      effectiveDate: null,
      expiryDate: null,
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.selectedFile === null) {
         errors.selectedFile = "Select a PDF document containing the rate bulletin information.";
      }

      if (!values.rateType) {
         errors.rateType = "Choose the rate type.";
      }

      if (!values.subtype) {
         errors.subtype = "Choose the rate subtype.";
      }

      if (values.bulletinNumber.trim() === "") {
         errors.bulletinNumber = "Enter a bulletin number.";
      }

      if (values.effectiveDate === null) {
         errors.effectiveDate = "Enter an effective date.";
      }

      if (values.effectiveDate !== null && values.expiryDate !== null && values.expiryDate < values.effectiveDate) {
         errors.expiryDate = "Expiry date must be after effective date.";
      }

      return errors;
   }

   async function postNewRateBulletin(values: FormValues, actions: FormikHelpers<FormValues>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postFormDataAsync<RateBulletin>(
               "/api/rateBulletin/document",
               {
                  RateSubtypeId: values.subtype!.id,
                  bulletinNumber: values.bulletinNumber,
                  effectiveDate: values.effectiveDate!.toISOString(),
                  expiryDate: values.expiryDate ? values.expiryDate.toISOString() : null,
               },
               accessToken,
               [values.selectedFile!],
            );
            notifications.success("Bulletin posted.");
         }
      } catch (error: any) {
         notifications.error(error.message ?? error);
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={props.open} onClose={props.handleClose} maxWidth="sm" fullWidth={true} scroll="paper">
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={postNewRateBulletin}>
            {(formikProps) => {
               const userProductIds = user.products.map((p) => p.id);

               const validTypes = userHasPermission(Permissions.ManageRateBulletinsOutsideYourProducts)
                  ? props.rateTypes
                  : props.rateTypes.filter((rt) => {
                       const subtypeProductIds = _.uniq(rt.subtypes.flatMap((st) => st.products.map((p) => p.id)));
                       return _.intersection(subtypeProductIds, userProductIds).length > 0;
                    });

               const subtypesForSelectedRateType = formikProps.values.rateType?.subtypes ?? [];
               const validSubtypes = userHasPermission(Permissions.ManageRateBulletinsOutsideYourProducts)
                  ? subtypesForSelectedRateType
                  : subtypesForSelectedRateType.filter((st) => {
                       const subtypeProductIds = st.products.map((p) => p.id);
                       return _.intersection(subtypeProductIds, userProductIds).length > 0;
                    });

               return (
                  <>
                     <DialogTitle id="form-dialog-title">Post a rate bulletin</DialogTitle>
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item>
                                 <Box mb={1}>
                                    <InputLabel>PDF bulletin file</InputLabel>
                                 </Box>
                                 <FormikField name="selectedFile" component={RateBulletinFileDropzone} />
                              </Grid>

                              <Grid item>
                                 <FormikField
                                    name="rateType"
                                    component={FmuiAutocomplete}
                                    handleHomeEndKeys={false}
                                    options={validTypes}
                                    getOptionLabel={(option: RateType) => option.name}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                       <TextField
                                          {...params}
                                          variant="outlined"
                                          size="small"
                                          label="Rate type"
                                          error={formikProps.touched.rateType && formikProps.errors.rateType !== undefined}
                                          helperText={formikProps.touched.rateType ? formikProps.errors.rateType : undefined}
                                       />
                                    )}
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>

                              <Grid item>
                                 <FormikField
                                    name="subtype"
                                    component={FmuiAutocomplete}
                                    handleHomeEndKeys={false}
                                    options={validSubtypes}
                                    getOptionLabel={(option: RateSubtype) => option.name}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                       <TextField
                                          {...params}
                                          variant="outlined"
                                          size="small"
                                          label="Rate subtype"
                                          error={formikProps.touched.subtype && formikProps.errors.subtype !== undefined}
                                          helperText={formikProps.touched.subtype ? formikProps.errors.subtype : undefined}
                                       />
                                    )}
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>

                              <Grid item>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="bulletinNumber"
                                    variant="outlined"
                                    size="small"
                                    label="Bulletin number"
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={6}>
                                    <FormikField name="effectiveDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             inputFormat="yyyy-MM-dd"
                                             label="Effective date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             onChange={(newDate : Date | null) =>
                                                fieldProps.form.setFieldValue("effectiveDate", pickedDateToUtcMidnight(newDate))
                                             }
                                             renderInput={(params) =>
                                                <TextField size="small" {...params}
                                                   fullWidth
                                                   error={
                                                      fieldProps.form.touched.effectiveDate && fieldProps.form.errors.effectiveDate !== undefined
                                                   }
                                                   helperText={
                                                      fieldProps.form.touched.effectiveDate ? fieldProps.form.errors.effectiveDate : undefined
                                                   }
                                                />
                                             }
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormikField name="expiryDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             inputFormat="yyyy-MM-dd"
                                             label="Expiry date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             minDate={utcToLocalDate(fieldProps.form.values.effectiveDate)??undefined}
                                             onChange={(newDate : Date | null) =>
                                                fieldProps.form.setFieldValue("expiryDate", pickedDateToUtcMidnight(newDate))
                                             }
                                             renderInput={(params) =>
                                                <TextField size="small" {...params}
                                                   fullWidth
                                                   error={fieldProps.form.touched.expiryDate && fieldProps.form.errors.expiryDate !== undefined}
                                                   helperText={fieldProps.form.touched.expiryDate ? fieldProps.form.errors.expiryDate : undefined}
                                                />
                                             }
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label="Post"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => {
                              formikProps.submitForm();
                           }}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

const RateBulletinFileDropzone: React.FunctionComponent<FieldProps<File, FormValues>> = (props) => {
   return (
      <FileDropzone
         prompt="Drag and drop a PDF file here or click to choose a file"
         touched={props.form.touched.selectedFile}
         error={props.form.errors.selectedFile}
         setFieldValue={(files) => {
            props.form.setFieldValue("selectedFile", files.length > 0 ? files[0] : null);
         }}
         limit={1}
         acceptedFiles={["application/pdf"]}
      />
   );
};

export default PostRateBulletinDialog;
