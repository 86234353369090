import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorResponse } from "@apollo/client/link/error";
import { SnackbarMessage, useSnackbar, VariantType } from "notistack";
import * as React from "react";
import { NotificationContext } from "./NotificationContext";
import { useAppInsights } from "application/AppInsightsProvider";
import { useSettings } from "application/SettingsProvider";

export interface Notification {
   message: SnackbarMessage;
   variant: VariantType;
   autoDismiss: number;
}

type Props = React.PropsWithChildren<{}>;

const NotificationProvider: React.FunctionComponent<Props> = (props) => {
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const appInsights = useAppInsights();
   const { showOriginalExceptionMessages } = useSettings();

   const addNotification = React.useCallback(
      (notification: Notification) => {
         enqueueSnackbar(notification.message, {
            variant: notification.variant,
            autoHideDuration: notification.autoDismiss !== 0 ? notification.autoDismiss * 1000 : undefined,
            persist: notification.autoDismiss === 0,
            preventDuplicate: notification.variant === "error",
            action: (key: string | number) => (
               <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
                  <CloseIcon fontSize="small" />
               </IconButton>
            ),
         });
      },
      [enqueueSnackbar, closeSnackbar],
   );

   const error = React.useCallback(
      (message: any) => {
         addNotification({
            message: typeof message === "object" ? message.toString() : message,
            variant: "error",
            autoDismiss: 0,
         });
      },
      [addNotification],
   );

   const handleApolloErrorResponse = React.useCallback(
      (errorResponse: ErrorResponse) => {
         let errorMessage = "Server error";

         if (errorResponse.graphQLErrors && errorResponse.graphQLErrors.length > 0) {
            errorMessage = errorResponse.graphQLErrors.map((e) => e.message).join(";");
         } else if (errorResponse.networkError) {
            appInsights.trackException({ exception: errorResponse.networkError });
            errorMessage = `${showOriginalExceptionMessages ? errorResponse.networkError.message : "An error occurred."}${
               (errorResponse.networkError as any).statusCode ? ` (${(errorResponse.networkError as any).statusCode})` : ""
            }`;
         }

         error(errorMessage);
      },
      [appInsights, error, showOriginalExceptionMessages],
   );

   const success = React.useCallback(
      (message: string, timeOutInSeconds?: number) => {
         addNotification({
            message,
            variant: "success",
            autoDismiss: timeOutInSeconds !== undefined ? timeOutInSeconds : 5,
         });
      },
      [addNotification],
   );

   const warning = React.useCallback(
      (message: string) => {
         addNotification({
            message,
            variant: "warning",
            autoDismiss: 0,
         });
      },
      [addNotification],
   );

   const info = React.useCallback(
      (message: string, timeOutInSeconds?: number) => {
         addNotification({
            message,
            variant: "info",
            autoDismiss: timeOutInSeconds !== undefined ? timeOutInSeconds : 5,
         });
      },
      [addNotification],
   );

   const removeAll = React.useCallback(() => {
      closeSnackbar();
   }, [closeSnackbar]);

   return (
      <NotificationContext.Provider
         value={{
            addNotification: (notification: Notification) => addNotification(notification),
            success: (message: string, timeOutInSeconds?: number) => success(message, timeOutInSeconds),
            warning: (message: string) => warning(message),
            error: (message: string) => error(message),
            info: (message: string, timeOutInSeconds?: number) => info(message, timeOutInSeconds),
            removeAll: () => removeAll(),
            handleApolloErrorResponse: (errorResponse: ErrorResponse) => handleApolloErrorResponse(errorResponse),
         }}
      >
         {props.children}
      </NotificationContext.Provider>
   );
};

export default NotificationProvider;
