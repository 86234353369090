import { gql, useMutation } from "@apollo/client";
import {   DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useState } from "react";
import { Doc } from "./models";
import SpinnerButton from "../SpinnerButton";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      deleteButton: {
         color: theme.palette.error.main,
      },
   }),
);

interface Props {
   handleClose: () => void;
   documentToDelete: Doc | null;
   callback?: () => void;
}

const DeleteDocumentDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const [deleting, setDeleting] = useState(false);

   const [deleteMutate] = useMutation<{ document: { delete: Doc } }, { documentId: number }>(
      gql`
         mutation DeleteDocument($documentId: Int!) {
            document {
               delete(id: $documentId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["ProgramProductsWithDocuments", "GetProductDocuments"],
         update(cache) {
            var cacheId = 'Document:' + props.documentToDelete!.id;
            cache.modify({
               id: 'ROOT_QUERY',
               fields: {
                  search(cachedSearch) {
                     var newResults = cachedSearch.results.filter((val: { document: { __ref: string; }; }) => {
                        return cacheId != val.document.__ref;
                     });

                     return {
                        __typename: cachedSearch.__typename,
                        totalCount: newResults.length,
                        results: newResults
                     }
                  }
               }
            })
            cache.evict({ id: cacheId });
            cache.gc();
         }
      },
   );

   async function deleteDocument() {
      setDeleting(true);
      const deleteResult = await deleteMutate({ variables: { documentId: props.documentToDelete!.id } });

      if (deleteResult.data?.document.delete) {
         notifications.success("Document deleted.");
         if (props.callback) {
            props.callback();
         }
      }

      setDeleting(false);
      props.handleClose();
   }

   if (!props.documentToDelete) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>{`Delete document "${props.documentToDelete.identifier}"?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>Do you want to delete the document "{props.documentToDelete.identifier}"?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton className={classes.deleteButton} label="Delete" inProgress={deleting} onClick={deleteDocument} />
         </DialogActions>
      </Dialog>
   );
};

export default DeleteDocumentDialog;
