import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {   DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import { RateBulletin } from "./models";
import SpinnerButton from "../SpinnerButton";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      deleteButton: {
         color: theme.palette.error.main,
      },
   }),
);

interface Props {
   handleClose: (deleted: boolean) => void;
   rateBulletinToDelete: RateBulletin | null;
}

const DeleteRateBulletinDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const [deleting, setDeleting] = useState(false);

   const [deleteMutate] = useMutation<{ rateBulletin: { delete: RateBulletin } }, { rateBulletinId: number }>(
      gql`
         mutation DeleteRateBulletin($rateBulletinId: Int!) {
            rateBulletin {
               delete(id: $rateBulletinId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["FetchRateBulletins"],
      },
   );

   async function deleteRateBulletin() {
      setDeleting(true);
      const deleteResult = await deleteMutate({ variables: { rateBulletinId: props.rateBulletinToDelete!.id } });
      setDeleting(false);

      if (deleteResult.data?.rateBulletin?.delete?.id) {
         notifications.success("Deleted rate bulletin.");
         props.handleClose(true);
      } else {
         props.handleClose(false);
      }
   }

   if (!props.rateBulletinToDelete) return null;

   return (
      <Dialog open={true} onClose={() => props.handleClose(false)}>
         <DialogTitle>{`Delete rate bulletin ${props.rateBulletinToDelete.bulletinNumber}?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>Do you want to delete the rate bulletin?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => props.handleClose(false)}>Cancel</Button>
            <SpinnerButton
               label="Delete"
               color="primary"
               inProgress={deleting}
               onClick={deleteRateBulletin}
               className={classes.deleteButton}
            />
         </DialogActions>
      </Dialog>
   );
};

export default DeleteRateBulletinDialog;
