import React, { useContext } from "react";
import { Notification } from "./NotificationProvider";
import { ErrorResponse } from "@apollo/client/link/error";

export interface NotificationProps {
   addNotification: (notification: Notification) => void;
   success: (message: string, timeOutInSeconds?: number) => void;
   warning: (message: string) => void;
   error: (message: any) => void;
   info: (message: string, timeOutInSeconds?: number) => void;
   removeAll: () => void;
}

export const NotificationContext = React.createContext({
   addNotification: (notification: Notification) => {},
   success: (message: string, timeOutInSeconds?: number) => {},
   warning: (message: string) => {},
   error: (message: any) => {},
   info: (message: string, timeOutInSeconds?: number) => {},
   removeAll: () => {},
   handleApolloErrorResponse: (errorResponse: ErrorResponse) => {},
});

export function useNotifications() {
   const context = useContext(NotificationContext);
   return {
      addNotification: context.addNotification,
      success: context.success,
      warning: context.warning,
      error: context.error,
      info: context.info,
      removeAll: context.removeAll,
      handleApolloErrorResponse: context.handleApolloErrorResponse,
   };
}
