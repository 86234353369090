import {   Card, CardContent, Typography, Box, Grid, FormGroup, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Collapse, TextField, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotifications } from "../notifications";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AccessReview, accessReviewPropertiesFragment, AccessReviewSchedule, accessReviewSchedulePropertiesFragment, AccessReviewUserOptionsQuery, AccessReviewUserOptionsQueryResult, AccessReviewUserOptionsQueryVariables, organizationReviewStatusesPropertiesFragment } from ".";
import _ from "lodash";
import useCurrentUser from "../users/useCurrentUser";
import { UserAccessReviewTable } from "./UserAccessReviewTable";
import { Skeleton } from "@mui/material";
import { AccessReviewScheduleTable } from "./AccessReviewScheduleTable";
import { OrganizationReviewStatusesTable } from "./OrganizationReviewStatusesTable";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      reviewCard: {
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(1),
      },
      header: {
         display: "flex",
         alignItems: "flex-start",
         "& > :first-child": {
            flexGrow: 1,
         },
      },
      buttons: {
         marginLeft: "auto",
         position: "relative",
         top: -theme.spacing(1.5),
         right: -theme.spacing(1.5),
      },
   }),
);

interface Props {

}


const WythAccessReviewTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const { user, userHasPermission } = useCurrentUser();

   const notifications = useNotifications();
   const [selectedTab, setSelectedTab] = useState(0);

   const allAccessReviewsQuery = useQuery<{ accessReviews: AccessReview[] }, {  }>(
      gql`
         query GetAccessReviews {
            accessReviews {
               ...accessReviewProperties
            }
         }
         ${accessReviewPropertiesFragment}
      `,
      { variables: { } }
   );

   const allAccessReviews = allAccessReviewsQuery.data?.accessReviews.map(d => ({ ...d }));


   const accessReviewSchedulesQuery = useQuery<{ accessReviewSchedules: AccessReviewSchedule[] }, {}>(
      gql`
         query GetAccessReviewSchedules {
            accessReviewSchedules {
               ...accessReviewScheduleProperties
            }
         }
         ${accessReviewSchedulePropertiesFragment}
      `,
      { variables: {} }
   );

   const accessReviewSchedules = accessReviewSchedulesQuery.data?.accessReviewSchedules.map(d => ({ ...d }));


   const organizationReviewStatusesQuery = useQuery<{ organizationReviewStatuses: AccessReview[] }, {}>(
      gql`
         query GetOrganizationReviewStatuses {
            organizationReviewStatuses {
               ...organizationReviewStatusesProperties
            }
         }
         ${organizationReviewStatusesPropertiesFragment}
      `,
      { variables: {} }
   );

   const organizationReviewStatuses = organizationReviewStatusesQuery.data?.organizationReviewStatuses.map(d => ({ ...d }));



   const queriesLoaded =
      !allAccessReviewsQuery.loading &&
      !!allAccessReviewsQuery.data &&
      !accessReviewSchedulesQuery.loading &&
      !!accessReviewSchedulesQuery.data &&
      !organizationReviewStatusesQuery.loading &&
      !!organizationReviewStatusesQuery.data
      ;




   return (

      <Card className={classes.reviewCard} variant="outlined" >
         <CardContent >

            {!queriesLoaded && (
               <Grid container spacing={2} direction="column">
                  {[...Array(12)].map((x, index) => (
                     <Grid item key={index}>
                        <Skeleton variant="rectangular" width="100%" height="2rem" />
                     </Grid>
                  ))}
               </Grid>
            )}

            {queriesLoaded && (
               <Grid item container direction="column" spacing={3}>
                  <Box>
                     <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
                        <Tab label="Organization statuses" />
                        <Tab label="Partner responses" />
                        <Tab label="Review cycle" />
                     </Tabs>
                  </Box>

                  <Box p={2}>

                     {selectedTab === 0 && (
                        <Grid container direction="column" spacing={3}>
                           <Grid item>
                              {(organizationReviewStatuses &&
                                 <OrganizationReviewStatusesTable organizationReviewStatuses={organizationReviewStatuses} />
                              )}
                           </Grid>
                        </Grid>
                     )}
                     {selectedTab === 1 && (
                        <Grid container direction="column" spacing={3}>
                           <Grid item>
                              {(allAccessReviews &&
                                 <UserAccessReviewTable showOrganization={true} accessReviews={allAccessReviews} />
                              )}
                           </Grid>
                        </Grid>
                     )}
                     {selectedTab === 2 && (
                        <Grid container direction="column" spacing={3}>
                           <Grid item>
                              {(accessReviewSchedules &&
                                 <AccessReviewScheduleTable accessReviewSchedules={accessReviewSchedules} />
                              )}
                           </Grid>
                        </Grid>
                     )}

                  </Box>
               </Grid>
            )}
         </CardContent>
      </Card>
   );
};

export default WythAccessReviewTile;
