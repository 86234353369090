import * as React from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import WaitingView from "./WaitingView";

const AuthComplete: React.FunctionComponent = (props) => {
   const [isStuck, setIsStuck] = React.useState(false);
   const navigate = useNavigate();

   // Check if the auth page is still in a waiting state for 15s.
   // This can happen if the login page is bookmarked and there isn't a valid redirect URL in storage.
   const delay = 8;
   React.useEffect(() => {
      const checkTimer = setTimeout(() => setIsStuck(true), delay * 1000);
      return () => {
         clearTimeout(checkTimer);
      };
   }, []);

   if (isStuck) {

      navigate("/");
   }

   return <WaitingView message="Completing authentication..." />;
};

export default AuthComplete;
