import {   Grid, Box, Typography, Button } from "@mui/material";
import React from "react";
import _ from "lodash";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { AccessReview } from "./models";
import { CSVLink } from "react-csv";
import { GetApp as FileDownloadIcon } from "@mui/icons-material";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      scrollList: {
         overflowY: 'scroll',
         maxHeight: '9.4em',
         //WebkitMaskImage: "linear-gradient(to bottom, black 80px, transparent 120px)"
      }
   }),
);

interface Props {
   showOrganization: boolean;
   accessReviews: AccessReview[];
}

export const UserAccessReviewTable: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   const tableData = props.accessReviews ?? [];

   function getTimeMs(date? : Date) {
      return date != null ? new Date(date).getTime() : 0;
   } 
   
   function mapToCSV(data: AccessReview[]) {
      var results: any[] = [];

      data.forEach(a => {
         let entry: any;
         entry = {};

         if (a.date) {
            entry.date = (a.date as Date).toLocaleString();
         } else {
            entry.date = "";
         }

         if (props.showOrganization) {
            entry.orgcode = a.organization?.code ?? "";
            entry.orgname = a.organization?.name ?? "";
         }
         entry.username = a.user?.name ?? "";
         entry.useremail = a.user?.email ?? "";
         entry.response = a.response ?? "";
         entry.alternateuser = a.alternateUser?.name ?? "";
         entry.alternateuseremail = a.alternateUser?.email ?? "";

         results.push(entry);
      });


      var headers = [];

      headers.push(
         { label: 'Date', key: 'date' }
      );

      if (props.showOrganization) {
         headers.push(
            { label: 'OrgCode', key: 'orgcode' },
            { label: 'Organization', key: 'orgname' },
         );
      }

      headers.push(
         { label: 'User', key: 'username' },
         { label: 'Email', key: 'useremail' },
         { label: 'Response', key: 'response' },
         { label: 'Delegated User', key: 'alternateuser' },
         { label: 'Delegated User Email', key: 'alternateuseremail' },
      );

      return { headers: headers, data: results.sort((a, b) => { return getTimeMs(b.date) - getTimeMs(a.date); })};

   }

   return (
            <MaterialTable
               title=""
               components={{
                  Toolbar: toolbarProps => (
                     <Box p={2}>
                        <Grid container justifyContent="space-between">

                           <Grid item>
                              <Typography variant="h6">Review responses</Typography>
                           </Grid>

                           <Grid item className={classes.actions}>
                              <MTableToolbar {...toolbarProps} />

                              <CSVLink {...mapToCSV(tableData ?? [])}
                                 className="btn btn-primary"
                                 target="_blank"
                                 filename={'PortalUserAccessReviewsExport' + new Date().toISOString() + '.csv'}

                              >
                                 <Button
                                    variant="outlined"
                                    startIcon={<FileDownloadIcon />}
                                 >
                                    Export access reviews
                                 </Button>
                              </CSVLink>

                           </Grid>


                        </Grid>
                     </Box>
                  )
               }}
               columns={[
                  {
                     title: "Date",
                     field: "date",
                     type: "datetime",
                     filtering: false,
                     sorting: true,
                     defaultSort: "desc",
                     width: "16em",
                  },

                  {
                     title: "Org Code",
                     field: "organization",
                     filtering: true,
                     sorting: true,
                     width: "16em",
                     render: (row => row.organization?.code ?? ""),
                     hidden: !props.showOrganization,
                     customSort: (a, b) => (a.organization?.code ?? "").localeCompare(b.organization?.code ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.organization?.code ?? "").toLowerCase().includes(term.toLowerCase()),
                  },

                  {
                     title: "Org Name",
                     field: "organization",
                     filtering: true,
                     sorting: true,
                     width: "16em",
                     render: (row => row.organization?.name ?? ""),
                     hidden: !props.showOrganization,
                     customSort: (a, b) => (a.organization?.name ?? "").localeCompare(b.organization?.name ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.organization?.name ?? "").toLowerCase().includes(term.toLowerCase()),
                  },

                  {
                     title: "User Name",
                     field: "user",
                     filtering: true,
                     sorting: true,
                     width: "16em",
                     render: (row => row.user?.name ?? ""),
                     customSort: (a, b) => (a.user.name ?? "").localeCompare(b.user?.name ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.user?.name ?? "").toLowerCase().includes(term.toLowerCase()),

                  },

                  {
                     title: "User Email",
                     field: "user",
                     filtering: true,
                     sorting: true,
                     width: "16em",
                     render: (row => row.user?.email ?? ""),
                     customSort: (a, b) => (a.user.email ?? "").localeCompare(b.user?.email ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.user?.email ?? "").toLowerCase().includes(term.toLowerCase()),
                     cellStyle: { wordBreak: "break-all" }
                  },

                  {
                     title: "Response",
                     field: "response",
                     filtering: true,
                     sorting: true,
                     width: "10em",
                     type: "boolean",
                     render: (row => row.response ? "Yes, confirmed." : "No, delegated to: ")
                  },

                  {
                     title: "Delegated User",
                     field: "alternateUser",
                     filtering: true,
                     sorting: false,
                     width: "16em",
                     render: (row => row.alternateUser?.name ?? ""),
                     customSort: (a, b) => (a.alternateUser.name ?? "").localeCompare(b.alternateUser?.name ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.alternateUser?.name ?? "").toLowerCase().includes(term.toLowerCase()),
                  },

                  {
                     title: "Delegated Email",
                     field: "alternateUser",
                     filtering: true,
                     sorting: false,
                     width: "16em",
                     render: (row => row.alternateUser?.email ?? ""),
                     customSort: (a, b) => (a.alternateUser.email ?? "").localeCompare(b.alternateUser?.email ?? "", 'en', { sensitivity: 'base' }),
                     customFilterAndSearch: (term, row) => (row.alternateUser?.email ?? "").toLowerCase().includes(term.toLowerCase()),
                     cellStyle: { wordBreak: "break-all" }
                  },

               ]}

               data={tableData ?? []}
               options={{
                  filtering: true,
                  search: false,
                  paging: true,
                  grouping: false,
                  toolbar: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100]
               }}

            />
   );
}