import { PublicClientApplication, Configuration, LogLevel } from "@azure/msal-browser";
import { MsalProvider as RootMsalProvider } from "@azure/msal-react";
import { useAuthConfig } from "application/SettingsProvider";

type Props = React.PropsWithChildren<{}>;

export const MsalProvider: React.FunctionComponent<Props> = (props) => {
   const authConfig = useAuthConfig();

   const msalConfig: Configuration = {
      auth: {
         knownAuthorities: [authConfig.knownAuthority],
         authority: authConfig.authority,
         clientId: authConfig.clientId,
         redirectUri: `${window.location.origin}/authComplete`,
         postLogoutRedirectUri: `${window.location.origin}/sign-out`,
         navigateToLoginRequestUrl: true,
      },
      cache: {
         cacheLocation: "localStorage",
         secureCookies: true,
         // Set this to "true" to save cache in cookies to address trusted zones limitations in IE and Edge
         // (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
         storeAuthStateInCookie: true,
      },
      system: {
         loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
               if (containsPii) {
                  return;
               }
               switch (level) {
                  case LogLevel.Error:
                     console.error(message);
                     return;
                  case LogLevel.Info:
                     console.info(message);
                     return;
                  case LogLevel.Verbose:
                     console.debug(message);
                     return;
                  case LogLevel.Warning:
                     console.warn(message);
                     return;
               }
            },
         },
      },
   };
   const msalInstance = new PublicClientApplication(msalConfig);

   return <RootMsalProvider instance={msalInstance}>{props.children}</RootMsalProvider>;
};
