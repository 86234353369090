import { ButtonPropsColorOverrides, CircularProgress,   PropTypes } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      buttonWrapper: {
         position: "relative",
         display: "inline-flex"
      },
      progressSpinner: {
         position: "absolute",
         top: "50%",
         left: "50%",
         marginTop: -12,
         marginLeft: -12,
      },
   }),
);




interface Props {
   label: string;
   inProgress: boolean;
   startIcon?: React.ReactNode;
   onClick: () => void;
   color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
   variant?: "text" | "outlined" | "contained";
   className?: string;
   disabled?: boolean;
}

const SpinnerButton: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   return (
      <div className={classes.buttonWrapper}>
         <Button
            onClick={props.onClick}
            startIcon={props.startIcon}
            color={props.color}
            variant={props.variant}
            className={props.className}
            disabled={props.disabled || props.inProgress}
         >
            {props.label}
         </Button>
         {props.inProgress && <CircularProgress size={24} className={classes.progressSpinner} />}
      </div>
   );
};

export default SpinnerButton;
