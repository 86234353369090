import {   Table, TableBody, TableRow, TableCell } from "@mui/material";
import React from "react";
import { Tag, DocType } from "documents";
import { Product } from "products";
import _ from "lodash";
import { User } from "../users";
import { Organization } from "organizations";
import { Program } from "programs";
import { makeStyles, createStyles } from '@mui/styles';
import { Plan } from "../plan";

const useStyles = makeStyles((theme) =>
   createStyles({
      criteriaTable: {
         width: "auto",
         "& td:last-child": {
            fontWeight: "bold",
         },
      },
   }),
);

interface Props {
   matchPartialFileName?: string;
   documentTypes?: DocType[];
   organizations?: Organization[];
   uploadedByUsers?: User[];
   sources?: string[];
   statuses?: string[];
   tags?: Tag[];
   products?: Product[];
   programs?: Program[];
   plans?: Plan[];
}

const AlertTable: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   function buildList(items: string[], and = false) {
      const sortedItems = _.orderBy(items, (i) => i.toLowerCase());
      let list = sortedItems[0];

      if (sortedItems.length > 2) {
         const middle = `, ${sortedItems.slice(1, sortedItems.length - 1).join(", ")}, `;
         list = list + middle.slice(0, middle.length - 1);
      }

      if (sortedItems.length > 1) {
         list = `${list} ${and ? "and" : "or"} ${sortedItems[sortedItems.length - 1]}`;
      }

      return list;
   }

   return (
      <Table className={classes.criteriaTable}>
         <TableBody>
            {props.matchPartialFileName && props.matchPartialFileName.trim().length > 0 && (
               <TableRow>
                  <TableCell>File name contains:</TableCell>
                  <TableCell>"{props.matchPartialFileName}"</TableCell>
               </TableRow>
            )}
            {props.documentTypes && props.documentTypes.length > 0 && (
               <TableRow>
                  <TableCell>Document type{props.documentTypes.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.documentTypes.map((dt) => dt.name))}</TableCell>
               </TableRow>
            )}
            {props.organizations && props.organizations.length > 0 && (
               <TableRow>
                  <TableCell>Organization{props.organizations.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.organizations.map((o) => o.name))}</TableCell>
               </TableRow>
            )}
            {props.uploadedByUsers && props.uploadedByUsers.length > 0 && (
               <TableRow>
                  <TableCell>Posted by:</TableCell>
                  <TableCell>{buildList(props.uploadedByUsers.map((u) => `${u.name} (${u.organization.name})`))}</TableCell>
               </TableRow>
            )}
            {props.products && props.products.length > 0 && (
               <TableRow>
                  <TableCell>Product{props.products.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.products.map((p) => p.name))}</TableCell>
               </TableRow>
            )}
            {props.programs && props.programs.length > 0 && (
               <TableRow>
                  <TableCell>Program{props.programs.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.programs.map((d) => d.name))}</TableCell>
               </TableRow>
            )}
            {props.plans && props.plans.length > 0 && (
               <TableRow>
                  <TableCell>Plan type{props.plans.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.plans.map((d) => d.name))}</TableCell>
               </TableRow>
            )}
            {props.sources && props.sources.length > 0 && (
               <TableRow>
                  <TableCell>Source{props.sources.length > 1 ? "s" : ""}:</TableCell>
                  <TableCell>{buildList(props.sources)}</TableCell>
               </TableRow>
            )}
            {props.statuses && props.statuses.length > 0 && (
               <TableRow>
                  <TableCell>Status:</TableCell>
                  <TableCell>{buildList(props.statuses)}</TableCell>
               </TableRow>
            )}
            {props.tags && props.tags.length > 0 && (
               <TableRow>
                  <TableCell>Tags (documents with every tag listed):</TableCell>
                  <TableCell>
                     {buildList(
                        props.tags.map((t) => `"${t.value}"`),
                        true,
                     )}
                  </TableCell>
               </TableRow>
            )}
         </TableBody>
      </Table>
   );
};

export default AlertTable;
