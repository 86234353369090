import { gql } from "@apollo/client";

export const UpsertAlertMutation = gql`
   mutation CreateAlert($alert: AlertInput!) {
      alert {
         upsert(alert: $alert) {
            id
         }
      }
   }
`;
