import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
   TextField,
} from "@mui/material";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "../SpinnerButton";
import { Organization } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikErrors, FormikHelpers } from "formik";
import { AutocompleteRenderInputParams, TextField as FmuiTextField } from "formik-mui";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles, createStyles } from '@mui/styles';

import { Autocomplete as FmuiAutocomplete } from "formik-mui";

const useStyles = makeStyles((theme) =>
   createStyles({
      dialogContent: {
         paddingTop: theme.spacing(1.5), //https://material-ui.com/components/grid/#negative-margin
         paddingBottom: theme.spacing(1.5),
      },
   }),
);

interface Props {
   open: boolean;
   organization: Organization;
   allOrganizations: Organization[];
   handleClose: (targetOrganizationId?: number) => void;
}

interface FormValues {
   targetOrganization: Organization | null;
   name: string;
}

const MergeOrganizationsDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const initialFormValues: FormValues = {
      targetOrganization: null,
      name: "",
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (!values.targetOrganization) {
         errors.targetOrganization = "Select the target organization to merge the organization into.";
      }

      if (values.name !== props.organization.name) {
         errors.name = "Confirm by entering the name of the organization being merged.";
      }

      return errors;
   }

   const [mergeMutate] = useMutation<
      { organization: { merge: Organization } },
      { sourceOrganizationId: number; targetOrganizationId: number }
   >(
      gql`
         mutation MergeOrganizations($sourceOrganizationId: Int!, $targetOrganizationId: Int!) {
            organization {
               merge(sourceOrganizationId: $sourceOrganizationId, targetOrganizationId: $targetOrganizationId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetOrganizationScreenOptions"],
      },
   );

   async function mergeOrganizations(values: FormValues, actions: FormikHelpers<FormValues>) {
      const result = await mergeMutate({
         variables: { sourceOrganizationId: props.organization.id, targetOrganizationId: values.targetOrganization!.id },
      });

      if (result.data?.organization.merge?.id) {
         notifications.success("Merged the two organizations.");
      }

      actions.setSubmitting(false);
      props.handleClose(result.data?.organization.merge.id);
   }

   if (!props.open) return null;

   const targetOrganizationOptions = props.allOrganizations.filter((o) => o.id !== props.organization.id && !o.isInactive);

   return (
      <Dialog open={true} onClose={() => props.handleClose()}>
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={mergeOrganizations}>
            {(formikProps) => (
               <>
                  <DialogTitle>{`Merge organizations?`}</DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                     <FormikForm>
                        {" "}
                        <DialogContentText>Organization to be merged: {props.organization.name}</DialogContentText>
                        <Grid container direction="column" spacing={2}>
                           <Grid item container spacing={1}>
                              <Grid item xs={12}>
                                 <FormikField
                                    name="targetOrganization"
                                    component={FmuiAutocomplete}
                                    handleHomeEndKeys={false}
                                    multiple={false}
                                    options={targetOrganizationOptions}
                                    getOptionLabel={(option: Organization) => option.name}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                       <TextField
                                          {...params}
                                          variant="outlined"
                                          size="small"
                                          label="Organization that will survive"
                                          error={
                                             formikProps.touched.targetOrganization && formikProps.errors.targetOrganization !== undefined
                                          }
                                          helperText={
                                             formikProps.touched.targetOrganization ? formikProps.errors.targetOrganization : undefined
                                          }
                                       />
                                    )}
                                    fullWidth
                                    disableClearable={true}
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                        <DialogContentText>
                           <p>
                              This will have the following effects:
                              <ul>
                                 <li>Documents belonging to the source organization will be transferred to the target organization</li>
                                 <li>Users will be transferred</li>
                                 <li>Announcements, if any, will be transferred</li>
                                 <li>Any alerts that Concentra users have set up referencing the source organization will be deleted</li>
                                 <li>
                                    The products assigned to the source organization will be merged into the set assigned to the target
                                    organization
                                 </li>
                              </ul>
                              To merge the organizations, confirm by typing the name of the organization being merged below.
                           </p>
                        </DialogContentText>
                        <Grid container direction="column" spacing={2}>
                           <Grid item container spacing={1}>
                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="name"
                                    variant="outlined"
                                    size="small"
                                    label="Name"
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </FormikForm>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={() => props.handleClose()}>Cancel</Button>
                     <SpinnerButton
                        label="Merge"
                        color="secondary"
                        variant="contained"
                        inProgress={formikProps.isSubmitting}
                        onClick={() => {
                           formikProps.submitForm();
                        }}
                     />
                  </DialogActions>
               </>
            )}
         </Formik>
      </Dialog>
   );
};

export default MergeOrganizationsDialog;
