import {   Typography, Link, Grid } from "@mui/material";
import { lighten } from "@mui/material";
import React from "react";
import { MarketingAnnouncement } from "./models";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         height: "15rem",
         width: "30rem",
         position: "relative",
         overflow: "hidden",
         borderRadius: theme.shape.borderRadius,
      },
      image: {
         objectFit: "cover",
         minHeight: "100%",
         minWidth: "100%",
      },
      defaultBackground: {
         backgroundColor: lighten(theme.palette.primary.main, 0.6),
         width: "100%",
         height: "100%",
      },
      content: {
         position: "absolute",
         top: theme.spacing(2),
         padding: theme.spacing(1),
         left: `calc(50% - ${theme.spacing(1)})`,
         width: `calc(50% - ${theme.spacing(1)})`,
         maxHeight: `calc(15rem - (2 * ${theme.spacing(1)}))`,
         overflow: "hidden",
         backdropFilter: "blur(10px)",
         borderRadius: theme.shape.borderRadius,
      },
      title: {
         marginBottom: theme.spacing(2),
         fontSize: "1.5rem",
         lineHeight: 1.1,
      },
      body: {
         marginBottom: theme.spacing(2),
         fontSize: "0.9rem",
         lineHeight: 1.4,
      },
      link: {
         fontSize: "0.9rem",
         alignSelf: "flex-end",
      },
      invertText: {
         color: "white !important",
      },
   }),
);

interface Props {
   announcement: MarketingAnnouncement;
}

const MarketingAnnouncementDisplay: React.FunctionComponent<Props> = ({ announcement }) => {
   const classes = useStyles();
   const navigate = useNavigate();

   return (
      <div className={classes.root}  >
         {announcement.encodedImage ? (
            <img alt="Announcement" className={classes.image} src={announcement.encodedImage} />
         ) : (
            <div className={classes.defaultBackground} />
         )}
         <Grid container className={classes.content} direction="column">
            {announcement.title && (
               <Grid item>
                  <Typography className={clsx(classes.title, { [classes.invertText]: announcement.invertText })}>
                     {announcement.title}
                  </Typography>
               </Grid>
            )}
            {announcement.body && (
               <Grid item>
                  <Typography className={clsx(classes.body, { [classes.invertText]: announcement.invertText })}>
                     {announcement.body}
                  </Typography>
               </Grid>
            )}
            {announcement.link &&
               (announcement.link.match("^/([^/].*)$") ? (
                  <Grid item>
                     <Link
                        className={clsx(classes.link, { [classes.invertText]: announcement.invertText })}
                        onClick={() => navigate(announcement.link!)}
                     >
                        More...
                     </Link>
                  </Grid>
               ) : (
                  <Grid item>
                     <Link
                        className={clsx(classes.link, { [classes.invertText]: announcement.invertText })}
                        href={announcement.link}
                        target="_blank"
                     >
                        More...
                     </Link>
                  </Grid>
               ))}
         </Grid>
      </div>
   );
};

export default MarketingAnnouncementDisplay;
