import {   Typography, Link } from "@mui/material";
import React from "react";
import { Announcement } from "./models";
import { RouteNames } from "application/routes";
import { useNavigate } from "react-router";
import clsx from "clsx";
import moment from "moment";
import { announcementStyles } from "./styles";
import DOMPurify from "dompurify";
import useCurrentUser from "users/useCurrentUser";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
   }),
);

interface Props {
   announcement: Announcement;
   showApplicableProductsForProgramId?: number;
}

const AnnouncementTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const navigate = useNavigate();
   const { user } = useCurrentUser();

   return (
      <div className={classes.base}>
         <Typography variant="body2" className={classes.effectiveDate}>
            {moment.utc(props.announcement.effectiveDate).calendar()}
         </Typography>

         {props.announcement.title && (
            <Typography variant="h1" className={classes.title}>
               {props.announcement.title}
            </Typography>
         )}

         <div
            className={clsx("ql-editor", classes.htmlContent)}
            dangerouslySetInnerHTML={{
               __html: DOMPurify.sanitize(props.announcement.htmlContent),
            }}
         />
         <div className={classes.applicabilities}>
            {props.showApplicableProductsForProgramId && (
               <Typography variant="body2" color="textSecondary" className={classes.applicability}>
                  Filed under:
               </Typography>
            )}
            {props.showApplicableProductsForProgramId &&
               props.announcement.products
                  .filter((p) => p.programId === props.showApplicableProductsForProgramId && user.products.some((up) => up.id === p.id))
                  .map((product) => (
                     <div key={product.id}>
                        <Link
                           key={product.id}
                           variant="body2"
                           className={classes.applicability}
                           color="inherit"
                           onClick={() => navigate(RouteNames.Product.replace(":id", product.id.toString()))}
                        >
                           {product.name}
                        </Link>
                     </div>
                  ))}
            {props.announcement.jurisdictions.length > 0 && (
               <div>
                  <Typography variant="body2" className={classes.applicability}>
                     {`Jurisdiction${props.announcement.jurisdictions.length > 1 ? "s" : ""}: ${props.announcement.jurisdictions
                        .map((jurisdiction) => jurisdiction.name)
                        .join(", ")}`}
                  </Typography>
               </div>
            )}
         </div>
      </div>
   );
};

export default AnnouncementTile;
