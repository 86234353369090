import { useMutation, gql } from "@apollo/client";
import { Grid, Typography, TextField, FormHelperText } from "@mui/material";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React, { useState, useEffect } from "react";
import SpinnerButton from "SpinnerButton";
import { Announcement, AnnouncementInput } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import { AutocompleteRenderInputParams, TextField as FmuiTextField } from "formik-mui";
import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Jurisdiction } from "documents";
import { Organization, defaultOrganization } from "organizations";
import { Product } from "products";
import ClosableDialogTitle from "application/ClosableDialogTitle";

import HtmlEditor from "./HtmlEditor";
import { dialogStyle } from "application";
import { pickedDateToUtcMidnight, utcToLocalDate } from "application/formats";
import { editAnnouncementPropertiesFragment } from "announcements";
import DOMPurify from "dompurify";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   handleClose: () => void;
   announcementToEdit: Announcement | null;
   organizations: Organization[];
   products: Product[];
   jurisdictions: Jurisdiction[];
}

interface FormValues {
   title: string;
   effectiveDate: Date;
   expiryDate: Date | null;
   organization: Organization | null;
   products: Product[];
   jurisdictions: Jurisdiction[];
}

const UpsertProductAnnouncementDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const [htmlContent, setHtmlContent] = useState("");
   const [htmlContentTouched, setHtmlContentTouched] = useState(false);
   const [htmlContentBlank, setHtmlContentBlank] = useState(true);

   useEffect(() => {
      setHtmlContent(props.announcementToEdit?.htmlContent ? DOMPurify.sanitize(props.announcementToEdit.htmlContent) : "");
      setHtmlContentTouched(false);
      setHtmlContentBlank(props.announcementToEdit === null);
   }, [props.announcementToEdit, props.open]);

   const organizationOptions = [defaultOrganization].concat(_.orderBy(props.organizations, (o) => o.name.toLowerCase()));
   const productOptions = _.orderBy(props.products, (p) => p.name.toLowerCase());
   const jurisdictionOptions = _.orderBy(props.jurisdictions, (j) => j.name.toLowerCase());

   const creating = props.announcementToEdit === null;

   const initialFormValues: FormValues = creating
      ? {
         title: "",
         effectiveDate: moment().startOf("day").toDate(),
         expiryDate: null,
         organization: null,
         products: [],
         jurisdictions: [],
      }
      : {
         title: props.announcementToEdit!.title ?? "",
         effectiveDate: props.announcementToEdit!.effectiveDate,
         expiryDate: props.announcementToEdit!.expiryDate,
         organization: props.announcementToEdit!.organization,
         products: props.announcementToEdit!.products,
         jurisdictions: props.announcementToEdit!.jurisdictions,
      };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.expiryDate === null) {
         errors.expiryDate = "Enter an expiry date.";
      }

      if (values.expiryDate && values.expiryDate <= values.effectiveDate) {
         errors.expiryDate = "Expiry date must be after effective date.";
      }

      if (values.products.length === 0) {
         errors.products = "Select at least one product.";
      }

      return errors;
   }

   const [createMutate] = useMutation<{ announcement: { create: Announcement } }, { announcement: AnnouncementInput }>(
      gql`
         mutation CreateAnnouncement($announcement: AnnouncementInput!) {
            announcement {
               create(announcement: $announcement) {
                  id
                  htmlContent
                  title
                  effectiveDate
                  expiryDate
                  organization {
                     id
                     name
                     code
                  }
                  products {
                     id
                     name
                  }
                  jurisdictions {
                     id
                     name
                  }
               }
            }
         }
      `,
      {
         refetchQueries: ["GetProductAnnouncementsForAnnouncementScreen"],
      },
   );

   async function saveNewAnnouncement(values: FormValues, actions: FormikHelpers<FormValues>) {
      const announcement: AnnouncementInput = {
         htmlContent: htmlContent,
         title: values.title,
         effectiveDate: values.effectiveDate,
         expiryDate: values.expiryDate!,
         organizationId: values.organization?.id ? values.organization.id : null,
         productIds: values.products.map((p) => p.id),
         jurisdictionIds: values.jurisdictions.map((j) => j.id),
      };

      const createResult = await createMutate({ variables: { announcement: announcement } });

      if (createResult.data?.announcement.create) {
         notifications.success("Announcement created.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   const [editAnnouncementMutate] = useMutation<{ announcement: { update: Announcement } }, { announcement: AnnouncementInput }>(
      gql`
         mutation UpdateAnnouncement($announcement: AnnouncementInput!) {
            announcement {
               update(announcement: $announcement) {
                  ...editAnnouncementProperties
               }
            }
         }
         ${editAnnouncementPropertiesFragment}
      `,
      {
         refetchQueries: ["GetProgramAnnouncements", "GetProductAnnouncements"],
      },
   );

   async function saveEditedAnnouncement(values: FormValues, actions: FormikHelpers<FormValues>) {
      const announcementInput: AnnouncementInput = {
         id: props.announcementToEdit!.id,
         htmlContent: htmlContent,
         title: values.title,
         effectiveDate: values.effectiveDate,
         expiryDate: values.expiryDate!,
         organizationId: values.organization?.id ? values.organization.id : null,
         productIds: values.products.map((p) => p.id),
         jurisdictionIds: values.jurisdictions.map((j) => j.id),
      };

      const updateResult = await editAnnouncementMutate({ variables: { announcement: announcementInput } });

      if (updateResult.data?.announcement.update) {
         notifications.success("Announcement updated.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         maxWidth="sm" fullWidth={true} scroll="paper">
         <Formik
            initialValues={initialFormValues}
            validate={validate}
            onSubmit={creating ? saveNewAnnouncement : saveEditedAnnouncement}
            enableReinitialize={true}
         >
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle
                        title={creating ? "Post a product announcement" : "Edit product announcement"}
                        handleClose={props.handleClose}
                     />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="title"
                                       variant="outlined"
                                       size="small"
                                       label="Title (optional)"
                                       fullWidth
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <HtmlEditor
                                       content={htmlContent}
                                       onChangeContent={(newValue) => {
                                          setHtmlContent(newValue);
                                          if (newValue.trim().length === 0) {
                                             setHtmlContentBlank(true);
                                          } else {
                                             setHtmlContentBlank(false);
                                          }
                                       }}
                                       isInErrorState={htmlContentTouched && htmlContentBlank}
                                    />
                                    {htmlContentTouched && htmlContentBlank && (
                                       <FormHelperText error>Enter the text of the announcement.</FormHelperText>
                                    )}
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={6}>
                                    <FormikField name="effectiveDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             renderInput={(params) => <TextField size="small" {...params} />}
                                             inputFormat="yyyy-MM-dd"
                                             label="Effective date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             onChange={(newDate: Date | null) =>
                                                fieldProps.form.setFieldValue("effectiveDate", pickedDateToUtcMidnight(newDate))
                                             }
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormikField name="expiryDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             renderInput={(params) => <TextField size="small" {...params} />}
                                             inputFormat="yyyy-MM-dd"
                                             label="Expiry date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             minDate={fieldProps.form.values.effectiveDate}
                                             onChange={(newDate: Date | null) =>
                                                fieldProps.form.setFieldValue("expiryDate", pickedDateToUtcMidnight(newDate))
                                             }
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="products"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={productOptions}
                                       getOptionLabel={(option: Product) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Product(s) (optional)"
                                             error={formikProps.touched.products && formikProps.errors.products !== undefined}
                                             helperText={formikProps.touched.products ? formikProps.errors.products : undefined}
                                          />
                                       )}
                                       renderOption={(props: any, product: Product) => (
                                          <div {...props} >
                                             <div>
                                                <Typography>{product.name}</Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                   {product.program.name}
                                                </Typography>
                                             </div>
                                          </div>
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="jurisdictions"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={jurisdictionOptions}
                                       getOptionLabel={(option: Jurisdiction) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Jurisdiction(s) (optional)"
                                             error={formikProps.touched.jurisdictions && formikProps.errors.jurisdictions !== undefined}
                                             helperText={formikProps.touched.jurisdictions ? formikProps.errors.jurisdictions : undefined}
                                          />
                                       )}
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="organization"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={false}
                                       options={organizationOptions}
                                       getOptionLabel={(option: Organization) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Organization (optional)"
                                             error={formikProps.touched.organization && formikProps.errors.organization !== undefined}
                                             helperText={formikProps.touched.organization ? formikProps.errors.organization : undefined}
                                          />
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label={creating ? "Post" : "Save"}
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => {
                              setHtmlContentTouched(true);

                              if (!htmlContentBlank) {
                                 formikProps.submitForm();
                              }
                           }}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default UpsertProductAnnouncementDialog;
