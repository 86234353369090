import { createTheme, lighten, darken, responsiveFontSizes, Theme } from "@mui/material/styles";



export const theme = responsiveFontSizes(createTheme({
   palette: {
      primary: {
         main: "#231f20",
      },
      secondary: {
         main: "#ffcb31",
         contrastText: "#231f20",
      },
      background: {
         default: "#ededed"
      },
      action: {
         hover: "#ffcb31",
         selected: "#ffcb31"
      },

   },
   typography: {
      htmlFontSize: 16,
      fontSize: 14,
      fontFamily: '"Segoe UI", "Open Sans", "sans-serif"',
      button: {
         textTransform: "unset",
      },
      h1: {
         fontSize: "4rem"
      },
      h2: {
         fontSize: "3.5rem"
      },
   },
   components: {
      MuiAppBar: {
         styleOverrides: {
            root: {
                color: "#231f20",
                backgroundColor: "#ededed"
            }
         }
      },

      MuiButton: {
         defaultProps: {
            disableElevation: true,
         },
         styleOverrides: {
            containedSecondary: {
               border: "solid rgba(0,0,0,0.23) 1px",
               "&:hover:not(:active)": {
                  color: "#fff",
                  backgroundColor: darken("#000", 1),
               },
               "&:active": {
                  color: "#fff",
                  backgroundColor: "#000",
               },
               color: "#231f20"
            }
         }
      },


      MuiTableRow: {
         styleOverrides: {          
            root: {
               "input[type=search]" : {
                  padding: "4px 4px 4px 0"
               }
            }
         }
      },


      MuiFormControl: {
         defaultProps: {
            fullWidth: true,
         },
      },
      
      MuiGrid: {
         styleOverrides: {
            root: {
               "&& a": {
                  cursor: "pointer",
                  color: "#231f20",
                  "&:hover": {
                     color: "#c33991"
                  }
               }
            }
         }
      },



      MuiLink: {
         styleOverrides: {
            root: {
               textDecoration: "none",
               cursor: "pointer",
               color: "#231f20",
               "&:hover": {
                  color: "#c33991"
               }
            },
         }
      },

      MuiListItemIcon: {
         styleOverrides: {
            root: {
               minWidth: "2rem"
            }
         }
      },
      MuiChip: {
         styleOverrides: {
            root: {
               backgroundColor: "#e0e0e0",
               "& .MuiChip-label": {
                  maxWidth: '16rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
               },
               "& .MuiChip-deleteIcon": {
                  color: 'rgba(0,0,0,0.3)'
               }
            }
         }
      }
   },
}));
