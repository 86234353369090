import { useMutation, gql } from "@apollo/client";
import {   DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useState } from "react";
import { Alert } from "./models";
import SpinnerButton from "../SpinnerButton";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      deleteButton: {
         color: theme.palette.error.main,
      },
   }),
);

interface Props {
   handleClose: () => void;
   alertToDelete: Alert | null;
}

const DeleteAlertDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const [deleting, setDeleting] = useState(false);

   const [deleteMutate] = useMutation<{ alert: { delete: Alert } }, { alertId: number }>(
      gql`
         mutation DeleteAlert($alertId: Int!) {
            alert {
               delete(id: $alertId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["AlertsForUser"],
      },
   );

   async function deleteAlert() {
      setDeleting(true);
      const deleteResult = await deleteMutate({ variables: { alertId: props.alertToDelete!.id } });

      if (deleteResult.data?.alert?.delete?.id) {
         notifications.success("Deleted alert.");
      }

      setDeleting(false);
      props.handleClose();
   }

   if (!props.alertToDelete) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>{`Delete ${props.alertToDelete.notificationType} alert?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>Do you want to delete the alert?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Delete" color="primary" inProgress={deleting} onClick={deleteAlert} className={classes.deleteButton} />
         </DialogActions>
      </Dialog>
   );
};

export default DeleteAlertDialog;
