import { useQuery, gql } from "@apollo/client";
import { Typography, Box, Grid, Card, CardContent, CardActions, Button } from "@mui/material";
import React, { useState } from "react";
import { Alert } from "alerts";

import AlertTable from "./AlertTable";
import CreateAlertDialog from "alerts/UpsertAlertDialog";
import DeleteAlertDialog from "alerts/DeleteAlertDialog";
import { Helmet } from "react-helmet";
import { makeStyles, createStyles } from '@mui/styles';
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) =>
   createStyles({
      deleteButton: {
         color: theme.palette.error.main,
      },
   }),
);

export const AlertList: React.FunctionComponent = () => {
   const classes = useStyles();

   const [creatingAlert, setCreatingAlert] = useState<boolean>(false);
   const [deletingAlert, setDeletingAlert] = useState<Alert | null>(null);
   const [editingAlert, setEditingAlert] = useState<Alert>();

   const query = useQuery<{ alerts: Alert[] }>(
      gql`
         query AlertsForUser {
            alerts {
               id
               notificationType
               matchPartialFileName
               documentTypes {
                  id
                  name
               }
               organizations {
                  id
                  name
               }
               products {
                  id
                  name
               }
               programs {
                  id
                  name
               }
               uploadedByUsers {
                  id
                  name
                  organization {
                     id
                     name
                  }
               }
               sources
               statuses
               tags {
                  id
                  value
               }
               plans{
                  id
                  name
               }
            }
         }
      `,
   );
   const alerts = query.data && query.data.alerts ? query.data.alerts : [];

   const onUpsertDialogClose = () => {
      if (creatingAlert) {
         setCreatingAlert(false);
      }
      if (!!editingAlert) {
         setEditingAlert(undefined);
      }
   };

   return (
      <Box p={3}>
         <Helmet>
            <title>Current alerts - Concentra Partner Portal</title>
         </Helmet>
         <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between">
               <Grid item>
                  <Typography variant="h2">Current alerts</Typography>
               </Grid>
               <Grid item>
                  <Button variant="contained" color="secondary" onClick={() => setCreatingAlert(true)}>
                     Create a new alert
                  </Button>
               </Grid>
            </Grid>
            <Grid item>
               <Typography variant="body1">
                  {alerts.length > 0
                     ? "You are being notified when documents are added matching these criteria."
                     : !query.loading
                        ? "You have no alerts in place."
                        : ""}
               </Typography>
            </Grid>
            <Grid item>
               {query.loading ? (
                  <Grid container spacing={3}>
                     {[...Array(3)].map((x, index) => (
                        <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
                           <Skeleton variant="rectangular" width="100%" height="12rem" />
                        </Grid>
                     ))}
                  </Grid>
               ) : (
                  <Grid container spacing={3}>
                     {alerts.map((alert) => (
                        <Grid item key={alert.id} xs={12} sm={12} md={6} lg={4}>
                           <Card variant="outlined">
                              <CardContent>
                                 <Typography variant="h5">{`${alert.notificationType} alert`}</Typography>
                                 <AlertTable
                                    matchPartialFileName={alert.matchPartialFileName}
                                    documentTypes={alert.documentTypes}
                                    organizations={alert.organizations}
                                    uploadedByUsers={alert.uploadedByUsers}
                                    sources={alert.sources}
                                    statuses={alert.statuses}
                                    tags={alert.tags}
                                    products={alert.products}
                                    programs={alert.programs}
                                    plans={alert.plans}
                                 />
                              </CardContent>
                              <CardActions>
                                 <Button onClick={() => setEditingAlert(alert)}>Edit</Button>
                                 <Button className={classes.deleteButton} onClick={() => setDeletingAlert(alert)}>
                                    Delete
                                 </Button>
                              </CardActions>
                           </Card>
                        </Grid>
                     ))}
                  </Grid>
               )}
            </Grid>
         </Grid>

         <CreateAlertDialog
            key={new Date().valueOf()}
            open={creatingAlert || !!editingAlert}
            alert={editingAlert}
            handleClose={onUpsertDialogClose}
         />
         <DeleteAlertDialog alertToDelete={deletingAlert} handleClose={() => setDeletingAlert(null)} />
      </Box>
   );
};
