import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
   size?: string;
}

export const GenericDocIcon: React.FunctionComponent<Props> = (props) => (
   <SvgIcon viewBox="0 0 48 48" style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }}>
      <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z" />
      <path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z" />
      <path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z" />
   </SvgIcon>
);
