import { useQuery, gql } from "@apollo/client";
import {   TextField, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "users/useCurrentUser";
import { Autocomplete } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import { RouteNames } from "application/routes";
import { RateBulletin, RateType, RateSubtype } from "rates";
import { Document as PdfDocument, Page as PdfPage, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css"
import moment from "moment";
import { dateFormat } from "application/formats";
import { Permissions } from "users";
import PostRateBulletinDialog from "./PostRateBulletinDialog";
import DepositRateBulletinDisplay from "./DepositRateBulletinDisplay";
import DeleteRateBulletinDialog from "./DeleteRateBulletinDialog";
import { useDocumentDownloader } from "../documents/DocumentDownloadProvider";
import { Doc } from "../documents";
import { makeStyles, createStyles } from '@mui/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },

      main: {
         flexGrow: 1,
         "& > *": {
            marginBottom: theme.spacing(2),
         },
         "& label": {
            color: theme.palette.text.secondary,
            display: "block",
         },
      },

      deleteButton: {
         color: theme.palette.error.main,
         marginTop: theme.spacing(5),
      },

      actions: {
         width: "10rem",
         flexShrink: 0,
      },
      buttonGrid: {
         height: "100%",
         display: "flex",
         flexDirection: "column",
         "& > :not(:last-child)": {
            marginBottom: theme.spacing(2),
         },
      },
   }));


export const RatesScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const navigate = useNavigate();
   const { openDocumentInWindow } = useDocumentDownloader();
   const { user, userHasPermission } = useCurrentUser();
   const [posting, setPosting] = useState(false);

   const [rateType, setRateType] = useState<RateType | null>(null);
   const [subtypes, setSubtypes] = useState<RateSubtype[] | null>(null);
   const [subtype, setSubtype] = useState<RateSubtype | null>(null);

   const [bulletinId, setBulletinId] = useState<number | null>(null);
   const [documentPageCount, setDocumentPageCount] = useState(0);

   const [deletingRateBulletin, setDeletingRateBulletin] = useState<RateBulletin | null>(null);

   const rateTypesQuery = useQuery<{ rateTypes: RateType[] }, { userIsWyth: boolean }>(
      gql`
         query GetRateTypes {
            rateTypes {
               id
               name
               subtypes {
                  id
                  name
                  products {
                     id
                  }
               }
            }
         }
      `,
      {
         variables: {
            userIsWyth: user.isWyth,
         },
      },
   );

   const rateTypes = useMemo(() => _.orderBy(rateTypesQuery.data?.rateTypes, (rt) => rt.name.toLowerCase()) ?? [], [rateTypesQuery.data]);

   const routeParams = useParams<{ rateType: string; subtype: string }>();

   useEffect(() => {
      const queryStringRateType =
         rateTypes && routeParams.rateType ? rateTypes.find((rt) => rt.name === decodeURIComponent(routeParams.rateType ?? "")) ?? null : null;
      setRateType(queryStringRateType);

      if (queryStringRateType) {
         setSubtypes(queryStringRateType.subtypes);
      }

      const queryStringSubtypeName = routeParams.subtype;
      setSubtype(
         queryStringSubtypeName && subtypes ? subtypes.find((rs) => rs.name === decodeURIComponent(queryStringSubtypeName)) ?? null : null,
      );
   }, [routeParams, rateTypes, subtypes]);

   function onChangeRateType(newSelectedRateType: RateType | null) {
      setRateType(newSelectedRateType);
      setBulletinId(null);

      if (newSelectedRateType) {
         setSubtypes(newSelectedRateType.subtypes);
      }

      navigate(RouteNames.Rates.replace(":rateType?/:subtype?", encodeURIComponent(newSelectedRateType?.name ?? "")), { replace: true });
   }

   function onChangeSubtype(newSelectedSubtype: RateSubtype | null) {
      setSubtype(newSelectedSubtype);
      setBulletinId(null);

      navigate(
         RouteNames.Rates.replace(":rateType?", encodeURIComponent(rateType?.name ?? "")).replace(
            ":subtype?",
            encodeURIComponent(newSelectedSubtype?.name ?? ""),
         ),
         { replace: true }
      );
   }

   function onChangeBulletin(newSelectedBulletin: RateBulletin | null) {
      setBulletinId(newSelectedBulletin ? newSelectedBulletin.id : null);
      setDocumentPageCount(0);
   }

   const rateBulletinsQuery = useQuery<{ rateBulletins: RateBulletin[] }, { rateSubtypeId: number }>(
      gql`
         query FetchRateBulletins($rateSubtypeId: Int!) {
            rateBulletins(rateSubtypeId: $rateSubtypeId) {
               ... on IRateBulletin {
                  id
                  rateSubtype {
                     id
                     name
                     rateType {
                        id
                        name
                     }
                     products {
                        id
                     }
                  }
                  bulletinNumber
                  effectiveDate
                  expiryDate
               }
            }
         }
      `,
      {
         skip: subtype === null,
         variables: {
            rateSubtypeId: subtype?.id ?? 0,
         },
      },
   );

   const selectedBulletinQuery = useQuery<{ rateBulletin: RateBulletin }, { id: number }>(
      gql`
         query FetchRateBulletin($id: Int!) {
            rateBulletin(id: $id) {
               ... on IRateBulletin {
                  id
                  rateSubtype {
                     id
                     name
                     rateType {
                        id
                        name
                     }
                     products {
                        id
                     }
                  }
                  bulletinNumber
                  effectiveDate
                  expiryDate
               }
               ... on DocumentRateBulletin {
                  documentUrl
                  document {
                     id
                     status
                  }
               }
               ... on DepositRateBulletin {
                  products {
                     id
                     name
                     order
                     rates {
                        id
                        term
                        value
                        changed
                        note
                        order
                     }
                     minimums {
                        id
                        name
                        annualInterestFrequency
                        productNote
                        interestNote
                        minimum
                        minimumNew
                        minimumLumpSumDeposit
                        minimumScheduledElectronicDeposit
                        order
                     }
                  }
                  notes {
                     id
                     note
                     order
                  }
               }
            }
         }
      `,
      {
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
         skip: bulletinId === null,
         variables: {
            id: bulletinId ?? 0,
         },
      },
   );

   const selectedBulletin = bulletinId && selectedBulletinQuery.data ? selectedBulletinQuery.data.rateBulletin : null;

   const rateBulletins = useMemo(() => rateBulletinsQuery.data?.rateBulletins ?? [], [rateBulletinsQuery.data]);

   useEffect(() => {
      // Select the currently-in-effect bulletin and load it by default.
      if (!selectedBulletin) {
         const now = new Date();
         const currentBulletins = rateBulletins.filter(
            (rb) =>
               (rb.expiryDate === undefined || rb.expiryDate === null || new Date(rb.expiryDate) < now) && new Date(rb.effectiveDate) < now,
         );
         if (currentBulletins.length === 0) return undefined;
         const bulletinInEffect = _.orderBy(currentBulletins, (rb) => new Date(rb.effectiveDate), "desc")[0];
         setBulletinId(bulletinInEffect.id);
      }
   }, [rateBulletins]);

   const documentBulletinUrl = useMemo(() => (selectedBulletin ? selectedBulletin.documentUrl : null), [selectedBulletin]);
   const userCanDeleteSelectedBulletin =
      selectedBulletin &&
      (userHasPermission(Permissions.ManageRateBulletinsOutsideYourProducts) ||
         (userHasPermission(Permissions.ManageRateBulletins) &&
            user.products.some((p) => selectedBulletin.rateSubtype.products.some((stp) => stp.id === p.id))));

   const rateTypeName = rateType ? ` - ${rateType.name}` : "";
   const subTypeName = subtype ? ` - ${subtype.name}` : "";
   const pageTitle = `Rates${rateTypeName}${subTypeName}`;

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`${pageTitle} - Concentra Partner Portal`}</title>
         </Helmet>

         {!rateTypesQuery.loading && rateTypes.length === 0 ? (
            <Typography>There are no rates that you have permission to see.</Typography>
         ) : (
            <Grid container direction="column" spacing={3}>
               <Grid item container justifyContent="space-between">
                  <Grid item>
                     <Typography variant="h4">{pageTitle}</Typography>
                  </Grid>
                  {userHasPermission(Permissions.ManageRateBulletins) && (
                     <Grid item>
                           <Button variant="contained" color="secondary" onClick={() => setPosting(true)}>
                           Post a rate bulletin document
                        </Button>
                     </Grid>
                  )}
               </Grid>

               <Grid item container spacing={3}>
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                     <Autocomplete
                           autoComplete
                           handleHomeEndKeys={false}
                        options={_.orderBy(rateTypes, (r) => r.name.toLowerCase()) ?? []}
                        getOptionLabel={(rt) => rt.name}
                        value={rateType}
                        onChange={(e, newValue) => onChangeRateType(newValue)}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Rate type" />}
                     />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                     <Autocomplete
                           autoComplete
                           handleHomeEndKeys={false}
                        options={_.orderBy(subtypes, (s) => s.name.toLowerCase()) ?? []}
                        getOptionLabel={(rs) => rs.name}
                        value={subtype}
                        disabled={subtypes === null}
                        onChange={(e, newValue) => onChangeSubtype(newValue)}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Subtype" />}
                     />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                     <Autocomplete
                           autoComplete
                           handleHomeEndKeys={false}
                        options={_.orderBy(rateBulletins, (rb) => rb.effectiveDate, "desc")}
                        getOptionLabel={(rb) => `${rb.bulletinNumber} (${moment.utc(rb.effectiveDate).format(dateFormat)})`}
                        value={bulletinId ? rateBulletins.find((rb) => rb.id === bulletinId) : null}
                        disabled={subtype === null}
                        onChange={(e, newValue) => onChangeBulletin(newValue)}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Bulletin" />}
                     />
                  </Grid>
               </Grid>
                  
               <Grid item container spacing={3} wrap="nowrap">
                  <Grid item className={classes.main}>

                     {bulletinId && !selectedBulletinQuery.loading && !selectedBulletin && (
                        <Grid item>
                           <Typography>Rate bulletin not found.</Typography>
                        </Grid>
                     )}

                     {selectedBulletin?.products && <DepositRateBulletinDisplay bulletin={selectedBulletin} />}

                     {selectedBulletin && selectedBulletin?.documentUrl && (
                        <PdfDocument
                           file={documentBulletinUrl}
                           externalLinkTarget="_blank"
                           onLoadSuccess={({ numPages }) => setDocumentPageCount(numPages)}
                        >
                           {Array.from(new Array(documentPageCount), (el, index) => (
                              <PdfPage key={index} pageNumber={index + 1} width={1200} />
                           ))}
                        </PdfDocument>
                     )}
                  </Grid>



                  <Grid item className={classes.actions}>
                     <div className={classes.buttonGrid}>
                        {selectedBulletin && selectedBulletin?.documentUrl && (
                              <Button variant="contained" color="secondary" onClick={() => openDocumentInWindow(selectedBulletin.document! as Doc)}>
                                 Open PDF
                              </Button>
                        )}
                        {userCanDeleteSelectedBulletin && (
                              <Button className={classes.deleteButton} onClick={() => setDeletingRateBulletin(selectedBulletin)}>
                                 Delete bulletin
                              </Button>
                        )}
                     </div>
                  </Grid>

               </Grid>
            </Grid>
         )}

         <PostRateBulletinDialog
            open={posting}
            handleClose={() => setPosting(false)}
            rateTypes={rateTypes}
            defaultRateType={rateType}
            defaultSubtype={subtype}
         />

         <DeleteRateBulletinDialog
            rateBulletinToDelete={deletingRateBulletin}
            handleClose={(deleted) => {
               setDeletingRateBulletin(null);
               if (deleted) setBulletinId(null);
            }}
         />
      </div>
   );
};
