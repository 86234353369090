import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";
import { User } from "users";

interface Props {
   handleClose: () => void;
   userTitle: string;
   userIdTarget: number;
   callback?: () => void;
}

const ConfirmSwitchUserDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();

   const [submitting, setSubmitting] = useState(false);

   const [submitMutate] = useMutation<{ user: { switchUser: User } }, { userId: number }>(
      gql`
         mutation SwitchUser($userId: Int!) {
            user {
               switchUser(userId: $userId) {
                  id
               }
            }
         }
      `,
      {
      refetchQueries: ["GetCurrentUser"],
      },
   );

   async function submitSwitch() {
      setSubmitting(true);
      const result = await submitMutate({ variables: { userId: props.userIdTarget } });
      if (result.data?.user?.switchUser?.id) {
         notifications.success("Switching account...");
         if (props.callback) {
            props.callback();
         }
      }

      setSubmitting(false);
      props.handleClose();
   }

   if (!props.userIdTarget) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>{`Do you want to switch to this account?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>{`${props.userTitle}`}</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Switch" color="primary" inProgress={submitting} onClick={submitSwitch} />
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmSwitchUserDialog;
