import { useLazyQuery, useQuery, gql, useMutation } from "@apollo/client";
import { Grid,   Typography, CircularProgress, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LastDocumentUpdateContext } from "../application/App";
import { ProgramWithProducts, Program } from "./models";
import ProductTile from "./ProductTile";
import { Helmet } from "react-helmet";
import { Product } from "products";
import UpsertDocumentDialog from "../documents/UpsertDocumentDialog";
import useCurrentUser from "../users/useCurrentUser";
import { Skeleton } from "@mui/material";
import { Announcement, announcementPropertiesFragment, announcementStyles } from "announcements";
import AnnouncementStack from "announcements/AnnouncementStack";
import moment from "moment";
import { useParams } from "react-router-dom";
import HtmlEditor from "announcements/HtmlEditor";
import clsx from "clsx";
import { Permissions } from "users";
import { useNotifications } from "notifications";
import DOMPurify from "dompurify";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
      root: {
         padding: theme.spacing(3),
      },
      sectionHeader: {
         marginBottom: theme.spacing(2),
      },
      progress: {
         margin: theme.spacing(2),
      },
      actionButton: {
         alignSelf: "flex-end",
      },
      screenHeader: {
         marginBottom: theme.spacing(4.5),
      },
      screenHeaderHtml: {
         height: "unset",
      },
   }),
);

export const ProgramScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const notifications = useNotifications();

   const [uploadingForProduct, setUploadingForProduct] = useState<Product | undefined>(undefined);

   const { user, userLoading, userHasPermission } = useCurrentUser();

   const routingParams = useParams<{ id: string }>();
   const programId = routingParams.id ? parseInt(routingParams.id) : NaN;

   const [htmlHeaderContent, setHtmlHeaderContent] = useState("");
   const [editingHeaderMode, setEditingHeaderMode] = useState(false);

   const documentFragment = gql`
      fragment productDocProperties on Document {
         id
         identifier
         title
         fileName
         type {
            id
            name
            category
            isRestrictedForWyth
         }
         date
         uploadDate
         source
         status
         uploadedById
         uploadedBy {
            id
            name
            organization {
               id
               name
            }
         }
         products {
            id
            name
         }
         jurisdictions {
            id
            name
         }
         organizationId
         organization {
            id
            name
            code
         }
         tags {
            id
            value
         }
         catalogueNumber
         version
         comments
      }
   `;

   const [getProgramWithProducts, programWithProductsQuery] = useLazyQuery<
      { programWithProducts: ProgramWithProducts },
      { programId: number; userIsWyth: boolean }
   >(
      gql`
         query ProgramProductsWithDocuments($programId: Int!, $userIsWyth: Boolean!) {
            programWithProducts(programId: $programId) {
               program {
                  id
                  name
                  htmlScreenHeader
               }
               productsWithDocuments {
                  product {
                     id
                     name
                     program {
                        id
                        name
                     }
                  }
                  wythPostedTransientDocuments @skip(if: $userIsWyth) {
                     ...productDocProperties
                  }
                  partnerPostedTransientDocuments {
                     ...productDocProperties
                  }
               }
            }
         }
         ${documentFragment}
      `,
      {
         variables: {
            programId: programId,
            userIsWyth: user.isWyth,
         },
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
         pollInterval: 300000,
      },
   );
   const program = programWithProductsQuery.data?.programWithProducts.program;
   const productsWithDocuments = programWithProductsQuery.data?.programWithProducts.productsWithDocuments;

   const lastDocumentUpdateContext = useContext(LastDocumentUpdateContext);

   useEffect(() => {
      if (!userLoading) {
         getProgramWithProducts();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userLoading, lastDocumentUpdateContext.lastDocumentUpdate]);

   const initiallyCollapseProductTiles = (programWithProductsQuery.data?.programWithProducts.productsWithDocuments.length ?? 0) > 6;

   useEffect(() => {
      setHtmlHeaderContent(program?.htmlScreenHeader ? program.htmlScreenHeader : "");
   }, [program]);

   const userCanEditHeader = userHasPermission(Permissions.ManageProductScreens) && user.products.some((p) => p.programId === program?.id);

   const announcementQuery = useQuery<{ announcements: Announcement[] }, { currentDate: Date; programId: number }>(
      gql`
         query GetProgramAnnouncements($currentDate: DateTime!, $programId: Int!) {
            announcements(currentDate: $currentDate, programId: $programId, includeExpired: false) {
               ...announcementProperties
            }
         }
         ${announcementPropertiesFragment}
      `,
      {
         variables: { currentDate: moment().startOf("day").toDate(), programId: programId },
      },
   );

   const [saveHtmlHeaderContentMutate] = useMutation<{ program: { updateHtmlScreenHeader: Program } }, { program: Partial<Program> }>(
      gql`
         mutation UpdateProgramHtmlScreenHeader($program: ProgramInput!) {
            program {
               updateHtmlScreenHeader(program: $program) {
                  id
                  htmlScreenHeader
               }
            }
         }
      `,
   );

   async function saveHtmlHeaderContent() {
      const saveResult = await saveHtmlHeaderContentMutate({
         variables: { program: { id: program!.id, htmlScreenHeader: htmlHeaderContent } },
      });

      if (saveResult.data?.program.updateHtmlScreenHeader) {
         notifications.success("Program header updated.");
      }

      setEditingHeaderMode(false);
   }

   async function cancelHtmlContent() {
      setEditingHeaderMode(false);
      setHtmlHeaderContent(program?.htmlScreenHeader ?? "");
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`${program ? program.name : "Program"} - Concentra Partner Portal`}</title>
         </Helmet>

         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
               <AnnouncementStack
                  announcements={announcementQuery.data?.announcements ?? []}
                  loading={announcementQuery.loading}
                  showApplicableProductsForProgramId={program?.id}
               />
            </Grid>

            <Grid item container spacing={3} direction="column" xs={12} sm={6} md={8} lg={9} xl={9}>
               {!program ? (
                  <CircularProgress className={classes.progress} />
               ) : (
                  <Grid item>
                     <Typography variant="h3">{program?.name}</Typography>
                  </Grid>
               )}

               <Grid item container spacing={3}>
                  {program &&
                     (editingHeaderMode ? (
                        <Grid item container direction="column" spacing={1}>
                           <Grid item>
                              <HtmlEditor content={htmlHeaderContent} onChangeContent={setHtmlHeaderContent} />
                           </Grid>
                           <Grid item className={classes.actionButton}>
                              <Button variant="contained" color="secondary" onClick={() => saveHtmlHeaderContent()}>
                                 Save
                              </Button>

                              <Button className={classes.actionButton} color="primary" size="small" onClick={() => cancelHtmlContent()}>
                                 Cancel
                              </Button>
                           </Grid>
                        </Grid>
                     ) : (
                        <Grid item container className={classes.screenHeader} direction="column" spacing={1}>
                           <Grid
                              item
                              className={clsx("ql-editor", classes.screenHeaderHtml, classes.htmlContent)}
                              dangerouslySetInnerHTML={{
                                 __html: DOMPurify.sanitize(program.htmlScreenHeader),
                              }}
                           />
                           {userCanEditHeader && (
                              <Button
                                 className={classes.actionButton}
                                 color="primary"
                                 size="small"
                                 onClick={() => setEditingHeaderMode(true)}
                              >
                                 Edit
                              </Button>
                           )}
                        </Grid>
                     ))}

                  <Grid item container>
                     {productsWithDocuments ? (
                        <Grid item container spacing={3}>
                           {productsWithDocuments.length > 0 ? (
                              productsWithDocuments.map((dp) => (
                                 <Grid item key={dp.product.id} xs={12} lg={6} xl={4}>
                                    <ProductTile
                                       product={dp}
                                       userIsWyth={user?.isWyth ?? false}
                                       openUploadDialog={(product) => setUploadingForProduct(product)}
                                       initiallyCollapsed={initiallyCollapseProductTiles}
                                    />
                                 </Grid>
                              ))
                           ) : (
                              <>
                                 <Typography>You haven't been assigned any products in this program.</Typography>
                              </>
                           )}
                        </Grid>
                     ) : (
                        <Grid item>
                           <Grid container spacing={3}>
                              {[...Array(3)].map((x, index) => (
                                 <Grid item key={index} xs={12}>
                                    <Skeleton variant="rectangular" width="100%" height="40rem" />
                                 </Grid>
                              ))}
                           </Grid>
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <UpsertDocumentDialog
            open={uploadingForProduct !== undefined}
            handleClose={() => setUploadingForProduct(undefined)}
            documentIdToEdit={null}
            productId={uploadingForProduct?.id}
         />
      </div>
   );
};
