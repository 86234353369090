import { useQuery, gql, useMutation } from "@apollo/client";
import {   Grid, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { basicScreenStyle } from "application";
import useCurrentUser from "../users/useCurrentUser";
import { Permissions } from "../users";
import { announcementStyles } from "announcements";
import { HtmlPage } from "./models";
import clsx from "clsx";
import HtmlEditor from "announcements/HtmlEditor";
import { useNotifications } from "notifications";
import DOMPurify from "dompurify";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...basicScreenStyle(theme),
      ...announcementStyles(theme),
      progress: {
         margin: theme.spacing(2),
      },
      actionButton: {
         alignSelf: "flex-end",
      },
      pageHeader: {
         marginBottom: theme.spacing(4.5),
      },
      pageHeaderHtml: {
         height: "unset",
         "& h1, & h2, & h3": {
            marginTop: theme.spacing(-9),
            paddingTop: theme.spacing(9),
         },
      },
   }),
);

export const PageScreen: React.FunctionComponent = () => {
   const classes = useStyles();

   const { userHasPermission } = useCurrentUser();

   const notifications = useNotifications();

   const routingParams = useParams<{ name: string }>();

   const [htmlFragmentContent, setHtmlFragmentContent] = useState("");
   const [editingHtmlContentMode, setEditingHtmlContentMode] = useState(false);

   const htmlPageQuery = useQuery<{ htmlPage: HtmlPage }, { name?: string }>(
      gql`
         query GetHtmlPage($name: String!) {
            htmlPage(name: $name) {
               id
               name
               title
               htmlContent
            }
         }
      `,
      {
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
         notifyOnNetworkStatusChange: true,
         variables: {
            name: routingParams.name ? routingParams.name : undefined,
         },
      },
   );

   const htmlPage = htmlPageQuery.data?.htmlPage;
   const hash = window.location.hash;

   useEffect(() => {
      setHtmlFragmentContent(htmlPage?.htmlContent ? DOMPurify.sanitize(htmlPage.htmlContent) : "");

      if (hash && document.getElementById(hash.substr(1))) {
         // Check if there is a hash and if an element with that id exists
         const element = document.getElementById(hash.substr(1));
         element?.scrollIntoView({ behavior: "smooth" });
      }
   }, [htmlPage, hash]);

   const userCanEditHtmlPages = userHasPermission(Permissions.EditHtmlPages);

   const [updateHtmlPageContentMutate] = useMutation<{ htmlPage: { updateHtmlContent: HtmlPage } }, { htmlPage: Partial<HtmlPage> }>(
      gql`
         mutation UpdateHtmlPageContent($htmlPage: HtmlPageInput!) {
            htmlPage {
               updateHtmlContent(htmlPage: $htmlPage) {
                  name
                  id
                  htmlContent
               }
            }
         }
      `,
   );

   async function saveHtmlContent() {
      const saveResult = await updateHtmlPageContentMutate({
         variables: { htmlPage: { name: htmlPage!.name, htmlContent: htmlFragmentContent } },
      });

      if (saveResult.data?.htmlPage.updateHtmlContent) {
         notifications.success("Html content updated.");
      }

      setEditingHtmlContentMode(false);
   }

   async function cancelHtmlContent() {
      setEditingHtmlContentMode(false);
      setHtmlFragmentContent(htmlPage?.htmlContent ?? "");
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`${htmlPage ? htmlPage.title : "Page"} - Concentra Partner Portal`}</title>
         </Helmet>
         <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item container direction="column" spacing={3} xs={12} sm={10} md={8} lg={6}>
               {!htmlPage ? (
                  <CircularProgress className={classes.progress} />
               ) : (
                  <Grid item container direction="column" alignItems="flex-start">
                     <Typography variant="h3">{DOMPurify.sanitize(htmlPage?.title)}</Typography>
                  </Grid>
               )}

               <Grid item container spacing={3} direction="column">
                  {htmlPage &&
                     (editingHtmlContentMode ? (
                        <Grid item container direction="column" spacing={1}>
                           <Grid item>
                              <HtmlEditor content={htmlFragmentContent} onChangeContent={setHtmlFragmentContent} />
                           </Grid>

                           <Grid item className={classes.actionButton}>
                              <Button variant="contained" color="secondary" onClick={() => saveHtmlContent()}>
                                 Save
                              </Button>
                           </Grid>

                           <Button className={classes.actionButton} color="primary" size="small" onClick={() => cancelHtmlContent()}>
                              Cancel
                           </Button>
                        </Grid>
                     ) : (
                        <Grid item container className={classes.pageHeader} direction="column" spacing={1}>
                           <Grid
                              item
                              className={clsx("ql-editor", classes.pageHeaderHtml, classes.htmlContent)}
                              dangerouslySetInnerHTML={{
                                 __html: DOMPurify.sanitize(htmlPage.htmlContent, { ADD_ATTR: ["target"] }),
                              }}
                           />
                           {userCanEditHtmlPages && (
                              <Button
                                 className={classes.actionButton}
                                 color="primary"
                                 size="small"
                                 onClick={() => setEditingHtmlContentMode(true)}
                              >
                                 Edit
                              </Button>
                           )}
                        </Grid>
                     ))}
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
};
