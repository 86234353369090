import { Box, Button, Chip, CircularProgress, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import useCurrentUser from "../users/useCurrentUser";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DocSource, DocStatus, documentListStyles, getIconForDocument, unpackDocumentTypeHierarchy } from "../documents";
import { basicScreenStyle, getIdsFromQueryString, isString } from "../application";
import { announcementStyles } from "../announcements";
import PartnerSpaceSearchFilters from "./PartnerSpaceSearchFilters";
import { useLocation } from "react-router";
import qs from "query-string";
import useDebounce from "../useDebounce";
import { DocFilters, RankedDoc } from "partnerspace";
import moment from "moment";
import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { Permissions } from "users";
import { DocumentTypeOptionQuery, DocumentTypeOptionQueryResult, DocumentTypeOptionQueryVariables, MetadataOptionQuery, MetadataOptionQueryResult, MetadataOptionQueryVariables, ProductOptionQueryVariables, SearchFilterProductOptionQuery, SearchFilterProductOptionQueryResult, UserOptionsQuery, UserOptionsQueryResult, UserOptionsQueryVariables } from "../documents/queries";
import { Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StatusMenu from "../documents/StatusMenu";
import Comments from "../documents/Comments";
import { useDocumentDownloader } from "../documents/DocumentDownloadProvider";
import * as formats from "application/formats";
import clsx from "clsx";
import { ActiveDocumentContext } from "../application/GlobalDocumentActions";
import UpsertDocumentDialog from "../documents/UpsertDocumentDialog";
import { CloudUpload } from "@mui/icons-material";
import { makeStyles, createStyles } from '@mui/styles';

enum SortOrder {
   ByRelevance = "relevance",
   NewestFirst = "newest",
   Alphabetically = "alphabetical",
   ByStatus = "status",
}

const useStyles = makeStyles((theme) =>
   createStyles({
      ...documentListStyles(theme),
      ...announcementStyles(theme),
      root: {
         display: "flex",
      },
      filters: {
         width: "25rem",
         flexShrink: 0,
         flexGrow: 0,
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         flexWrap: "wrap",
         "& > *:first-child": {
            marginRight: "auto",
         },
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            marginBottom: theme.spacing(1),
         },
      },
      searchResults: {
         flexGrow: 1,
         padding: theme.spacing(3),
      },
      resultSecondaryAction: {
         right: theme.spacing(10),
      },
      searchResult: {
         marginBottom: theme.spacing(1),
         paddingLeft: 0,
      },
      sortSelect: {
         marginRight: "auto",
      },
      upload: {
         marginTop: theme.spacing(3),
         marginLeft: theme.spacing(1),
      },
      resultList: {
         maxWidth: "40rem",
      },
      screenHeader: {
         marginTop: theme.spacing(3),
         marginLeft: theme.spacing(1),
      },
      screenDescription: {
         marginLeft: theme.spacing(1),
      },

   }),
);

export const PartnerSpace: React.FunctionComponent = () => {
   const classes = useStyles();

   function excludeNulls(value: string | null | (string | null)[]) {
      if (value === null || value === undefined) {
         return "";
      } else if (typeof value === "string") {
         return value;
      } else {
         return value.flatMap(v => !!v ? [v] : []);
      }
   }

   const { user, userLoading, userHasPermission } = useCurrentUser();
   const location = useLocation();
   const queryStringObject = qs.parse(location.search);
   const activeDocumentContext = useContext(ActiveDocumentContext);
   const { openDocumentInWindow, openBatchDocumentDownloadInNewWindow, isGeneratingBatchFile } = useDocumentDownloader();
   const [postDocumentDialogOpen, setPostDocumentDialogOpen] = useState(false);

   const [highlightedDocumentId, setHighlightedDocumentId] = useState(0);
   const [searchTerms, setSearchTerms] = useState(
      queryStringObject.terms ? (typeof queryStringObject.terms === "string" ? queryStringObject.terms : (queryStringObject.terms[0] ? queryStringObject.terms[0] : "")) : "",
   );
   const debouncedSearchTerms = useDebounce<string>(searchTerms ?? "", 500);

   const initialFilters: DocFilters = {
      startDate:
         queryStringObject.startDate && typeof queryStringObject.startDate === "string"
            ? moment.utc(queryStringObject.startDate as string).toDate()
            : null,
      endDate:
         queryStringObject.endDate && typeof queryStringObject.endDate === "string" ? moment.utc(queryStringObject.endDate as string).toDate() : null,
      documentTypeIds: getIdsFromQueryString(excludeNulls(queryStringObject.typeId)),
      productIds: getIdsFromQueryString(excludeNulls(queryStringObject.productId)),
      jurisdictionIds: getIdsFromQueryString(excludeNulls(queryStringObject.jurisdictionId)),
      organizationIds: [user.organizationId],
   };

   const [filters, setFilters] = useState<DocFilters>(initialFilters);
   const [sortOrder, setSortOrder] = useState((queryStringObject.sort as SortOrder) ?? SortOrder.ByRelevance);
   const [fullyFetched, setFullyFetched] = useState(false);
   useEffect(() => {
      setFullyFetched(false);
   }, [searchTerms, filters, sortOrder]);

   const documentTypeOptionsQuery = useQuery<DocumentTypeOptionQueryResult, DocumentTypeOptionQueryVariables>(DocumentTypeOptionQuery, {
      variables: {},
   });

   const optionsQuery = useQuery<MetadataOptionQueryResult, MetadataOptionQueryVariables>(MetadataOptionQuery, {
      variables: {},
      skip: userLoading,
   });

   const productOptionsQuery = useQuery<SearchFilterProductOptionQueryResult, ProductOptionQueryVariables>(SearchFilterProductOptionQuery, {
      variables: {
         includeAllProducts: !userHasPermission(Permissions.ViewDocumentsOutsideYourProducts)
      },
   });

   const documentTypeOptions = documentTypeOptionsQuery.data
      ? unpackDocumentTypeHierarchy(documentTypeOptionsQuery.data.documentTypes)
      : [];

   const administrationFormType = documentTypeOptions.find(dto => dto.name === "Administration Form");
   const administrationFormTypeId = administrationFormType ? [administrationFormType.id] : [];

   const productOptions = productOptionsQuery.data ? productOptionsQuery.data.searchFilterProducts : [];
   const jurisdictionOptions = optionsQuery.data ? optionsQuery.data.jurisdictions : [];

   const fetchLimit = 50;
   const searchQuery = useQuery<
      { search: { totalCount: number; results: RankedDoc[] } },
      {
         searchText: string;
         filters: {
            startDate: Date | null;
            endDate: Date | null;
            typeIds: number[];
            organizationIds: number[];
            uploadedByUserIds: number[];
            sources: DocSource[];
            statuses: DocStatus[];
            tagIds: number[];
            productIds: number[];
            programIds: number[];
            jurisdictionIds: number[];
            planIds: number[];
         };
         offset: number;
         limit: number;
         sort: SortOrder;
      }
   >(
      gql`
         query DocumentSearch($searchText: String!, $filters: DocumentFiltersInput!, $offset: Int!, $limit: Int!, $sort: String!) {
            search(searchText: $searchText, filters: $filters, offset: $offset, limit: $limit, sort: $sort) {
               totalCount
               results {
                  score
                  document {
                     id
                     identifier
                     title
                     fileName
                     type {
                        id
                        name
                        isRestrictedForWyth
                     }
                     date
                     status
                     source
                     uploadedById
                     uploadedBy {
                        id
                        name
                     }
                     uploadDate
                     organizationId
                     organization {
                        id
                        name
                     }
                     products {
                        id
                        name
                     }
                     jurisdictions {
                        id
                        name
                        order
                     }
                     tags {
                        id
                        value
                     }
                     catalogueNumber
                     version
                     comments
                     plans{
                        id
                        name
                     }
                  }
               }
            }
         }
      `,
      {
         variables: {
            searchText: debouncedSearchTerms,
            filters: {
               startDate: filters.startDate,
               endDate: filters.endDate,
               typeIds: administrationFormTypeId,
               organizationIds: [user.organizationId],
               uploadedByUserIds: [],
               sources: [],
               statuses: [],
               tagIds: [],
               productIds: filters.productIds,
               programIds: [],
               jurisdictionIds: filters.jurisdictionIds,
               planIds: []
            },
            offset: 0,
            limit: fetchLimit,
            sort: sortOrder,
         },
         skip: filtersEmpty() && debouncedSearchTerms.length < 3,
         notifyOnNetworkStatusChange: true,
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
      },
   );

   const totalCount = searchQuery.data ? searchQuery.data.search.totalCount : 0;
   const searchResults = searchQuery.data ? searchQuery.data.search.results : [];
   function filtersEmpty() {
      return (
         filters.startDate === null &&
         filters.endDate === null &&
         filters.documentTypeIds.length === 0 &&
         filters.productIds.length === 0 &&
         filters.jurisdictionIds.length === 0 &&
         filters.organizationIds.length === 0
      );
   }
   function loadMoreResults() {
      if (searchQuery.networkStatus !== NetworkStatus.ready) return;

      // We fetch one more than we want to display--that way we will know in advance if there are no more items left to fetch.
      searchQuery.fetchMore({
         variables: {
            offset: searchResults.length,
            limit: fetchLimit + 1,
         },
         updateQuery: (previousQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return previousQueryResult;
            }

            const fetchedResults = fetchMoreResult.search.results;
            if (fetchedResults.length < fetchLimit + 1) {
               setFullyFetched(true);
            }

            const concatenatedResults = [...searchQuery.data?.search.results!, ...fetchedResults.slice(0, fetchLimit)];

            return {
               search: {
                  totalCount: fetchMoreResult.search.totalCount,
                  results: concatenatedResults,
               },
            };
         },
      });
   }

   function renderSearchResults() {
      if (!searchQuery.data) return null;

      if (searchQuery.networkStatus === NetworkStatus.ready && searchResults.length === 0) {
         return (
            <Grid item>
               <Typography variant="body1" color="textSecondary">
                  No documents found.
               </Typography>
            </Grid>
         );
      }

      return (
         <Grid item>
            <Typography variant="body2" color="textSecondary">
               {`${totalCount} result${totalCount > 1 ? "s" : ""}`}
            </Typography>
            <List dense className={classes.resultList} onMouseLeave={() => setHighlightedDocumentId(0)}>
               <InfiniteScroll
                  loadMore={loadMoreResults}
                  hasMore={!fullyFetched && searchResults.length >= fetchLimit}
                  useWindow={true}
                  loader={
                     <ListItem key={0} alignItems="center">
                        <ListItemIcon className={classes.icon}>
                           <CircularProgress size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Loading..." primaryTypographyProps={{ color: "textSecondary" }} />
                     </ListItem>
                  }
               >
                  {searchResults.map((rankedDoc) => {
                     const document = rankedDoc.document;
                     const icon = getIconForDocument(document);

                     return (
                        <ListItem
                           key={document.id}
                           alignItems="flex-start"
                           className={clsx(classes.searchResult, classes.documentListItem, {
                              [classes.highlightedDocument]: document.id === highlightedDocumentId,
                           })}
                           title={`Product(s): ${document.products.map((p) => p.name).join(", ")}\nPosted by ${document.uploadedBy ? `${document.uploadedBy.name}` : "unknown"
                              } at ${moment(document.uploadDate).format(formats.dateWithTimeFormat)}`}
                           onMouseEnter={() => setHighlightedDocumentId(document.id)}
                        >
                           <ListItemIcon className={classes.icon}>
                              <Link onClick={() => openDocumentInWindow(document)}>{icon}</Link>
                           </ListItemIcon>
                           <ListItemText
                              classes={{ root: classes.documentText, primary: classes.identifier, secondary: classes.details }}
                              primary={<Link onClick={() => openDocumentInWindow(document)}>{document.identifier}</Link>}
                              primaryTypographyProps={{ variant: "body1" }}
                              secondary={
                                 <>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                       <div className={classes.documentDate}>{moment.utc(document.date).calendar()}</div>
                                    </Grid>
                                    <Box mt={0.25}>{document.fileName}</Box>
                                    {user.isWyth && document.organization && <Box mt={0.25}>{document.organization.name}</Box>}
                                    <Box mt={0.25}>
                                       {document.type.name}
                                       {document.version && `, v${document.version}`}
                                       {document.jurisdictions.length > 0 &&
                                          `, Jurisdictions: ${document.jurisdictions.map((j) => j.name).join(", ")}\n`}
                                    </Box>
                                    {(document.tags.length > 0 || (document.comments && document.comments.length > 0)) && (
                                       <Box mt={0.25}>
                                          {document.tags.map((t) => (
                                             <Chip key={t.id} size="small" label={t.value} className={classes.tagChip} />
                                          ))}
                                          {document.comments && document.comments.length > 0 && <Comments getDocument={() => document!} />}
                                       </Box>
                                    )}
                                 </>
                              }
                              secondaryTypographyProps={{ component: "div" }}
                           />

                           <ListItemSecondaryAction className={classes.documentAction}>
                              <IconButton
                                 aria-label="more"
                                 aria-controls="action-menu"
                                 aria-haspopup="true"
                                 onClick={(e) => {
                                    activeDocumentContext.setMenuAnchorEl(e.currentTarget);
                                    activeDocumentContext.setActiveDoc(document);
                                 }}
                              >
                                 <MoreVertIcon />
                              </IconButton>
                           </ListItemSecondaryAction>
                        </ListItem>
                     );
                  })}
               </InfiniteScroll>
            </List>
         </Grid>
      );
   }
   function renderSearchResultsSkeleton() {
      return (
         <Grid item>
            {[...Array(10)].map((x, index) => (
               <Box key={index} mb={2}>
                  <Skeleton variant="rectangular" width="100%" height="6rem" />
               </Box>
            ))}
         </Grid>
      );
   }
   return (
      <div className={classes.root}>
         <Helmet>
            <title>{"Administration Forms"}</title>
         </Helmet>
         <Grid container spacing={3} justifyContent="space-between" className={classes.screenDescription}>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9} className={classes.screenHeader}>
               <Typography variant="h4">{"Administration forms"}</Typography>
            </Grid>
            {userHasPermission(Permissions.PostEditDeleteAdministrationForms) && (
               <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className={classes.upload}>
                  <Button
                     variant="contained"
                     color="secondary"
                     component="span"
                     startIcon={<CloudUpload />}
                     onClick={() => setPostDocumentDialogOpen(true)}
                  >
                     Add administration forms
                  </Button>
               </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.screenDescription}>
               <Typography>A central location to store your organization’s unique registered plans administration forms (deposit form, withdrawal form, etc.) for use by staff at your organization. To upload forms, click on the “Add administration forms” button.</Typography>
            </Grid>

            <div className={classes.filters}>
               <PartnerSpaceSearchFilters
                  searchTerms={searchTerms}
                  updateSearchTerms={(terms) => setSearchTerms(terms)}
                  filters={filters}
                  updateFilters={(f) => {
                     setFilters(f);
                  }}
                  documentTypes={documentTypeOptions}
                  documentTypesLoading={documentTypeOptionsQuery.loading}
                  products={productOptions}
                  productsLoading={productOptionsQuery.loading}
                  jurisdictions={jurisdictionOptions}
                  jurisdictionsLoading={optionsQuery.loading}
               />
            </div>
            <div className={classes.searchResults}>
               <Grid container direction="column" spacing={3} alignItems="stretch">
                  <Grid item className={classes.actions}>
                     <TextField
                        select
                        className={classes.sortSelect}
                        variant="outlined"
                        size="small"
                        label="Sort results"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value as SortOrder)}
                     >
                        <MenuItem value={SortOrder.ByRelevance}>By relevance</MenuItem>
                        <MenuItem value={SortOrder.NewestFirst}>Newest first</MenuItem>
                        <MenuItem value={SortOrder.ByStatus}>By status</MenuItem>
                        <MenuItem value={SortOrder.Alphabetically}>Alphabetically</MenuItem>
                     </TextField>
                  </Grid>
                  {searchQuery.networkStatus === NetworkStatus.loading ? renderSearchResultsSkeleton() : renderSearchResults()}
               </Grid>
            </div>
         </Grid>
         <UpsertDocumentDialog
            open={postDocumentDialogOpen}
            handleClose={() => { setPostDocumentDialogOpen(false); searchQuery.refetch() }}
            documentIdToEdit={null}
            administrationForms={true}
         />
      </div>

   );
}