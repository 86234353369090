import {  MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { DocStatus } from "./models";
import StatusChip from "./StatusChip";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   selectedValue: {
      padding: theme.spacing(1),
   },
}));

const StatusSelect: React.FunctionComponent<{ value?: DocStatus; onChange: (value: DocStatus) => any }> = (props) => {
   const classes = useStyles();
   const { value } = props;
   const handleChange = (event: SelectChangeEvent<DocStatus>) => {
      props.onChange(event.target.value as DocStatus);
   };
   return (
      <Select
         value={value ?? ""}
         displayEmpty
         renderValue={(itemValue) => (
            <span className={classes.selectedValue}>
               {itemValue === undefined ? "Select a status" : <StatusChip status={itemValue as unknown as DocStatus} />}
            </span>
         )}
         onChange={handleChange}
      >
         {Object.values(DocStatus)
            .filter((s) => s !== DocStatus.NA)
            .map((status) => (
               <MenuItem key={status} value={status} selected={status === value}>
                  <StatusChip status={status} />
               </MenuItem>
            ))}
      </Select>
   );
};

export default StatusSelect;
