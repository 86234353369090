import { HasId } from "application";
import { Product } from "products";
import { User } from "users";
import { Offering } from "plan";
import { Portfolio, PortfolioInput } from "../portfolio/models";

export interface Organization extends HasId {
   id: number;
   name: string;
   code: string;
   alternateCodes: string | null;
   emailDomain: string | null;
   alternateEmailDomains: string | null;
   hasAzureAd: boolean;
   users: User[];
   products: Product[];
   portfolios: Portfolio[];
   offerings: Offering[];
   isInactive: boolean;
   isWyth: boolean;
   lastUpdated: Date | string | null;

}

export const defaultOrganization: Organization = {
   id: 0,
   name: "none",
   code: "",
   alternateCodes: null,
   emailDomain: null,
   alternateEmailDomains: null,
   hasAzureAd: false,
   products: [],
   portfolios: [],
   offerings: [],
   users: [],
   isInactive: false,
   isWyth: false,
   lastUpdated: null
};

export interface OrganizationInput {
   id: number;
   name: string;
   code: string;
   alternateCodes: string[];
   emailDomain: string | null;
   alternateEmailDomains: string[];
   hasAzureAd: boolean;
   productIds: number[];
   lastUpdated: Date | string | null;
}

export interface OrganizationOfferingsInput {
   id: number;
   offeringIds: number[];
   lastUpdated: Date | string | null;
}

export interface OrganizationPortfoliosInput {
   id: number | null;
   portfolios: PortfolioInput[];
   lastUpdated: Date | string | null;
}

export const WythOrgCode = "0000";
