import { useQuery, useLazyQuery, gql, useMutation } from "@apollo/client";
import {


   CircularProgress,
   Grid,
   Card,
   CardContent,
   Typography,
   IconButton,
   List,
   ListItem,
   ListItemIcon,
   Link,
   ListItemText,
   ListItemSecondaryAction,
   CardActions,
   Button,
   Box,
   Chip,
   TextField,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { Doc, DocType, DocStatus, getIconForDocument, documentListStyles, DocSource } from "documents";
import _ from "lodash";
import StatusMenu from "documents/StatusMenu";
import UpsertDocumentDialog from "documents/UpsertDocumentDialog";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { basicScreenStyle, getIdsFromQueryString, SearchLink } from "application";
import { RouteNames } from "../application/routes";
import { ActiveDocumentContext } from "../application/GlobalDocumentActions";
import useCurrentUser from "../users/useCurrentUser";
import { Permissions } from "../users";
import { dateWithTimeFormat } from "application/formats";
import { Autocomplete } from "@mui/material";
import qs from "query-string";
import { getOrganizationFilterOptions, getOrganizationOptionLabel, Organization } from "organizations";
import AnnouncementStack from "announcements/AnnouncementStack";
import { Announcement, announcementPropertiesFragment, announcementStyles } from "announcements";
import { LastDocumentUpdateContext } from "application/App";
import { ProductLinkVM, Product } from "./models";
import LinkTile from "./LinkTile";
import DeleteProductLinkDialog from "./DeleteProductLinkDialog";
import clsx from "clsx";
import HtmlEditor from "announcements/HtmlEditor";
import { useNotifications } from "notifications";
import Comments from "../documents/Comments";
import DOMPurify from "dompurify";
import { Listserv } from "../listserv";
import ListservTile from "../listserv/ListservTile";
import OrganizationOption from "organizations/OrganizationOption";
import { useDocumentDownloader } from "documents/DocumentDownloadProvider";
import { makeStyles, createStyles } from '@mui/styles';
import { useSettings } from "../application/SettingsProvider";


const useStyles = makeStyles((theme) =>
   createStyles({
      ...basicScreenStyle(theme),
      ...documentListStyles(theme),
      ...announcementStyles(theme),
      first: {
         order: 1,
      },
      second: {
         order: 2,
      },
      third: {
         order: 3,
      },
      fourth: {
         order: 4,
      },
      fifth: {
         order: 5,
      },
      organizationFilter: {
         marginTop: theme.spacing(2),
      },
      progress: {
         margin: theme.spacing(2),
      },
      sectionHeader: {
         marginBottom: theme.spacing(2),
      },
      actionButton: {
         alignSelf: "flex-end",
      },
      screenHeader: {
         marginBottom: theme.spacing(4.5),
      },
      screenHeaderHtml: {
         height: "unset",
      },
   }),
);

export const ProductScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const { hideRespPortal, respPortalUrl, hideMemberSearch } = useSettings();
   const { user, userHasPermission, userLoading } = useCurrentUser();
   const { openDocumentInWindow } = useDocumentDownloader();
   const userCanEditStatus = userHasPermission(Permissions.EditDocumentStatus);
   const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

   const activeDocumentContext = useContext(ActiveDocumentContext);
   const navigate = useNavigate();
   const location = useLocation();
   const notifications = useNotifications();

   const routingParams = useParams<{ id: string }>();
   const productId = routingParams.id ? parseInt(routingParams.id) : NaN;

   const userHasResourceProduct = user.resourceProducts.some((p) => p.id === productId);
   const userHasReportingProduct = user.products.some((p) => p.id === productId);
   const userHasMemberSearch = userHasPermission(Permissions.SubmitMemberSearchQuery);
   const userHasOutsideProductReportingPermission = userHasPermission(Permissions.ViewDocumentsOutsideYourProducts) ||
      userHasPermission(Permissions.PostEditDeleteDocumentsOutsideYourProducts) ||
      userHasPermission(Permissions.EditDeletePartnerPostedDocumentsOutsideYourProducts);
   const userHasOutsideAnnouncementPermission = userHasPermission(Permissions.ManageAnnouncementsOutsideYourProducts);


   const [highlightedDocumentId, setHighlightedDocumentId] = useState(0);
   const [deletingLink, setDeletingLink] = useState<SearchLink | null>(null);

   const [htmlHeaderContent, setHtmlHeaderContent] = useState("");
   const [editingHeaderMode, setEditingHeaderMode] = useState(false);

   const documentFragment = gql`
      fragment productDocProperties on Document {
         id
         identifier
         title
         fileName
         url
         type {
            id
            name
            category
            isRestrictedForWyth
         }
         date
         status
         source
         uploadedById
         uploadedBy {
            id
            name
            organization {
               id
               name
            }
         }
         uploadDate
         jurisdictions {
            id
            name
            order
         }
         organizationId
         organization {
            id
            name
         }
         products {
            id
            name
         }
         tags {
            id
            value
         }
         catalogueNumber
         version
         comments
      }
   `;

   const optionsQuery = useQuery<
      { resourceType: DocType; transientType: DocType; organizations: Organization[] },
      { userIsWyth: boolean }
   >(
      gql`
         query GetProductScreenOptions($userIsWyth: Boolean!) {
            resourceType: documentType(name: "Resource") {
               id
               children {
                  id
                  name
               }
            }
            transientType: documentType(name: "Transient") {
               id
            }
            organizations(includeWyth: false) @include(if: $userIsWyth) {
               id
               name
               code
            }
         }
      `,
      {
         variables: {
            userIsWyth: user.isWyth,
         },
      },
   );

   const TaskDocumentTypeQuery = useQuery<{ documentTypes: DocType[] }>(
      gql`
         query GetTaskDocumentType{
            documentTypes(isForTaskTile: true) {
               id
               name
            }
         }
      `
   );

   const taskTypes = TaskDocumentTypeQuery.data?.documentTypes;
   const resourceType = optionsQuery.data?.resourceType;
   const transientTypeId = optionsQuery.data?.transientType.id;

   const organizationOptions = _.orderBy(optionsQuery.data?.organizations ?? [], (o) => o.name);

   const queryStringObject = qs.parse(location.search);

   function excludeNulls(value: string | null | (string | null)[]) {
      if (value === null || value === undefined) {
         return "";
      } else if (typeof value === "string") {
         return value;
      } else {
         return value.flatMap(v => !!v ? [v] : []);
      }
   }

   const queryStringOrgIds = getIdsFromQueryString(excludeNulls(queryStringObject.organizationId));

   const [selectedOrganizations, setSelectedOrganizations] = useState([] as Organization[]);
   useEffect(() => {
      setSelectedOrganizations(organizationOptions.filter((o) => queryStringOrgIds.some((oid) => oid === o.id)));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [optionsQuery.data]);

   const [getProduct, productQuery] = useLazyQuery<{ product: Product }, { productId: number }>(
      gql`
         query GetProduct($productId: Int!) {
            product(productId: $productId) {
               id
               name
               code
               htmlScreenHeader
               hasMemberSearch
               programId
               program {
                  id
                  name
               }
            }
         }
      `,
      {
         variables: {
            productId: productId,
         },
      },
   );
   const product = productQuery.data?.product;

   useEffect(() => {
      if (!userLoading) {
         getProduct();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userLoading]);

   const incomingProductDocumentsQuery = useQuery<
      { incomingProductDocuments: Doc[] },
      { productId: number; organizationIds: number[] }
   >(
      gql`
         query GetIncomingProductDocuments($productId: Int!, $organizationIds: [Int]!) {
            incomingProductDocuments(productId: $productId, organizationIds: $organizationIds) {
               ...productDocProperties
            }
         }
         ${documentFragment}
      `,
      {
         skip: userLoading || (!userHasOutsideProductReportingPermission && !userHasReportingProduct),
         notifyOnNetworkStatusChange: true,
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
         variables: {
            productId: productId,
            organizationIds: selectedOrganizations.map((o) => o.id),
         },
      },
   );
   const incomingDocuments = incomingProductDocumentsQuery.data?.incomingProductDocuments;

   const outgoingProductDocumentsQuery = useQuery<{ outgoingProductDocuments: Doc[] }, { productId: number }>(
      gql`
         query GetOutgoingProductDocuments($productId: Int!) {
            outgoingProductDocuments(productId: $productId) {
               ...productDocProperties
            }
         }
         ${documentFragment}
      `,
      {
         skip: userLoading || (!userHasOutsideProductReportingPermission && !userHasReportingProduct),
         notifyOnNetworkStatusChange: true,
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
         variables: {
            productId: productId,
         },

      },
   );
   const outgoingDocuments = outgoingProductDocumentsQuery.data?.outgoingProductDocuments;


   const lastDocumentUpdateContext = useContext(LastDocumentUpdateContext);

   useEffect(() => {
      if (!userLoading) {
         if (userHasOutsideProductReportingPermission || userHasReportingProduct) {
            incomingProductDocumentsQuery.refetch();
            outgoingProductDocumentsQuery.refetch();
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userLoading, lastDocumentUpdateContext.lastDocumentUpdate]);

   useEffect(() => {
      setHtmlHeaderContent(product?.htmlScreenHeader ? product.htmlScreenHeader : "");
   }, [product]);

   const userCanEditHeader = userHasPermission(Permissions.ManageProductScreens) && user.products.some((p) => p.id === product?.id);
   const announcementQuery = useQuery<{ announcements: Announcement[] }, { currentDate: Date; productId: number }>(
      gql`
         query GetProductAnnouncements($currentDate: DateTime!, $productId: Int) {
            announcements(currentDate: $currentDate, includeExpired: false, productId: $productId) {
               ...announcementProperties
            }
         }
         ${announcementPropertiesFragment}
      `,
      {
         variables: { currentDate: moment().startOf("day").toDate(), productId: productId },
      },
   );

   const listservsQuery = useQuery<{ productListservs: Listserv[] }, { productId: number }>(
      gql`
         query GetProductListservs($productId: Int) {
            productListservs(productId: $productId) {
               id
               listName
               title
               htmlContent
            }
         }
      `,
      {
         variables: { productId: productId },
      },
   );

   const listservs = listservsQuery.data?.productListservs ?? [];

   const productLinkQuery = useQuery<{ productLinks: ProductLinkVM[] }, { productId: number }>(
      gql`
         query GetProductLinks($productId: Int) {
            productLinks(productId: $productId) {
               id
               title
               query
               isManual
            }
         }
      `,
      {
         variables: { productId: productId },
      },
   );
   const productLinks = productLinkQuery.data?.productLinks ?? [];

   const resourceCountQuery = useQuery<{ resourceCounts: { docTypeId: number; count: number }[] }, { productId: number }>(
      gql`
         query GetResourceCounts($productId: Int) {
            resourceCounts(productId: $productId) {
               docTypeId
               count
            }
         }
      `,
      {
         variables: { productId: productId },
      },
   );
   let resourceCounts = resourceCountQuery.data?.resourceCounts ?? [];


   function onChangeOrganizations(newSelectedOrganizations: Organization[]) {
      setSelectedOrganizations(newSelectedOrganizations);

      const query = {
         organizationId: newSelectedOrganizations.map((o) => o.id),
      };

      navigate({
         ...location,
         search: qs.stringify(query),
      },
         { replace: true });
   }

   function getResourceLinkTitle(resourceTypeName: string) {
      if (resourceTypeName === "Correspondence") {
         return "Correspondence";
      } else if (resourceTypeName === "Addendum") {
         return "Addenda";
      } else if (resourceTypeName === "Indicative Pricing") {
         return "Indicative Pricing";
      } else {
         return `${resourceTypeName}s`;
      }
   }

   function getResourceCount(docType: DocType) {
      return resourceCounts.find((rc) => rc.docTypeId === docType.id)?.count;
   }

   const [saveHtmlHeaderContentMutate] = useMutation<{ product: { updateHtmlScreenHeader: Product } }, { product: Partial<Product> }>(
      gql`
         mutation UpdateProductHtmlScreenHeader($product: ProductInput!) {
            product {
               updateHtmlScreenHeader(product: $product) {
                  id
                  htmlScreenHeader
               }
            }
         }
      `,
   );

   async function saveHtmlHeaderContent() {
      const saveResult = await saveHtmlHeaderContentMutate({
         variables: { product: { id: product!.id, htmlScreenHeader: htmlHeaderContent } },
      });

      if (saveResult.data?.product.updateHtmlScreenHeader) {
         notifications.success("Product header updated.");
      }

      setEditingHeaderMode(false);
   }

   async function cancelHtmlContent() {
      setEditingHeaderMode(false);
      setHtmlHeaderContent(product?.htmlScreenHeader ?? "");
   }

   function renderDocument(document: Doc) {
      const icon = getIconForDocument(document);

      return <>
         {(userHasOutsideAnnouncementPermission || userHasOutsideProductReportingPermission || userHasResourceProduct || userHasReportingProduct) && (

            <ListItem
               key={document.id}
               className={clsx(classes.documentListItem, {
                  [classes.highlightedDocument]: document.id === highlightedDocumentId,
               })}
               alignItems="flex-start"
               title={`${document.fileName && document.fileName !== document.title ? `File name: ${document.fileName}\n` : ""}${document.url ? `URL: ${document.url}\n` : ""
                  }Posted by ${document.uploadedBy ? `${document.uploadedBy.name} (${document.uploadedBy.organization.name})` : "unknown"
                  } at ${moment(document.uploadDate).format(dateWithTimeFormat)}`}
               onMouseEnter={() => setHighlightedDocumentId(document.id)}
            >
               <ListItemIcon className={classes.icon}>
                  <Link onClick={() => openDocumentInWindow(document)}>{icon}</Link>
               </ListItemIcon>
               <ListItemText
                  classes={{ root: classes.documentText, primary: classes.identifier, secondary: classes.details }}
                  primary={<Link onClick={() => openDocumentInWindow(document)}>{document.identifier}</Link>}
                  primaryTypographyProps={{ variant: "body1" }}
                  secondary={
                     <>
                        <Grid container justifyContent="space-between" alignItems="center">
                           <div className={classes.documentDate}>{moment.utc(document.date).calendar()}</div>
                           {document.status !== DocStatus.NA && <StatusMenu document={document} canEdit={userCanEditStatus && (
                              (user.isWyth && document.source !== "Concentra") ||
                              (!user.isWyth && document.source === "Concentra" && user.organizationId === document.organizationId))
                           } />}
                        </Grid>
                        {user.isWyth && document.organization && <Box mt={0.25}>{document.organization.name}</Box>}
                        <Box mt={0.25}>
                           {document.type.name}
                           {document.version ? `, v${document.version}` : ""}
                        </Box>
                        {document.jurisdictions.length > 0 && (
                           <Box mt={0.25}>Jurisdiction(s): {document.jurisdictions.map((j) => j.name).join(", ")}</Box>
                        )}
                        {(document.tags.length > 0 || (document.comments && document.comments.length > 0)) && (
                           <Box mt={0.25}>
                              {document.tags.map((t) => (
                                 <Chip key={t.id} size="small" label={t.value} className={classes.tagChip} />
                              ))}
                              {document.comments && document.comments.length > 0 && <Comments getDocument={() => document!} />}
                           </Box>
                        )}
                     </>
                  }
                  secondaryTypographyProps={{ component: "div" }}
               />
               <ListItemSecondaryAction className={classes.documentAction}>
                  <IconButton
                     onClick={(e) => {
                        activeDocumentContext.setMenuAnchorEl(e.currentTarget);
                        activeDocumentContext.setActiveDoc(document);
                     }}
                  >
                     <MoreVertIcon />
                  </IconButton>
               </ListItemSecondaryAction>
            </ListItem>
         )}  </>
   }

   return <>
      {(userHasOutsideAnnouncementPermission || userHasResourceProduct || userHasReportingProduct) && (
         <div className={classes.root}>
            <Helmet>
               <title>{`${product ? product.name : "Product"} - Concentra Partner Portal`}</title>
            </Helmet>

            <Grid container spacing={3}>
               <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <AnnouncementStack announcements={announcementQuery.data?.announcements ?? []} loading={announcementQuery.loading} />
               </Grid>

               <Grid item container spacing={3} direction="column" xs={12} sm={6} md={8} lg={9} xl={9}>
                  {!product ? (
                     <CircularProgress className={classes.progress} />
                  ) : (
                     <Grid item container direction="column" alignItems="flex-start">
                        <Typography variant="h3">{product?.name}</Typography>
                        <Link color="inherit" to={RouteNames.Program.replace(":id", product?.programId.toString())} component={RouterLink}>
                           <Typography variant="h6" color="textSecondary">
                              {product?.program.name}
                           </Typography>
                        </Link>
                     </Grid>
                  )}

                  <Grid item container spacing={3}>
                     {product &&
                        (editingHeaderMode ? (
                           <Grid item container direction="column" spacing={1}>
                              <Grid item>
                                 <HtmlEditor content={htmlHeaderContent} onChangeContent={setHtmlHeaderContent} />
                              </Grid>
                              <Grid item className={classes.actionButton}>
                                 <Button variant="contained" color="secondary" onClick={() => saveHtmlHeaderContent()}>
                                    Save
                                 </Button>
                                 <Button className={classes.actionButton} color="primary" size="small" onClick={() => cancelHtmlContent()}>
                                    Cancel
                                 </Button>
                              </Grid>
                           </Grid>
                        ) : (
                           <Grid item container className={classes.screenHeader} direction="column" spacing={1}>
                              <Grid
                                 item
                                 className={clsx("ql-editor", classes.screenHeaderHtml, classes.htmlContent)}
                                 dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(product.htmlScreenHeader),
                                 }}
                              />
                              {userCanEditHeader && (
                                 <Button
                                    className={classes.actionButton}
                                    color="primary"
                                    size="small"
                                    onClick={() => setEditingHeaderMode(true)}
                                 >
                                    Edit
                                 </Button>
                              )}
                           </Grid>
                        ))}

                     <Grid item container spacing={3}>
                        {productLinks.length > 0 && (
                           <Grid item xs={12} lg={6} xl={4} className={classes.fourth}>
                              <LinkTile
                                 links={productLinks}
                                 deleteLink={userHasPermission(Permissions.ManageProductScreens) ? (link) => setDeletingLink(link) : undefined}
                                 title="Tasks"
                                 docTypes={taskTypes}
                              />
                           </Grid>
                        )}

                        <Grid item xs={12} lg={6} xl={4} className={classes.third}>
                           {resourceType && (
                              <LinkTile
                                 links={_.orderBy(resourceType.children, (rt) => rt.name.toLowerCase()).flatMap((rt) => {
                                    const resourceCount = getResourceCount(rt);
                                    if (!resourceCount) {
                                       return [];
                                    } else {
                                       const sortParam =
                                          rt.name === "Update" || rt.name === "Correspondence" || rt.name === "Webinar" ? "&sort=newest" : "";
                                       const resourceCountDescription = resourceCount !== undefined ? ` (${resourceCount})` : "";
                                       return [
                                          {
                                             title: `${getResourceLinkTitle(rt.name)}${resourceCountDescription}`,
                                             query: resourceCount ? `?productId=${productId}&typeId=${rt.id}${sortParam}` : undefined,
                                             isManual: true
                                          },
                                       ];
                                    }
                                 })}
                                 title="Resources"
                              />
                           )}

                           {(product) && (!hideRespPortal) && (userHasReportingProduct) && (product.code === "CUDP_RESP" || product.code === "DRP_RESP") && (
                              <Box mt={3}>
                              <Card variant="outlined">
                                 <CardContent>
                                    <Typography variant="h5">RESP online transactions</Typography>
                                    <Box mt={1} display="flex" justifyContent="space-around" alignItems="center">
                                       <Button
                                          variant="contained" color="secondary"
                                          onClick={() => window.open(respPortalUrl, "_blank")}
                                       >
                                          Open RESP portal
                                       </Button>
                                    </Box>
                                 </CardContent>
                                 </Card>
                              </Box>
                           )}

                           {(product) && (!hideMemberSearch) && (product.hasMemberSearch) && (userHasMemberSearch) && (userHasReportingProduct) && (
                              <Box mt={3}>
                                 <Card variant="outlined">
                                    <CardContent>
                                       <Typography variant="h5">Member search</Typography>
                                       <Box mt={1} display="flex" justifyContent="space-around" alignItems="center">
                                          <Button
                                             variant="contained" color="secondary"
                                             onClick={() => navigate(RouteNames.MemberSearch)}
                                          >
                                             Open member search
                                          </Button>
                                       </Box>
                                    </CardContent>
                                 </Card>
                              </Box>
                           )}
                        </Grid>
                        {(userHasOutsideProductReportingPermission || userHasReportingProduct) && (
                           <Grid item xs={12} lg={6} xl={4} className={user.isWyth ? classes.second : classes.first}>
                              <Card variant="outlined">
                                 <CardContent>
                                    <Link component={RouterLink} to={`${RouteNames.Search}?productId=${product?.id}&typeId=${transientTypeId}&source=${DocSource.Wyth}&sort=newest`}>
                                       <Typography variant="h5">{user.isWyth ? "Outgoing to partners" : "Incoming from Concentra"}</Typography>
                                    </Link>
                                    {!outgoingDocuments ? (
                                       <CircularProgress className={classes.progress} />
                                    ) : outgoingDocuments.length > 0 ? (
                                       <List dense onMouseLeave={() => setHighlightedDocumentId(0)}>
                                          {outgoingDocuments.map((d) => renderDocument(d))}
                                       </List>
                                    ) : (
                                       <Box mt={3}>
                                          <Typography>No documents found.</Typography>
                                       </Box>
                                    )}
                                 </CardContent>
                                 <CardActions>
                                    <Button
                                       size="small"
                                       onClick={() =>
                                          navigate(
                                             `${RouteNames.Search}?productId=${product?.id}&typeId=${transientTypeId}&source=${DocSource.Wyth}&sort=newest`,
                                          )
                                       }
                                    >
                                       Search for more
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                        )}
                        {(userHasOutsideProductReportingPermission || userHasReportingProduct) && (
                           <Grid item xs={12} lg={6} xl={4} className={user.isWyth ? classes.first : classes.second}>
                              <Card variant="outlined">
                                 <CardContent>
                                    <Link component={RouterLink} to={`${RouteNames.Search}?productId=${product?.id}&typeId=${transientTypeId}&source=${DocSource.Partner
                                       }&sort=newest${selectedOrganizations.map((o) => `&organizationId=${o.id}`).join()}`}>
                                       <Typography variant="h5">{user.isWyth ? "Incoming from partners" : "Outgoing to Concentra"}</Typography>
                                    </Link>
                                    {user.isWyth && (
                                       <Autocomplete
                                          className={classes.organizationFilter}
                                          multiple
                                          autoComplete
                                          size="small"
                                          options={organizationOptions}
                                          filterSelectedOptions
                                          getOptionLabel={(org: Organization) => getOrganizationOptionLabel(org, user)}
                                          value={selectedOrganizations}
                                          onChange={(e, newValue) => onChangeOrganizations(newValue)}
                                          renderInput={(params) => <TextField {...params} variant="outlined" label="Organization" />}
                                          renderOption={(props: any, org) => <OrganizationOption props={props} organization={org} user={user} />}
                                          filterOptions={getOrganizationFilterOptions()}
                                          handleHomeEndKeys={false}
                                       />
                                    )}
                                    {!incomingDocuments ? (
                                       <CircularProgress className={classes.progress} />
                                    ) : incomingDocuments.length > 0 ? (
                                       <List dense onMouseLeave={() => setHighlightedDocumentId(0)}>
                                          {incomingDocuments.map((d) => renderDocument(d))}
                                       </List>
                                    ) : (
                                       <Box mt={3}>
                                          <Typography>No documents found.</Typography>
                                       </Box>
                                    )}
                                 </CardContent>
                                 <CardActions>
                                    <Button
                                       size="small"
                                       onClick={() =>
                                          navigate(
                                             `${RouteNames.Search}?productId=${product?.id}&typeId=${transientTypeId}&source=${DocSource.Partner
                                             }&sort=newest${selectedOrganizations.map((o) => `&organizationId=${o.id}`).join()}`,
                                          )
                                       }
                                    >
                                       Search for more
                                    </Button>
                                 </CardActions>
                              </Card>
                           </Grid>
                        )}

                        {listservs.length > 0 &&
                           _.orderBy(listservs, (ls) => ls.title?.toLowerCase()).map((lserv, index) => (
                              <Grid item xs={12} lg={6} xl={4} style={{ order: index + 5 }} key={`listservs${lserv.id}`}>
                                 <ListservTile listserv={lserv} />
                              </Grid>
                           ))}
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <UpsertDocumentDialog
               open={uploadDialogOpen}
               handleClose={() => setUploadDialogOpen(false)}
               documentIdToEdit={null}
               productId={product?.id}
            />
            <DeleteProductLinkDialog
               linkId={deletingLink?.id ?? null}
               linkTitle={deletingLink?.title ?? null}
               handleClose={() => setDeletingLink(null)}
            />
         </div>
      )}</>;
};
