import {
   Box,
   Card,
   CardContent,
   
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemIcon,
   ListItemSecondaryAction,
   ListItemText,
   ListSubheader,
   
   Typography,
   Chip,
   Grid,
   Collapse,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Doc, DocStatus, getIconForDocument, documentListStyles } from "documents";
import moment from "moment";
import React, { useContext, useState } from "react";
import { ProductWithDocuments } from "./models";
import { ActiveDocumentContext } from "../application/GlobalDocumentActions";
import StatusMenu from "documents/StatusMenu";
import { CloudUpload, ExpandMore, ExpandLess } from "@mui/icons-material";
import { RouteNames } from "../application/routes";
import { Link as RouterLink } from "react-router-dom";
import useCurrentUser from "../users/useCurrentUser";
import { Permissions } from "../users";
import { dateWithTimeFormat } from "application/formats";
import { Product } from "products";
import clsx from "clsx";
import Comments from "../documents/Comments";
import { useDocumentDownloader } from "documents/DocumentDownloadProvider";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...documentListStyles(theme),
      root: {},
      header: {
         display: "flex",
         alignItems: "flex-start",
         "& > :first-child": {
            flexGrow: 1,
         },
      },
      buttons: {
         marginLeft: "auto",
         position: "relative",
         top: -theme.spacing(1.5),
         right: -theme.spacing(1.5),
      },
   }),
);

interface Props {
   product: ProductWithDocuments;
   userIsWyth: boolean;
   openUploadDialog: (product: Product) => void;
   initiallyCollapsed?: boolean;
}

const ProductTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const { user, userHasPermission } = useCurrentUser();
   const { openDocumentInWindow } = useDocumentDownloader();

   const { product } = props.product;

   const userHasReportingProduct = user.products.some((p) => p.id === product.id);
   const userCanPostDocuments = userHasReportingProduct && userHasPermission(Permissions.PostEditOwnDocuments);
   const userCanEditStatus = userHasPermission(Permissions.EditDocumentStatus);
   const [collapsed, setCollapsed] = useState(props.initiallyCollapsed);
   const [highlightedDocumentId, setHighlightedDocumentId] = useState(0);

   const activeDocumentContext = useContext(ActiveDocumentContext);

   function renderDocument(document: Doc) {
      const icon = getIconForDocument(document);

      return (
         <ListItem
            key={document.id}
            alignItems="flex-start"
            className={clsx(classes.documentListItem, {
               [classes.highlightedDocument]: document.id === highlightedDocumentId,
            })}
            title={`${document.fileName && document.fileName !== document.title ? `File name: ${document.fileName}\n` : ""}${
               document.url ? `URL: ${document.url}\n` : ""
            }Posted by ${
               document.uploadedBy ? `${document.uploadedBy.name} (${document.uploadedBy.organization.name})` : "unknown"
            } at ${moment(document.uploadDate).format(dateWithTimeFormat)}`}
            onMouseEnter={() => setHighlightedDocumentId(document.id)}
         >
            <ListItemIcon>
               <Link onClick={() => openDocumentInWindow(document)}>{icon}</Link>
            </ListItemIcon>
            <ListItemText
               classes={{ root: classes.documentText, primary: classes.identifier, secondary: classes.details }}
               primary={<Link onClick={() => openDocumentInWindow(document)}>{document.identifier}</Link>}
               primaryTypographyProps={{ variant: "body1" }}
               secondary={
                  <>
                     <Grid container justifyContent="space-between" alignItems="center">
                        <div className={classes.documentDate}>{moment.utc(document.date).calendar()}</div>
                        {document.status !== DocStatus.NA && <StatusMenu document={document} canEdit={userCanEditStatus && (
                           (user.isWyth && document.source !== "Concentra") ||
                           (!user.isWyth && document.source === "Concentra" && user.organizationId === document.organizationId))
                        } />}
                     </Grid>
                     {user.isWyth && document.organization && <Box mt={0.25}>{document.organization.name}</Box>}
                     <Box mt={0.25}>
                        {document.type.name}
                        {document.version ? `, v${document.version}` : ""}
                     </Box>
                     {document.jurisdictions.length > 0 && (
                        <Box mt={0.25}>Jurisdiction(s): {document.jurisdictions.map((j) => j.name).join(", ")}</Box>
                     )}
                     {(document.tags.length > 0 || (document.comments && document.comments.length > 0)) && (
                        <Box mt={0.25}>
                           {document.tags.map((t) => (
                              <Chip key={t.id} size="small" label={t.value} className={classes.tagChip} />
                           ))}
                           {document.comments && document.comments.length > 0 && <Comments getDocument={() => document!} />}
                        </Box>
                     )}
                  </>
               }
               secondaryTypographyProps={{ component: "div" }}
            />
            <ListItemSecondaryAction className={classes.documentAction}>
               <IconButton
                  aria-label="more"
                  aria-haspopup="true"
                  onClick={(e) => {
                     activeDocumentContext.setMenuAnchorEl(e.currentTarget);
                     activeDocumentContext.setActiveDoc(document);
                  }}
               >
                  <MoreVertIcon />
               </IconButton>
            </ListItemSecondaryAction>
         </ListItem>
      );
   }

   function renderWythTileContents() {
      return (
         (props.product.partnerPostedTransientDocuments.length > 0) &&
         <List onMouseLeave={() => setHighlightedDocumentId(0)}>
            {props.product.partnerPostedTransientDocuments.filter(d=>d.type.name != "Administration Form").map((d) => renderDocument(d))}
            </List>
      );
   }

   function renderPartnerTileContents() {
      return (
         (props.product.wythPostedTransientDocuments.length > 0 || props.product.partnerPostedTransientDocuments.length > 0) &&
         <div onMouseLeave={() => setHighlightedDocumentId(0)}>
            <List>
               <ListSubheader>Incoming from Concentra</ListSubheader>
               {props.product.wythPostedTransientDocuments.map((d) => renderDocument(d))}
            </List>
            <Divider />
            <List>
               <ListSubheader>Outgoing to Concentra</ListSubheader>
               {props.product.partnerPostedTransientDocuments.map((d) => renderDocument(d))}
            </List>
         </div>
      );
   }

   return (
      <Card className={classes.root} variant="outlined">
         <CardContent>
            <div className={classes.header}>
               <Grid container direction="column">
                  <Link component={RouterLink} to={RouteNames.Product.replace(":id", product.id.toString())}>
                     <Typography variant="h5">{product.name}</Typography>
                  </Link>
                  <Typography variant="caption" color="textSecondary">
                     {product.program.name}
                  </Typography>
               </Grid>
               <div className={classes.buttons}>
                  <IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
                  {userCanPostDocuments && (
                     <IconButton
                        onClick={() => props.openUploadDialog(props.product.product)}
                        title={`Post ${props.product.product.name} document`}
                     >
                        <CloudUpload />
                     </IconButton>
                  )}
               </div>
            </div>
            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
               {props.userIsWyth ? renderWythTileContents() : renderPartnerTileContents()}
            </Collapse>
         </CardContent>
      </Card>
   );
};

export default ProductTile;
