import { useMutation, useQuery } from "@apollo/client";
import { DialogContentText, Dialog, DialogContent, DialogActions, Button, Grid } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useState } from "react";
import { DocFilters } from "search";
import { Alert, AlertInput, UpsertAlertMutation } from "alerts";
import { unpackDocumentTypeHierarchy } from "documents";
import { Permissions } from "../users";
import SpinnerButton from "../SpinnerButton";
import SearchFilters from "search/SearchFilters";
import useCurrentUser from "users/useCurrentUser";
import {
   MetadataOptionQueryResult,
   MetadataOptionQueryVariables,
   MetadataOptionQuery,
   UserOptionsQueryResult,
   UserOptionsQuery,
   UserOptionsQueryVariables,
   DocumentTypeOptionQueryResult,
   DocumentTypeOptionQueryVariables,
   DocumentTypeOptionQuery,
   ProductOptionQueryResult,
   ProductOptionQueryVariables,
   ProductOptionQuery,
   SearchFilterProductOptionQueryResult,
   SearchFilterProductOptionQuery,
} from "documents/queries";
import ClosableDialogTitle from "application/ClosableDialogTitle";

interface Props {
   alert?: Alert;
   open: boolean;
   handleClose: () => void;
}

const CreateAlertDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const { userHasPermission } = useCurrentUser();
   const [saving, setSaving] = useState(false);

   const [matchPartialFileName, setMatchPartialFileName] = useState(props.alert?.matchPartialFileName ?? "");
   const [filters, setFilters] = useState<DocFilters>({
      startDate: null,
      endDate: null,
      documentTypeIds: props.alert?.documentTypes.map((d) => d.id) ?? [],
      organizationIds: props.alert?.organizations.map((o) => o.id) ?? [],
      uploadedByUserIds: props.alert?.uploadedByUsers.map((u) => u.id) ?? [],
      statuses: props.alert?.statuses ?? [],
      sources: props.alert?.sources ?? [],
      tagIds: props.alert?.tags.map((t) => t.id) ?? [],
      productIds: props.alert?.products.map((p) => p.id) ?? [],
      programIds: props.alert?.programs.map((p) => p.id) ?? [],
      jurisdictionIds: [],
      planIds: props.alert?.plans.map((p) => p.id) ?? [],
   });

   const productOptionsQuery = useQuery<SearchFilterProductOptionQueryResult, ProductOptionQueryVariables>(SearchFilterProductOptionQuery, {
      variables: {
         includeAllProducts: userHasPermission(Permissions.ViewDocumentsOutsideYourProducts),
      },
   });

   const documentTypesQuery = useQuery<DocumentTypeOptionQueryResult, DocumentTypeOptionQueryVariables>(DocumentTypeOptionQuery, {
      variables: {},
   });

   const usersQuery = useQuery<UserOptionsQueryResult, UserOptionsQueryVariables>(UserOptionsQuery, {
      variables: {},
   });

   const optionsQuery = useQuery<MetadataOptionQueryResult, MetadataOptionQueryVariables>(MetadataOptionQuery, {
      variables: {},
   });

   const documentTypeOptions = documentTypesQuery.data ? unpackDocumentTypeHierarchy(documentTypesQuery.data.documentTypes) : [];
   const organizationOptions = optionsQuery.data?.organizations ?? [];
   const statusOptions = optionsQuery.data?.statuses ?? [];
   const sourceOptions = optionsQuery.data?.sources ?? [];
   const userOptions = usersQuery.data?.users ?? [];
   const tagOptions = optionsQuery.data?.tags ?? [];
   const productOptions = productOptionsQuery.data?.searchFilterProducts ?? [];
   const programOptions = optionsQuery.data?.programs ?? [];
   const jurisdictionOptions = optionsQuery.data?.jurisdictions ?? [];
   const planOptions = optionsQuery.data?.plans ?? [];

   function filtersEmpty() {
      return (
         matchPartialFileName.trim() === "" &&
         filters.organizationIds.length === 0 &&
         filters.productIds.length === 0 &&
         filters.documentTypeIds.length === 0 &&
         filters.tagIds.length === 0 &&
         filters.statuses.length === 0 &&
         filters.jurisdictionIds.length === 0 &&
         filters.programIds.length === 0 &&
         filters.sources.length === 0 &&
         filters.uploadedByUserIds.length === 0 &&
         filters.planIds.length === 0
      );
   }

   const [upsertAlertMutate] = useMutation<
      { alert: { upsert: Alert } },
      {
         alert: AlertInput;
      }
   >(UpsertAlertMutation, { refetchQueries: ["AlertsForUser"] });

   async function confirmCreate() {
      setSaving(true);

      const isUpdate = !!props.alert?.id;

      const upsertAlert = await upsertAlertMutate({
         variables: {
            alert: {
               id: props.alert?.id,
               matchPartialFileName: matchPartialFileName,
               documentTypeIds: filters.documentTypeIds,
               organizationIds: filters.organizationIds,
               uploadedByUserIds: filters.uploadedByUserIds,
               sources: filters.sources,
               statuses: filters.statuses,
               tagIds: filters.tagIds,
               productIds: filters.productIds,
               programIds: filters.programIds,
               planIds: filters.planIds
            },
         },
      });

      if (upsertAlert.data?.alert.upsert) {
         notifications.success(`Alert ${isUpdate ? "updated" : "created"}.`);
      }

      setSaving(false);
      props.handleClose();
   }

   return (
      <Dialog open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         scroll="paper">
         <ClosableDialogTitle title="Get notified when documents are posted" handleClose={props.handleClose} />
         <DialogContent>
            <Grid container direction="column" spacing={3}>
               <Grid item>
                  <DialogContentText>
                     You will be notified by email when documents are posted that meet the criteria you enter below.
                  </DialogContentText>
               </Grid>
               <Grid item>
                  <SearchFilters
                     forAlertCreation={true}
                     searchTerms={matchPartialFileName}
                     updateSearchTerms={(terms) => setMatchPartialFileName(terms)}
                     filters={filters}
                     updateFilters={(f) => {
                        setFilters(f);
                     }}
                     documentTypes={documentTypeOptions}
                     documentTypesLoading={documentTypesQuery.loading}
                     organizations={organizationOptions}
                     organizationsLoading={optionsQuery.loading}
                     statuses={statusOptions}
                     sources={sourceOptions}
                     users={userOptions}
                     usersLoading={usersQuery.loading}
                     tags={tagOptions}
                     tagsLoading={optionsQuery.loading}
                     products={productOptions}
                     productsLoading={productOptionsQuery.loading}
                     programs={programOptions}
                     programsLoading={optionsQuery.loading}
                     jurisdictions={jurisdictionOptions}
                     jurisdictionsLoading={optionsQuery.loading}
                     plans={planOptions}
                     plansLoading={optionsQuery.loading}
                  />
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton variant="contained" label="Alert me" color="secondary" inProgress={saving} onClick={confirmCreate} disabled={filtersEmpty()} />
         </DialogActions>
      </Dialog>
   );
};

export default CreateAlertDialog;
