import {
   
   
   Grid,
   TableContainer,
   Table,
   TableBody,
   TableRow,
   TableCell,
   TableHead,
   Typography,
} from "@mui/material";
import React from "react";
import { DepositRateProduct } from "rates";
import _ from "lodash";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {},
      header: {
         marginTop: 0,
         marginBottom: theme.spacing(0),
         fontSize: theme.typography.h5.fontSize,
      },
      rateTable: {
         width: "24em",
         tableLayout: "fixed",
      },
      simpleMinimumTable: {
         width: "32em",
         tableLayout: "fixed",
      },
      complexMinimumTable: {
         width: "48em",
         tableLayout: "fixed",
      },
      changed: {
         color: theme.palette.warning.dark,
      },
      subtext: {
         fontSize: "smaller",
      },
      footnotes: {
         marginTop: theme.spacing(1),
         maxWidth: "50em",
      },
   }),
);

interface Props {
   product: DepositRateProduct;
}

const DepositRateProductDisplay: React.FunctionComponent<Props> = ({ product }) => {
   const classes = useStyles();

   const moneyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
   });

   const minimumType = product.minimums.every((m) => m.minimum !== null) ? "simple" : "complex";
   const hasAnnualInterestFrequency = product.minimums.some((m) => m.annualInterestFrequency !== null);

   let noteCount = 0;
   const footnotes: string[] = [];
   function addFootnote(note: string) {
      footnotes.push(note);
      noteCount++;
      return noteCount;
   }

   return (
      <div className={classes.root}>
         <h3 className={classes.header}>{product.name}</h3>

         <Grid container spacing={6}>
            <Grid item>
               <TableContainer>
                  <Table size="small" className={classes.rateTable}>
                     <colgroup>
                        <col width="12em" />
                        <col width="6em" />
                        <col width="6em" />
                     </colgroup>
                     <TableHead>
                        <TableRow>
                           <TableCell colSpan={3}>&nbsp;</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {_.orderBy(product.rates, (r) => r.order).map((rate) => (
                           <TableRow key={rate.id}>
                              <TableCell component="th" scope="row">
                                 {rate.term}
                              </TableCell>
                              <TableCell align="right">{`${(rate.value * 100).toFixed(3)}%`}</TableCell>
                              <TableCell className={classes.changed}>{rate.changed ? "changed" : ""}</TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
            <Grid item>
               <TableContainer>
                  {minimumType === "simple" ? (
                     <Table size="small" className={classes.simpleMinimumTable}>
                        <colgroup>
                           <col width="10em" />
                           <col width="10em" />
                           <col width="16em" />
                        </colgroup>
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={2}>Minimums</TableCell>
                              <TableCell>{hasAnnualInterestFrequency ? "Annual interest frequency" : ""}</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {_.orderBy(product.minimums, (m) => m.order).map((min) => (
                              <TableRow key={min.id}>
                                 <TableCell component="th" scope="row">
                                    {min.name}
                                    {min.productNote && <sup>({addFootnote(min.productNote)})</sup>}
                                 </TableCell>
                                 <TableCell align="right">{moneyFormatter.format(min.minimum!)}</TableCell>
                                 <TableCell>
                                    {hasAnnualInterestFrequency ? min.annualInterestFrequency : ""}
                                    {min.interestNote && <sup>({addFootnote(min.interestNote)})</sup>}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  ) : (
                     <Table size="small" className={classes.complexMinimumTable}>
                        <colgroup>
                           <col width="10em" />
                           <col width="10em" />
                           <col width="12em" />
                           <col width="16em" />
                        </colgroup>
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={4}>Minimums</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell></TableCell>
                              <TableCell align="center">New</TableCell>
                              <TableCell colSpan={2} align="center">
                                 Subsequent
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="center">Lump sum deposit</TableCell>
                              <TableCell align="center">
                                 Scheduled electronic deposit
                                 <br />
                                 <span className={classes.subtext}>(biweekly or monthly)</span>
                              </TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {product.minimums.map((min) => (
                              <TableRow key={min.id}>
                                 <TableCell component="th" scope="row">
                                    {min.name}
                                 </TableCell>
                                 <TableCell align="right">
                                    {min.minimumNew === -1
                                       ? "Not available"
                                       : min.minimumNew === null
                                       ? "No minimum"
                                       : moneyFormatter.format(min.minimumNew!)}
                                 </TableCell>
                                 <TableCell align="right">
                                    {min.minimumLumpSumDeposit !== null ? moneyFormatter.format(min.minimumLumpSumDeposit!) : "--"}
                                 </TableCell>
                                 <TableCell align="right">
                                    {min.minimumScheduledElectronicDeposit
                                       ? moneyFormatter.format(min.minimumScheduledElectronicDeposit)
                                       : "--"}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  )}
               </TableContainer>
            </Grid>
         </Grid>
         <div className={classes.footnotes}>
            {footnotes.map((f, index) => (
               <div key={index}>
                  <Typography variant="caption">{`(${index + 1}) ${f}`}</Typography>
               </div>
            ))}
         </div>
      </div>
   );
};

export default DepositRateProductDisplay;
