import { gql, useMutation } from "@apollo/client";
import {   Grid, Typography, FormGroup, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "./useCurrentUser";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
   }),
);

export const UserSettingsScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const { user, userLoading } = useCurrentUser();

   const [toggleNotifyOnCriticalStatusChangesMutate] = useMutation(gql`
      mutation ToggleNotifyOnCriticalStatusChanges {
         user {
            toggleNotifyOnCriticalStatusChanges {
               id
               notifyOnCriticalStatusChanges
            }
         }
      }
   `);

   async function toggleNotifyOnCriticalStatusChanges() {
      await toggleNotifyOnCriticalStatusChangesMutate({
         variables: {
            userId: user.id,
         },
      });
   }

   const [toggleShowOrganizationCodesMutate] = useMutation(gql`
      mutation ToggleShowOrganizationCodesMutate {
         user {
            toggleShowOrganizationCodes {
               id
               showOrganizationCodes
            }
         }
      }
   `);

   async function toggleShowOrganizationCodes() {
      await toggleShowOrganizationCodesMutate({
         variables: {
            userId: user.id,
         },
      });
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>My settings - Concentra Partner Portal</title>
         </Helmet>

         <Grid container direction="column" spacing={3}>
            <Grid item>
               <Typography variant="h4">My settings</Typography>
            </Grid>
            {!userLoading && (
               <>
                  {!user.isWyth && (
                     <>
                        {" "}
                        <Grid item>
                           <Typography variant="h5">Notifications</Typography>{" "}
                        </Grid>
                        <Grid item>
                           <FormGroup>
                              <FormControlLabel
                                 control={
                                    <Switch
                                       checked={user.notifyOnCriticalStatusChanges}
                                       onChange={() => toggleNotifyOnCriticalStatusChanges()}
                                       color="secondary"
                                    />
                                 }
                                 label='Notify me when the status of documents I posted changes to "Corrections required"'
                              />
                           </FormGroup>
                        </Grid>
                     </>
                  )}
                  {user.isWyth && (
                     <Grid item>
                        <FormGroup>
                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={user.showOrganizationCodes}
                                    onChange={() => toggleShowOrganizationCodes()}
                                    color="secondary"
                                 />
                              }
                              label="Show codes in organization selectors"
                           />
                        </FormGroup>
                     </Grid>
                  )}
               </>
            )}
         </Grid>
      </div>
   );
};
