import React from "react";
import linkIcon from "./link_icon.svg";

interface Props {
   size?: string;
}

export const ExternalDocIcon: React.FunctionComponent<Props> = (props) => (
   <img alt="External doc icon" src={linkIcon} style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }} />
);
