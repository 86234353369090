import { gql, useQuery } from "@apollo/client";
import { User } from "users";
import { defaultOrganization } from "organizations";

export default function useCurrentPrimaryUser() {
   const query = useQuery<{
      currentPrimaryUser: User;
   }>(gql`
      query GetCurrentPrimaryUser {
         currentPrimaryUser {
            id
            name
            email
            isWyth
            notifyOnCriticalStatusChanges
            showOrganizationCodes
            isPrimary
            permissions
            products {
               id
               name
               programId
               program {
                  id
                  name
               }
            }
            resourceProducts {
               id
               name
               programId
               program {
                  id
                  name
               }
            }
            organizationId
            organization {
               id
               name
               code
               hasAzureAd
            }
            favourites {
               id
               documentId
               document {
                  id
                  identifier
                  fileName
                  catalogueNumber
               }
            }
            userGroup {
               users {
                  id
                  name
                  organization {
                     name
                  }
               }
            }
            isAutomationUser
         }
      }
   `);

   const defaultUser: User = {
      id: 0,
      name: "",
      email: "",
      isWyth: false,
      notifyOnCriticalStatusChanges: false,
      showOrganizationCodes: false,
      roles: [],
      permissions: [],
      organizationId: 0,
      organization: defaultOrganization,
      products: [],
      resourceProducts: [],
      alerts: [],
      favourites: [],
      userGroup: null!,
      isAutomationUser: false,
      lastUpdated: null,
      lastSignin: null,
      isInactive: false,
      isPrimary: true,
      userGroupId: null!
   };
   const user = query.data?.currentPrimaryUser ?? defaultUser;

   return {
      currentPrimaryUser: user,
   };
}
