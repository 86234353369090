import { gql } from "@apollo/client";

export const UpdateUserMutation = gql`
   mutation UpdateUser($user: UserInput!) {
      user {
         update(user: $user) {
            id
            products {
               id
               name
            }
            resourceProducts {
               id
               name
            }
            roles {
               id
               name
            }
         }
      }
   }
`;

export const DeactivateUserMutation = gql`
   mutation DeactivateUser($id: Int!) {
      user {
         deactivate(id: $id) {
            id
         }
      }
   }
`;

export const ReactivateUserMutation = gql`
   mutation ReactivateUser($id: Int!) {
      user {
         reactivate(id: $id) {
            id
         }
      }
   }
`;

export const AddUserGroupMutation = gql`
   mutation AddUserGroup($userGroup: UserGroupInput!) {
      userGroup {
         addUserGroup(userGroup: $userGroup) {
            groupName
         }
      }
   }
`;

export const UpdateUserGroupMutation = gql`
   mutation UpdateUserGroup($userGroup: UserGroupInput!) {
      userGroup {
         updateUserGroup(userGroup: $userGroup) {
            id
            groupName
            users {
               id
            }
         }
      }
   }
`;

export const DeleteUserGroupMutation = gql`
   mutation DeleteUserGroup($id: Int!) {
      userGroup {
         deleteUserGroup(id: $id) {
            id
         }
      }
   }
`;