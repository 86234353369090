import {   InputAdornment, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { Search, ArrowForward } from "@mui/icons-material";
import { RouteNames } from "application/routes";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      searchBar: {
         width: "36em",
         marginTop: "2em",
         marginBottom: "2em",
         "& .Mui-focused": {
            backgroundColor: "white",
            "& .MuiSvgIcon-root": {
               color: "#c33991 !important"
            }
         },
         
      },
   }),
);

const MainSearchBar: React.FunctionComponent = () => {
   /// Pulled this into its own component because re-rendering the whole screen on each keypress was slow.

   const classes = useStyles();
   const navigate = useNavigate();
   const [searchTerms, setSearchTerms] = useState("");

   const handleSearch = () => {
      navigate(`${RouteNames.Search}?terms=${encodeURI(searchTerms)}`);
   };

   return (
      <TextField
         className={classes.searchBar}
         label="Search for documents by keyword"
         value={searchTerms}
         onChange={(e) => setSearchTerms(e.target.value)}
         variant="outlined"
         onKeyPress={(e) => {
            if (e.key === "Enter") {
               handleSearch();
            }
         }}
         InputProps={{
            startAdornment: (
               <InputAdornment position="start">
                  <Search />
               </InputAdornment>
            ),
            endAdornment: (
               <InputAdornment position="end">
                  <IconButton onClick={handleSearch} disabled={searchTerms.trim() === ""}>
                     <ArrowForward />
                  </IconButton>
               </InputAdornment>
            ),
         }}
      />
   );
};

export default MainSearchBar;
