import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

export function announcementStyles(theme: Theme) {
   return createStyles({
      base: {
         "& h1, & h2, & h3, & p": {
            marginTop: 0,
         },
      },
      applicabilities: {
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(1),
      },
      applicability: {
         fontSize: "0.8em",
      },
      title: {
         fontSize: theme.typography.h6.fontSize,
         fontWeight: 500,
      },
      effectiveDate: {
         fontSize: theme.typography.body2.fontSize,
         color: theme.palette.text.secondary,
         marginBottom: theme.spacing(1),
      },
      htmlContent: {
         fontSize: theme.typography.body2.fontSize,
         padding: 0,
         "& h1": {
            fontSize: theme.typography.h6.fontSize,
            fontWeight: 500,
         },
         "& h2": {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 500,
         },
         "& > :last-child": {
            marginBottom: 0,
         },
         "& img": {
            maxWidth: "100%",
         },
      },
   });
}
