import { useState } from "react";
import { useNotifications } from "notifications";

// From https://usehooks.com/useLocalStorage/
export default function useLocalStorage(key: string, initialValue: any) {
   const notifications = useNotifications();

   // State to store our value
   // Pass initial state function to useState so logic is only executed once
   const [storedValue, setStoredValue] = useState(() => {
      try {
         // Get from local storage by key
         const item = window.localStorage.getItem(key);
         // Parse stored json or if none return initialValue
         return item ? JSON.parse(item) : initialValue;
      } catch (error) {
         // If error also return initialValue
         notifications.error(error);
         return initialValue;
      }
   });

   // Return a wrapped version of useState's setter function that ...
   // ... persists the new value to localStorage.
   const setValue = (value: any) => {
      try {
         // Allow value to be a function so we have same API as useState
         const valueToStore = value instanceof Function ? value(storedValue) : value;
         // Save state
         setStoredValue(valueToStore);
         // Save to local storage
         window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
         notifications.error(error);
      }
   };

   return [storedValue, setValue];
}
