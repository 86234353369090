import React, { useEffect, useRef } from "react";
import { useAuthentication } from "./AuthenticationProvider";
import { useNotifications } from "notifications";
import WaitingView from "./WaitingView";
import { AuthError } from "@azure/msal-browser";
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache, HttpLink, NormalizedCacheObject, ApolloError, split } from "@apollo/client";
import { ErrorLink } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useApi } from "../api";
import  packageInfo  from '../../package.json';
import { useState } from "react";

const AppApolloProvider = (props: { children: React.ReactNode }) => {
   const notifications = useNotifications();
   const authentication = useAuthentication();
   const [lastDocumentUpdate, setLastDocumentUpdate] = useState(new Date());
   const version = packageInfo.version;

   var lastDate = React.useRef(new Date());

   const { fetchObjectAsync } = useApi();

   function createOmitTypenameLink() {
      // Adapted from https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
      return new ApolloLink((operation, forward) => {
         if (operation.variables) {
            operation.variables = JSON.parse(JSON.stringify(operation.variables), (key, value) => {
               return key === "__typename" ? undefined : value;
            });
         }

         return forward(operation);
      });
   }

   const versionCheck = setContext(async operation => {
      var version = await fetchObjectAsync<{ version: string }>("meta.json")
      return { remoteVersion: version.version };
   });

   function createAuthLink(notifyError: (authError: any) => void) {
      return setContext(async (operation, { headers }) => {
         try {
            const token = await authentication.getAccessToken!();

            if (!token) throw new AuthError("Could not get access token");
            return {
               headers: {
                  ...headers,
                  authorization: token ? `Bearer ${token}` : "",
               },
            };
         } catch (authErr) {
            notifyError(authErr);
         }
      });
   }

   const apolloClient = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

   const terminalLink = split((operation) => {
      if ((operation.operationName === "GetCurrentUser" || operation.operationName === "GetCurrentPrimaryUser") || operation.getContext().remoteVersion === version) {
         return true;
      } else {
         if (new Date().valueOf() - lastDate.current.valueOf() >= 5000) {
            lastDate.current = new Date();
            notifications.warning("This webpage is out of date. Please refresh your browser before continuing.");
         }

         return false;
      }
   },
      new HttpLink({
         uri: "/graphql",
      })
   )

   if (apolloClient.current === null) {
      apolloClient.current = new ApolloClient({
         assumeImmutableResults: true,
         link: ApolloLink.from([
            new ErrorLink(notifications.handleApolloErrorResponse),
            createAuthLink((authError: AuthError) => {
               console.warn(authError.message);
            }),
            versionCheck,
            createOmitTypenameLink(),
            terminalLink
         ]),
         cache: new InMemoryCache({
            possibleTypes: {
               IRateBulletin: ["DocumentRateBulletin", "DepositRateBulletin"],
               RateBulletin: ["DocumentRateBulletin", "DepositRateBulletin"],
            },
         }),
         resolvers: {},
         defaultOptions: {
            query: {
               errorPolicy: "all",
            },
            mutate: {
               errorPolicy: "all",
            },
         },
      });
   }

   return apolloClient.current !== null ? (
      <ApolloProvider client={apolloClient.current}>{props.children}</ApolloProvider>
   ) : (
      <WaitingView message="Loading..." />
   );
};

export default AppApolloProvider;
