import { Grid, Card, CardContent, Typography, Link, Box, IconButton, List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { SearchLink } from "application";
import { RouteNames } from "application/routes";
import { Delete as DeleteIcon } from "@mui/icons-material";
import useCurrentUser from "../users/useCurrentUser";
import { makeStyles, createStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DocType } from "../documents";

const useStyles = makeStyles((theme) =>
   createStyles({
      link: {
         "&:hover $deleteButton": {
            display: "flex",
         },
      },
      deleteButton: {
         display: "none",
         marginLeft: theme.spacing(1),
         padding: 0,
      },
      deleteButtonLabel: {
         "& svg": {
            fontSize: "1em",
         },
      },
   }),
);

interface Props {
   links: SearchLink[];
   title?: string;
   deleteLink?: (link: SearchLink) => void;
   docTypes?: DocType[];
}

const LinkTile: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const navigate = useNavigate();
   const { user } = useCurrentUser();
   const [tasksOpen, setTasksOpen] = useState<{ [key: string]: boolean }>({});

   const handleClick = (key: any) => {
      setTasksOpen({ ...tasksOpen, [key]: !tasksOpen[key] });
   }

   const defaultSortLinks = props.title?.includes('task') ? _.orderBy(props.links, (pl) => pl.title.toLowerCase()) : props.links;

   return (
      <Card variant="outlined">
         <CardContent>
            <Box mb={1}>
               <Typography variant="h5">{props.title ?? "Links"}</Typography>
            </Box>
            {props.links.length === 0 ? (
               <Box mt={3}>
                  <Typography>No applicable resources.</Typography>
               </Box>
            ) : (
               <Grid container direction="column" spacing={1}>
                     {defaultSortLinks.map((pl) => (
                        !(pl.title.includes('Administration Forms') && user.isWyth) &&
                        <Grid key={pl.title} item container className={classes.link} alignItems="center">
                           {pl.query ?
                              (
                                 pl.title.includes('Administration Forms') ?
                                    !user.isWyth &&
                                       <Link key={pl.title} onClick={() => navigate(`${RouteNames.PartnerSpace}${pl.query}`)}>{pl.title}</Link>
                                    :
                                    (!pl.isManual ?
                                       <div key={pl.title}>
                                          <Link onClick={() => handleClick(pl.query)}>{pl.title} {tasksOpen[pl.query] ? <ExpandLess style={{ position: 'relative', top: '6px' }} /> : <ExpandMore style={{ position: 'relative', top: '6px' }} />} </Link>
                                          <Collapse in={tasksOpen[pl.query]}>
                                             <List>
                                                {props.docTypes?.map(dt => (
                                                   <ListItem key={dt.name}>
                                                      <Link onClick={() => navigate(`${RouteNames.Search}${pl.query}&typeId=${dt.id}`)}>{(dt.name === "Form" || dt.name === "Calculator" ? dt.name + "s" : dt.name)}</Link>
                                                   </ListItem>
                                                ))}
                                             </List>
                                          </Collapse>
                                       </div>
                                       :
                                       <Link onClick={() => navigate(`${RouteNames.Search}${pl.query}`)}>{pl.title}</Link>
                                    )
                              ) : (
                                 <div key={pl.title}>{pl.title}</div>
                              )}
                           {props.deleteLink && pl.isManual && (
                              <IconButton
                                 classes={{ root: classes.deleteButton }}
                                 onClick={() => props.deleteLink!(pl!)}
                                 size="small"
                              >
                                 <DeleteIcon />
                              </IconButton>
                           )}
                        </Grid>
                  ))}
               </Grid>
            )}
         </CardContent>
      </Card>
   );
};

export default LinkTile;
