import { gql } from "@apollo/client";

export const listservPropertiesFragment = gql`
   fragment listservProperties on Listserv {
      id
      listName
      title
      htmlContent
      products {
         id
         name
      }
   }
`;
