import { useMutation, gql } from "@apollo/client";
import {  Grid, TextField } from "@mui/material";
import {Dialog} from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useState, useEffect } from "react";
import SpinnerButton from "SpinnerButton";
import { MarketingAnnouncement } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel } from "formik-mui";
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClosableDialogTitle from "application/ClosableDialogTitle";
import HtmlEditor from "./HtmlEditor";
import MarketingAnnouncementDisplay from "./MarketingAnnouncementDisplay";
import { getEncodedImage } from "./AnnouncementScreen";
import { dialogStyle } from "application";
import { pickedDateToUtcMidnight, utcToLocalDate } from "application/formats";
import { editMarketingAnnouncementPropertiesFragment } from "announcements";
import { Button } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';


const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   announcementToEdit: MarketingAnnouncement | null;
   handleClose: () => void;
}

interface FormValues {
   title: string;
   body: string;
   link: string;
   effectiveDate: Date;
   expiryDate: Date | null;
   invertText: boolean;
}

const UpsertProductAnnouncementDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const creating = props.announcementToEdit === null;

   const [imageHtml, setImageHtml] = useState("");
   useEffect(() => {
      setImageHtml(props.announcementToEdit?.encodedImage ? `<img src="${props.announcementToEdit!.encodedImage}"/>` : "");
   }, [props.announcementToEdit, props.open]);

   const initialFormValues: FormValues = creating
      ? {
           title: "",
           body: "",
           link: "",
           effectiveDate: moment().startOf("day").toDate(),
           expiryDate: null,
           invertText: false,
        }
      : {
           title: props.announcementToEdit!.title ?? "",
           body: props.announcementToEdit!.body ?? "",
           link: props.announcementToEdit!.link ?? "",
           effectiveDate: props.announcementToEdit!.effectiveDate,
           expiryDate: props.announcementToEdit!.expiryDate,
           invertText: props.announcementToEdit!.invertText,
        };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.expiryDate === null) {
         errors.expiryDate = "Enter an expiry date.";
      }

      if (values.expiryDate && values.expiryDate <= values.effectiveDate) {
         errors.expiryDate = "Expiry date must be after effective date.";
      }

      return errors;
   }

   function buildMarketingAnnouncementFromFormValues(values: FormValues) {
      const encodedImage = getEncodedImage(imageHtml);

      const announcement: MarketingAnnouncement = {
         id: 0,
         title: values.title.length > 0 ? values.title : null,
         body: values.body.length > 0 ? values.body : null,
         link: values.link.length > 0 ? values.link : null,
         encodedImage: encodedImage,
         effectiveDate: values.effectiveDate,
         expiryDate: values.expiryDate!,
         invertText: values.invertText,
      };

      return announcement;
   }

   const [createMutate] = useMutation<
      { marketingAnnouncement: { create: MarketingAnnouncement } },
      { marketingAnnouncement: MarketingAnnouncement }
   >(
      gql`
         mutation CreateMarketingAnnouncement($marketingAnnouncement: MarketingAnnouncementInput!) {
            marketingAnnouncement {
               create(marketingAnnouncement: $marketingAnnouncement) {
                  id
                  title
                  body
                  link
                  encodedImage
                  effectiveDate
                  expiryDate
               }
            }
         }
      `,
      {
         refetchQueries: ["GetMarketingAnnouncementsForAnnouncementScreen"],
      },
   );

   async function saveNewAnnouncement(values: FormValues, actions: FormikHelpers<FormValues>) {
      const announcement = buildMarketingAnnouncementFromFormValues(values);

      const createResult = await createMutate({ variables: { marketingAnnouncement: announcement } });

      if (createResult.data?.marketingAnnouncement.create) {
         notifications.success("Announcement created.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   const [editMarketingAnnouncementMutate] = useMutation<
      { marketingAnnouncement: { update: MarketingAnnouncement } },
      { marketingAnnouncement: MarketingAnnouncement }
   >(
      gql`
         mutation UpdateMarketingAnnouncement($marketingAnnouncement: MarketingAnnouncementInput!) {
            marketingAnnouncement {
               update(marketingAnnouncement: $marketingAnnouncement) {
                  ...editMarketingAnnouncementProperties
               }
            }
         }
         ${editMarketingAnnouncementPropertiesFragment}
      `,
      {
         refetchQueries: ["GetMarketingAnnouncements"],
      },
   );

   async function saveEditedAnnouncement(values: FormValues, actions: FormikHelpers<FormValues>) {
      const announcementInput = {
         id: props.announcementToEdit!.id,
         title: values.title,
         body: values.body,
         link: values.link,
         encodedImage: getEncodedImage(imageHtml),
         effectiveDate: values.effectiveDate,
         expiryDate: values.expiryDate!,
         invertText: values.invertText,
      };
      const updateResult = await editMarketingAnnouncementMutate({ variables: { marketingAnnouncement: announcementInput } });

      if (updateResult.data?.marketingAnnouncement.update) {
         notifications.success("Announcement updated.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         maxWidth="sm" fullWidth={true} scroll="paper">
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={creating ? saveNewAnnouncement : saveEditedAnnouncement}>
            {(formikProps) => {
               const previewAnnouncement: MarketingAnnouncement = buildMarketingAnnouncementFromFormValues(formikProps.values);

               return (
                  <>
                     <ClosableDialogTitle
                        title={creating ? "Post a marketing announcement" : "Edit marketing announcement"}
                        handleClose={props.handleClose}
                     />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container xs={12} direction="column" spacing={1}>
                                 <Grid item>
                                    <label>Preview</label>
                                 </Grid>
                                 <Grid item>
                                    <MarketingAnnouncementDisplay announcement={previewAnnouncement} />
                                 </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="title"
                                    variant="outlined"
                                    size="small"
                                    label="Title (optional)"
                                    fullWidth
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="body"
                                    variant="outlined"
                                    size="small"
                                    label="Body (optional)"
                                    fullWidth
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="link"
                                    variant="outlined"
                                    size="small"
                                    label="Link (optional)"
                                    fullWidth
                                 />
                              </Grid>

                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiCheckboxWithLabel}
                                    type="checkbox"
                                    name="invertText"
                                    Label={{ label: "Invert text" }}
                                 />
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item container xs={12} direction="column" spacing={1}>
                                    <Grid item>
                                       <label>Background image (twice as wide as it is tall)</label>
                                    </Grid>
                                    <Grid item>
                                       <HtmlEditor imageOnly={true} content={imageHtml} onChangeContent={setImageHtml} />
                                    </Grid>
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={6}>
                                    <FormikField name="effectiveDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             renderInput={(params) => <TextField size="small" {...params} />}
                                             inputFormat="yyyy-MM-dd"
                                             label="Effective date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             onChange={(newDate: Date | null) =>
                                                fieldProps.form.setFieldValue("effectiveDate", pickedDateToUtcMidnight(newDate))
                                             }
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormikField name="expiryDate">
                                       {(fieldProps: FieldProps<Date>) => (
                                          <DatePicker
                                             renderInput={(params) => <TextField size="small" {...params} />}
                                             inputFormat="yyyy-MM-dd"
                                             label="Expiry date"
                                             disabled={fieldProps.form.isSubmitting}
                                             value={utcToLocalDate(fieldProps.field.value)}
                                             minDate={fieldProps.form.values.effectiveDate}
                                             onChange={(newDate: Date | null) =>
                                                fieldProps.form.setFieldValue("expiryDate", pickedDateToUtcMidnight(newDate))
                                             }
                                             
                                          />
                                       )}
                                    </FormikField>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label={creating ? "Post" : "Save"}
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default UpsertProductAnnouncementDialog;
