import { Doc, DocType, DocTypeWithDepth } from "documents";
import { ExcelDocIcon } from "icons/ExcelDocIcon";
import { GenericDocIcon } from "icons/GenericDocIcon";
import { PdfDocIcon } from "icons/PdfDocIcon";
import { PowerPointDocIcon } from "icons/PowerPointDocIcon";
import { WordDocIcon } from "icons/WordDocIcon";
import { ExternalDocIcon } from "icons/ExternalDocIcon";
import { RouteNames } from "application/routes";
import _ from "lodash";
import { DocumentType } from "@apollo/client";

export function getIconForDocument(document: Doc, size?: string) {
   if (!document.fileName) {
      return <ExternalDocIcon size={size} />;
   }

   const extensionMatch = document.fileName.match(/\.[^.]+$/);
   const fileExtension = extensionMatch !== null ? extensionMatch[0] : "";

   let icon: any;
   switch (fileExtension) {
      case ".pdf":
         icon = <PdfDocIcon size={size} />;
         break;

      case ".doc":
      case ".docx":
         icon = <WordDocIcon size={size} />;
         break;

      case ".xls":
      case ".xlsx":
      case ".csv":
         icon = <ExcelDocIcon size={size} />;
         break;

      case ".ppt":
      case ".pptx":
         icon = <PowerPointDocIcon size={size} />;
         break;

      default:
         icon = <GenericDocIcon size={size} />;
         break;
   }

   return icon;
}

export function unpackDocumentTypeHierarchy(parentTypes: DocType[], parentDepth = 0): DocTypeWithDepth[] {
   const orderedParentTypes = _.orderBy(parentTypes, (dt) => dt.name.toLowerCase());
   const parentsWithDescendantTypes = orderedParentTypes.map((parent) =>
      [{ ...parent, depth: parentDepth }].concat(unpackDocumentTypeHierarchy(parent.children, parentDepth + 1)),
   );
   return parentsWithDescendantTypes.flatMap((p) => p);
}

export function getDocumentUrl(document: Doc) {
   if (document.type?.name == "Administration Form" ?? false) {
      return RouteNames.Document.replace(":id?", document.id.toString());
   }

   return document.catalogueNumber
      ? RouteNames.Document.replace("/:id?", `?catalogueNumber=${document.catalogueNumber}`)
      : RouteNames.Document.replace(":id?", document.id.toString());
}
