import {
   Grid,
   TextField,
   Card,
   CardContent,
   Typography,
   InputAdornment,
   Button,
} from "@mui/material";
import { HasId } from "application";
import { DocSource, DocStatus, Tag, DocTypeWithDepth, Jurisdiction } from "documents";
import { Product } from "products";
import React from "react";
import { DocFilters } from "search";
import { Autocomplete } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Search, ArrowDropUp, ArrowRight } from "@mui/icons-material";
import _ from "lodash";
import { User } from "../users";
import useLocalStorage from "useLocalStorage";
import { Organization, defaultOrganization, getOrganizationFilterOptions, getOrganizationOptionLabel } from "organizations";
import { Program } from "programs";
import useCurrentUser from "users/useCurrentUser";
import OrganizationOption from "organizations/OrganizationOption";
import { pickedDateToUtcMidnight, utcToLocalDate } from "application/formats";
import { Permissions } from "users";

import { makeStyles, createStyles } from '@mui/styles';
import { useTheme } from "@mui/styles";
import { Plan } from "../plan";

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         "& > *": { maxWidth: "100%" },

         "&& .MuiFormControl-root": {
            "& button": {
               color: "#ededed"
            },

            "& .MuiChip-root": {
               backgroundColor: "#ffcb31"
            },
            "& .MuiInputBase-root": {
               backgroundColor: "#231f20 !important",
            },
            "& .MuiInputBase-input, & .MuiInputAdornment-root": {
               color: "#ededed",
            },
            "& .MuiFormLabel-root": {
               color: "#ededed",
               padding: "0 1em",
            },
            "& .MuiInputLabel-shrink": {
               backgroundColor: "#231f20",
               borderRadius: "5px",

            }
         },
      },

      dateFilters: {
         "&&.MuiCard-root": {
            backgroundColor: "#ededed",
         },
         "& .MuiCardContent-root": {

            padding: theme.spacing(1),
            "& > *:not(:first-child)": {
               marginTop: theme.spacing(2),
            },
         },
      },
      moreFiltersButton: {
         justifyContent: "flex-start",
      },
   }),
);

interface Props {
   searchTerms: string;
   updateSearchTerms: (searchTerms: string) => void;
   filters: DocFilters;
   updateFilters: (filters: DocFilters) => void;
   documentTypes: DocTypeWithDepth[];
   documentTypesLoading: boolean;
   organizations: Organization[];
   organizationsLoading: boolean;
   sources: DocSource[];
   statuses: DocStatus[];
   users: User[];
   usersLoading: boolean;
   tags: Tag[];
   tagsLoading: boolean;
   products: Product[];
   productsLoading: boolean;
   programs: Program[];
   programsLoading: boolean;
   jurisdictions: Jurisdiction[];
   jurisdictionsLoading: boolean;
   forAlertCreation?: boolean;
   plans: Plan[];
   plansLoading: boolean;
}

const SearchFilters: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const { user, userLoading, userHasPermission } = useCurrentUser();
   const {
      productsLoading,
      programsLoading,
      organizationsLoading,
      documentTypesLoading,
      jurisdictionsLoading,
      tagsLoading,
      usersLoading,
      plansLoading
   } = props;

   const [moreFiltersExpanded, setMoreFiltersExpanded] = useLocalStorage(
      props.forAlertCreation ? "alertCreationFiltersExpanded" : "searchMoreFiltersExpanded",
      false,
   );

   function getSelectedItems<T extends HasId>(allItems: T[], selectedIds: number[]) {
      return allItems.length > 0 ? allItems.filter((item) => selectedIds.some((id) => id === item.id)) : [];
   }

   const canViewDocumentsOutsideYourProducts = userHasPermission(Permissions.ViewDocumentsOutsideYourProducts);

   const organizationOptions = [defaultOrganization].concat(_.orderBy(props.organizations, (o) => o.name.toLowerCase()));
   const selectedOrganizations = getSelectedItems(organizationOptions, props.filters.organizationIds);

   const selectedDocumentTypes = getSelectedItems(props.documentTypes, props.filters.documentTypeIds);
   const selectedUsers = getSelectedItems(props.users, props.filters.uploadedByUserIds);
   const selectedTags = getSelectedItems(props.tags, props.filters.tagIds);
   const selectedProducts = getSelectedItems(props.products, props.filters.productIds);
   const selectedPrograms = getSelectedItems(props.programs, props.filters.programIds);
   const selectedJurisdictions = getSelectedItems(props.jurisdictions, props.filters.jurisdictionIds);
   const selectedPlans = getSelectedItems(props.plans, props.filters.planIds)

   const orderedTags = React.useMemo(() => _.orderBy(props.tags, (t) => t.value.toLowerCase()), [props.tags]);
   const orderedJurisdictions = React.useMemo(() => _.orderBy(props.jurisdictions, [(j) => j.order, (j) => j.name]), [props.jurisdictions]);
   const orderedPrograms = React.useMemo(() => _.orderBy(props.programs, (p) => p.name.toLowerCase()), [props.programs]);
   const orderedUsers = React.useMemo(() => _.orderBy(props.users, (u) => u.name.toLowerCase()), [props.users]);
   const orderedPlans = React.useMemo(() => _.orderBy(props.plans, (p) => p.name.toLowerCase()), [props.plans]);

   const moreFiltersSelectedCount =
      selectedJurisdictions.length + selectedPrograms.length + props.filters.sources.length + selectedUsers.length;

   function clearFilters() {
      props.updateSearchTerms("");
      props.updateFilters({
         startDate: null,
         endDate: null,
         documentTypeIds: [],
         organizationIds: [],
         uploadedByUserIds: [],
         statuses: [],
         sources: [],
         tagIds: [],
         productIds: [],
         programIds: [],
         jurisdictionIds: [],
         planIds: []
      });
   }

   // do some extra filtering
   // because some users may have access only to the resource doctypes of some products
   // isRestrictedForWyth is currently set for any resource doctype
   // normally a user has access to all doctypes under user.products
   // but some users may have only the resource doctypes belonging to user.resourceProducts
   // and there are some doctypes (where doctype.products length === 0) which belong to all products
   const validDocTypes = props.documentTypes
      .filter((dt) => (
         canViewDocumentsOutsideYourProducts ||
         dt.products.length === 0 &&
         (user.products.length > 0 || (dt.isRestrictedForWyth && user.resourceProducts.length > 0))
      ) || (
            dt.products.some((dtp) =>
               user.products.some((up) => up.id === dtp.id) ||
               (dt.isRestrictedForWyth && user.resourceProducts.some((urp) => urp.id === dtp.id))
            )
         )).filter((dt) =>
            selectedProducts.length === 0 ||
            dt.products.length === 0 ||
            selectedProducts.every((p) => dt.products.some((dtp) => dtp.id === p.id))
         );

   // if any doctypes selected, filter product options
   // looking at all selected doctypes
   // if doctype has no product association (then it is associated with ALL products)
   // but only include it if user has that product
   // or if it is a resourceProduct and user has that resourceProduct
   // alternatively
   // if doctype has specific product associations
   // include the product if it is associated with doctype
   // but only if the user has *that* product
   // or if it is a resourceProduct and the user has *that* resourceProduct

   const validProducts =
      selectedDocumentTypes.length > 0
         ? props.products.filter((p) =>
            selectedDocumentTypes.every((dt) => (
               (
                  dt.products.length === 0 &&
                  (
                     (dt.isRestrictedForWyth && user.resourceProducts.some((urp) => urp.id === p.id))) ||
                  user.products.some((up) => up.id === p.id)
               )
            ) || (
                  dt.products.some((dtp) =>
                     dtp.id === p.id &&
                     (
                        (dt.isRestrictedForWyth && user.resourceProducts.some((urp) => urp.id === dtp.id)) ||
                        user.products.some((up) => up.id === dtp.id)
                     )
                  )
               )
            )
         )
         : props.products;

   const orderedProducts = React.useMemo(() => _.orderBy(validProducts, (p) => p.name.toLowerCase()), [validProducts]);

   return (
      <Grid container direction="column" spacing={2} className={classes.root}>
         {!props.forAlertCreation && (
            <Grid item>
               <Typography variant="body1" color="textSecondary">
                  Find documents by name or contents...
               </Typography>
            </Grid>
         )}
         <Grid item>
            <TextField
               label={props.forAlertCreation ? "File name contains" : undefined}
               value={props.searchTerms}
               onChange={(e) => props.updateSearchTerms(e.target.value)}
               InputProps={{
                  startAdornment: props.forAlertCreation ? undefined : (
                     <InputAdornment position="start">
                        <Search />
                     </InputAdornment>
                  ),
               }}
               size="small"
               autoFocus
               fullWidth
            />
         </Grid>
         {!props.forAlertCreation && (
            <Grid item>
               <Typography variant="body1" color="textSecondary">
                  Or, use filters to narrow down your results.
               </Typography>
            </Grid>
         )}

         <Grid item>
            <Autocomplete
               multiple
               autoComplete
               handleHomeEndKeys={false}
               size="small"
               options={organizationsLoading ? [] : organizationOptions}
               loading={organizationsLoading}
               filterSelectedOptions
               getOptionLabel={(org: Organization) => getOrganizationOptionLabel(org, user)}
               value={selectedOrganizations}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, organizationIds: newValue.map((o) => o.id) })}
               renderInput={(params) => <TextField {...params} variant="outlined" label="Organization" />}
               renderOption={(props: any, org) => <OrganizationOption props={props} organization={org} user={user} />}
               filterOptions={getOrganizationFilterOptions()}
            />
         </Grid>

         <Grid item>
            <Autocomplete
               multiple
               autoComplete
               handleHomeEndKeys={false}
               size="small"
               options={productsLoading ? [] : orderedProducts}
               loading={productsLoading}
               filterSelectedOptions
               getOptionLabel={(option) => option.name}
               renderOption={(props: any, product) => (
                  <div {...props} key={product.id}>
                     <div>
                        <Typography>{product.name}</Typography>
                        <Typography variant="caption" color="textSecondary">
                           {product.program.name}
                        </Typography>
                     </div>
                  </div>
               )}
               value={selectedProducts}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, productIds: newValue.map((p) => p.id) })}
               renderInput={(params) => <TextField {...params} variant="outlined" label="Product" />}
            />
         </Grid>
         <Grid item>
            <Autocomplete
               multiple
               autoComplete
               handleHomeEndKeys={false}
               size="small"
               options={plansLoading ? [] : orderedPlans}
               loading={plansLoading}
               filterSelectedOptions
               getOptionLabel={(option) => option.name}
               renderOption={(props: any, plan) => (
                  <div {...props}>
                     <div>
                        <Typography>{plan.name}</Typography>
                     </div>
                  </div>
               )}
               value={selectedPlans}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, planIds: newValue.map((p) => p.id) })}
               renderInput={(params) => <TextField {...params} variant="outlined" label="Plan type" />}
            />
         </Grid>
         <Grid item>
            <Autocomplete
               multiple
               handleHomeEndKeys={false}
               size="small"
               options={documentTypesLoading ? [] : validDocTypes}
               loading={documentTypesLoading}
               filterSelectedOptions
               getOptionLabel={(option) => option.name}
               renderOption={(props: any, dt) => <div {...props} key={dt.id}><div style={{ marginLeft: theme.spacing(dt.depth * 4) }}>{dt.name}</div></div>}
               value={selectedDocumentTypes}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, documentTypeIds: newValue.map((dt) => dt.id) })}
               renderInput={(params) => <TextField {...params} variant="outlined" label="Document type" />}
            />
         </Grid>

         <Grid item>
            <Autocomplete
               multiple
               autoComplete
               handleHomeEndKeys={false}
               size="small"
               options={tagsLoading ? [] : orderedTags}
               loading={tagsLoading}
               filterSelectedOptions
               getOptionLabel={(option) => option.value}
               value={selectedTags}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, tagIds: newValue.map((t) => t.id) })}
               renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={props.forAlertCreation ? "Tags (documents with every tag)" : "Tags"} />
               )}
            />
         </Grid>

         <Grid item>
            <Autocomplete
               multiple
               size="small"
               handleHomeEndKeys={false}
               options={[DocStatus.Received, DocStatus.CorrectionsRequired, DocStatus.Retrieved]}
               filterSelectedOptions
               getOptionLabel={(option) => option}
               value={props.filters.statuses}
               onChange={(e, newValue) => props.updateFilters({ ...props.filters, statuses: newValue.map((nv) => nv as DocStatus) })}
               renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
            />
         </Grid>

         {!props.forAlertCreation && (
            <Grid item>
               <Card variant="outlined" className={classes.dateFilters}>
                  <CardContent>
                     <Typography variant="body1">Dated</Typography>
                     <DatePicker
                        renderInput={(params) => <TextField size="small" sx={{ width: '100%' }} {...params} />}
                        inputFormat="yyyy-MM-dd"
                        label="From"
                        value={utcToLocalDate(props.filters.startDate) ?? null}
                        onChange={(newDate: Date | null) =>
                           props.updateFilters({
                              ...props.filters,
                              startDate: pickedDateToUtcMidnight(newDate),
                           })
                        }
                     />
                     <DatePicker
                        renderInput={(params) => <TextField size="small" sx={{ width: '100%' }} {...params} />}
                        inputFormat="yyyy-MM-dd"
                        label="To"
                        value={utcToLocalDate(props.filters.endDate) ?? null}
                        onChange={(newDate: Date | null) =>
                           props.updateFilters({
                              ...props.filters,
                              endDate: pickedDateToUtcMidnight(newDate),
                           })
                        }
                     />
                  </CardContent>
               </Card>
            </Grid>
         )}

         <Grid item>
            <Button
               endIcon={moreFiltersExpanded ? <ArrowDropUp /> : <ArrowRight />}
               fullWidth
               onClick={() => setMoreFiltersExpanded(!moreFiltersExpanded)}
               className={classes.moreFiltersButton}
            >
               {moreFiltersExpanded ? (
                  "Fewer"
               ) : (
                  <>
                     More filters
                     {moreFiltersSelectedCount > 0 ? (
                        <Typography color="textSecondary">&nbsp;({moreFiltersSelectedCount} selected)</Typography>
                     ) : null}
                  </>
               )}
            </Button>
         </Grid>

         {moreFiltersExpanded && (
            <>
               {!props.forAlertCreation && (
                  <Grid item>
                     <Autocomplete
                        multiple
                        autoComplete
                        size="small"
                        handleHomeEndKeys={false}
                        options={jurisdictionsLoading ? [] : orderedJurisdictions}
                        loading={jurisdictionsLoading}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.name}
                        value={selectedJurisdictions}
                        onChange={(e, newValue) => props.updateFilters({ ...props.filters, jurisdictionIds: newValue.map((j) => j.id) })}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Jurisdiction" />}
                     />
                  </Grid>
               )}
               <Grid item>
                  <Autocomplete
                     multiple
                     autoComplete
                     size="small"
                     handleHomeEndKeys={false}
                     options={programsLoading ? [] : orderedPrograms}
                     loading={programsLoading}
                     filterSelectedOptions
                     getOptionLabel={(option) => option.name}
                     value={selectedPrograms}
                     onChange={(e, newValue) => props.updateFilters({ ...props.filters, programIds: newValue.map((d) => d.id) })}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Program" />}
                  />
               </Grid>

               <Grid item>
                  <Autocomplete
                     multiple
                     size="small"
                     handleHomeEndKeys={false}
                     options={[DocSource.Wyth, DocSource.Partner]}
                     filterSelectedOptions
                     getOptionLabel={(option) =>
                        option === DocSource.Wyth
                           ? DocSource.Wyth
                           : user.isWyth
                              ? "Partner organization"
                              : user.organization.name
                     }
                     value={props.filters.sources}
                     onChange={(e, newValue) => props.updateFilters({ ...props.filters, sources: newValue.map((nv) => nv as DocSource) })}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Source" />}
                  />
               </Grid>

               <Grid item>
                  <Autocomplete
                     multiple
                     autoComplete
                     size="small"
                     handleHomeEndKeys={false}
                     options={usersLoading ? [] : orderedUsers}
                     loading={usersLoading}
                     filterSelectedOptions
                     getOptionLabel={(option) => `${option.name} (${option.organization.name})`}
                     renderOption={(props: any, upuser) => (
                        <div {...props} key={upuser.id}>
                           <div>
                              <Typography>{upuser.name}</Typography>
                              <Typography variant="caption" color="textSecondary">
                                 {upuser.organization.name}
                              </Typography>
                           </div>
                        </div>
                     )}
                     value={selectedUsers}
                     onChange={(e, newValue) => props.updateFilters({ ...props.filters, uploadedByUserIds: newValue.map((u) => u.id) })}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Posted by" />}
                  />
               </Grid>
            </>
         )}

         {!props.forAlertCreation && (
            <Grid item>
               <Button variant="contained" color="primary" onClick={() => clearFilters()}>
                  Clear filters
               </Button>{" "}
            </Grid>
         )}
      </Grid>
   );
};

export default SearchFilters;