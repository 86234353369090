import {  FormHelperText } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { DropzoneArea } from "react-mui-dropzone";
import React, { useState } from "react";
import { makeStyles, createStyles } from '@mui/styles';
import { useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) =>
   createStyles({
      dropzoneArea: {
         display: "flex",
         flexWrap: "wrap",
         "& > span": {
            // Hide the "Preview:" label
            display: "none",
         },
      },
      dropzoneChip: {
         maxWidth: "100%",
         fontFamily: theme.typography.fontFamily,
      },
      filesSelected: {
         display: "none",
      },
   }),
);

interface Props {
   touched?: any;
   error?: any;
   prompt: string;
   limit: number;
   acceptedFiles?: string[];
   setFieldValue: (files: File[]) => void;
}

const FileDropzone: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const theme = useTheme();

   const dropzoneTheme = createTheme({
      components: {
         MuiDropzoneArea: {
            styleOverrides: {
               root: {
                  minHeight: 0,
                  borderColor: props.touched && props.error ? theme.palette.error.main : theme.palette.grey[300],
               },
               textContainer: {
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
               },
               text: {
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body1.fontSize,
                  color: props.touched && props.error ? theme.palette.error.main : undefined,
               },
               icon: {
                  display: "none",
               },
            }
         },
         MuiDropzonePreviewList: {
            styleOverrides: {
               root: {
                  marginTop: theme.spacing(0), // this creates a bit of space between the zone and the chips, don't remove
                  maxWidth: "100%",
                  overflowX: "hidden",
               },
            }
         },
      },
      overrides: {
         typography: {
            subtitle1: {
               fontFamily: theme.typography.fontFamily,
            },
         },
         
      },
   } as any);

   const [filesSelected, setFilesSelected] = useState(0);

   return (
      <div className={classes.dropzoneArea}>
         <ThemeProvider theme={dropzoneTheme}>
            <DropzoneArea
               acceptedFiles={
                  props.acceptedFiles ?? [
                     "application/gzip", // .gz
                     "application/msword", // .doc
                     "application/postscript", // .ai, .eps, .ps
                     "application/pdf", // .pdf
                     "application/rtf", // .rtf
                     "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
                     "application/vnd.ms-excel", // .xls
                     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                     "application/vnd.ms-outlook", // .msg
                     "application/vnd.ms-project", // .mpp
                     "application/vnd.visio", // .vsd
                     "application/vnd.ms-powerpoint", // .ppt
                     "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
                     "application/vnd.rar", // .rar
                     "application/vnd.oasis.opendocument.presentation", // .odp
                     "application/vnd.oasis.opendocument.spreadsheet", // .ods
                     "application/vnd.oasis.opendocument.text", // .odt
                     "application/xml", // .xml
                     "application/x-7z-compressed", // .7z
                     "application/x-tar", // .tar
                     "application/x-latex", // .latex
                     "application/x-msaccess", // .mdb
                     "application/zip", // .zip
                     "application/x-zip-compressed", // .zip
                     "audio/mpeg", // .mp3
                     "image/bmp", // .bmp
                     "image/gif", // .gif
                     "image/jpeg", // .jpeg, .jpg
                     "image/png", // .png
                     "image/tiff", // .tiff
                     "image/webp", // .webp
                     "text/xml",
                     "image/x-icon", // .ico
                     "text/csv", // .csv
                     "text/plain", // .txt
                     "text/richtext", // .rtx
                     "video/mp4", // .mp4
                     "video/mpeg", // .mpeg
                     "video/ogg", // .ogv
                     "video/quicktime", // .mov
                     "video/webm", // .webm
                     "video/x-msvideo", // .avi
                     "video/x-sgi-movie", // .movie
                     "application/vnd.ms-excel.sheet.macroEnabled.12", // .xlsm
                     ".csv", // text/csv doesn't work by itself
                     ".msg", // another necessary addition, apparently
                     ".new",
                     ".bkg",
                     ".ren",
                     ".dat",
                     ".st2",
                     ".oft"
                  ]
               }
               dropzoneClass={props.limit === 1 && filesSelected === 1 ? classes.filesSelected : undefined}
               onChange={(files: File[]) => {
                  setFilesSelected(files.length);
                  props.setFieldValue(files);
               }}
               maxFileSize={100000000}
               filesLimit={props.limit}
               dropzoneText={props.prompt}
               useChipsForPreview={true}
               showPreviews={true}
               showPreviewsInDropzone={false}
               showAlerts={false}
               previewText="Selected files"
               previewChipProps={{
                  size: "small",
                  classes: {
                     root: classes.dropzoneChip,
                  },
               }}
            />
         </ThemeProvider>
         {props.touched && props.error && <FormHelperText error>{props.error}</FormHelperText>}
      </div>
   );
};

export default FileDropzone;
