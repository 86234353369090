import { useLazyQuery, useMutation } from "@apollo/client";
import { filter as gqlFilter } from "graphql-anywhere";
import { Doc, DocStatus } from "documents";
import React from "react";
import useCurrentUser from "users/useCurrentUser";
import {
   BatchUpdateDocumentStatusMutation,
   documentInputFragment,
   DocumentDownloadInfoQuery,
   UpdateDocumentStatusMutation,
} from "./queries";
import { useNotifications } from "notifications";

interface DocumentDownloadInfo {
   title: string;
   url: string;
}
interface DocumentDownloadContextType {
   openDocumentInWindow: (document: Doc) => any;
   openBatchDocumentDownloadInNewWindow: (documents: Doc[]) => any;
   isGeneratingBatchFile: boolean;
}

export const DocumentDownloadContext = React.createContext<DocumentDownloadContextType>({
   openDocumentInWindow: (document: Doc) => {},
   openBatchDocumentDownloadInNewWindow: async (documents: Doc[]) => {
      return Promise.resolve();
   },
   isGeneratingBatchFile: false,
});

export const useDocumentDownloader = () => React.useContext(DocumentDownloadContext);

type Props = React.PropsWithChildren<{}>;

export const DocumentDownloadProvider: React.FunctionComponent<Props> = (props) => {
   const { user } = useCurrentUser();
   const { info, success } = useNotifications();

   const [updateDocumentStatus] = useMutation<{ document: { updateStatus: Doc } }, { document: Doc }>(UpdateDocumentStatusMutation, {
      onCompleted: () => {
         success("Updated document status.");
      },
   });
   const [batchUpdateDocumentStatus] = useMutation<{ document: { batchUpdateStatus: Doc[] } }, { documents: Doc[] }>(
      BatchUpdateDocumentStatusMutation,
      {
         onCompleted: () => {
            success("Updated document statuses.");
         },
         fetchPolicy: "no-cache",
      },
   );

   const [getDocumentDownloadInfo, { loading: isGeneratingBatchFile }] = useLazyQuery<{ documentDownloadInfo: DocumentDownloadInfo }>(
      DocumentDownloadInfoQuery,
      {
         onCompleted: (result) => {
            if (result.documentDownloadInfo.url) {
               window.open(result.documentDownloadInfo.url, "_blank");
            }
         },
         fetchPolicy: "network-only",
      },
   );

   const openDocumentInWindow = React.useCallback(
      async (document: Doc) => {
         getDocumentDownloadInfo({
            variables: {
               ids: [document.id],
            },
         });

         if (
            (user.isWyth && document.source === 'Partner' && document.status === DocStatus.Received) ||
            (!user.isWyth && document.source === 'Concentra' && user.organizationId === document.organizationId && document.status === DocStatus.Received)
         ) {
            const editedDocument: Doc = {
               ...gqlFilter(documentInputFragment, document),
               status: DocStatus.Retrieved,
            };
            await updateDocumentStatus({ variables: { document: editedDocument } });
         }
      },
      [getDocumentDownloadInfo, user.isWyth, user.organizationId, updateDocumentStatus],
   );

   const openBatchDocumentDownloadInNewWindow = React.useCallback(
      async (docs: Doc[]) => {
         const documentIds = docs.map((doc) => doc.id);
         getDocumentDownloadInfo({
            variables: {
               ids: documentIds,
            },
         });

         if (user.isWyth) {
            const docsInReceivedStatus = docs.filter((d) => d.source === 'Partner' && d.status === DocStatus.Received);
            if (docsInReceivedStatus.length) {
               info("Updating document statuses to Retrieved");
               const editedDocuments: Doc[] = docsInReceivedStatus.map((d) => {
                  return {
                     ...gqlFilter(documentInputFragment, d),
                     status: DocStatus.Retrieved,
                  };
               });
               await batchUpdateDocumentStatus({ variables: { documents: editedDocuments } });
            }
         } else {
            const docsInReceivedStatus = docs.filter((d) => d.source === 'Concentra' && user.organizationId === d.organizationId  && d.status === DocStatus.Received);
            if (docsInReceivedStatus.length) {
               info("Updating document statuses to Retrieved");
               const editedDocuments: Doc[] = docsInReceivedStatus.map((d) => {
                  return {
                     ...gqlFilter(documentInputFragment, d),
                     status: DocStatus.Retrieved,
                  };
               });
               await batchUpdateDocumentStatus({ variables: { documents: editedDocuments } });
            }
         }

      },
      [getDocumentDownloadInfo, user.isWyth, user.organizationId, info, batchUpdateDocumentStatus],
   );

   const contextValue = React.useMemo(
      () => ({
         openDocumentInWindow,
         openBatchDocumentDownloadInNewWindow,
         isGeneratingBatchFile,
      }),
      [isGeneratingBatchFile, openBatchDocumentDownloadInNewWindow, openDocumentInWindow],
   );

   return <DocumentDownloadContext.Provider value={contextValue}>{props.children}</DocumentDownloadContext.Provider>;
};
