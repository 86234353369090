import { useQuery, NetworkStatus, gql } from "@apollo/client";
import {
   Box,

   IconButton,
   Link,
   List,
   ListItem,
   ListItemIcon,
   ListItemSecondaryAction,
   ListItemText,

   Typography,
   Button,
   Grid,
   TextField,
   MenuItem,
   CircularProgress,
   Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getIconForDocument, DocStatus, DocSource, unpackDocumentTypeHierarchy, documentListStyles } from "documents";
import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { RankedDoc, DocFilters } from "search";
import useDebounce from "useDebounce";
import { ActiveDocumentContext } from "application/GlobalDocumentActions";
import SearchFilters from "./SearchFilters";
import { Skeleton } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import qs from "query-string";
import * as formats from "application/formats";
import { GetApp, Notifications, EditAttributes } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroller";
import ConfirmCreateAlertDialog from "alerts/ConfirmCreateAlertDialog";
import { Helmet } from "react-helmet";
import {
   MetadataOptionQueryResult,
   MetadataOptionQuery,
   MetadataOptionQueryVariables,
   UserOptionsQueryResult,
   UserOptionsQueryVariables,
   UserOptionsQuery,
   DocumentTypeOptionQueryResult,
   DocumentTypeOptionQueryVariables,
   DocumentTypeOptionQuery,
   ProductOptionQueryVariables,
   SearchFilterProductOptionQuery,
   SearchFilterProductOptionQueryResult,
} from "../documents/queries";
import useCurrentUser from "users/useCurrentUser";
import StatusMenu from "documents/StatusMenu";
import { Permissions } from "users";
import { getIdsFromQueryString, isString } from "application";
import CreateProductLinkDialog from "products/CreateProductLinkDialog";
import clsx from "clsx";
import Comments from "../documents/Comments";
import { useDocumentDownloader } from "documents/DocumentDownloadProvider";
import BulkUpdateDocumentStatusDialog from "documents/BulkUpdateDocumentStatusDialog";
import SpinnerButton from "SpinnerButton";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...documentListStyles(theme),
      root: {
         display: "flex",
      },
      filters: {
         width: "25rem",
         flexShrink: 0,
         flexGrow: 0,
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         flexWrap: "wrap",
         "& > *:first-child": {
            marginRight: "auto",
         },
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            marginBottom: theme.spacing(1),
         },
      },
      searchResults: {
         flexGrow: 1,
         padding: theme.spacing(3),
      },
      resultSecondaryAction: {
         right: theme.spacing(10),
      },
      searchResult: {
         marginBottom: theme.spacing(1),
         paddingLeft: 0,
      },
      sortSelect: {
         marginRight: "auto",
      },
      resultList: {
         maxWidth: "40rem",
      },
   }),
);

enum SortOrder {
   ByRelevance = "relevance",
   NewestFirst = "newest",
   Alphabetically = "alphabetical",
   ByStatus = "status",
}

export const SearchScreen: React.FunctionComponent = () => {
   const classes = useStyles();

   function excludeNulls(value: string | null | (string | null)[]) {
      if (value === null || value === undefined) {
         return "";
      } else if (typeof value === "string") {
         return value;
      } else {
         return value.flatMap(v => !!v ? [v] : []);
      }
   }

   const { user, userLoading, userHasPermission } = useCurrentUser();
   const { openDocumentInWindow, openBatchDocumentDownloadInNewWindow, isGeneratingBatchFile } = useDocumentDownloader();

   const userCanEditStatus = userHasPermission(Permissions.EditDocumentStatus);
   const userCanBatchEditStatus = userHasPermission(Permissions.BatchEditDocumentStatus);

   const activeDocumentContext = useContext(ActiveDocumentContext);
   const navigate = useNavigate();
   const location = useLocation();
   const queryStringObject = qs.parse(location.search);

   const [searchTerms, setSearchTerms] = useState(
      queryStringObject.terms ? (typeof queryStringObject.terms === "string" ? queryStringObject.terms : (queryStringObject.terms[0] ? queryStringObject.terms[0] : "")) : "",
   );
   const debouncedSearchTerms = useDebounce<string>(searchTerms ?? "", 500);

   const [highlightedDocumentId, setHighlightedDocumentId] = useState(0);

   const initialFilters: DocFilters = {
      startDate:
         queryStringObject.startDate && typeof queryStringObject.startDate === "string"
            ? moment.utc(queryStringObject.startDate).toDate()
            : null,
      endDate:
         queryStringObject.endDate && typeof queryStringObject.endDate === "string" ? moment.utc(queryStringObject.endDate).toDate() : null,
      documentTypeIds: getIdsFromQueryString(excludeNulls(queryStringObject.typeId)),
      organizationIds: getIdsFromQueryString(excludeNulls(queryStringObject.organizationId)),
      uploadedByUserIds: getIdsFromQueryString(excludeNulls(queryStringObject.uploadedByUserId)),
      sources: queryStringObject.source
         ? typeof queryStringObject.source === "string"
            ? [queryStringObject.source as DocSource]
            : (queryStringObject.source as DocSource[])
         : [],
      statuses: queryStringObject.status
         ? typeof queryStringObject.status === "string"
            ? [queryStringObject.status as DocStatus]
            : (queryStringObject.status as DocStatus[])
         : [],
      tagIds: getIdsFromQueryString(excludeNulls(queryStringObject.tagId)),
      productIds: getIdsFromQueryString(excludeNulls(queryStringObject.productId)),
      programIds: getIdsFromQueryString(excludeNulls(queryStringObject.programId)),
      jurisdictionIds: getIdsFromQueryString(excludeNulls(queryStringObject.jurisdictionId)),
      planIds: getIdsFromQueryString(excludeNulls(queryStringObject.planId))
   };

   const [filters, setFilters] = useState<DocFilters>(initialFilters);
   const [sortOrder, setSortOrder] = useState((queryStringObject.sort as SortOrder) ?? SortOrder.ByRelevance);

   useEffect(() => {
      updateUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [debouncedSearchTerms, filters, sortOrder]);

   const [fullyFetched, setFullyFetched] = useState(false);
   useEffect(() => {
      setFullyFetched(false);
   }, [searchTerms, filters, sortOrder]);

   function updateUrl() {
      const query = {
         sort: sortOrder !== SortOrder.ByRelevance ? sortOrder : undefined,
         terms: searchTerms ? searchTerms : undefined,
         startDate: filters.startDate ? moment.utc(filters.startDate).format(formats.dateFormat) : undefined,
         endDate: filters.endDate ? moment.utc(filters.endDate).format(formats.dateFormat) : undefined,
         typeId: filters.documentTypeIds,
         organizationId: filters.organizationIds,
         uploadedByUserId: filters.uploadedByUserIds,
         status: filters.statuses,
         source: filters.sources,
         productId: filters.productIds,
         programId: filters.programIds,
         tagId: filters.tagIds,
         jurisdictionId: filters.jurisdictionIds,
         planId: filters.planIds
      };

      navigate({
         ...location,
         search: qs.stringify(query),
      },
         { replace: true }
      );
   }

   const productOptionsQuery = useQuery<SearchFilterProductOptionQueryResult, ProductOptionQueryVariables>(SearchFilterProductOptionQuery, {
      variables: {
         includeAllProducts: userHasPermission(Permissions.ViewDocumentsOutsideYourProducts)
      },
   });

   const documentTypeOptionsQuery = useQuery<DocumentTypeOptionQueryResult, DocumentTypeOptionQueryVariables>(DocumentTypeOptionQuery, {
      variables: {},
   });

   const usersQuery = useQuery<UserOptionsQueryResult, UserOptionsQueryVariables>(UserOptionsQuery, {
      variables: {},
   });

   const optionsQuery = useQuery<MetadataOptionQueryResult, MetadataOptionQueryVariables>(MetadataOptionQuery, {
      variables: {},
      skip: userLoading,
   });

   const documentTypeOptions = function () {
      if (!documentTypeOptionsQuery.loading && !!documentTypeOptionsQuery.data) {
         var documentTypesData = unpackDocumentTypeHierarchy(documentTypeOptionsQuery.data?.documentTypes) ?? [];
         if (user.isWyth) {
            documentTypesData = documentTypesData.filter(dtc => dtc.name != "Administration Form");
         }
         return documentTypesData;
      } else {
         return [];
      }
   }();

   const organizationOptions = optionsQuery.data?.organizations ?? [];
   const statusOptions = optionsQuery.data ? optionsQuery.data.statuses : [];
   const sourceOptions = optionsQuery.data ? optionsQuery.data.sources : [];
   const userOptions = usersQuery.data ? usersQuery.data.users : [];
   const tagOptions = optionsQuery.data ? optionsQuery.data.tags : [];
   const productOptions = productOptionsQuery.data ? productOptionsQuery.data.searchFilterProducts : [];
   const programOptions = optionsQuery.data ? optionsQuery.data.programs : [];
   const jurisdictionOptions = optionsQuery.data ? optionsQuery.data.jurisdictions : [];
   const planOptions = optionsQuery.data ? optionsQuery.data.plans : [];

   const fetchLimit = 50;
   const searchQuery = useQuery<
      { search: { totalCount: number; results: RankedDoc[] } },
      {
         searchText: string;
         filters: {
            startDate: Date | null;
            endDate: Date | null;
            typeIds: number[];
            organizationIds: (number | null)[];
            uploadedByUserIds: number[];
            sources: DocSource[];
            statuses: DocStatus[];
            tagIds: number[];
            productIds: number[];
            programIds: number[];
            jurisdictionIds: number[];
            planIds: number[];
         };
         offset: number;
         limit: number;
         sort: SortOrder;
      }
   >(
      gql`
         query DocumentSearch($searchText: String!, $filters: DocumentFiltersInput!, $offset: Int!, $limit: Int!, $sort: String!) {
            search(searchText: $searchText, filters: $filters, offset: $offset, limit: $limit, sort: $sort) {
               totalCount
               results {
                  score
                  document {
                     id
                     identifier
                     title
                     fileName
                     type {
                        id
                        name
                        isRestrictedForWyth
                     }
                     date
                     status
                     source
                     uploadedById
                     uploadedBy {
                        id
                        name
                     }
                     uploadDate
                     organizationId
                     organization {
                        id
                        name
                     }
                     products {
                        id
                        name
                     }
                     jurisdictions {
                        id
                        name
                        order
                     }
                     tags {
                        id
                        value
                     }
                     catalogueNumber
                     version
                     comments
                     plans {
                        id
                        name
                     }
                  }
               }
            }
         }
      `,
      {
         variables: {
            searchText: debouncedSearchTerms,
            filters: {
               startDate: filters.startDate,
               endDate: filters.endDate,
               typeIds: filters.documentTypeIds,
               organizationIds: filters.organizationIds.map((oid) => (oid !== 0 ? oid : null)),
               uploadedByUserIds: filters.uploadedByUserIds,
               sources: filters.sources,
               statuses: filters.statuses,
               tagIds: filters.tagIds,
               productIds: filters.productIds,
               programIds: filters.programIds,
               jurisdictionIds: filters.jurisdictionIds,
               planIds: filters.planIds
            },
            offset: 0,
            limit: fetchLimit,
            sort: sortOrder,
         },
         skip: filtersEmpty() && debouncedSearchTerms.length < 3,
         notifyOnNetworkStatusChange: true,
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
      },
   );
   const totalCount = searchQuery.data ? searchQuery.data.search.totalCount : 0;
   const searchResults = searchQuery.data ? searchQuery.data.search.results : [];

   const [confirmingCreateAlert, setConfirmingCreateAlert] = useState(false);
   const [creatingProductLink, setCreatingProductLink] = useState(false);
   const [bulkUpdatingStatus, setBulkUpdatingStatus] = useState(false);

   function filtersEmpty() {
      return (
         filters.startDate === null &&
         filters.endDate === null &&
         filters.documentTypeIds.length === 0 &&
         filters.organizationIds.length === 0 &&
         filters.uploadedByUserIds.length === 0 &&
         filters.sources.length === 0 &&
         filters.statuses.length === 0 &&
         filters.productIds.length === 0 &&
         filters.programIds.length === 0 &&
         filters.tagIds.length === 0 &&
         filters.jurisdictionIds.length === 0 &&
         filters.planIds.length == 0
      );
   }

   function notifiableFiltersSet() {
      return (
         searchTerms.trim().length > 0 ||
         filters.documentTypeIds.length > 0 ||
         filters.organizationIds.length > 0 ||
         filters.uploadedByUserIds.length > 0 ||
         filters.sources.length > 0 ||
         filters.statuses.length > 0 ||
         filters.tagIds.length > 0 ||
         filters.productIds.length > 0 ||
         filters.programIds.length > 0 ||
         filters.planIds.length > 0
      );
   }

   function downloadAllSearchResults() {
      openBatchDocumentDownloadInNewWindow(searchResults.map((r) => r.document));
   }

   function loadMoreResults() {
      if (searchQuery.networkStatus !== NetworkStatus.ready) return;

      // We fetch one more than we want to display--that way we will know in advance if there are no more items left to fetch.
      searchQuery.fetchMore({
         variables: {
            offset: searchResults.length,
            limit: fetchLimit + 1,
         },
         updateQuery: (previousQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return previousQueryResult;
            }

            const fetchedResults = fetchMoreResult.search.results;
            if (fetchedResults.length < fetchLimit + 1) {
               setFullyFetched(true);
            }

            const concatenatedResults = [...searchQuery.data?.search.results!, ...fetchedResults.slice(0, fetchLimit)];

            return {
               search: {
                  totalCount: fetchMoreResult.search.totalCount,
                  results: concatenatedResults,
               },
            };
         },
      });
   }

   function renderSearchResults() {
      if (!searchQuery.data) return null;

      if (searchQuery.networkStatus === NetworkStatus.ready && searchResults.length === 0) {
         return (
            <Grid item>
               <Typography variant="body1" color="textSecondary">
                  No documents found.
               </Typography>
            </Grid>
         );
      }

      return (
         <Grid item>
            <Typography variant="body2" color="textSecondary">
               {`${totalCount} result${totalCount > 1 ? "s" : ""}`}
            </Typography>
            <List dense className={classes.resultList} onMouseLeave={() => setHighlightedDocumentId(0)}>
               <InfiniteScroll
                  loadMore={loadMoreResults}
                  hasMore={!fullyFetched && searchResults.length >= fetchLimit}
                  useWindow={true}
                  loader={
                     <ListItem key={0} alignItems="center">
                        <ListItemIcon className={classes.icon}>
                           <CircularProgress size={24} />
                        </ListItemIcon>
                        <ListItemText primary="Loading..." primaryTypographyProps={{ color: "textSecondary" }} />
                     </ListItem>
                  }
               >
                  {searchResults.map((rankedDoc) => {
                     const document = rankedDoc.document;
                     const icon = getIconForDocument(document);

                     return (
                        <ListItem
                           key={document.id}
                           alignItems="flex-start"
                           className={clsx(classes.searchResult, classes.documentListItem, {
                              [classes.highlightedDocument]: document.id === highlightedDocumentId,
                           })}
                           title={`Product(s): ${document.products.map((p) => p.name).join(", ")}\nPosted by ${document.uploadedBy ? `${document.uploadedBy.name}` : "unknown"
                              } at ${moment(document.uploadDate).format(formats.dateWithTimeFormat)}`}
                           onMouseEnter={() => setHighlightedDocumentId(document.id)}
                        >
                           <ListItemIcon className={classes.icon}>
                              <Link onClick={() => openDocumentInWindow(document)}>{icon}</Link>
                           </ListItemIcon>
                           <ListItemText
                              classes={{ root: classes.documentText, primary: classes.identifier, secondary: classes.details }}
                              primary={<Link onClick={() => openDocumentInWindow(document)}>{document.identifier}</Link>}
                              primaryTypographyProps={{ variant: "body1" }}
                              secondary={
                                 <>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                       <div className={classes.documentDate}>{moment.utc(document.date).calendar()}</div>
                                       {document.status !== DocStatus.NA && <StatusMenu document={document} canEdit={userCanEditStatus && (
                                          (user.isWyth && document.source !== "Concentra") ||
                                          (!user.isWyth && document.source === "Concentra" && user.organizationId === document.organizationId))
                                       } />}
                                    </Grid>
                                    <Box mt={0.25}>{document.fileName}</Box>
                                    {user.isWyth && document.organization && <Box mt={0.25}>{document.organization.name}</Box>}
                                    <Box mt={0.25}>
                                       {document.type.name}
                                       {document.plans.length > 0 && `, Plan types: ${document.plans.map(p => p.name).join(", ")}\n`}
                                       {document.version && `, v${document.version}`}
                                       {document.jurisdictions.length > 0 &&
                                          `, Jurisdictions: ${document.jurisdictions.map((j) => j.name).join(", ")}\n`}
                                    </Box>
                                    {(document.tags.length > 0 || (document.comments && document.comments.length > 0)) && (
                                       <Box mt={0.25}>
                                          {document.tags.map((t) => (
                                             <Chip key={t.id} size="small" label={t.value} className={classes.tagChip} />
                                          ))}
                                          {document.comments && document.comments.length > 0 && <Comments getDocument={() => document!} />}
                                       </Box>
                                    )}
                                 </>
                              }
                              secondaryTypographyProps={{ component: "div" }}
                           />

                           <ListItemSecondaryAction className={classes.documentAction}>
                              <IconButton
                                 aria-label="more"
                                 aria-controls="action-menu"
                                 aria-haspopup="true"
                                 onClick={(e) => {
                                    activeDocumentContext.setMenuAnchorEl(e.currentTarget);
                                    activeDocumentContext.setActiveDoc(document);
                                 }}
                              >
                                 <MoreVertIcon />
                              </IconButton>
                           </ListItemSecondaryAction>
                        </ListItem>
                     );
                  })}
               </InfiniteScroll>
            </List>
         </Grid>
      );
   }

   function renderSearchResultsSkeleton() {
      return (
         <Grid item>
            {[...Array(10)].map((x, index) => (
               <Box key={index} mb={2}>
                  <Skeleton variant="rectangular" width="100%" height="6rem" />
               </Box>
            ))}
         </Grid>
      );
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Search - Concentra Partner Portal</title>
         </Helmet>
         <div className={classes.filters}>
            <SearchFilters
               searchTerms={searchTerms}
               updateSearchTerms={(terms) => setSearchTerms(terms)}
               filters={filters}
               updateFilters={(f) => {
                  setFilters(f);
               }}
               documentTypes={documentTypeOptions}
               documentTypesLoading={documentTypeOptionsQuery.loading}
               organizations={organizationOptions}
               organizationsLoading={optionsQuery.loading}
               statuses={statusOptions}
               sources={sourceOptions}
               users={userOptions}
               usersLoading={usersQuery.loading}
               tags={tagOptions}
               tagsLoading={optionsQuery.loading}
               products={productOptions}
               productsLoading={productOptionsQuery.loading}
               programs={programOptions}
               programsLoading={optionsQuery.loading}
               jurisdictions={jurisdictionOptions}
               jurisdictionsLoading={optionsQuery.loading}
               plans={planOptions}
               plansLoading={optionsQuery.loading}
            />
         </div>
         <div className={classes.searchResults}>
            <Grid container direction="column" spacing={3} alignItems="stretch">
               <Grid item className={classes.actions}>
                  <TextField
                     select
                     className={classes.sortSelect}
                     variant="outlined"
                     size="small"
                     label="Sort results"
                     value={sortOrder}
                     onChange={(e) => setSortOrder(e.target.value as SortOrder)}
                  >
                     <MenuItem value={SortOrder.ByRelevance}>By relevance</MenuItem>
                     <MenuItem value={SortOrder.NewestFirst}>Newest first</MenuItem>
                     <MenuItem value={SortOrder.ByStatus}>By status</MenuItem>
                     <MenuItem value={SortOrder.Alphabetically}>Alphabetically</MenuItem>
                  </TextField>
                  {userHasPermission(Permissions.ManageProductScreens) && (
                     <Button
                        variant="outlined"
                        onClick={() => setCreatingProductLink(true)}
                        disabled={filters.productIds.length !== 1 || !user.products.some((p) => p.id === filters.productIds[0])}
                     >
                        Create product task...
                     </Button>
                  )}
                  <Button
                     variant="outlined"
                     startIcon={<Notifications />}
                     onClick={() => setConfirmingCreateAlert(true)}
                     disabled={!notifiableFiltersSet()}
                  >
                     Alert me...
                  </Button>
                  <SpinnerButton
                     variant="outlined"
                     startIcon={<GetApp />}
                     onClick={() => downloadAllSearchResults()}
                     disabled={searchResults.length === 0}
                     inProgress={isGeneratingBatchFile}
                     label={"Download all search results"}
                  />
                  {userCanBatchEditStatus && (
                     <Button
                        variant="outlined"
                        startIcon={<EditAttributes />}
                        onClick={() => setBulkUpdatingStatus(true)}
                        disabled={searchResults.length === 0}
                     >
                        Bulk update status
                     </Button>
                  )}
               </Grid>
               {searchQuery.networkStatus === NetworkStatus.loading ? renderSearchResultsSkeleton() : renderSearchResults()}
            </Grid>
         </div>

         <CreateProductLinkDialog
            open={creatingProductLink}
            searchTerms={searchTerms}
            filters={filters}
            documentTypes={documentTypeOptions}
            tags={tagOptions}
            products={productOptions}
            handleClose={() => setCreatingProductLink(false)}
         />

         <ConfirmCreateAlertDialog
            open={confirmingCreateAlert}
            matchPartialFileName={searchTerms}
            filters={filters}
            documentTypes={documentTypeOptions}
            organizations={organizationOptions}
            users={userOptions}
            tags={tagOptions}
            products={productOptions}
            programs={programOptions}
            plans={planOptions}
            handleClose={() => setConfirmingCreateAlert(false)}
         />
         <BulkUpdateDocumentStatusDialog
            open={bulkUpdatingStatus}
            documents={searchResults.map((s) => s.document)}
            handleClose={() => setBulkUpdatingStatus(false)}
         />
      </div>
   );
};
