import React from "react";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import { Tooltip, Typography } from "@mui/material";
import { Doc, getDocumentUrl } from ".";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import { makeStyles, createStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         backgroundColor: theme.palette.background.paper,
      },
      inlineIcon: {
         verticalAlign: "middle",
         marginTop: "2px",
         marginRight: "6px",
         cursor: "pointer",
         color: "#c33991"
      },
   }),
);

interface Props {
   getDocument: () => Doc;
}

const HtmlTooltip = withStyles((theme) => ({
   tooltip: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #c33991",
   },
}))(Tooltip);

const Comments: React.FunctionComponent<Props> = (props) => {
   const navigate = useNavigate();

   function navigateToDocument() {
      const url = getDocumentUrl(props.getDocument());
      navigate(url);
   }

   const comments = props.getDocument().comments || "";

   const classes = useStyles();

   return (
      <HtmlTooltip
         disableInteractive={false}
         title={
            <React.Fragment>
               <Typography color="inherit" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comments) }} />
            </React.Fragment>
         }
      >
         <MessageTwoToneIcon
            className={classes.inlineIcon}
            fontSize="small"
            titleAccess="View comment"
            onClick={() => navigateToDocument()}
         />
      </HtmlTooltip>
   );
};
export default Comments;
