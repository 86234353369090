import { Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

export function basicScreenStyle(theme: Theme) {
   return createStyles({
      root: {
         padding: theme.spacing(3),
      },
   });
}

export function dialogStyle(theme: Theme) {
   return createStyles({
      dialogContent: {
         paddingTop: theme.spacing(1.5), //https://material-ui.com/components/grid/#negative-margin
         paddingBottom: theme.spacing(1.5),
      },
   });
}
