import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
} from "@mui/material";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "../SpinnerButton";
import { Organization } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikErrors, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      dialogContent: {
         paddingTop: theme.spacing(1.5), //https://material-ui.com/components/grid/#negative-margin
         paddingBottom: theme.spacing(1.5),
      },
   }),
);

interface Props {
   open: boolean;
   organization: Organization;
   handleClose: (deactivatedOrganizationId?: number) => void;
}

interface FormValues {
   name: string;
}

const ConfirmDeactivateOrganizationDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const initialFormValues: FormValues = {
      name: "",
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.name !== props.organization.name) {
         errors.name = "Confirm by entering the organization name.";
      }

      return errors;
   }

   const [deactivateMutate] = useMutation<{ organization: { deactivate: Organization } }, { id: number }>(
      gql`
         mutation DeactivateOrganization($id: Int!) {
            organization {
               deactivate(id: $id) {
                  id
               }
            }
         }
      `,
   );

   async function deactivateOrganization(values: FormValues, actions: FormikHelpers<FormValues>) {
      const result = await deactivateMutate({ variables: { id: props.organization.id } });

      if (result.data?.organization.deactivate?.id) {
         notifications.success("Deactivated the organization.");
      }

      actions.setSubmitting(false);
      props.handleClose(result.data?.organization.deactivate.id);
   }

   if (!props.open) return null;

   return (
      <Dialog open={true} onClose={() => props.handleClose()}>
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={deactivateOrganization}>
            {(formikProps) => (
               <>
                  <DialogTitle>{`Deactivate organization?`}</DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                     <DialogContentText>
                        This will remove the organization from selection lists and deactivate all users belonging to this organization.
                        Documents belonging to the organization will remain. To deactivate the organization "{props.organization.name}",
                        confirm by typing the organization name below.
                     </DialogContentText>
                     <FormikForm>
                        <Grid container direction="column" spacing={2}>
                           <Grid item container spacing={1}>
                              <Grid item xs={12}>
                                 <FormikField
                                    component={FmuiTextField}
                                    name="name"
                                    variant="outlined"
                                    size="small"
                                    label="Name"
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </FormikForm>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={() => props.handleClose()}>Cancel</Button>
                     <SpinnerButton
                        label="Deactivate"
                        color="secondary"
                        variant="contained"
                        inProgress={formikProps.isSubmitting}
                        onClick={() => {
                           formikProps.submitForm();
                        }}
                     />
                  </DialogActions>
               </>
            )}
         </Formik>
      </Dialog>
   );
};

export default ConfirmDeactivateOrganizationDialog;
