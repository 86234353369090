import { AppBar, Box, Button, IconButton, InputBase, Toolbar, Typography, InputAdornment, Grid } from "@mui/material";
import {  alpha,  lighten } from "@mui/material";
import { CloudUpload, Search, ArrowForward, ChevronLeft } from "@mui/icons-material";
import UpsertDocumentDialog from "documents/UpsertDocumentDialog";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate, Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteNames } from "./routes";
import useCurrentUser from "users/useCurrentUser";
import clsx from "clsx";
import { drawerWidth } from "./Layout";
import { Permissions } from "../users";

import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
   const appBarHeight = theme.spacing(8);
   return createStyles({
      appBar: {
         height: appBarHeight,
         transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
      },
      appBarShift: {
         width: `calc(100% - ${drawerWidth}px)`,
         transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
         marginRight: drawerWidth,
      },
      hide: {
         display: "none",
      },
      grow: {
         flexGrow: 1,
      },
      menuButton: {
         marginRight: theme.spacing(2),
      },
      title: {
         display: "none",
         [theme.breakpoints.up("sm")]: {
            display: "block",
            fontWeight: "500"
         },
      },
      search: {
         flexBasis: "20rem",
         flexShrink: 100,
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         backgroundColor: alpha(theme.palette.common.white, 0.15),
         "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
         },
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "100%",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         width: theme.spacing(7),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      searchRoot: {
         color: "inherit",
      },
      searchInput: {
         padding: theme.spacing(1, 1, 1, 7),
         transition: theme.transitions.create("width"),
      },

      sectionDesktop: {
         display: "none",
         [theme.breakpoints.up("md")]: {
            display: "flex",
            marginLeft: theme.spacing(2),
         },
      },
      sectionMobile: {
         display: "flex",
         [theme.breakpoints.up("md")]: {
            display: "none",
         },
      },
      logoBg: {
         padding: theme.spacing(2.5, 1, 2.5, 0),
         margin: theme.spacing(0, 1, 0, 0),
         height: appBarHeight,
         backgroundColor: "transparent",
         "& img": {
            maxWidth: "100%",
            height: "100%",
         },
         [theme.breakpoints.down("sm")]: {
            maxWidth: "10rem",
         },
      },
      uploadInput: {
         display: "none",
      },
      homeLink: {
         flexShrink: 0,
         display: "flex",
         alignItems: "center",
         color: "inherit",
         textDecoration: "inherit",
      },
      uploadButton: {
         whiteSpace: "nowrap",
         flexShrink: 0,
         backgroundColor: "#fff",
         "&:hover:not(:active)": {
            backgroundColor: lighten(theme.palette.primary.light, 0.9),
         },
         "&:active": {
            backgroundColor: "#fff",
         },
      },
      userInfo: {
         fontSize: 14,
         "& > *": {
            whiteSpace: "nowrap",
         },
      },
   });
});

interface Props {
   open: boolean;
   handleDrawerOpen: () => void;
}

const SiteAppBar = (props: Props) => {
   const classes = useStyles();
   const navigate = useNavigate();

   const [uploadDocumentDialogOpen, setUploadDocumentDialogOpen] = useState(false);
   const [searchTerms, setSearchTerms] = useState("");

   const { userLoading, user, userHasPermission } = useCurrentUser();

   const userHasReportingProducts = user.products.length > 0;

   const handleSearch = () => {
      navigate(`${RouteNames.Search}?terms=${encodeURI(searchTerms)}`);
   };

   const productRouteMatch = useParams<{ id: string }>();

   return (
      <>
      <AppBar
         position="fixed"
         className={clsx(classes.appBar, {
            [classes.appBarShift]: props.open,
         })}
      >
         <Toolbar>
            <RouterLink to="/" className={classes.homeLink}>
               <Box className={classes.logoBg}>
                     <img src="/assets/concentra-logo.svg" alt="Concentra EQ logo" />
               </Box>
               <Typography className={classes.title} variant="subtitle1" noWrap>
                  Partner Portal
               </Typography>
            </RouterLink>
            <div className={classes.grow} />

            <Routes>
               <Route path={RouteNames.Home} />
               <Route path={RouteNames.Search} />
                  <Route path={"*"} element={ 
                     <div className={classes.search}>
                        <div className={classes.searchIcon}>
                           <Search />
                        </div>
                        <InputBase
                           placeholder="Search…"
                           classes={{
                              root: classes.searchRoot,
                              input: classes.searchInput,
                           }}
                           value={searchTerms}
                           onChange={(e) => setSearchTerms(e.target.value)}
                           onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                 handleSearch();
                              }
                           }}
                           endAdornment={
                              <InputAdornment position="end">
                                 <IconButton onClick={handleSearch} disabled={searchTerms.trim() === ""}>
                                    <ArrowForward />
                                 </IconButton>
                              </InputAdornment>
                           }
                           />
                         
                     </div>
                  }>
               </Route>
            </Routes>

            {userHasReportingProducts && userHasPermission(Permissions.PostEditOwnDocuments) && (
               <Button
                  className={classes.uploadButton}
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<CloudUpload />}
                  onClick={() => setUploadDocumentDialogOpen(true)}
               >
                  Post a document
               </Button>
            )}

            <div className={classes.sectionDesktop}>
               {userLoading ? (
                  ""
               ) : (
                  <Grid container direction="column" alignItems="flex-start" className={classes.userInfo}>
                     <div>{user.name}</div>
                     <div>{user.organization.name}</div>
                  </Grid>
               )}
            </div>

            <IconButton color="inherit" edge="end" onClick={() => props.handleDrawerOpen()} className={clsx(props.open && classes.hide)}>
               <ChevronLeft />
            </IconButton>
         </Toolbar>

         <UpsertDocumentDialog
            open={uploadDocumentDialogOpen}
            handleClose={() => setUploadDocumentDialogOpen(false)}
            documentIdToEdit={null}
            productId={productRouteMatch.id ? parseInt(productRouteMatch.id) : undefined}
         />
         </AppBar>
      </>
   );
};

export default SiteAppBar;
