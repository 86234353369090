import { gql } from "@apollo/client";
import { Product } from "../products";

export const PortfolioProductOptionsQuery = gql`
   query GetPortfolioProductOptions {
      products {
        id
        name
      }
   }
`;

export interface PortfolioProductOptionQueryVariables {

}

export interface PortfolioProductOptionQueryResult {
   products: Product[];
}

