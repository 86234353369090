import React from "react";
import docFileIcon from "./docx_icon.svg";

interface Props {
   size?: string;
}

export const WordDocIcon: React.FunctionComponent<Props> = (props) => (
   <img
      alt="Word doc icon"
      src={docFileIcon}
      style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }}
   />
);
