import React from "react";
import { AccountInfo, BrowserAuthError, InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAuthConfig } from "application/SettingsProvider";
import { useAppInsights } from "application/AppInsightsProvider";
import { useNotifications } from "notifications";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import WaitingView from "./WaitingView";
import DOMPurify from "dompurify";

interface MsalAuthContext {
   getAccessToken: () => Promise<string | null>;
   handleRedirectPromise: any;
   authenticated: boolean;
   logOut?: () => Promise<void>;
   resetPassword?: () => void;
}

export const AuthenticationContext = React.createContext<MsalAuthContext>({
   getAccessToken: () => Promise.resolve(null),
   handleRedirectPromise: null,
   authenticated: false,
});

export const useAuthentication = () => React.useContext(AuthenticationContext);

type Props = React.PropsWithChildren<{}>;

export const AuthenticationProvider: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const authConfig = useAuthConfig();
   const appInsights = useAppInsights();
   const { instance: msalInstance } = useMsal();

   const { error, login } = useMsalAuthentication(InteractionType.Redirect, {
      redirectUri: `${window.location.origin}/authComplete`,
   });

   React.useEffect(() => {
      if (!!error) {
         if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            // User clicked "forgot password" in B2C sign in form
            const request = {
               authority: authConfig.resetPasswordAuthority,
               scopes: []
            };
            login(InteractionType.Redirect, request);
         } else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
            // user cancelled out of change password flow
            window.location.href = DOMPurify.sanitize(window.location.origin);
         } else if (error.errorCode == "no_cached_authority_error") {
            window.location.href = DOMPurify.sanitize(window.location.origin);
         } else {
            notifications.error(error.errorMessage);
         }
      }
   }, [authConfig.resetPasswordAuthority, error, login, notifications]);

   // We need to ensure that we use the account from the Sign In policy authority
   // After redirecting to the reset password authority, we will have multiple accounts present
   const signInPolicyId = authConfig.authority.substring(authConfig.authority.lastIndexOf("/") + 1).toLowerCase();
   // We will sort the accounts by claim expiry descending
   // to default to the most recently signed in account in the case that the user has two active accounts
   const sortedAccounts = [...msalInstance.getAllAccounts()].sort((a, b) => {
      if ((a.idTokenClaims as any).exp > (b.idTokenClaims as any).exp) {
         return -1;
      } else {
         return 1;
      }
   });

   const foundAccount = sortedAccounts.find((a) => ((a.homeAccountId.toLowerCase().includes(signInPolicyId.toLowerCase())) && (authConfig.authority.toLowerCase().includes(a.environment.toLowerCase())))) ?? {};
   const account = foundAccount as AccountInfo;

   const handleError = React.useCallback(
      (err: any) => {
         appInsights.trackTrace({ message: err.toString ? err.toString() : err, severityLevel: SeverityLevel.Error });
      },
      [appInsights],
   );

   const getAccessToken = React.useCallback(async () => {
      if (!account) {
         return null;
      }

      const apiScopes = authConfig?.requestScopes ?? [];
      try {
         const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: apiScopes,
            account: { ...account, username: authConfig.userEmail },
            authority: authConfig.authority,
         });
         return tokenResponse?.accessToken ?? null;
      } catch (err) {
         if (err instanceof InteractionRequiredAuthError || err instanceof BrowserAuthError) {
            const redirectTokenRequest = {
               scopes: apiScopes,
               loginHint: authConfig.userEmail,
               authority: authConfig.authority,
            };
            await msalInstance.acquireTokenRedirect(redirectTokenRequest);
            return null;
         } else {
            handleError(err);
            return null;
         }
      }
   }, [account, authConfig.authority, authConfig?.requestScopes, authConfig.userEmail, handleError, msalInstance]);

   const resetPassword = React.useCallback(() => {
      const request = {
         authority: authConfig.resetPasswordAuthority,
         scopes: []
      };
      login(InteractionType.Redirect, request);
   }, [authConfig.resetPasswordAuthority, login]);

   const logOut = React.useCallback(async (): Promise<void> => {
      return msalInstance.logout().catch((logoutError) => handleError(logoutError));
   }, [handleError, msalInstance]);

   const authenticated = useIsAuthenticated();

   const contextValue = React.useMemo(
      () => ({
         getAccessToken,
         handleRedirectPromise: msalInstance.handleRedirectPromise,
         authenticated,
         resetPassword,
         logOut,
      }),
      [authenticated, getAccessToken, logOut, msalInstance.handleRedirectPromise, resetPassword],
   );

   return authenticated ? (
      <AuthenticationContext.Provider value={contextValue}>{props.children}</AuthenticationContext.Provider>
   ) : (
      <WaitingView message="Authenticating..." />
   );
};
