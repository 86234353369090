import React from "react";
import pdfFileIcon from "./PDF_file_icon.svg";

interface Props {
   size?: string;
}

export const PdfDocIcon: React.FunctionComponent<Props> = (props) => (
   <img
      alt="PDF doc icon"
      src={pdfFileIcon}
      style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }}
   />
);
