import {
   Grid,
   Button,
   Typography,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import useCurrentUser from "users/useCurrentUser";
import { Permissions } from "users";
import { makeStyles, createStyles } from '@mui/styles';
import { useTheme } from "@mui/styles";
import { MemberSearchClientFilters } from ".";
import { Formik, Form as FormikForm, Field as FormikField, FormikErrors, useFormik, FormikProvider} from "formik";
import { TextField as FmuiTextField } from "formik-mui";

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         "& > *": { maxWidth: "100%" },

         "&& .MuiFormControl-root": {
            "& button": {
               color: "#ededed"
            },
            "& .MuiInputBase-root": {
               backgroundColor: "#231f20 !important",
            },
            "& .MuiInputBase-input, & .MuiInputAdornment-root": {
               color: "#ededed",
            },
            "& .MuiFormLabel-root": {
               color: "#ededed",
               padding: "0 0em",
            },
            "& .MuiInputLabel-shrink": {
               backgroundColor: "#231f20",
               borderRadius: "5px",
               padding: "0 2px"
            }
         },
      },
      "memberSearch": {
         marginLeft: theme.spacing(1),
      },
   }),
);

interface Props {
   submitClientSearch: (values: MemberSearchClientFilters) => void;
   clearScreen: () => void;
}

const MemberSearchFilters: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const { user, userLoading, userHasPermission } = useCurrentUser();

   function validate(values: MemberSearchClientFilters) {
      const errors: FormikErrors<MemberSearchClientFilters> = {};
      if (values.lastName === "") { errors.lastName = "Cannot be blank" }
      if (values.sin.replace(/\D/g, '').length !== 9) { errors.sin = "Must be 9 digits" }
      return errors;
   }

   function clearFilters() {
      formik.resetForm();
      props.clearScreen();
   }

   const initialFormValues: MemberSearchClientFilters = {
      lastName: "",
      sin: ""
   }


   const formik = useFormik({
      initialValues: initialFormValues,
      validate, 
      enableReinitialize: false,
      onSubmit: (values) => {
         props.submitClientSearch( values );
         formik.setSubmitting(false);
      }
      
   });

   const handleLastNameChange = (event: any) => {
      const { value } = event.target;
      if (/^[a-zA-Z' ./\\-]*$/.test(value)) {
         formik.handleChange(event);
      }
   };

   const handleSINChange = (event: any) => {
      const { value } = event.target;
      if (value.replace(/\D/g, '').length <= 9) {
         formik.handleChange(event);
      }
   };

   function handleSINInput(input: string): string {
      let value = input.replace(/\D/g, '');
      if (value.length > 9) {
         value = value.slice(0, 9); // Limit to 9 digits
      }
      value = value.replace(/(.{3})/g, '$1 ').trim();
      return value;
   }


   return (
      <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>

         <Grid container direction="column" spacing={2} className={classes.root}>
            <Grid item>
               <Typography variant="h4" color="textSecondary">
                  Member search
               </Typography>
            </Grid>
            <Grid item>
               <FormikField
                  component={FmuiTextField}
                  name="lastName"
                  label="Subscriber/joint subscriber last name"
                  size="small"
                  fullWidth
                  onChange={handleLastNameChange}
                  autoComplete="off"
                  error={formik.touched.lastName && formik.errors.lastName !== undefined}
                  helperText={formik.touched.lastName ? formik.errors.lastName : undefined}
               />
            </Grid>
            <Grid item>
               <FormikField
                  component={FmuiTextField}
                  name="sin"
                  label="Subscriber/joint subscriber SIN"
                  size="small"
                  fullWidth
                  value={handleSINInput(formik.values.sin)}
                  autoComplete="off"
                  onChange={handleSINChange}
                  error={formik.touched.sin && formik.errors.sin !== undefined}
                  helperText={formik.touched.sin ? formik.errors.sin : undefined}
               />
            </Grid>

            <Grid xs={2} item>
               <Grid display="flex" justifyContent="flex-end">
                  <Button className={classes.memberSearch} variant="outlined" color="primary" onClick={() => clearFilters()}>
                     Clear
                  </Button>

                     <Button disabled={!userHasPermission(Permissions.SubmitMemberSearchQuery)} type="submit" className={classes.memberSearch} variant="contained" color="secondary" >
                     Search
                  </Button>
               </Grid>
            </Grid>

         </Grid>
         </form>
      </FormikProvider>
   )
};

export default MemberSearchFilters;