import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";
import { Doc } from "documents";

interface Props {
   handleClose: () => void;
   documentWithBrokenLink: Doc | null;
}

const ConfirmReportBrokenLinkDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const [submitting, setSubmitting] = useState(false);

   const [submitMutate] = useMutation<{ document: { reportBrokenLink: Doc } }, { documentId: number }>(
      gql`
         mutation ReportBrokenLink($documentId: Int!) {
            document {
               reportBrokenLink(documentId: $documentId) {
                  id
               }
            }
         }
      `,
   );

   async function submitReport() {
      setSubmitting(true);
      const result = await submitMutate({ variables: { documentId: props.documentWithBrokenLink!.id } });

      if (result.data?.document.reportBrokenLink?.id) {
         notifications.success("Submitted report. The administrators will be notified.");
      }

      setSubmitting(false);
      props.handleClose();
   }

   if (!props.documentWithBrokenLink) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>{`Report broken link?`}</DialogTitle>
         <DialogContent>
            <DialogContentText>Do you want to report a broken link for this document?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Submit" color="primary" inProgress={submitting} onClick={submitReport} />
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmReportBrokenLinkDialog;
