import {  gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
   Typography,
   Grid,
   Button,
   Box,
   Tabs,
   Tab,
   TextField,
   FormControlLabel,
   Switch,
   CircularProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState, } from "react";
import { Helmet } from "react-helmet";
import { Permissions } from "../users";
import useCurrentUser from "users/useCurrentUser";
import { makeStyles, createStyles } from '@mui/styles';
import MemberSearchFilters from "./MemberSearchFilters";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MaterialTable, { MTableBodyRow } from "@material-table/core";
import {
   MemberSearchClient,
   MemberSearchClientFilters,
   MemberSearchClientResults,
   MemberSearchInvestment,
   MemberSearchInvestmentsResults,
   MemberSearchHoldingsResults,
   MemberSearchTransactionsResults,
   MemberSearchRESPNotionalsResults,
   MemberSearchRDSPNotionalsResults

} from ".";
import { announcementStyles } from "../announcements/styles";
import { basicScreenStyle } from "../application/styles";
import DOMPurify from "dompurify";
import { HtmlPage } from "../pages/models";
import { useNotifications } from "../notifications";
import HtmlEditor from "../announcements/HtmlEditor";
import clsx from "clsx";
import { theme } from "../styles/theme";

const useStyles = makeStyles((theme) =>
   createStyles({
      ...basicScreenStyle(theme),
      progress: {
         width: "25px !important",
         height: "25px !important",
         marginRight: theme.spacing(1),
         color: '#00000099'
      },
      loadingLeft: {
         marginLeft: "-32px"
      },
      loading: {
      },
      actionButton: {
         alignSelf: "flex-end",
            marginTop: theme.spacing(1),
            marginBotom: theme.spacing(-4),
      },
      pageHeader: {
         marginBottom: theme.spacing(0),
      },
      pageHeaderHtml: {
         paddingLeft: theme.spacing(4),
         paddingRight: theme.spacing(4),
         height: "unset",
         "& h1, & h2, & h3": {
            marginTop: theme.spacing(-9),
            paddingTop: theme.spacing(9),
            fontWeight: "normal",
         },
      },

      components: {
         MuiSwitch: {
            styleOverrides: {
               switchBase: {
                  "&.Mui-checked": {
                     // Controls checked color for the thumb
                     color: "#c33991"
                  }
               },
            }
         },
      },
      noticePane: {
         flexGrow: 1,
         paddingTop: "1px"
      },

      root: {
         display: "flex",
      },
      "& .MuiTable-root": {
         width: "100%"
      },

      default: {
      },

      filters: {
         width: "25rem",
         flexShrink: 0,
         flexGrow: 0,
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         flexWrap: "wrap",
         "& > *:first-child": {
            marginRight: "auto",
         },
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            marginBottom: theme.spacing(1),
         },
      },

      searchResultsPane: {
         width: "100%",

         '@media print': {
            zoom: '75%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 999999,
            width: '100%',
            height: '100%',
            visibility: 'visible',
            overflow: 'visible',
            '& *': {
               visibility: 'visible',
            },
         },
      },
      clientsResultsPane: {
         flexGrow: 1,
         padding: theme.spacing(3),
         maxWidth: '1500px'
      },
      clientsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
      },
      investmentsResultsPane: {
         padding: theme.spacing(3),
         width: '100%',
         minWidth: '700px',
         maxWidth: '900px'
      },
      investmentsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
         '& > h5': {
            paddingBottom: theme.spacing(1)
         }
      },
      investmentsResultsSection: {
         paddingBottom: theme.spacing(2)
      },
      detailsResultsPane: {
         padding: theme.spacing(3),
         width: '100%',
         minWidth: '500px',
         maxWidth: '1100px'
      },
      holdingsTab: {

      },
      holdingsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
      },
      holdingsTitle: {
         paddingBottom: theme.spacing(1)
      },
      holdingsResultsSection: {
         paddingBottom: theme.spacing(2)
      },

      transactionsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
      },
      transactionsTitle: {
         paddingBottom: theme.spacing(1)
      },

      dateFilters: {
         marginBottom: theme.spacing(1),
      },

      transactionsResultsSection: {
         paddingBottom: theme.spacing(2)
      },

      amountCR: {
         color: "#33cc33 !important"
      },

      relatedClientsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
      },
      relatedClientsTitle: {
         paddingBottom: theme.spacing(1)
      },
      relatedClientsResultsSection: {
         paddingBottom: theme.spacing(2),
      },

      relatedClientsFlex: {
         margin: '0 auto',
         display: 'flex',
         flexWrap: 'wrap',
         alignContent: 'space-between',
         gap: '10px',
      },

      relatedClient: {
         border: '1px solid black',
         width: '500px',
         textAlign: 'left',
      },

      cf: {
         width: '100%',
         textAlign: 'left',
         margin: theme.spacing(1),
         alignItems: 'start',
      },


      cfrt: {
         fontWeight: 500
      },

      notionalsResults: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
      },
      notionalsTitle: {
         paddingBottom: theme.spacing(1)
      },
      notionalsResultsSection: {
         paddingBottom: theme.spacing(2),
         width: '650px'
      },

      notionalsFlex: {
         margin: '0 auto',
         marginTop: theme.spacing(1),
         display: 'flex',
         flexWrap: 'wrap',
         alignContent: 'space-between',
         gap: '10px',
      },

      notional: {
         border: '1px solid black',
         textAlign: 'left',
         width: '100%'
      },

      indent: {
         paddingLeft: theme.spacing(2),
      },

      nleft: {
         width: '100%',
         textAlign: 'left',
         margin: theme.spacing(1),
         alignItems: 'start',
      },

      nright: {
         width: '100%',
         textAlign: 'right',
         margin: theme.spacing(1),
         alignItems: 'end',
      },

      nfn: {
         fontWeight: 500
      },

      sortSelect: {
         marginRight: "auto",
      },
      resultList: {
         maxWidth: "40rem",
      },

   }),
);

export const MemberSearchScreen: React.FunctionComponent = () => {
   const classes = useStyles();

   const { user, userLoading, userHasPermission } = useCurrentUser();

   const notifications = useNotifications();
   const clientsTableRef = useRef<any>(null);

   const [showClients, setShowClients] = useState(false);
   const [clientsResults, setClientsResults] = useState<MemberSearchClientResults | undefined>(undefined);
   const [clientSelection, setClientSelection] = useState<MemberSearchClient | undefined>(undefined);

   const [showInvestments, setShowInvestments] = useState(false);
   const [investmentsResults, setInvestmentsResults] = useState<MemberSearchInvestmentsResults | undefined>(undefined);
   const [investmentSelection, setInvestmentSelection] = useState<MemberSearchInvestment | undefined>(undefined);

   const [showDetails, setShowDetails] = useState(false);
   const [selectedTab, setSelectedTab] = useState(0);
   const [holdingsResults, setHoldingsResults] = useState<MemberSearchHoldingsResults | undefined>(undefined);
   const [transactionsResults, setTransactionsResults] = useState<MemberSearchTransactionsResults | undefined>(undefined);
   const [showDatePickers, setShowDatePickers] = useState(false);
   const [transactionsDateFrom, setTransactionsDateFrom] = useState<Date | null | undefined>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
   const [transactionsDateTo, setTransactionsDateTo] = useState<Date | null | undefined>(new Date());

   const [relatedClientsResults, setRelatedClientsResults] = useState<MemberSearchClientResults | undefined>(undefined);
   const [respNotionalsResults, setRESPNotionalsResults] = useState<MemberSearchRESPNotionalsResults | undefined>(undefined);
   const [rdspNotionalsResults, setRDSPNotionalsResults] = useState<MemberSearchRDSPNotionalsResults | undefined>(undefined);

   function formatDate(inputDate: string): string {
      if (inputDate === "" || inputDate === undefined) {
         return "";
      }
      const [year, month, day] = inputDate.split('-').map(Number);
      if (year === undefined || month === undefined || day === undefined || month < 1 || month > 12 || day < 1 || day > 31) {
         return "";
      }
      const date = new Date(year, month - 1, day);
      const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const formattedDate = `${monthAbbreviations[date.getMonth()]}-${date.getDate().toString().padStart(2, '0')}-${year}`;
      return formattedDate;
   }

   function clearScreen() {
      setShowClients(false);
      setClientsResults(undefined);
      setClientSelection(undefined);

      setShowInvestments(false);
      setInvestmentsResults(undefined);
      setInvestmentSelection(undefined);

      setShowDetails(false);
      setSelectedTab(0);
      setHoldingsResults(undefined);
      setTransactionsResults(undefined);
      setShowDatePickers(false);
      setTransactionsDateFrom(undefined);
      setTransactionsDateTo(undefined);

      setRelatedClientsResults(undefined);
      setRDSPNotionalsResults(undefined);
      setRESPNotionalsResults(undefined);

   }

   const memberSearchClientQuery = gql`
         query MemberSearchClientResults($lastName: String!, $sin: String!) {
            memberSearchClientResults(lastName: $lastName, sin: $sin) {
               memberSearchClients {
                  clientNumber
                  firstName
                  lastName
                  phoneNumber
                  address
                  city
                  provinceState
                  mailCode
                  country
               }
            }
         }
      `;

   const [loadClients, { loading: clientsLoading, error: clientsError, data: clientsData }] = useLazyQuery(
      memberSearchClientQuery,
      {
         fetchPolicy: "network-only",
      }
   );


   function performClientSearch(filters:MemberSearchClientFilters) {
      clearScreen();
      setShowClients(true);
      loadClients({ variables: { lastName: filters.lastName, sin: filters.sin } }).then(response => setClientsResults(response.data.memberSearchClientResults));
   }


   const memberSearchInvestmentsQuery = gql`
         query MemberSearchInvestmentsResults($clientNumber: Int!) {
            memberSearchInvestmentsResults(clientNumber: $clientNumber) {
               memberSearchInvestments {
                  name
                  productCode
                  instrumentNumber
                  instrumentType
                  currentBalance
                  status
               }
            }
         }
      `;

   const [loadInvestments, { loading: investmentsLoading, error: investmentsError, data: investmentsData }] = useLazyQuery(
      memberSearchInvestmentsQuery,
      {
         fetchPolicy: "network-only",
      }
   );


   function performInvestmentsSearch(client: MemberSearchClient) {
      setClientSelection(client);
      setShowClients(false);
      setShowInvestments(true);
      loadInvestments({ variables: { clientNumber: client.clientNumber } }).then(response => setInvestmentsResults(response.data.memberSearchInvestmentsResults));
   }


   function exitInvestments() {
      setShowInvestments(false);
      setInvestmentsResults(undefined);
      setShowClients(true);
   }

   function loadTab(newValue: any) {
      switch (newValue) {
         case 0: // holdings
            if (investmentSelection !== undefined && !holdingsError && !holdingsLoading && ( holdingsResults == null || holdingsResults === undefined)) {
               loadHoldings({ variables: { instrumentNumber: investmentSelection.instrumentNumber } }).then(response => setHoldingsResults(response.data.memberSearchHoldingsResults));
            }
            break;
         case 1: // transactions
            if (investmentSelection !== undefined && !transactionsLoading && (transactionsResults == null || transactionsResults === undefined)) {
               setTransactionsDateFrom(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
               setTransactionsDateTo(new Date());
               loadTransactions({ variables: { accountNumber: investmentSelection.instrumentNumber } }).then(response => setTransactionsResults(response.data.memberSearchTransactionsResults));
            }
            break;
         case 2: // member info
            if (investmentSelection !== undefined && !relatedClientsLoading && (relatedClientsResults == null || relatedClientsResults === undefined)) {
               loadRelatedClients({ variables: { accountNumber: investmentSelection.instrumentNumber } }).then(response => setRelatedClientsResults(response.data.memberSearchRelatedClientsResults));
            }
            break;
         case 3: // notionals
            if (investmentSelection !== undefined) {
               if (investmentSelection.productCode === "DRP_RESP" || investmentSelection.productCode === "CUDP_RESP") {
                  if (!respNotionalsLoading && (respNotionalsResults == null || respNotionalsResults === undefined)) {
                     loadRESPNotionals({ variables: { accountNumber: investmentSelection.instrumentNumber } }).then(response => setRESPNotionalsResults(response.data.memberSearchRESPNotionalsResults));
                  }
               }
               if (investmentSelection.productCode === "DRP_RDSP" || investmentSelection.productCode === "CUDP_RDSP") {
                  if (!rdspNotionalsLoading && (rdspNotionalsResults == null || rdspNotionalsResults === undefined)) {
                     loadRDSPNotionals({ variables: { accountNumber: investmentSelection.instrumentNumber } }).then(response => setRDSPNotionalsResults(response.data.memberSearchRDSPNotionalsResults));
                  }
               }
            }
            break;
         default:
      }
      setSelectedTab(newValue);
   }


   function performDetailsSearch(investment: MemberSearchInvestment) {
      setInvestmentSelection(investment);
      setShowClients(false);
      setShowInvestments(false);
      setShowDetails(true);
      loadHoldings({ variables: { instrumentNumber: investment.instrumentNumber } }).then(response => setHoldingsResults(response.data.memberSearchHoldingsResults));
   }

   const memberSearchHoldingsQuery = gql`
         query MemberSearchHoldingsResults($instrumentNumber: Int!) {
            memberSearchHoldingsResults(instrumentNumber: $instrumentNumber) {
               memberSearchHoldings {
                  accountType
                  accountNumber
                  instrumentNumber
                  name
                  holdingName
                  assetType
                  maturityDate
                  principalAmount
                  currentBalance
                  purchaseDate
                  rate
                  holdingStatus
                  bookValue
               }
            }
         }
      `;

   const [loadHoldings, { loading: holdingsLoading, error: holdingsError, data: holdingsData }] = useLazyQuery(
      memberSearchHoldingsQuery,
      {
         fetchPolicy: "network-only",
      }
   );


   const memberSearchTransactionsQuery = gql`
         query MemberSearchTransactionsResults($accountNumber: Int!) {
            memberSearchTransactionsResults(accountNumber: $accountNumber) {
               memberSearchTransactions {
                  paidDate
                  description
                  transactionAmount
               }
            }
         }
      `;

   const [loadTransactions, { loading: transactionsLoading, error: transactionsError, data: transactionsData }] = useLazyQuery(
      memberSearchTransactionsQuery,
      {
         fetchPolicy: "network-only",
      }
   );

   const handleDateSwitchChange = (event:any) => {
      setShowDatePickers(event.target.checked);
   };   

   const filteredTransactionsData = useMemo(() => {
      if (transactionsResults === undefined) {
         return [];
      }
      if (!showDatePickers) {
         return transactionsResults.memberSearchTransactions;
      }

      return transactionsResults.memberSearchTransactions.filter(item => {
         const itemDate = new Date(item.paidDate);
         return (transactionsDateFrom === undefined || !transactionsDateFrom || itemDate >= transactionsDateFrom) && (transactionsDateTo === undefined || !transactionsDateTo || itemDate <= transactionsDateTo);
      });
   }, [showDatePickers, transactionsResults,transactionsDateFrom, transactionsDateTo]);


   const pageSizeOptions = filteredTransactionsData.length > 21 ? [5, 10, 20, filteredTransactionsData.length] : [5, 10, 20];

   const memberSearchRelatedClientsQuery = gql`
         query MemberSearchRelatedClientsResults($accountNumber: Int!) {
            memberSearchRelatedClientsResults(accountNumber: $accountNumber) {
               memberSearchClients {
                  clientNumber
                  firstName
                  lastName
                  sin
                  relationType
                  dateOfBirth
                  phoneNumber
                  address
                  address2
                  address3
                  city
                  provinceState
                  mailCode
                  country
               }
            }
         }
      `;

   const [loadRelatedClients, { loading: relatedClientsLoading, error: relatedClientsError, data: relatedClientsData }] = useLazyQuery(
      memberSearchRelatedClientsQuery,
      {
         fetchPolicy: "network-only",
      }
   );


   function sortRelatedClients(a: MemberSearchClient, b: MemberSearchClient) {
      var relations = ["SU", "OW", "JO", "PC", "EB", "PB"];
      var ar = relations.indexOf(a.relationType.toUpperCase());
      var br = relations.indexOf(b.relationType.toUpperCase());

      var comparison = (ar === -1 ? 100 : ar) - (br === -1 ? 100 : br);
      if (comparison === 0) {
         comparison = a.lastName.localeCompare(b.lastName);
         if (comparison === 0) {
            comparison = a.firstName.localeCompare(b.firstName);
         }
      }
      return comparison;
   }

   function convertRelationType(relationType: string) {
      var pc = investmentSelection !== undefined ? investmentSelection.productCode : "";
      var codeToStringMap = new Map([
         ["SU", "Primary Subscriber"],
         ["OW", "Primary Holder"],
         ["JO", pc === "CUDP_RDSP" || pc === "DRP_RDSP" ? "Joint Holder" : "Joint Subscriber"],
         ["PC", "Primary Caregiver"],
         ["EB", "Beneficiary"],
         ["PB", "Beneficiary"]
      ]);
      if (codeToStringMap.has(relationType.toUpperCase())) {
         return codeToStringMap.get(relationType);
      } else {
         return "";
      }
   }


   const memberSearchRESPNotionalsQuery = gql`
         query MemberSearchRESPNotionalsResults($accountNumber: Int!) {
            memberSearchRESPNotionalsResults(accountNumber: $accountNumber) {
               memberSearchNotionals {
                  name
                  currentBalance 
                  pre98Unassisted 
                  post97Unassisted
                  post97Assisted
                  cesgBalance
                  clbBalance
                  bctesgBalance
                  planIncome
                  inceptionDate
               }
            }
         }
      `;

   const [loadRESPNotionals, { loading: respNotionalsLoading, error: respNotionalsError, data: respNotionalsData }] = useLazyQuery(
      memberSearchRESPNotionalsQuery,
      {
         fetchPolicy: "network-only",
      }
   );

   const memberSearchRDSPNotionalsQuery = gql`
         query MemberSearchRDSPNotionalsResults($accountNumber: Int!) {
            memberSearchRDSPNotionalsResults(accountNumber: $accountNumber) {
               memberSearchNotionals {
                  contributionRoom    
                  contributionBalance 
                  grantCurrentBalance 
                  bondCurrentBalance  
                  ldapAnnualPayment   
                  dapAnnualPayment    
                  ldapAnnualNonTaxable
                  fairMarketValueToday
                  inceptionDate
                  minimumLdap
                  maximumLdap
                  maximumDapldap
                  availableDapldap
               }
            }
         }
      `;

   const [loadRDSPNotionals, { loading: rdspNotionalsLoading, error: rdspNotionalsError, data: rdspNotionalsData }] = useLazyQuery(
      memberSearchRDSPNotionalsQuery,
      {
         fetchPolicy: "network-only",
      }
   );

   function exitDetails() {
      setShowDetails(false);
      setInvestmentSelection(undefined);
      setHoldingsResults(undefined);
      setTransactionsResults(undefined);
      setShowDatePickers(false);
      setTransactionsDateFrom(undefined);
      setTransactionsDateTo(undefined);
      setRelatedClientsResults(undefined);
      setRESPNotionalsResults(undefined);
      setRDSPNotionalsResults(undefined);
      setSelectedTab(0);
      setShowInvestments(true);
   }

   const [htmlFragmentContent, setHtmlFragmentContent] = useState("");
   const [editingHtmlContentMode, setEditingHtmlContentMode] = useState(false);

   const htmlPageQuery = useQuery<{ htmlPage: HtmlPage }>(
      gql`
         query GetHtmlPage {
            htmlPage(name: "MemberSearch") {
               id
               name
               title
               htmlContent
            }
         }
      `,
      {
         fetchPolicy: "network-only",
         nextFetchPolicy: "network-only",
         notifyOnNetworkStatusChange: true,
      },
   );

   const htmlPage = htmlPageQuery.data?.htmlPage;

   useEffect(() => {
      setHtmlFragmentContent(htmlPage?.htmlContent ? DOMPurify.sanitize(htmlPage.htmlContent) : "");
   }, [htmlPage]);

   const userCanEditHtmlPages = userHasPermission(Permissions.EditHtmlPages);

   const [updateHtmlPageContentMutate] = useMutation<{ htmlPage: { updateHtmlContent: HtmlPage } }, { htmlPage: Partial<HtmlPage> }>(
      gql`
         mutation UpdateHtmlPageContent($htmlPage: HtmlPageInput!) {
            htmlPage {
               updateHtmlContent(htmlPage: $htmlPage) {
                  name
                  id
                  htmlContent
               }
            }
         }
      `,
   );

   async function saveHtmlContent() {
      const saveResult = await updateHtmlPageContentMutate({
         variables: { htmlPage: { name: htmlPage!.name, htmlContent: htmlFragmentContent } },
      });

      if (saveResult.data?.htmlPage.updateHtmlContent) {
         notifications.success("Html content updated.");
         console.log(htmlPage?.htmlContent);
      }
      
      setEditingHtmlContentMode(false);
   }

   async function cancelHtmlContent() {
      setEditingHtmlContentMode(false);
      setHtmlFragmentContent(htmlPage?.htmlContent ?? "");
   }

   function containsText(htmlString: string) {
      const textContent = htmlString.replace(/<[^>]*>/g, '');
      return textContent.trim().length > 0;
   }

   return userLoading && user != null ? (<></>) : (
      <>

         <div className={classes.noticePane}>
            <Grid container direction="column" spacing={3} alignItems="stretch">
               <Grid item container direction="column" spacing={3} xs={12} sm={10} md={8} lg={6}>
                  {htmlPage &&
                     (editingHtmlContentMode ? (
                        <Grid item container direction="column" spacing={1}>
                           <Grid item>
                              <HtmlEditor content={htmlFragmentContent} onChangeContent={setHtmlFragmentContent} />
                           </Grid>
                           <Grid item container direction="column" spacing={3} xs={12} sm={10} md={8} lg={6}>
                              <Grid item className={classes.actionButton}>
                                 <Button variant="contained" color="secondary" onClick={() => saveHtmlContent()}>
                                    Save
                                 </Button>
                              </Grid>

                              <Button className={classes.actionButton} color="primary" size="small" onClick={() => cancelHtmlContent()}>
                                 Cancel
                              </Button>
                           </Grid>
                        </Grid>
                     ) : (
                        <>
                           {containsText(htmlPage?.htmlContent) && (
                              <Grid item container className={classes.pageHeader} direction="column" spacing={1}>
                                 <Box
                                    sx={{
                                       width: '100%',
                                       backgroundColor: theme.palette.grey[300],
                                       display: 'flex',
                                    }}
                                 >
                                    <Grid
                                       item
                                       className={clsx("ql-editor", classes.pageHeaderHtml)}
                                       dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(htmlPage.htmlContent, { ADD_ATTR: ["target"] }),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              )}

                        {userCanEditHtmlPages && (
                           <Grid item container className={classes.pageHeader} direction="column" spacing={1}>
                              <Button
                                 className={classes.actionButton}
                                 color="primary"
                                 size="small"
                                 onClick={() => setEditingHtmlContentMode(true)}
                              >
                              Edit
                              </Button>
                           </Grid>
                        )}
                     </>
                  ))}
               </Grid>
            </Grid>
         </div>
      <div className={classes.root}>
         <Helmet>
            <title>Member Search - Concentra Partner Portal</title>
         </Helmet>

         

         <div className={classes.filters}>
            <MemberSearchFilters
               submitClientSearch={(filters: MemberSearchClientFilters) => performClientSearch(filters)}
               clearScreen={() => clearScreen()}
            />
         </div>
         <div className={classes.searchResultsPane}>
            {showClients && (
               <div className={classes.clientsResultsPane}>
                  <Grid container direction="column" spacing={3} alignItems="stretch">
                     <Grid item container justifyContent="space-between">
                           {clientsLoading && <div className={classes.loadingLeft}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                        {!clientsLoading && clientsError && <Typography variant="h4" color="textSecondary">Error... {clientsError.networkError != null ? clientsError.networkError.message : ""}</Typography>}
                        {!clientsLoading && !clientsError &&
                           <>
                              <Typography variant="h4" color="textSecondary">Results</Typography>
                              <Grid item className={classes.actions}>
                                 <Button variant="outlined" color="primary" onClick={() => window.print()}>
                                    Print
                                 </Button>
                              </Grid>
                           </>
                        }

                     </Grid>
                  </Grid>
                  {!clientsLoading && !clientsError && showClients && clientsResults != null && clientsResults !== undefined && (
                     <>
                        <div className={classes.clientsResults}>
                              <MaterialTable
                                 localization={{
                                    body: {
                                       emptyDataSourceMessage: (
                                          "No active plans or records to display"
                                       ),
                                    },
                                 }}
                              tableRef={clientsTableRef}
                              title=""
                              isLoading={clientsLoading}
                              columns={[
                                 {
                                    title: "Givenname",
                                    field: "firstName",
                                    editable: "never",
                                    sorting: false,
                                    width: '10%'
                                 },
                                 {
                                    title: "Surname",
                                    field: "lastName",
                                    editable: "never",
                                    sorting: false,
                                    width: '15%'
                                 },
                                 {
                                    title: "Address 1",
                                    field: "address",
                                    editable: "never",
                                    sorting: false
                                 },
                                 {
                                    title: "City",
                                    field: "city",
                                    editable: "never",
                                    sorting: false,
                                 },
                                 {
                                    title: "Province / State",
                                    field: "provinceState",
                                    editable: "never",
                                    sorting: false,
                                    width: '90px'
                                 },
                                 {
                                    title: "Postal / Zip Code",
                                    field: "mailCode",
                                    editable: "never",
                                    sorting: false,
                                    width: '100px'
                                 },
                                 {
                                    title: "Country",
                                    field: "country",
                                    editable: "never",
                                    sorting: false,
                                    width: '90px'
                                 },
                                 {
                                    title: "Phone #",
                                    field: "phoneNumber",
                                    editable: "never",
                                    sorting: false,
                                    width: '150px'
                                 },
                                 {
                                    title: "",
                                    field: "clientNumber",
                                    render: (rowData) => { return <Button variant="contained" color="primary" onClick={() => performInvestmentsSearch(rowData)} >Select</Button> },
                                    editable: "never",
                                    sorting: false,
                                    width: '100px' 
                                 }
                              ]}
                              data={clientsResults.memberSearchClients ?? []}
                              options={{
                                 filtering: false,
                                 search: false,
                                 paging: false,
                                 grouping: false,
                                 toolbar: false,
                                 columnsButton: false,

                              }}
                           />
                        </div>
                     </>
                  )}
               </div>
            )}
            {showInvestments && (
               <div className={classes.investmentsResultsPane}>
                  <Grid container direction="column" spacing={3} alignItems="stretch">
                     <Grid item container justifyContent="space-between">
                           {investmentsLoading && <div className={classes.loadingLeft}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                        {!investmentsLoading && investmentsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                        {!investmentsLoading && !investmentsError &&
                           <>
                           <Typography variant="h4" color="textSecondary">{clientSelection != null ? clientSelection.firstName + " " + clientSelection.lastName : ""}</Typography>
                              <Grid item className={classes.actions}>
                                 <Button variant="outlined" color="primary" onClick={() => window.print()}>
                                    Print
                                 </Button>
                              </Grid>
                           </>
                        }
                     </Grid>
                  </Grid>
                  {!investmentsLoading && !investmentsError && showInvestments && investmentsResults != null && investmentsResults !== undefined && (
                     <div className={classes.investmentsResults}>
                        <>
                           <Typography variant="h5" color="textSecondary">Investments</Typography>
                           {user.products.filter(p => p.hasMemberSearch && investmentsResults.memberSearchInvestments != null && investmentsResults.memberSearchInvestments.some(i=> i.productCode === p.code)).map(p => 
                              (
                                 <div className={classes.investmentsResultsSection}>
                                    <MaterialTable
                                       style={{ width: '100%', minWidth: '600px', maxWidth: '800px', tableLayout: "auto" }}
                                       key={p.code}
                                       title=""
                                       isLoading={investmentsLoading}
                                       columns={[
                                          {
                                          title: p.name,
                                             field: "name",
                                             editable: "never",
                                             sorting: false,
                                             width: '60%'
                                          },
                                          {
                                             title: "Status",
                                             field: "status",
                                             render: (rowData) => { return { 'A': 'Active', 'H': 'Hold', 'L': 'Locked' }[rowData.status.toUpperCase()] ?? "" },
                                             editable: "never",
                                             sorting: false,
                                             width: '10%'
                                          },
                                          {
                                             title: "$" + (Math.round(investmentsResults.memberSearchInvestments.filter(i => i.productCode === p.code).reduce(function (acc, obj) { return acc + (obj.currentBalance * 100); }, 0)) / 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })+" *",
                                             field: "currentBalance",
                                             render: (rowData) => { return "$" + rowData.currentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })+" *" },
                                             editable: "never",
                                             sorting: false,
                                             width: '15%',
                                             minWidth: 150,
                                             align: 'right'
                                          },
                                          {
                                             title: "",
                                             field: "instrumentNumber",
                                             render: (rowData) => { return <Button variant="contained" color="primary" onClick={() => performDetailsSearch(rowData)} >Select</Button> },
                                             editable: "never",
                                             sorting: false,
                                             width: '15%',
                                             align: 'right'
                                          }
                                       ]}
                                       data={investmentsResults.memberSearchInvestments.filter(i => i.productCode === p.code) ?? []}
                                       options={{
                                             draggable: false,
                                             filtering: false,
                                             search: false,
                                             paging: false,
                                             grouping: false,
                                             toolbar: false,
                                             columnsButton: false,
                                       }}
                                    />
                                 </div>
                              )
                           )}
                           <Grid container spacing={3} direction="column" >
                              <Grid item display="flex" justifyContent="space-between">
                                 <Typography variant="body2" color="textSecondary">
                                    * This amount represents the combined principal amount of all portfolio holdings.
                                 </Typography>
                                 <Button variant="outlined" color="primary" onClick={exitInvestments}>
                                    Back
                                 </Button>
                              </Grid>
                           </Grid>
                        </>
                     </div>
                  )}
               </div>
            )}
            {showDetails && (
               <div className={classes.detailsResultsPane}>
                  <Grid container direction="column" spacing={3} alignItems="stretch">
                     <Grid item container justifyContent="space-between">
                        <Typography variant="h4" color="textSecondary">{clientSelection != null ? clientSelection.firstName + " " + clientSelection.lastName : ""}</Typography>
                        <Grid item className={classes.actions}>
                           <Button variant="outlined" color="primary" onClick={() => window.print()}>
                              Print
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
                  
                  <div className={classes.holdingsResults}>
                     <>
                        <Grid container direction="column" spacing={3} alignItems="stretch" className={classes.holdingsTitle} >
                           <Grid item container justifyContent="space-between">
                              <Typography variant="h5" color="textSecondary">{investmentSelection != null ? investmentSelection.productCode.replace("_"," ") + " - " + investmentSelection.instrumentNumber + " " + investmentSelection.instrumentType : ""}</Typography>
                              <Typography variant="h5" color="textSecondary">{investmentSelection != null ? "$" + investmentSelection.currentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + " *" : ""}</Typography>
                           </Grid>
                        </Grid>

                        <Box>
                           <Tabs value={selectedTab} onChange={(e, newValue) => loadTab(newValue) } TabIndicatorProps={{
                              style: {
                                 backgroundColor: "#c33991"
                              }
                           }}>
                              <Tab label="Portfolio holdings" />
                              <Tab label="Transaction summary" />
                              <Tab label="Member information" />
                              <Tab label="Notional information" />
                           </Tabs>
                        </Box>


                        <Box p={2}>
                           {selectedTab === 0 && (
                              <div className={classes.holdingsResultsSection}>
                                 {holdingsLoading && <div className={classes.loading}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                                 {!holdingsLoading && holdingsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                                 {!holdingsLoading && !holdingsError && holdingsResults != null && holdingsResults !== undefined && (
                                    <>
                                       <MaterialTable
                                          style={{ width: '100%', minWidth: '500px', maxWidth: '1000px', tableLayout: "auto" }}
                                          title=""
                                          isLoading={holdingsLoading}
                                          columns={[
                                             {
                                                title: "Holding",
                                                field: "name",
                                                editable: "never",
                                                sorting: true,
                                                width: '18%'
                                             },
                                             {
                                                title: "Principal amount",
                                                field: "principalAmount",
                                                render: (rowData) => { return "$" + rowData.principalAmount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) },
                                                editable: "never",
                                                sorting: true,
                                                align: "right",
                                                width: '18%'
                                             },
                                             {
                                                title: "Current balance",
                                                field: "currentBalance",
                                                render: (rowData) => { return rowData.currentBalance == null || rowData.currentBalance === 0 ? "" : "$" + rowData.currentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) },
                                                editable: "never",
                                                sorting: true,
                                                align: "right",
                                                width: '18%'
                                             },
                                             {
                                                title: "Purchase date",
                                                field: "purchaseDate",
                                                editable: "never",
                                                sorting: true,
                                                align: "right",
                                                width: '15%',
                                                render: (rowData) => { return formatDate(rowData.purchaseDate); }
                                             },
                                             {
                                                title: "Maturity date",
                                                field: "maturityDate",
                                                editable: "never",
                                                sorting: true,
                                                defaultSort: "asc",
                                                align: "right",
                                                width: '15%',
                                                render: (rowData) => { return formatDate(rowData.maturityDate); }
                                             },
                                             {
                                                title: "Interest rate %",
                                                field: "rate",
                                                render: (rowData) => { return rowData.rate === 0 ? "" : rowData.rate.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 }) },
                                                editable: "never",
                                                sorting: true,
                                                align: "right",
                                                width: '16%'
                                             },
                                          ]}
                                          data={holdingsResults.memberSearchHoldings}
                                          options={{
                                             draggable: false,
                                             filtering: false,
                                             search: false,
                                             paging: false,
                                             grouping: false,
                                             toolbar: false,
                                             columnsButton: false,
                                          }}
                                       />
                                    </>
                                 )}
                              </div>
                           )}
                           {selectedTab === 1 && (
                              <div className={classes.transactionsResultsSection}>
                                 {transactionsLoading && <div className={classes.loading}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                                 {!transactionsLoading && transactionsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                                 {!transactionsLoading && !transactionsError && transactionsResults != null && transactionsResults !== undefined && (
                                    <>
                                       <Box className={classes.dateFilters}>
                                          <Grid gap={2} container>
                                             <Grid item>
                                             <FormControlLabel
                                                control={
                                                   <Switch
                                                      checked={showDatePickers}
                                                      onChange={handleDateSwitchChange}
                                                      color="primary"
                                                   />
                                                }
                                                label="Filter by date"
                                                labelPlacement="start"
                                                />
                                             </Grid>
                                             {showDatePickers && (
                                                <>
                                                   <Grid item>
                                                      <DatePicker
                                                         label="From date"
                                                         value={transactionsDateFrom}
                                                         onChange={(newValue) => setTransactionsDateFrom(newValue)}
                                                         renderInput={(params) => <TextField {...params} autoComplete="off" />}
                                                         inputFormat="MMM-dd-yyyy"

                                                         minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                                         maxDate={transactionsDateTo}
                                                      />
                                                   </Grid>
                                                   <Grid item>
                                                      <DatePicker
                                                         label="To date"
                                                         value={transactionsDateTo}
                                                         onChange={(newValue) => setTransactionsDateTo(newValue)}
                                                         renderInput={(params) => <TextField {...params} autoComplete="off" />}
                                                         inputFormat="MMM-dd-yyyy"
                                                         minDate={transactionsDateFrom}
                                                         maxDate={new Date()}
                                                      />
                                                   </Grid>
                                                </>
                                             )}
                                             </Grid>

                                       </Box>
                                       <Typography variant="body2" color="textSecondary">Please note that the transaction summary will display transactions for the last 365 days and the ability to filter by date within the last 365 days.</Typography>
                                       <MaterialTable
                                          style={{ width: '100%', minWidth: '500px', maxWidth: '1000px', tableLayout: "auto" }}
                                          title=""
                                          isLoading={transactionsLoading}
                                          columns={[
                                             {
                                                title: "Date",
                                                field: "paidDate",
                                                editable: "never",
                                                sorting: true,
                                                defaultSort: "desc",
                                                filtering: false,
                                                render: (rowData) => { return formatDate(rowData.paidDate); }
                                             },
                                             {
                                                title: "Description",
                                                field: "description",
                                                editable: "never",
                                                sorting: false,
                                                width: '25%',
                                                filtering: false
                                             },
                                             {
                                                title: "Amount",
                                                field: "transactionAmount",
                                                render: (rowData) => {
                                                   return <span className={rowData.transactionAmount > 0 ? classes.amountCR : classes.default} >{"$" + Math.abs(rowData.transactionAmount).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                                },
                                                editable: "never",
                                                sorting: false,
                                                align: "right",
                                                filtering: false
                                             },
                                             {
                                                title: "",
                                                field: "transactionAmount",
                                                render: (rowData) => { return rowData.transactionAmount > 0 ? <span className={classes.amountCR}>CR</span> : "" },
                                                editable: "never",
                                                sorting: false,
                                                align: "left",
                                                filtering: false,
                                                width: '10px',
                                             }
                                          ]}
                                          data={filteredTransactionsData}
                                          options={{
                                             draggable: false,
                                             filtering: false,
                                             search: false,
                                             grouping: false,
                                             toolbar: false,
                                             columnsButton: false,
                                             paging: true,
                                             pageSize: 10,
                                             pageSizeOptions: pageSizeOptions,
                                             emptyRowsWhenPaging: false
                                          }}
                                       />
                                    </>
                                 )}
                              </div>
                           )}
                           {selectedTab === 2 && (
                              <div className={classes.relatedClientsResultsSection}>
                                 {relatedClientsLoading && <div className={classes.loading}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                                 {!relatedClientsLoading && relatedClientsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                                 {!relatedClientsLoading && !relatedClientsError && relatedClientsResults != null && relatedClientsResults !== undefined && (
                                    <div className={classes.relatedClientsResults}>
                                       <div className={classes.relatedClientsFlex}>
                                       {relatedClientsResults.memberSearchClients.length > 0 ? (
                                             relatedClientsResults.memberSearchClients.slice().sort(sortRelatedClients).map((c) =>
                                                <Box className={classes.relatedClient}>
                                                <Grid container spacing={0}>
                                                   <Grid item container xs={8}>
                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf + ' ' + classes.cfrt}>{convertRelationType(c.relationType)}</div>
                                                         </Grid>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf}>{c.firstName} {c.lastName}</div>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf}>Date of birth:</div>
                                                         </Grid>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf}>{ formatDate(c.dateOfBirth) }</div>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf}>SIN:</div>
                                                         </Grid>
                                                         <Grid item container xs={6}>
                                                               <div className={classes.cf}>{c.sin.replace(/(.{3})/g, '$1 ').trim()}</div>
                                                         </Grid>
                                                      </Grid>
                                                   </Grid>
                                                   <Grid item container xs={4}>
                                                         {(c.address !== "" && <div className={classes.cf}>{c.address}</div>)}
                                                         {(c.address2 !== "" && <div className={classes.cf}>{c.address2}</div>)}
                                                         {(c.address3 !== "" && <div className={classes.cf}>{c.address3}</div>)}
                                                         <div className={classes.cf}>{c.city}, {c.provinceState}</div>
                                                         <div className={classes.cf}>{c.mailCode}</div>
                                                   </Grid>
                                                </Grid>
                                             </Box>
                                          )
                                       ) : (
                                          <Typography variant="body1">None</Typography>
                                       )}
                                       </div>
                                       
                                    </div>
                                 )}
                              </div>
                           )}
                           {selectedTab === 3 && investmentSelection != null && (investmentSelection.productCode === "CUDP_RESP" || investmentSelection.productCode === "DRP_RESP") && (
                              <div className={classes.notionalsResultsSection}>
                                 {respNotionalsLoading && <div className={classes.loading}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                                 {!respNotionalsLoading && respNotionalsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                                 {!respNotionalsLoading && !respNotionalsError && respNotionalsResults != null && respNotionalsResults !== undefined && (
                                    <div className={classes.notionalsResults}>
                                    <Grid container>
                                          <Grid item xs={6} ><Typography variant="h6" color="textSecondary">Beneficiary details</Typography></Grid>
                                       </Grid>
                                       <div className={classes.notionalsFlex}>
                                          {respNotionalsResults.memberSearchNotionals.length > 0 ? (
                                             respNotionalsResults.memberSearchNotionals.map((n) =>
                                                <Box className={classes.notional}>
                                                   <Grid container spacing={0}>
                                                      <Grid item container xs={12}>
                                                         <Grid item xs={8}>
                                                            <div className={classes.nleft + ' ' + classes.nfn}>{n.name}</div>
                                                         </Grid>
                                                      </Grid>

                                                      {n.name === "Plan Notional" && (
                                                         <Grid item container xs={12}>
                                                            <Grid item container xs={8}>
                                                               <div className={classes.nleft}>Plan inception:</div>
                                                            </Grid>
                                                            <Grid item container xs={4}>
                                                               <div className={classes.nright}>{formatDate(n.inceptionDate)}</div>
                                                            </Grid>
                                                         </Grid>
                                                      )}

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Contributions</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft + " " + classes.indent}>Contributions balance:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.currentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft + " " + classes.indent}>Pre 98 unassisted:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.pre98Unassisted.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft + " " + classes.indent}>Post 97 unassisted:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.post97Unassisted.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft + " " + classes.indent}>Post 97 assisted:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.post97Assisted.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>CESG:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.cesgBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>CLB:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.clbBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>BCTESG:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.bctesgBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      {n.planIncome != null && (
                                                         <Grid item container xs={12}>
                                                            <Grid item container xs={8}>
                                                               <div className={classes.nleft}>Plan income:</div>
                                                            </Grid>
                                                            <Grid item container xs={4}>
                                                               <div className={classes.nright}>{"$" + n.planIncome.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                            </Grid>
                                                         </Grid>
                                                      )}

                                                   </Grid>
                                                </Box>
                                             )
                                          ) : (
                                             <Typography variant="body1">None</Typography>
                                          )}
                                       </div>
                                    </div>
                                 )}
                              </div>
                           )}

                           {selectedTab === 3 && investmentSelection != null && (investmentSelection.productCode === "CUDP_RDSP" || investmentSelection.productCode === "DRP_RDSP") && (
                              <div className={classes.notionalsResultsSection}>
                                 {rdspNotionalsLoading && <div className={classes.loading}><Typography variant="h4" color="textSecondary"><CircularProgress className={classes.progress} />Loading...</Typography></div>}
                                 {!rdspNotionalsLoading && rdspNotionalsError && <Typography variant="h4" color="textSecondary">Error...</Typography>}
                                 {!rdspNotionalsLoading && !rdspNotionalsError && rdspNotionalsResults != null && rdspNotionalsResults !== undefined && (
                                    <div className={classes.notionalsResults}>
                                       <Grid container>
                                          <Grid item xs={6} ><Typography variant="h6" color="textSecondary">Plan details</Typography></Grid>
                                       </Grid>
                                       <div className={classes.notionalsFlex}>
                                          {rdspNotionalsResults.memberSearchNotionals.length > 0 ? (
                                             rdspNotionalsResults.memberSearchNotionals.map((n) =>
                                                <Box className={classes.notional}>
                                                   <Grid container spacing={0}>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Plan inception:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{formatDate(n.inceptionDate)}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Contribution room:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}> 
                                                            <div className={classes.nright}>{"$" + n.contributionRoom.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2 }) }</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Contribution balance:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.contributionBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Canada Disability Savings Grant Balance:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.grantCurrentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Canada Disability Savings Bond Balance:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.bondCurrentBalance.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Lifetime Disability Assistance Payment:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.ldapAnnualPayment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Disability Assistance Payment:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.dapAnnualPayment.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Non Taxable Lifetime Disability Assistance Payment:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.ldapAnnualNonTaxable.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>FMV Today:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.fairMarketValueToday.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Min LDAP:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.minimumLdap.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Max LDAP:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.maximumLdap.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Max DAP/LDAP:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.maximumDapldap.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                      <Grid item container xs={12}>
                                                         <Grid item container xs={8}>
                                                            <div className={classes.nleft}>Avail. DAP/LDAP:</div>
                                                         </Grid>
                                                         <Grid item container xs={4}>
                                                            <div className={classes.nright}>{"$" + n.availableDapldap.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                         </Grid>
                                                      </Grid>

                                                   </Grid>
                                                </Box>
                                             )
                                          ) : (
                                             <Typography variant="body1">None</Typography>
                                          )}
                                       </div>
                                    </div>
                                 )}
                              </div>
                           )}

                        </Box>

                        <Grid container spacing={3} direction="column" >
                           <Grid item display="flex" justifyContent="space-between">
                              <Typography variant="body2" color="textSecondary">
                                 * This amount represents the combined principal amount of all portfolio holdings.
                              </Typography>
                              <Button variant="outlined" color="primary" onClick={exitDetails}>
                                 Back
                              </Button>
                           </Grid>
                        </Grid>
                     </>
                  </div>
               </div>
            )}
         </div>
         </div>
      </>
   )
};
