import { gql } from "@apollo/client";
import { AccessReviewSchedule } from ".";
import { User } from "../users";

export const accessReviewPropertiesFragment = gql`
   fragment accessReviewProperties on AccessReview {
      id
      date
      user {
         id
         name
         email
      }
      organization {
         id
         code
         name
      }
      response
      alternateUser {
         id
         name
         email
      }
   }
`;

export interface AccessReviewUserOptionsQueryVariables { }

export interface AccessReviewUserOptionsQueryResult {
   users: User[];
}

export const AccessReviewUserOptionsQuery = gql`
   query GetAccessReviewUserOptions {
      users(includeAutomatedUser: false, includeAllOrganizations: false, includeWythUsers: false) {
         id
         name
         email
         organization {
            id
         }
         roles {
            id
            name
         }
      }
   }
`;


export const accessReviewSchedulePropertiesFragment = gql`
   fragment accessReviewScheduleProperties on AccessReviewSchedule {
      id
      date
      user {
         id
         name
         email
      }
   }
`;

export interface AccessReviewScheduleQueryResult {
   accessReviewSchedules: AccessReviewSchedule[];
}


export const organizationReviewStatusesPropertiesFragment = gql`
   fragment organizationReviewStatusesProperties on AccessReview {
      id
      date
      organization {
         id
         code
         name
      }
      response
   }
`;

export interface AccessReviewUserOptionsQueryVariables { }

export interface AccessReviewUserOptionsQueryResult {
   users: User[];
}