import { useQuery, gql } from "@apollo/client";
import { Grid,   Typography, Link } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { RouteNames } from "application/routes";
import { Program } from "programs";
import MainSearchBar from "./MainSearchBar";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import { MarketingAnnouncement, marketingAnnouncementPropertiesFragment } from "announcements";
import moment from "moment";
import MarketingAnnouncementCarousel from "announcements/MarketingAnnouncementCarousel";
import { Skeleton } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      searchBarContainer: {
         marginTop: theme.spacing(3),
         marginBottom: theme.spacing(6),
      },
      programGridContainer: {
         width: "100%",
         maxWidth: "1800px",
         flexWrap: "nowrap",
         padding: theme.spacing(3)
      },
      programGrid: {
         paddingLeft: theme.spacing(10),
         paddingRight: theme.spacing(10),
         display: "grid",
         gridGap: theme.spacing(10),
         alignItems: "start",
         justifyItems: "center",
         gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      },
      programName: {
         textAlign: "center",
      },

      programIcon: {
         height: "9em",
         width: "9em",
      },
      programLink: {
         display: "block",
         textDecoration: "none",
         "&:hover": {
            textDecoration: "none",
            color: "#c33991"
         },
      },
      
   }),
);

export const HomeScreen: React.FunctionComponent = () => {
   const classes = useStyles();

   const programQuery = useQuery<{ programs: Program[] }>(
      gql`
         query HomePrograms {
            programs {
               id
               name
               iconPath
            }
         }
      `,
   );

   const announcementQuery = useQuery<{ marketingAnnouncements: MarketingAnnouncement[] }, { currentDate: Date }>(
      gql`
         query GetMarketingAnnouncements($currentDate: DateTime!) {
            marketingAnnouncements(currentDate: $currentDate, includeExpired: false) {
               ...marketingAnnouncementProperties
            }
         }
         ${marketingAnnouncementPropertiesFragment}
      `,
      {
         variables: { currentDate: moment().startOf("day").toDate() },
      },
   );

   return (
      <div className={classes.root}>
         <Helmet>
            <title>Home - Concentra Partner Portal</title>
         </Helmet>
         <MarketingAnnouncementCarousel
            announcements={announcementQuery.data?.marketingAnnouncements ?? []}
            loading={announcementQuery.loading}
         />
         <Grid container direction="column" spacing={3} alignItems="center">
            <Grid item>

            </Grid>
            <Grid item className={classes.searchBarContainer}>
               <MainSearchBar />
            </Grid>
            <Grid container className={classes.programGridContainer} direction="column" spacing={6}>
               <Grid item>
                  <Typography variant="h3">Our Programs & Services</Typography>
               </Grid>

               <Grid item className={classes.programGrid}>
                  {programQuery.data
                     ? _.orderBy(programQuery.data.programs, (p) => p.name).map((p) => (
                          <div key={p.id}>
                           <Link className={classes.programLink} component={RouterLink} to={RouteNames.Program.replace(":id", p.id.toString())} color="inherit">
                                <Grid container direction="column" spacing={2} alignItems="center">

                                   <img
                                      className={classes.programIcon}
                                      src={
                                         p.iconPath ? `/assets/program-icons/${p.iconPath}.svg` : "/assets/program-icons/wythBankBuilding.svg"
                                      }
                                      alt={p.name}
                                 />
                                 <Typography variant="h5" className={classes.programName}>
                                    {p.name}
                                 </Typography>
                                </Grid>
                             </Link>
                          </div>
                       ))
                     : [...Array(10)].map((x, index) => <Skeleton key={index} variant="rectangular" width="12rem" height="12rem" />)}
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
};
