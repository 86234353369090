import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import NotificationProvider from "notifications/NotificationProvider";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { theme } from "styles/theme";
import "./App.scss";
import "./formats";
import { RoutesComponent } from "./routes";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthenticationProvider } from "auth/AuthenticationProvider";
import AppApolloProvider from "auth/AppApolloProvider";
import { SettingsProvider } from "application/SettingsProvider";
import { AppInsightsProvider } from "./AppInsightsProvider";
import { MsalProvider } from "auth/MsalProvider";
import { DocumentDownloadProvider } from "documents/DocumentDownloadProvider";
import CacheBuster from 'react-cache-buster';
import { Theme } from '@mui/material/styles';

// Insert a date parser that will parse date strings from the server into JavaScript dates
require("json.date-extensions"); // tslint:disable-line:no-var-requires
(JSON as any).useDateParser();

declare module '@mui/styles/defaultTheme' {
   interface DefaultTheme extends Theme { }
}

export const LastDocumentUpdateContext = React.createContext({
   lastDocumentUpdate: new Date(),
   setLastDocumentUpdate: (date: Date) => { },
});

const App: React.FunctionComponent<{}> = () => {
   const [lastDocumentUpdate, setLastDocumentUpdate] = useState(new Date());

   const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")!;
   return (
         <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
               <CssBaseline />
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SettingsProvider>
                     <AppInsightsProvider>
                        <MsalProvider>
                           <SnackbarProvider>
                              <LastDocumentUpdateContext.Provider
                                 value={{ lastDocumentUpdate, setLastDocumentUpdate: (date) => setLastDocumentUpdate(date) }}
                              >
                                 <BrowserRouter basename={baseUrl}>
                                    <NotificationProvider>
                                       <AuthenticationProvider>
                                          <AppApolloProvider>
                                             <DocumentDownloadProvider>
                                                <RoutesComponent />

                                             </DocumentDownloadProvider>
                                          </AppApolloProvider>
                                       </AuthenticationProvider>
                                    </NotificationProvider>
                                 </BrowserRouter>
                              </LastDocumentUpdateContext.Provider>
                           </SnackbarProvider>
                        </MsalProvider>
                     </AppInsightsProvider>
                  </SettingsProvider>
               </LocalizationProvider>
            </ThemeProvider>
         </StyledEngineProvider>
   );
};

export default App;
