import React from "react";
import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
} from "@mui/material";
import { useNotifications } from "notifications";
import { DocFilters } from "../search";
import { Tag, DocType } from "documents";
import { Product, ProductLink } from "products";
import SpinnerButton from "../SpinnerButton";
import { useLocation } from "react-router";
import { FormikErrors, FormikHelpers, Formik, Form as FormikForm, Field as FormikField } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { dialogStyle } from "application";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   searchTerms: string;
   filters: DocFilters;
   documentTypes: DocType[];
   tags: Tag[];
   products: Product[];

   handleClose: () => void;
}

interface FormValues {
   title: string;
}

const CreateProductLinkDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const location = useLocation();

   const initialFormValues: FormValues = {
      title: "",
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.title.trim() === "") {
         errors.title = "Enter a title for the link.";
      }

      if (values.title.length > 100) {
         errors.title = "Link titles cannot be longer than 100 characters.";
      }

      return errors;
   }

   const [createMutate] = useMutation<
      { productLink: ProductLink },
      {
         productLink: ProductLink;
      }
   >(
      gql`
         mutation CreateProductLink($productLink: ProductLinkInput!) {
            productLink {
               create(productLink: $productLink) {
                  id
               }
            }
         }
      `,
   );

   async function createProductLink(values: FormValues, actions: FormikHelpers<FormValues>) {
      const createResult = await createMutate({
         variables: {
            productLink: {
               title: values.title,
               query: location.search,
               productId: props.filters.productIds[0],
            },
         },
      });

      if (createResult.data?.productLink) {
         notifications.success("Product link created.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   const productsForLink = props.products.filter((p) => props.filters.productIds.some((pid) => pid === p.id));

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={createProductLink}>
            {(formikProps) => {
               return (
                  <>
                     <DialogTitle>Create product task</DialogTitle>
                     <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                           {`Create a link to the current search setup that will appear on the product screen for ${productsForLink
                              .map((p) => p.name)
                              .join(", ")}.`}
                        </DialogContentText>

                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="title"
                                       variant="outlined"
                                       size="small"
                                       label="Title"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                       error={formikProps.errors.title && formikProps.submitCount > 0}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label="Save"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default CreateProductLinkDialog;
