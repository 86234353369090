
import moment from "moment";

export const dateFormat = "YYYY-MM-DD";
export const longDateFormat = "MMMM D, YYYY";
export const dateWithTimeFormat = "h:mm a on YYYY-MM-DD";

moment.updateLocale("en", {
   longDateFormat: {
      LT: "h:mm a",
      LTS: "h:mm:ss a",
      L: dateFormat,
      l: "YYYY-M-D",
      LL: "MMMM Do YYYY",
      ll: "MMM D YYYY",
      LLL: "MMMM Do YYYY LT",
      lll: "MMM D YYYY LT",
      LLLL: "dddd, MMMM Do YYYY LT",
      llll: "ddd, MMM D YYYY LT",
   },
   calendar: {
      lastDay: "MMMM D",
      sameDay: "MMMM D",
      nextDay: "MMMM D",
      lastWeek: "MMMM D",
      nextWeek: "MMMM D",
      lastYear: "MMMM D",
      sameElse: "L",
   },
});

moment.calendarFormat = (myMoment: moment.Moment, now: moment.Moment) => {
   const differenceInDays = myMoment.diff(now, "days", true);
   return differenceInDays < -300
      ? "sameElse"
      : differenceInDays < -6
      ? "lastYear"
      : differenceInDays < -1
      ? "lastWeek"
      : differenceInDays < 0
      ? "lastDay"
      : differenceInDays < 1
      ? "sameDay"
      : differenceInDays < 2
      ? "nextDay"
      : differenceInDays < 7
      ? "nextWeek"
      : "sameElse";
};

export function utcToLocalDate(utcDate: Date | null) {
   return utcDate !== null ? moment.utc(utcDate).local(true).toDate() : null;
}

export function pickedDateToUtcMidnight(pickedDate: Date | null) {
   return pickedDate !== null ? moment(pickedDate).startOf("day").utc(true).toDate() : null;
}
