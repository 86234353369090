import React from "react";
import pptFileIcon from "./pptx_icon.svg";

interface Props {
   size?: string;
}

export const PowerPointDocIcon: React.FunctionComponent<Props> = (props) => (
   <img
      alt="PowerPoint doc icon"
      src={pptFileIcon}
      style={{ width: "1em", height: "1em", fontSize: props.size || "1.5rem" }}
   />
);
