import {  Grid, useMediaQuery, Typography, Box } from "@mui/material";
import React from "react";
import { Announcement } from "./models";
import AnnouncementTile from "./AnnouncementTile";
import _ from "lodash";
import { Skeleton } from "@mui/material";
import { useTheme } from "@mui/styles";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      announcement: {
         "&:not(:nth-child(2))": {
            paddingTop: theme.spacing(3),
         },
         "&:not(:last-child)": {
            paddingBottom: theme.spacing(3),
            borderWidth: "0 0 1px 0",
            borderStyle: "solid",
            borderColor: theme.palette.grey[500],
         },
      },
   }),
);

interface Props {
   announcements: Announcement[];
   loading: boolean;
   showApplicableProductsForProgramId?: number;
}

const AnnouncementStack: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const xl = useMediaQuery(theme.breakpoints.up("xl"));
   const lg = useMediaQuery(theme.breakpoints.up("lg"));
   const md = useMediaQuery(theme.breakpoints.up("md"));

   const setSize = xl ? 4 : lg ? 3 : md ? 2 : 1;
   const setIndexArray: number[] = [];
   for (let i = 0; i < setSize; i++) {
      setIndexArray.push(i);
   }

   const itemColumnSize = xl ? 3 : lg ? 4 : md ? 6 : 12;

   const orderedAnnouncements = _.orderBy(props.announcements, (a) => a.effectiveDate, "desc");
   const announcementSets: JSX.Element[] = [];

   for (let announcementIndex = 0; announcementIndex < orderedAnnouncements.length; announcementIndex += setSize) {
      announcementSets.push(
         <Grid key={announcementIndex} container spacing={3}>
            {[...Array(setSize)].map((x, setIndex) => (
               <Grid item key={setIndex} xs={itemColumnSize}>
                  {announcementIndex + setIndex < orderedAnnouncements.length ? (
                     <AnnouncementTile
                        announcement={orderedAnnouncements[announcementIndex + setIndex]}
                        showApplicableProductsForProgramId={props.showApplicableProductsForProgramId}
                     />
                  ) : null}
               </Grid>
            ))}
         </Grid>,
      );
   }

   const skeleton = (
      <Grid container direction="column" spacing={3}>
         {[...Array(3)].map((x, index) => (
            <Grid item key={index} xs={12}>
               <Skeleton variant="rectangular" width="100%" height="20rem" />
            </Grid>
         ))}
      </Grid>
   );

   return (
      <div>
         <Box mb={3}>
            <Typography variant="h6">Announcements</Typography>
         </Box>
         {props.loading ? (
            skeleton
         ) : orderedAnnouncements.length > 0 ? (
            orderedAnnouncements.map((a) => (
               <Box className={classes.announcement} key={a.id}>
                  <AnnouncementTile
                     key={a.id}
                     announcement={a}
                     showApplicableProductsForProgramId={props.showApplicableProductsForProgramId}
                  />
               </Box>
            ))
         ) : (
            <Typography>No current announcements.</Typography>
         )}
      </div>
   );
};

export default AnnouncementStack;
