import { useMutation, gql, useQuery } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, Grid,  Typography } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React, { useEffect, useState } from "react";
import { Organization, OrganizationPortfoliosInput } from "organizations";
import { makeStyles, createStyles } from '@mui/styles';
import { Permissions } from "users";
import useCurrentUser from "../users/useCurrentUser";
import { Portfolio, PortfolioInput } from "../portfolio/models";
import { FieldArray, Formik, Form as FormikForm, FormikErrors, FormikHelpers, Field as FormikField } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import ClosableDialogTitle from "../application/ClosableDialogTitle";
import SpinnerButton from "../SpinnerButton";
import { PortfolioProductOptionQueryResult, PortfolioProductOptionQueryVariables, PortfolioProductOptionsQuery } from "../portfolio/queries";
import { dialogStyle } from "../application";

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
      portfolioRow: {
         paddingTop: theme.spacing(1),
         paddingBottom: theme.spacing(1),
         borderBottom: '1px solid black'

      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      typeHeader: {
      }
   }),
);
interface Props {
   open: boolean;
   handleClose: (addedOrganizationId?: number) => void;
   organizationToEdit: Organization | null;
}



const UpdateOrganizationPortfolioDialog: React.FunctionComponent<Props> = (props) => {
   const { user, userHasPermission } = useCurrentUser();
   const classes = useStyles();
   const notifications = useNotifications();

   function currentUserCanManagePortfolios() {
      return user.isWyth && userHasPermission(Permissions.ManageOrganizations);
   } 

   const { loading, error, data } = useQuery<PortfolioProductOptionQueryResult, PortfolioProductOptionQueryVariables>(
      PortfolioProductOptionsQuery,
      {
         variables: {

         },
         fetchPolicy: "network-only"
      }
   );

   const dataLoaded =
      !error &&
      !loading &&
      props.organizationToEdit !== null
      ;

   const validProducts = dataLoaded ?
      _.orderBy(
         props.organizationToEdit!.products.filter((p) => p.usesCompanyNumber || p.usesAgentClientNumber),
         [(p) => p.code]
      ) : [];

   const organizationPortfolios = dataLoaded ?
      _.orderBy(
         props.organizationToEdit!.portfolios,
         (p) => [p.product.code]
      ) : [];

   interface PortfolioDialogInput {
      id: number;
      productId: number;
      productName: string;
      productCode: string;
      companyNumber: string;
      agentClientNumber: string;
      usesCompanyNumber: boolean;
      hasDefaultCompanyNumber: boolean;
      usesAgentClientNumber: boolean;
   }

   interface FormValues {
      portfolios: PortfolioDialogInput[];
      lastUpdated: null | string | Date;
   }

   const initialFormValues: FormValues = {
      portfolios: makeInitialFormValues(),
      lastUpdated: dataLoaded ? props.organizationToEdit!.lastUpdated : null
   };

   function makeInitialFormValues() {
      var pdi: PortfolioDialogInput[] = []; 
      validProducts.forEach((vp) => {
         var portfolios: (Portfolio | null)[] = [];
         portfolios = organizationPortfolios.filter(op => op.product.id === vp.id);
         if (portfolios.length === 0) {
            portfolios.push(null);
         }
         //portfolio can be null
         portfolios.forEach((portfolio) => {
            var cn = "";
            if (vp.usesCompanyNumber) {
               if (vp.hasDefaultCompanyNumber) {
                  cn = vp.defaultCompanyNumber ?? "";
               } else {
                  if (portfolio != null) {
                     cn = portfolio.companyNumber ?? "";
                  } else {
                     cn = "";
                  }
               }
            }
            var acn = "";
            if (vp.usesAgentClientNumber) {
               if (portfolio != null) {
                  acn = portfolio.agentClientNumber ?? "";
               } else {
                  acn = "";
               }
            }

            pdi.push({
               id: portfolio != null ? portfolio.id : 0,
               productId: vp.id,
               productName: vp.name,
               productCode: vp.code,
               usesCompanyNumber: vp.usesCompanyNumber ?? false,
               hasDefaultCompanyNumber: vp.hasDefaultCompanyNumber ?? false,
               usesAgentClientNumber: vp.usesAgentClientNumber ?? false,
               companyNumber: cn,
               agentClientNumber: acn
            });
         });
      });
      return pdi;
   }


   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};
      return errors;
   }

   function getOrgInputFromFormValues(values: FormValues) {
      var ps: PortfolioInput[] = [];
      values.portfolios.forEach((p) => {
         ps.push({
            id: p.id === 0 ? null : p.id,
            organizationId: props.organizationToEdit!.id,
            productId: p.productId,
            companyNumber: p.companyNumber,
            agentClientNumber: p.agentClientNumber
         });
      });
      return {
         id: props.organizationToEdit!.id,
         portfolios: ps,
         lastUpdated: values.lastUpdated
      };
   }

   const [updateMutate] = useMutation<{ organization: { updatePortfolios: Organization } }, { organization: OrganizationPortfoliosInput }>(
      gql`
         mutation UpdateOrganization($organization: OrganizationPortfoliosInput!) {
            organization {
               updatePortfolios(organization: $organization) {
                  id
                  portfolios {
                     id
                     organization {
                        id
                     }
                     product {
                        id
                     }
                     companyNumber
                     agentClientNumber
                  }
                  lastUpdated
               }
            }
         }
      `,
      {
         refetchQueries: ["GetOrganizationForEdit"],
      },
   );

   async function updateOrganization(values: FormValues, actions: FormikHelpers<FormValues>) {
    
      var editedOrganization = getOrgInputFromFormValues(values);

      const updateResult = await updateMutate({ variables: { organization: editedOrganization } });

      if (updateResult.data?.organization.updatePortfolios) {
         notifications.success("Organization portfolios updated.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) {
      return null;
   }

   if (!currentUserCanManagePortfolios()) {
      return null;
   }

   return (
      <Dialog
         open={props.open}
         maxWidth="sm"
         fullWidth={true}
         scroll="paper"
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
      >
         <Formik
            initialValues={initialFormValues}
            validate={validate}
            onSubmit={updateOrganization}
            enableReinitialize={true}
         >
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle title={"Edit portfolios"} handleClose={props.handleClose} />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    {dataLoaded ? (
                                       <div>
                                          <FieldArray
                                             name="portfolios"
                                             render={arrayHelpers => (
                                                <>
                                                   <Grid className={classes.portfolioRow} container spacing={1} xs={12}>
                                                      <Grid item xs><Typography variant="subtitle2" className={classes.typeHeader}>Product</Typography></Grid>
                                                      <Grid item xs><Typography variant="subtitle2" className={classes.typeHeader}>CompanyNumber</Typography></Grid>
                                                      <Grid item xs><Typography variant="subtitle2" className={classes.typeHeader}>AgentClientNumber</Typography></Grid>
                                                   </Grid>
                                                   {formikProps.values.portfolios.length > 0 && formikProps.values.portfolios.map((p, index) => (
                                                      <Grid className={classes.portfolioRow} container spacing={1} xs={12}>
                                                         <Grid item xs><Typography variant="subtitle2">{p.productName}</Typography></Grid>
                                                         <Grid item xs>
                                                            {(p.usesCompanyNumber &&
                                                               <FormikField
                                                                  name={`portfolios.${index}.companyNumber`}
                                                                  key={`portfolios.${index}.companyNumber`}
                                                                  component={FmuiTextField}
                                                                  color="primary"
                                                                  variant="outlined"
                                                                  size="small"
                                                                  disabled={p.hasDefaultCompanyNumber}
                                                               />
                                                            )}
                                                         </Grid>
                                                         <Grid item xs>
                                                            {(p.usesAgentClientNumber &&
                                                               <FormikField
                                                                  name={`portfolios.${index}.agentClientNumber`}
                                                                  key={`portfolios.${index}.agentClientNumber`}
                                                                  component={FmuiTextField}
                                                                  color="primary"
                                                                  variant="outlined"
                                                                  size="small"
                                                               />
                                                            )}
                                                         </Grid>
                                                      </Grid>
                                                   ))}
                                                </>
                                             )}
                                          />
                                       </div>
                                    ) : (
                                       <Typography variant="body1">Loading...</Typography>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={() => props.handleClose()}>Cancel</Button>
                        <SpinnerButton
                           label="Save"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => formikProps.submitForm()}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default UpdateOrganizationPortfolioDialog;
