import { useMutation, gql } from "@apollo/client";
import {  Grid,  Typography, TextField } from "@mui/material";
import {Button} from "@mui/material";
import {Dialog} from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React, { useState, useEffect } from "react";
import SpinnerButton from "SpinnerButton";
import { Listserv, ListservInput } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FormikErrors } from "formik";
import { AutocompleteRenderInputParams, TextField as FmuiTextField } from "formik-mui";

import { Product } from "products";
import ClosableDialogTitle from "application/ClosableDialogTitle";
import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import HtmlEditor from "announcements/HtmlEditor";
import { dialogStyle } from "application";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   handleClose: () => void;
   products: Product[];
}

interface FormValues {
   listName: string;

   title: string;
   products: Product[];
}

const CreateListservDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();

   const [htmlContent, setHtmlContent] = useState("");
   useEffect(() => {
      setHtmlContent("");
   }, [props.open]);

   const productOptions = _.orderBy(props.products, (p) => p.name.toLowerCase());

   const initialFormValues: FormValues = {
      title: "",
      listName: "",
      products: [],
   };

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.products.length === 0) {
         errors.products = "Select at least one product.";
      }

      if (values.listName.trim() === "") {
         errors.listName = "Enter the listserv's name.";
      }

      return errors;
   }

   const [createMutate] = useMutation<{ listserv: { create: Listserv } }, { listserv: ListservInput }>(
      gql`
         mutation CreateListserv($listserv: ListservInput!) {
            listserv {
               create(listserv: $listserv) {
                  id
                  listName
                  title
                  htmlContent
                  products {
                     id
                     name
                  }
               }
            }
         }
      `,
      {
         refetchQueries: ["GetListservsForListservScreen"],
      },
   );

   async function saveNewListserv(values: FormValues, actions: FormikHelpers<FormValues>) {
      const listserv: ListservInput = {
         listName: values.listName,
         title: values.title,
         htmlContent: htmlContent,
         productIds: values.products.map((p) => p.id),
      };

      const createResult = await createMutate({ variables: { listserv: listserv } });

      if (createResult.data?.listserv.create) {
         notifications.success("Listserv created.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={props.open}
         onClose={(event, reason) => {
         if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            props.handleClose();
         }
      }}
         maxWidth="sm" fullWidth={true} scroll="paper">
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={saveNewListserv}>
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle title="Add a listserv" handleClose={props.handleClose} />
                     <DialogContent className={classes.dialogContent}>
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="listName"
                                       variant="outlined"
                                       size="small"
                                       label="List Name"
                                       fullWidth
                                    />
                                 </Grid>
                              </Grid>
                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="title"
                                       variant="outlined"
                                       size="small"
                                       label="Title (optional)"
                                       fullWidth
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <HtmlEditor fieldLabel="Description" content={htmlContent} onChangeContent={setHtmlContent} />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="products"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={productOptions}
                                       getOptionLabel={(option: Product) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Product(s)"
                                             error={formikProps.touched.products && formikProps.errors.products !== undefined}
                                             helperText={formikProps.touched.products ? formikProps.errors.products : undefined}
                                          />
                                       )}
                                       renderOption={(props: any, product: Product) => (
                                          <div {...props} >
                                             <div>
                                             <Typography>{product.name}</Typography>
                                             <Typography variant="caption" color="textSecondary">
                                                {product.program.name}
                                                </Typography>
                                                </div>
                                          </div>
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label="Post"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => {
                              formikProps.submitForm();
                           }}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default CreateListservDialog;
