import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
} from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";

interface Props {
   open: boolean;
   handleClose: () => void;
}

const CreateAccessReviewScheduleDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const [confirming, setConfirming] = useState(false);

   const [createMutate] = useMutation(
      gql`
         mutation CreateAccessReviewSchedule {
            accessReviewSchedule {
               add {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetAccessReviews", "GetAccessReviewSchedules", "GetOrganizationReviewStatuses"],
         awaitRefetchQueries: true
      },
   );

   async function createAccessReviewSchedule() {
      setConfirming(true);

      const createResult = await createMutate();

      if (createResult.data?.accessReviewSchedule) {
         notifications.success("User access review cycle started.");
      }
      setConfirming(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>User access review schedule</DialogTitle>
         <DialogContent>
            <DialogContentText>The existing user access review cycle will end and a new cycle will begin. Do you wish to proceed?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton variant="contained" color="secondary" label="Start" inProgress={confirming} onClick={createAccessReviewSchedule} />
         </DialogActions>
      </Dialog>
   );
};

export default CreateAccessReviewScheduleDialog;
