import { useMutation } from "@apollo/client";
import { DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useState } from "react";
import { DocFilters } from "../search";
import { Alert, AlertInput } from "alerts";
import AlertTable from "./AlertTable";
import { Tag, DocType } from "documents";
import { Product } from "products";
import { User } from "../users";
import SpinnerButton from "../SpinnerButton";
import { Organization } from "organizations";
import { Program } from "programs";
import { UpsertAlertMutation } from "./queries";
import { Plan } from "../plan";

interface Props {
   open: boolean;
   matchPartialFileName: string;
   filters: DocFilters;
   documentTypes: DocType[];
   organizations: Organization[];
   users: User[];
   tags: Tag[];
   products: Product[];
   programs: Program[];
   plans: Plan[];

   handleClose: () => void;
}

const ConfirmCreateAlertDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const [saving, setSaving] = useState(false);

   const [createMutate] = useMutation<
      { alert: { upsert: Alert } },
      {
         alert: AlertInput;
      }
   >(UpsertAlertMutation);

   async function confirmCreate() {
      setSaving(true);

      const createResult = await createMutate({
         variables: {
            alert: {
               matchPartialFileName: props.matchPartialFileName,
               documentTypeIds: props.filters.documentTypeIds,
               organizationIds: props.filters.organizationIds,
               uploadedByUserIds: props.filters.uploadedByUserIds,
               sources: props.filters.sources,
               statuses: props.filters.statuses,
               tagIds: props.filters.tagIds,
               productIds: props.filters.productIds,
               programIds: props.filters.programIds,
               planIds: props.filters.planIds
            },
         },
      });

      if (createResult.data?.alert.upsert) {
         notifications.success("Alert confirmed.");
      }

      setSaving(false);
      props.handleClose();
   }

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{`Alert me when similar documents are posted`}</DialogTitle>
         <DialogContent>
            <DialogContentText>
               You will be notified by email when similar documents are posted that meet the following criteria:
            </DialogContentText>
            <AlertTable
               matchPartialFileName={props.matchPartialFileName}
               documentTypes={props.documentTypes.filter((dt) => props.filters.documentTypeIds.some((dtid) => dtid === dt.id))}
               organizations={props.organizations.filter((o) => props.filters.organizationIds.some((oid) => oid === o.id))}
               uploadedByUsers={props.users.filter((u) => props.filters.uploadedByUserIds.some((ubid) => ubid === u.id))}
               sources={props.filters.sources}
               statuses={props.filters.statuses}
               tags={props.tags.filter((t) => props.filters.tagIds.some((tid) => tid === t.id))}
               products={props.products.filter((p) => props.filters.productIds.some((pid) => pid === p.id))}
               programs={props.programs.filter((d) => props.filters.programIds.some((did) => did === d.id))}
               plans={props.plans.filter((d) => props.filters.planIds.some(pid => pid === d.id))}
            />
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Alert me" color="secondary" variant="contained" inProgress={saving} onClick={confirmCreate} />
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmCreateAlertDialog;
