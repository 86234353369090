import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import {   Grid, Typography, TextField, Button, IconButton } from "@mui/material";
import { Helmet } from "react-helmet";
import MaterialTable from "@material-table/core";
import { useNotifications } from "notifications";
import { Tag } from "documents";
import { Add as AddIcon } from "@mui/icons-material";
import MergeTagsDialog from "./MergeTagsDialog";
import useCurrentUser from "users/useCurrentUser";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > button:first-child": {
            marginRight: theme.spacing(3),
         },
      },
   }),
);

export const TagsScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { userLoading } = useCurrentUser();

   const [newTagValue, setNewTagValue] = useState("");
   const [mergingTags, setMergingTags] = useState(false);
   const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

   const tagsQuery = useQuery<{ tags: Tag[] }>(
      gql`
         query GetTagsForTagScreen {
            tags {
               id
               value
               usageCount
            }
         }
      `,
      {
         skip: userLoading,
      },
   );

   const [addMutate] = useMutation<{ tag: { add: Tag } }, { tag: Tag }>(
      gql`
         mutation AddTag($tag: TagInput!) {
            tag {
               add(tag: $tag) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetTagsForTagScreen"],
      },
   );

   async function addTag() {
      const addResult = await addMutate({ variables: { tag: { id: 0, value: newTagValue } } });

      if (addResult.data?.tag.add) {
         notifications.success("Tag added.");
      }
   }

   const [deleteMutate] = useMutation<{ tag: { delete: Tag } }, { id: number }>(
      gql`
         mutation DeleteTag($id: Int!) {
            tag {
               delete(id: $id) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetTagsForTagScreen"],
      },
   );

   async function deleteTag(tag: Tag) {
      const deleteResult = await deleteMutate({ variables: { id: tag.id } });

      if (deleteResult.data?.tag.delete) {
         notifications.success("Tag deleted.");
      }
   }

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`Tags - Concentra Partner Portal`}</title>
         </Helmet>

         <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between">
               <Grid item>
                  <Typography variant="h4">Tags</Typography>
               </Grid>
               <Grid item className={classes.actions}>
                  <Button variant="contained" color="secondary" onClick={() => setMergingTags(true)} disabled={selectedTags.length < 2}>
                     Merge selected...
                  </Button>
                  <TextField
                     variant="outlined"
                     size="small"
                     label="Add tag"
                     value={newTagValue}
                     onChange={(e) => setNewTagValue(e.target.value)}
                  />
                  <IconButton
                     onClick={() => addTag()}
                     disabled={newTagValue.trim() === "" || tagsQuery.data?.tags.some((t) => t.value === newTagValue.trim())}
                  >
                     <AddIcon />
                  </IconButton>
               </Grid>
            </Grid>
            <Grid item>
               <MaterialTable
                  title="Tags"
                  isLoading={tagsQuery.loading}
                  columns={[
                     {
                        title: "Tag",
                        field: "value",
                        customSort: (a, b) => a.value.localeCompare(b.value),
                        defaultSort: "asc",
                        editable: "never",
                     },
                     {
                        title: "Usages",
                        field: "usageCount",
                        editable: "never",
                        filtering: false,
                     },
                  ]}
                  data={
                     tagsQuery.data?.tags.map((t) => ({ ...t, tableData: { checked: selectedTags.some((st) => st.id === t.id) } })) ?? []
                  }
                  options={{
                     filtering: true,
                     search: false,
                     paging: false,
                     grouping: false,
                     toolbar: false,
                     selection: true,
                     selectionProps: (tag: Tag) => ({ color: "primary" }),
                  }}
                  onSelectionChange={(tags) => setSelectedTags(tags)}
                  editable={{
                     isEditable: (t) => false,
                     isDeletable: (t) => t.usageCount === 0,
                     onRowDelete: deleteTag,
                  }}
                  localization={{
                     body: {
                        deleteTooltip: "Delete tag",
                        editRow: {
                           deleteText: "Are you sure you want to delete this tag?",
                        },
                     },
                  }}
               />
            </Grid>
         </Grid>

         <MergeTagsDialog
            handleClose={(merged) => {
               setMergingTags(false);
               if (merged) setSelectedTags([]);
            }}
            open={mergingTags}
            tagsToMerge={selectedTags}
         />
      </div>
   );
};
