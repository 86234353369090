import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
export function documentListStyles(theme: Theme) {
   return createStyles({
      icon: {
         minWidth: 0,
         marginRight: theme.spacing(1),
      },
      details: {
         fontSize: "smaller",
         "& > *": {
            overflowX: "hidden",
            textOverflow: "ellipsis",
         },
      },
      documentDate: {
         color: theme.palette.text.primary,
      },
      documentAction: {
         top: "0.25rem",
         right: theme.spacing(-1),
         transform: "none",
      },
      documentText: {
         overflowX: "hidden",
      },
      identifier: {
         overflowX: "hidden",
         textOverflow: "ellipsis",
      },
      tagChip: {
         "& .MuiChip-label": {
            paddingLeft: "6px",
            paddingRight: "6px",
            fontSize: "0.65rem",
         },
         height: "20px",
         "&:not(:last-child)": {
            marginRight: theme.spacing(0.5),
         },
      },
      documentListItem: {
         borderStyle: "solid",
         borderTopWidth: "1px",
         borderBottomWidth: "1px",
         borderLeftWidth: "0px",
         borderRightWidth: "0px",
         borderColor: "transparent",
      },
      highlightedDocument: {
         borderColor: theme.palette.grey[500],
      },
   });
}
