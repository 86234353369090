import { useQuery, gql, useMutation } from "@apollo/client";
import {   Grid, Typography, TextField, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "users/useCurrentUser";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import { User, Permissions, UserGroup, UserGroupInput, AddUserGroupMutation } from "users";
import { UserGroupsTable } from "./UserGroupsTable";
import { useNotifications } from "../notifications";
import { Add as AddIcon } from "@mui/icons-material";

import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
   }),
);

export const UserGroupsScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { userHasPermission } = useCurrentUser();
   const [newUserGroupName, setNewUserGroupName] = useState("");

   const [addUserGroupMutate] = useMutation<{ userGroup: { addUserGroup: UserGroup } }, { userGroup: UserGroupInput }>(AddUserGroupMutation, {
      refetchQueries: ["GetAllUserGroups"],
   });

   async function addUserGroup() {
      const addResult = await addUserGroupMutate({ variables: { userGroup: { groupName: newUserGroupName, userIds: [] } } });

      if (addResult.data?.userGroup.addUserGroup) {
         notifications.success("UserGroup added.");
      } 
   }

   const optionsQuery = useQuery<{ users: User[] } >(
      gql`
         query GetUsersGroupScreenOptions {
             users(includeAllOrganizations: true, includeWythUsers: true) {
               id
               name
               email
               isPrimary
               userGroupId
               organization {
                  id
                  name
                  code
               }
            }
         }
      `,
   );

   const availableUsers =
      _.orderBy(optionsQuery.data?.users.filter(u => !u.isInactive));

   const userGroupsQuery = useQuery<{ userGroups: UserGroup[] }>(
      gql`
         query GetAllUserGroups {
            userGroups {
               id
               groupName
               users {
                  id
                  name
                  email
                  isPrimary
                  userGroupId
                  organization {
                     id
                     name
                     code
                  }
               }
            }
         }
      `,
      {
         skip: !userHasPermission(Permissions.ViewUsersInAnyOrganization),
      },
   );
   const userGroups = userGroupsQuery.data?.userGroups ?? [];

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`Users - Concentra Partner Portal`}</title>
         </Helmet>

         <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between">
               <Grid item>
                  <Typography variant="h4">User Groups</Typography>
               </Grid>
               <Grid item className={classes.actions}>
               <TextField
                  variant="outlined"
                  size="small"
                  label="Add user group"
                  value={newUserGroupName}
                  onChange={(e) => setNewUserGroupName(e.target.value)}
               />
               <IconButton
                  onClick={() => addUserGroup()}
                  disabled={newUserGroupName.trim() === ""}
               >
                  <AddIcon />
               </IconButton>
               </Grid>
            </Grid>

            <Grid item container spacing={3}>
               <Grid item xs={12}>
                  {userGroupsQuery.loading && (
                     <Grid item>
                        <Grid container spacing={2} direction="column">
                           {[...Array(12)].map((x, index) => (
                              <Grid item key={index}>
                                 <Skeleton variant="rectangular" width="100%" height="2rem" />
                              </Grid>
                           ))}
                        </Grid>
                     </Grid>
                  )}

                  <Grid container direction="column" spacing={3}>
                     {userGroups.length > 0 && (
                        <Grid item>
                           <UserGroupsTable
                              userGroups={userGroups}
                              loading={userGroupsQuery.loading}
                              getAvailableUsers={availableUsers}
                           />
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

      </div>
   );
};
