import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { MarketingAnnouncement } from "./models";
import Carousel from "react-material-ui-carousel";
import _ from "lodash";
import { Skeleton } from "@mui/material";
import MarketingAnnouncementDisplay from "./MarketingAnnouncementDisplay";
import json2mq from "json2mq";
import { drawerWidth } from "application/Layout";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      set: {
         display:"flex",
         justifyContent: "center",
      },
      panel: {
         overflow: "hidden",
         padding: `0 ${theme.spacing(1)}`,
      },
      navButton: {
         // Determined empirically
         top: "calc(50% - 36px) !important",
      },
   }),
);

interface Props {
   announcements: MarketingAnnouncement[];
   loading: boolean;
}

const MarketingAnnouncementCarousel: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   const panelWidth = 480;
   const gap = 24;

   function getMinWidthMediaQuery(size: number) {
      return json2mq({ minWidth: size });
   }

   const breakpoint4 = useMediaQuery(getMinWidthMediaQuery(panelWidth * 4 + gap * 5 + drawerWidth));
   const breakpoint3 = useMediaQuery(getMinWidthMediaQuery(panelWidth * 3 + gap * 4 + drawerWidth));
   const breakpoint2 = useMediaQuery(getMinWidthMediaQuery(panelWidth * 2 + gap * 3 + drawerWidth));

   const setSize = breakpoint4 ? 4 : breakpoint3 ? 3 : breakpoint2 ? 2 : 1;
   const setIndexArray: number[] = [];
   for (let i = 0; i < setSize; i++) {
      setIndexArray.push(i);
   }

   const itemColumnSize = breakpoint4 ? 3 : breakpoint3 ? 4 : breakpoint2 ? 6 : 12;

   const orderedAnnouncements = _.orderBy(props.announcements, (a) => a.effectiveDate, "desc");
   const announcementSets: JSX.Element[] = [];

   for (let announcementIndex = 0; announcementIndex < orderedAnnouncements.length; announcementIndex += setSize) {
      announcementSets.push(
         <div key={announcementIndex} className={classes.set}>
            {[...Array(setSize)].map((x, setIndex) =>
               announcementIndex + setIndex < orderedAnnouncements.length ? (
                  <div key={setIndex} className={classes.panel}>
                     <MarketingAnnouncementDisplay announcement={orderedAnnouncements[announcementIndex + setIndex]} />
                  </div>
               ) : null,
            )}
         </div>,
      );
   }

   const skeleton = (
      <Grid container spacing={3}>
         {[...Array(setSize)].map((x, index) => (
            <Grid item key={index} xs={itemColumnSize}>
               <Skeleton variant="rectangular" width="100%" height="15rem" />
            </Grid>
         ))}
      </Grid>
   );

   return props.loading ? (
      skeleton
   ) : (
      <Carousel
         key={Math.random()}
         interval={10000}
         navButtonsProps={{
            className: classes.navButton,
            style: {},
         }}
      >
         {announcementSets}
      </Carousel>
   );
};

export default MarketingAnnouncementCarousel;
