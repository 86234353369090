import {   Grid, Box, Typography } from "@mui/material";
import React from "react";
import _ from "lodash";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { AccessReviewSchedule } from "./models";
import { makeStyles, createStyles } from '@mui/styles';


const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      scrollList: {
         overflowY: 'scroll',
         maxHeight: '9.4em',
         //WebkitMaskImage: "linear-gradient(to bottom, black 80px, transparent 120px)"
      }
   }),
);

interface Props {
   accessReviewSchedules: AccessReviewSchedule[]; 
}

export const AccessReviewScheduleTable: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   const tableData = props.accessReviewSchedules ?? [];

   return (
      <MaterialTable
         title=""
         components={{
            Toolbar: toolbarProps => (
               <Box p={2}>
                  <Grid container justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h6">Access review start dates</Typography>
                     </Grid>

                     <Grid item className={classes.actions}>
                        <MTableToolbar {...toolbarProps} />
                     </Grid>
                  </Grid>
               </Box>
            )
         }}
         columns={[
            {
               title: "Start date",
               field: "date",
               type: "datetime",
               filtering: false,
               sorting: true,
               defaultSort: "desc",
               width: "16em",
            },

            {
               title: "Initiator",
               field: "user",
               filtering: true,
               sorting: true,
               width: "16em",
               render: (row => row.user?.name ?? "")
            },

         ]}

         data={tableData ?? []}
         options={{
            filtering: true,
            search: false,
            paging: true,
            grouping: false,
            toolbar: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100]
         }}

      />
   );
}