import { gql } from "@apollo/client";

export const announcementPropertiesFragment = gql`
   fragment announcementProperties on Announcement {
      id
      htmlContent
      title
      effectiveDate
      products {
         id
         name
         programId
      }
      jurisdictions {
         id
         name
      }
   }
`;

export const editAnnouncementPropertiesFragment = gql`
   fragment editAnnouncementProperties on Announcement {
      ...announcementProperties
      expiryDate
      organizationId
      organization {
         id
         name
      }
   }
   ${announcementPropertiesFragment}
`;

export const marketingAnnouncementPropertiesFragment = gql`
   fragment marketingAnnouncementProperties on MarketingAnnouncement {
      id
      title
      body
      link
      encodedImage
      effectiveDate
      invertText
   }
`;

export const editMarketingAnnouncementPropertiesFragment = gql`
      fragment editMarketingAnnouncementProperties on MarketingAnnouncement {
         ...marketingAnnouncementProperties
         expiryDate
      }
      ${marketingAnnouncementPropertiesFragment}
   `;
