import { useQuery, gql } from "@apollo/client";
import {   Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useCurrentUser from "users/useCurrentUser";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import { User, Role, Permissions } from "users";
import { UsersTable } from "./UsersTable";
import AddUserDialog from "./AddUserDialog";
import { WythOrgCode, Organization } from "organizations";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
   }),
);

export const UsersScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const { userHasPermission } = useCurrentUser();
   const userCanManageUsersInAnyOrganization = userHasPermission(Permissions.ManageUsersInAnyOrganization);
   const [includeInactiveUsers, setIncludeInactiveUsers] = useState(false);

   const [addingUser, setAddingUser] = useState(false);

   const optionsQuery = useQuery<{ organizations: Organization[]; roles: Role[] }, { includeInactive: boolean }>(
      gql`
         query GetUsersScreenOptions {
            organizations(includeWyth: true, includeInactive: false) {
               id
               name
               code
               emailDomain
               alternateEmailDomains
               isWyth
               lastUpdated
               products {
                  id
                  name
                  program {
                     id
                     name
                  }
               }
            }
            roles {
               id
               name
               type
            }
         }
      `,
   );

   const organizations =
      _.orderBy(optionsQuery.data?.organizations, (o) => (o.code === WythOrgCode ? "!" : o.name.toLowerCase())) ?? [];

   const roles = optionsQuery.data?.roles ?? [];

   const usersQuery = useQuery<{ users: User[] }, { includeInactive: boolean }>(
      gql`
         query GetAllUsers($includeInactive: Boolean!) {
            users(includeAllOrganizations: true, includeWythUsers: true, includeInactive: $includeInactive) {
               id
               organizationId
               organization {
                  id
                  name
                  code
               }
               name
               email
               isPrimary
               isWyth
               lastUpdated
               lastSignin
               products {
                  id
                  name
               }
               resourceProducts {
                  id
                  name
               }
               roles {
                  id
                  name
               }
               isAutomationUser
               isInactive
            }
         }
      `,
      {
         skip: !userHasPermission(Permissions.ViewUsersInAnyOrganization),
         variables: {
            includeInactive: includeInactiveUsers,
         },
      },
   );
   const users = usersQuery.data?.users ?? [];

   return (
      <div className={classes.root}>
         <Helmet>
            <title>{`Users - Concentra Partner Portal`}</title>
         </Helmet>

         <Grid container direction="column" spacing={3}>
            <Grid item container justifyContent="space-between">
               <Grid item>
                  <Typography variant="h4">Users</Typography>
               </Grid>
            </Grid>

            <Grid item container spacing={3}>
               <Grid item xs={12}>
                  {usersQuery.loading && (
                     <Grid item>
                        <Grid container spacing={2} direction="column">
                           {[...Array(12)].map((x, index) => (
                              <Grid item key={index}>
                                 <Skeleton variant="rectangular" width="100%" height="2rem" />
                              </Grid>
                           ))}
                        </Grid>
                     </Grid>
                  )}

                  <Grid container direction="column" spacing={3}>
                     {users.length > 0 && (
                        <Grid item>
                           <UsersTable
                              users={users}
                              loading={usersQuery.loading}
                              showOrganization={true}
                              showIsPrimaryColumn={userCanManageUsersInAnyOrganization}
                              addUser={() => setAddingUser(true)}
                              getAvailableProductsForUser={(u) => (organizations.filter((o) => o.id == u.organizationId))[0]?.products}
                              roles={roles}
                              setIncludeInactiveUsers={setIncludeInactiveUsers}
                              includeInactiveUsers={includeInactiveUsers}
                           />
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <AddUserDialog
            open={addingUser}
            handleClose={() => setAddingUser(false)}
            organization={null}
            organizations={organizations}
            roles={roles}
            includeIsPrimaryCheckbox={userCanManageUsersInAnyOrganization}
         />
      </div>
   );
};
