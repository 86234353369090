import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { filter as gqlFilter } from "graphql-anywhere";
import { DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";
import { Doc } from "documents";
import { BatchUpdateDocumentStatusMutation, documentInputFragment } from "./queries";
import { DocStatus } from "./models";
import StatusSelect from "./StatusSelect";

interface Props {
   open: boolean;
   documents: Doc[];
   handleClose: () => void;
}

const BulkUpdateDocumentStatusDialog: React.FunctionComponent<Props> = (props) => {
   const { open, documents } = props;

   const { success } = useNotifications();
   const [saving, setSaving] = useState(false);
   const [selectedStatus, setSelectedStatus] = useState<DocStatus>();

   const [batchUpdateDocumentStatus] = useMutation<{ document: { batchUpdateStatus: Doc[] } }, { documents: Doc[] }>(
      BatchUpdateDocumentStatusMutation,
      {},
   );

   async function update() {
      if (!!selectedStatus) {
         setSaving(true);
         // filter to documents that don't already have the status since we don't need
         // to unnecessarily update the ones that already match...
         const docsNotMatchingTargetStatus = documents.filter((d) => d.status !== selectedStatus);
         const editedDocuments: Doc[] = docsNotMatchingTargetStatus.map((d) => {
            return {
               ...gqlFilter(documentInputFragment, d),
               status: selectedStatus,
            };
         });

         const result = await batchUpdateDocumentStatus({
            variables: {
               documents: editedDocuments,
            },
         });

         if (result.data?.document.batchUpdateStatus?.length) {
            success("Updated document statuses.");
         }

         setSaving(false);
         props.handleClose();
      }
   }

   return (
      <Dialog open={open} onClose={props.handleClose}>
         <DialogTitle>Bulk update document status</DialogTitle>
         <DialogContent>
            <DialogContentText>All documents matching the current search filter, and which you have permission to edit, will be updated to the following status:</DialogContentText>
            <StatusSelect value={selectedStatus} onChange={(value) => setSelectedStatus(value)} />
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton label="Update" color="secondary" variant="contained" inProgress={saving} onClick={update} disabled={!selectedStatus} />
         </DialogActions>
      </Dialog>
   );
};

export default BulkUpdateDocumentStatusDialog;
