import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
   DialogContentText,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   Grid,
   
   
} from "@mui/material";
import { useNotifications } from "notifications";
import SpinnerButton from "../SpinnerButton";

interface Props {
   open: boolean;
   handleClose: () => void;
}

const EmailAccessReviewDialog: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const [confirming, setConfirming] = useState(false);

   const [emailMutate] = useMutation(
      gql`
         mutation EmailAccessReviewNotice {
            accessReviewSchedule {
               email {
                  id
               }
            }
         }
      `,
      {

      },
   );

   async function emailAccessReviewNotices() {
      setConfirming(true);

      const emailResult = await emailMutate();

      if (emailResult.data?.accessReviewSchedule) {
         notifications.success("User access review notifications emailed.");
      }
      setConfirming(false);
      props.handleClose();
   }

   if (!props.open) return null;

   return (
      <Dialog open={true} onClose={props.handleClose}>
         <DialogTitle>User access review notifications</DialogTitle>
         <DialogContent>
            <DialogContentText>This will notify ALL partner administrators by email to complete a user access review. Do you wish to proceed?</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <SpinnerButton variant="contained" color="secondary" label="Start" inProgress={confirming} onClick={emailAccessReviewNotices} />
         </DialogActions>
      </Dialog>
   );
};

export default EmailAccessReviewDialog;
