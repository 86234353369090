import React, { useState } from "react";
import { Doc } from "documents";
import UpsertDocumentDialog from "documents/UpsertDocumentDialog";
import DeleteDocumentDialog from "documents/DeleteDocumentDialog";
import DocumentActionMenu from "documents/DocumentActionMenu";
import { useMutation, gql } from "@apollo/client";
import { Favourite, userCanEditDocument, userCanDeleteDocument } from "users";
import { useNotifications } from "notifications";
import useCurrentUser from "../users/useCurrentUser";
import { useLocation, useNavigate } from "react-router";

export const ActiveDocumentContext = React.createContext({
   setMenuAnchorEl: (el: null | HTMLElement) => { },
   setActiveDoc: (document: Doc) => { },
});

type Props = React.PropsWithChildren<{}>;

const GlobalDocumentActions: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const { user } = useCurrentUser();

   const [activeDoc, setActiveDoc] = useState<Doc | null>(null);
   const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

   const [editingDocId, setEditingDocId] = useState<number | null>(null);
   const [deletingDoc, setDeletingDoc] = useState<Doc | null>(null);

   const [favouriteMutate] = useMutation<{ favourite: { create: Favourite } }, { documentId: number }>(
      gql`
         mutation AddToFavourites($documentId: Int!) {
            favourite {
               create(documentId: $documentId) {
                  id
               }
            }
         }
      `,
      {
         refetchQueries: ["GetCurrentUser"],
      },
   );
   const location = useLocation();
   const isPartnerSpace = location.pathname == "/PartnerSpace";
   function handleMenuClose() {
      setMenuAnchorEl(null);
      setActiveDoc(null);
   }

   const addToFavourites =
      activeDoc !== null && user && user.favourites.every((f) => f.document.id !== activeDoc.id)
         ? async () => {
            const result = await favouriteMutate({ variables: { documentId: activeDoc!.id } });
            if (result.data?.favourite?.create?.id) {
               notifications.success("Added favourite.");
            }
         }
         : undefined;

   return (
      <>
         <ActiveDocumentContext.Provider value={{ setMenuAnchorEl, setActiveDoc }}>{props.children}</ActiveDocumentContext.Provider>
         <DocumentActionMenu
            getDocument={() => activeDoc!}
            menuAnchorEl={menuAnchorEl}
            handleMenuClose={handleMenuClose}
            editDocument={() => setEditingDocId(activeDoc!.id)}
            userCanEdit={() => (user && activeDoc ? userCanEditDocument(user, activeDoc) : false)}
            addToFavourites={addToFavourites}
            deleteDocument={() => setDeletingDoc(activeDoc!)}
            userCanDelete={() => (user && activeDoc ? userCanDeleteDocument(user, activeDoc) : false)}
         />
         <UpsertDocumentDialog open={editingDocId !== null} documentIdToEdit={editingDocId} handleClose={() => setEditingDocId(null)} administrationForms={isPartnerSpace} />
         <DeleteDocumentDialog documentToDelete={deletingDoc} handleClose={() => setDeletingDoc(null)} />
      </>
   );
};

export default GlobalDocumentActions;
