import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Notice } from ".";

const GetUserNotices = () => {
   const noticesQuery = useQuery<{ userNotices: Notice[] }>(
      gql`
         query GetUserNotices {
            userNotices {
               name
               active
            }
         }
      `,
      {
         variables: {
         },
      },
   );

   const userNotices = React.useMemo(
      () => noticesQuery.data?.userNotices ?? [],
      [noticesQuery.data]
   );

   return React.useMemo(
      () => ({
         userLoading: noticesQuery.loading,
         userNotices: userNotices,
      }),
      [noticesQuery.loading, userNotices],
   );
};

export default GetUserNotices;
